import React from "react";
import { Route, RouteComponentProps, RouteProps } from "react-router";
import { useSelector } from "react-redux";
import ViewNotAllowed from "components/app/viewNotAllowed";
import { verifyPermisisons } from "utils/authUtils";
import { permissionsSelector } from "store/selectors/auth.selectors";
import { TRequiredPermission } from "models/Permissions";

interface Props extends RouteProps {
  requiredRole?: TRequiredPermission;
}

const RestrictedRoute = ({
  requiredRole,
  component: Component,
  ...rest
}: Props) => {
  const permissions = useSelector(permissionsSelector);
  return (
    <Route
      render={(props: RouteComponentProps<any>) => {
        if (Component) {
          return verifyPermisisons(permissions, requiredRole) ? (
            <Component {...props} />
          ) : (
            <ViewNotAllowed />
          );
        }
        return <ViewNotAllowed />;
      }}
      {...rest}
    />
  );
};

export default RestrictedRoute;
