import {
  IDataModel,
  INestedDataModel,
} from "components/common/ui/charts/chart";
import { AGE_DEF, ETHNICITY_DEF, MALIGNANT_DEF } from "models/insights/common";
import {
  INDICATOR_TYPES,
  indicatorDefinitionMap,
  indicatorKeys,
  TIndicatorItem,
} from "models/insights/QualityIndicators";
import { mapReduce, Reducer } from "utils/common";
import { formatChangeInNumber, formatPercent } from "utils/format/numberUtils";
import {
  IIndicatorBucketDef,
  qualityIndicatorBucketsDef,
} from "models/insights/QualityIndicatorBucketsDef";
import { getScale } from "utils/chartColorUtils";

export interface IIndicatorsDataNestedByOrgType {
  org: IDataModel[];
  cohort: IDataModel[];
}

export interface IQueryIndicator {
  byOrgType: IIndicatorsDataNestedByOrgType;
  byIndicatorKey: INestedDataModel[];
  max: number;
}

export type QueryIndicatorsDataShape = Record<string, IQueryIndicator>;
const reducer: Reducer<TIndicatorItem> = (d, i, k, m) =>
  (m[k] ? m[k] : 0) + d.indicator_value;
const bucketReducer: Reducer<IIndicatorBucketDef> = (b) =>
  b.group ? b.group : b.key;

export const rollupIndicatorsList = (data: {
  org: Record<string, TIndicatorItem[] | null>;
  all_orgs: Record<string, TIndicatorItem[] | null>;
}) =>
  indicatorKeys.reduce((output, k) => {
    const def = mapReduce(qualityIndicatorBucketsDef[k], "key", bucketReducer);
    const orgDataMap = mapReduce(
      data.org[k] || [],
      (d) => (d.indicator_key === "all" ? "all" : def[d.indicator_key]),
      reducer
    );

    const cohortDataMap = mapReduce(
      data.all_orgs[k] || [],
      (d) => (d.indicator_key === "all" ? "all" : def[d.indicator_key]),
      reducer
    );

    return {
      ...output,
      [k]: createFormattedDataset(k, orgDataMap, cohortDataMap),
    };
  }, {} as QueryIndicatorsDataShape);

export const rollupIndicatorDetail = (
  rawData: {
    org: TIndicatorItem[];
    all_orgs: TIndicatorItem[];
  },
  indicatorKey: string
) => {
  const buckets = qualityIndicatorBucketsDef[indicatorKey];
  const orgData = rollupDataArray(rawData.org || [], buckets);
  const cohortData = rollupDataArray(rawData.all_orgs || [], buckets);

  const total = createFormattedDataset(
    indicatorKey,
    orgData.total,
    cohortData.total
  );
  const ethnicity = rollupDimension(
    indicatorKey,
    orgData.ethnicity,
    cohortData.ethnicity,
    Object.entries(ETHNICITY_DEF)
  );
  const malignant = rollupDimension(
    indicatorKey,
    orgData.malignant,
    cohortData.malignant,
    Object.entries(MALIGNANT_DEF)
  );
  const sixty_five_plus = rollupDimension(
    indicatorKey,
    orgData.sixty_five_plus,
    cohortData.sixty_five_plus,
    Object.entries(AGE_DEF)
  );

  return {
    total,
    ethnicity,
    malignant,
    sixty_five_plus,
    map: {
      orgData,
      cohortData,
    },
    max: {
      ethnicity: Math.max(...ethnicity.map((k) => k.data.max)),
      malignant: Math.max(...malignant.map((k) => k.data.max)),
      sixty_five_plus: Math.max(...sixty_five_plus.map((k) => k.data.max)),
    },
  };
};

const extractCounts = (
  data: Record<string, TIndicatorItem[]>,
  type: "ethnicity" | "malignant" | "sixty_five_plus",
  bucketDef: Record<string, string>
) => {
  let def = [];
  const keyArr = ["all", "all", "all"];
  const MATCH_KEY = "KEY";

  switch (type) {
    case "ethnicity":
      def = Object.keys(ETHNICITY_DEF);
      keyArr[0] = MATCH_KEY;
      break;
    case "malignant":
      def = Object.keys(MALIGNANT_DEF);
      keyArr[1] = MATCH_KEY;
      break;
    case "sixty_five_plus":
      def = Object.keys(AGE_DEF);
      keyArr[2] = MATCH_KEY;
      break;
  }

  const key = keyArr.join("::");
  return mapReduce(
    def,
    (k) => k,
    (e) =>
      mapReduce(
        data[key.replace(MATCH_KEY, e)],
        (d) => (d.indicator_key === "all" ? "all" : bucketDef[d.indicator_key]),
        reducer
      )
  );
};

interface IndicatorDetailDataObj {
  total: Record<string, number>;
  ethnicity: Record<keyof ETHNICITY_DEF, Record<string, number>>;
  malignant: Record<keyof MALIGNANT_DEF, Record<string, number>>;
  sixty_five_plus: Record<keyof AGE_DEF, Record<string, number>>;
}

const rollupDataArray = (
  rawData: TIndicatorItem[],
  buckets: IIndicatorBucketDef[]
): IndicatorDetailDataObj => {
  const rolled = mapReduce(
    rawData,
    (d) => `${d.ethnicity}::${d.malignant}::${d.sixty_five_plus}`,
    (d, i, k, m) => (m[k] ? [...m[k], d] : [d])
  );
  const def = mapReduce(buckets, "key", bucketReducer);
  return {
    total: mapReduce(
      rolled["all::all::all"],
      (d) => (d.indicator_key === "all" ? "all" : def[d.indicator_key]),
      reducer
    ),
    ethnicity: extractCounts(rolled, "ethnicity", def),
    malignant: extractCounts(rolled, "malignant", def),
    sixty_five_plus: extractCounts(rolled, "sixty_five_plus", def),
  };
};

const rollupDimension = (
  indicatorKey: string,
  orgData: Record<string, Record<string, number>>,
  cohortData: Record<string, Record<string, number>>,
  def: [key: string, value: string][]
) =>
  def.map(([k, v]) => {
    const d = orgData[k];
    const c = cohortData[k];
    return {
      key: k,
      data: createFormattedDataset(indicatorKey, d, c),
      metadata: {
        label: v,
      },
    };
  });

const createFormattedDataset = (
  indicatorKey: string,
  org: Record<string, number>,
  cohort: Record<string, number>
): IQueryIndicator => {
  const iDef = indicatorDefinitionMap[indicatorKey];
  let buckets = qualityIndicatorBucketsDef[indicatorKey];
  let max = 0;

  const byIndicatorKey = [] as INestedDataModel[];
  const byOrgType = {
    org: [],
    cohort: [],
  } as IIndicatorsDataNestedByOrgType;
  const totalCohort = cohort["all"] || 1;
  const totalOrg = org["all"] || 1;
  if (buckets[0].group) {
    buckets = Object.values(
      mapReduce(buckets, "group", (b, i) => ({
        index: i,
        key: b.group,
        label: b.group,
        color: b.color,
      }))
    );
    buckets.sort((a, b) => (a.index || 1) - (b.index || 2));
  }
  const scale = getScale(iDef.theme, buckets.length);

  if (indicatorKey === "002") {
    const data002 = calculateIndicator002(org, cohort);
    byOrgType.org.push(data002.org);
    byOrgType.cohort.push(data002.cohort);

    byIndicatorKey.push({
      key: "002",
      data: [data002.cohort, data002.org],
      metadata: {
        label: iDef.short_title,
      },
    });
  } else if (indicatorKey === "019") {
    const data019 = calculateIndicator019(org, cohort);
    byOrgType.org = data019.byOrgType.org;
    byOrgType.cohort = data019.byOrgType.cohort;
    byIndicatorKey.push(...data019.byIndicatorKey);
  } else {
    buckets.forEach((b, i) => {
      // if (d === "all") return;
      const d = b.key;
      const o = org[d];
      const c = cohort[d];
      const orgValue = o ? o / totalOrg : 0;
      const cohortValue = c ? c / totalCohort : 0;

      const orgObj = {
        data: orgValue,
        // key: `${indicatorKey}::${d}::hospice`,
        key: `${d}::hospice`,
        metadata: {
          color:
            iDef.type === INDICATOR_TYPES.SINGLE && d === "false"
              ? "#eee"
              : b.color || scale(i).hex(),
          group: b.label,
          label: "Hospice",
          value: o ? formatPercent(orgValue) : undefined,
          numerator: o,
          demoninator: totalOrg,
          disabled: b.disabled,
        },
      };
      const cohortObj = {
        data: cohortValue,
        // key: `${indicatorKey}::${d}::cohort`,
        key: `${d}::cohort`,
        metadata: {
          color:
            iDef.type === INDICATOR_TYPES.SINGLE && d === "false"
              ? "#eee"
              : "#ccc",
          group: b.label,
          label: "Cohort",
          value: c ? formatPercent(cohortValue) : undefined,
          numerator: c,
          demoninator: totalCohort,
          disabled: iDef.type === INDICATOR_TYPES.MULTI ? true : b.disabled,
        },
      };

      byOrgType.org.push(orgObj);
      byOrgType.cohort.push(cohortObj);

      byIndicatorKey.push({
        key: d,
        data: [cohortObj, orgObj],
        metadata: {
          label: d,
        },
      });

      if (orgValue > max) {
        max = orgValue;
      }

      if (cohortValue > max) {
        max = cohortValue;
      }
    });
  }

  return {
    byOrgType,
    byIndicatorKey,
    max,
  };
};

const calc002 = (data: Record<string, number>) => {
  const patientsAliveAtStart = data["patient_alive"] || 0;
  const aliveAtStartAndDied = data["patient_died"] || 0;
  const admittedAndAlive = data["referral_admitted"] || 0;
  const admittedAndDied = data["referral_admitted_and_patient_died"] || 0;

  const initialPatients = patientsAliveAtStart + aliveAtStartAndDied;
  const finalPatients = patientsAliveAtStart + admittedAndAlive;

  const changeInPatients = finalPatients - initialPatients;

  return {
    data: initialPatients > 0 ? changeInPatients / initialPatients : 0,
    numerator: changeInPatients,
    demoninator: initialPatients,
    edges: [
      { source: "old", target: "alive", value: patientsAliveAtStart },
      { source: "old", target: "deaths", value: aliveAtStartAndDied },
      { source: "new", target: "alive", value: admittedAndAlive },
      { source: "new", target: "deaths", value: admittedAndDied },
    ],
  };
};

const calculateIndicator002 = (
  org: Record<string, number>,
  cohort: Record<string, number>
) => {
  const orgData = calc002(org);
  const cohortData = calc002(cohort);
  const orgObj = {
    data: orgData.data,
    key: `002::hopsice`,
    metadata: {
      color: orgData.data > 0 ? "#20c29d" : "#ff5674",
      group: "Change in patient volumes",
      label: "Hospice",
      value:
        orgData.demoninator > 0
          ? formatChangeInNumber(orgData.data, 0, { type: "percent" })
          : undefined,
      numerator: orgData.numerator,
      demoninator: orgData.demoninator,
      edges: orgData.edges.filter((e) => e.value !== 0),
    },
  };
  const cohortObj = {
    data: cohortData.data,
    key: `002::cohort`,
    metadata: {
      color: cohortData.data > 0 ? "green" : "red",
      group: "Change in patient volumes",
      label: "Cohort",
      value:
        cohortData.demoninator > 0
          ? formatChangeInNumber(cohortData.data, 0, { type: "percent" })
          : undefined,
      numerator: cohortData.numerator,
      demoninator: cohortData.demoninator,
      edges: cohortData.edges.filter((e) => e.value !== 0),
    },
  };

  return {
    org: orgObj,
    cohort: cohortObj,
  };
};

const calc019 = (data: Record<string, number>) => ({
  numerator: data["died_in_prev_four_year_periods"] || 0,
  demoninator: data["alive"] + data["died_in_prev_four_year_periods"] || 0,
  data:
    (data["died_in_prev_four_year_periods"] || 0) /
    (data["alive"] + data["died_in_prev_four_year_periods"] || 1),
});

const calculateIndicator019 = (
  org: Record<string, number>,
  cohort: Record<string, number>
) => {
  const orgData = calc019(org);
  const orgOutput = ["true", "false"].map((k) => {
    const v = k === "true" ? orgData.data : 1 - orgData.data;
    return {
      data: v,
      key: `${k}::hospice`,
      metadata: {
        // color: "#ff5674",
        group:
          k === "true"
            ? "Patients who died in the year"
            : "Patients who didn't die",
        label: "Hospice",
        value: formatPercent(v),
        numerator:
          k === "true"
            ? orgData.numerator
            : orgData.demoninator - orgData.numerator,
        demoninator: orgData.demoninator,
        disabled: k === "false",
        color: k === "false" ? "#eee" : "#231465",
      },
    };
  });

  const cohortData = calc019(cohort);
  const cohortOutput = ["true", "false"].map((k) => {
    const v = k === "true" ? cohortData.data : 1 - cohortData.data;
    return {
      data: v,
      key: `${k}::cohort`,
      metadata: {
        // color: "#ff5674",
        group:
          k === "true"
            ? "Patients who died in the year"
            : "Patients who didn't die",
        label: "Cohort",
        value: formatPercent(v),
        numerator:
          k === "true"
            ? cohortData.numerator
            : cohortData.demoninator - cohortData.numerator,
        demoninator: cohortData.demoninator,
        disabled: k === "false",
        color: k === "false" ? "#eee" : "#ccc",
      },
    };
  });

  return {
    byOrgType: {
      org: orgOutput,
      cohort: cohortOutput,
    },
    byIndicatorKey: [
      {
        key: "true",
        data: [cohortOutput[0], orgOutput[0]],
      },
      {
        key: "false",
        data: [cohortOutput[1], orgOutput[1]],
      },
    ],
  };
};
