import React from "react";
import { TUser, USER_LIST_FILTER_KEYS, USER_STATUS } from "models/User";
import { IconEye, IconUser } from "components/common/icons/twoToneIcons";
import Table from "components/common/ui/table/Table";
import { IColumn } from "components/common/ui/table/utils";
import { ADMIN_USER_DETAIL } from "constants/routes";
import { formatPhoneNumber } from "components/common/ui/form-elements/display-elements/FormattedPhoneNumber";
import { coreService } from "services/CoreService";
import Chip from "components/common/ui/chip/Chip";
import {
  IDimensions,
  TSetActiveBucket,
  TSetManyBucketsActive,
} from "models/Dimension";
import Container from "components/common/layouts/Container";
import ListFilters from "components/common/ui/filter/ListFilters";
import { IconSuspendUser } from "components/common/icons/IconUser";
import Accordian from "components/common/ui/accordian/Accordian";
import styles from "components/users/admin/list/UsersList.module.scss";

interface Props {
  users: TUser[];
  dimensions: IDimensions;
  setActive: TSetActiveBucket;
  setManyActive: TSetManyBucketsActive;
  isAppLoaded: boolean;
  loading?: boolean;
}

interface IData {
  active: TUser[];
  archived: TUser[];
}

const UsersList = ({
  users,
  loading,
  dimensions,
  setActive,
  setManyActive,
  isAppLoaded,
}: Props) => {
  const data = React.useMemo(
    () =>
      users.reduce(
        (d, u) => {
          if (u.active) {
            d.active.push(u);
          } else {
            d.archived.push(u);
          }
          return d;
        },
        { active: [], archived: [] } as IData
      ),
    [users]
  );
  const columns = [
    {
      id: "icon",
      label: " ",
      width: "50px",
      type: "icon",
      className: styles.iconCol,
      cell: (cm, row) =>
        row.active ? (
          <IconUser />
        ) : (
          <IconSuspendUser className={styles.danger} />
        ),
    },
    {
      id: "name",
      label: "Name",
      cell: (c, row: TUser) => `${row.firstname} ${row.surname}`,
      className: styles.clientColumn,
    },
    {
      label: "Email",
      id: "email",
    },
    {
      id: "phone",
      label: "Phone",
      cell: (c, row: TUser) => (row.phone ? formatPhoneNumber(row.phone) : ""),
    },
    {
      id: "status",
      label: "Status",
      className: styles.statusContainer,
      cell: (c, row: TUser) => getStatus(row),
    },
    {
      id: "link",
      label: " ",
      width: "50px",
      type: "icon",
      className: styles.iconCol,
      cell: () => <IconEye />,
    },
  ] as IColumn[];

  return (
    <div className={styles.root}>
      <Container spacing={"none"}>
        <ListFilters
          filters={USER_LIST_FILTER_KEYS}
          dimensions={dimensions}
          setActive={setActive}
          setManyActive={setManyActive}
          isLoading={!isAppLoaded}
          isUpdating={loading}
        />
      </Container>
      <Table
        className={styles.table}
        columns={columns}
        data={data.active}
        getRowProps={(row) => ({
          to: coreService.getRoute(ADMIN_USER_DETAIL, { id: row.id }),
        })}
        loading={loading}
        // loading={true}
        placeholder={{ noDataLabel: "No Users Found", loadingRows: 5 }}
      />
      {data.archived.length > 0 && (
        <Accordian
          headerClassName={styles.deletedAccordian}
          header={<h4>Suspended Users</h4>}
        >
          <Table
            className={styles.table}
            columns={columns}
            data={data.archived}
            getRowProps={(row) => ({
              to: coreService.getRoute(ADMIN_USER_DETAIL, { id: row.id }),
            })}
            loading={loading}
            placeholder={{
              noDataLabel: "No Suspended Users Found",
              loadingRows: 2,
            }}
          />
        </Accordian>
      )}
    </div>
  );
};

const getStatus = (user: TUser) => {
  if (!user.active) {
    return (
      <Chip condensed theme="danger" className={styles.status}>
        <b>SUSPENDED</b>
      </Chip>
    );
  }
  if (!user.status) {
    return "";
  }
  const text = USER_STATUS[user.status];
  switch (user.status) {
    case "FORCE_CHANGE_PASSWORD":
      return (
        <>
          <Chip condensed theme="warning" className={styles.status}>
            {text}
          </Chip>
          {/*<Tooltip content={"Resend Invite Email"} offset={6}>*/}
          {/*  <button*/}
          {/*    onClick={(e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      userService.resendInvitation(user);*/}
          {/*    }}*/}
          {/*    className={cx(styles.resend, styles.icon)}*/}
          {/*  >*/}
          {/*    <IconResendEmail />*/}
          {/*  </button>*/}
          {/*</Tooltip>*/}
        </>
      );
    case "RESET_REQUIRED":
      return (
        <Chip condensed theme="danger" className={styles.status}>
          {text}
        </Chip>
      );
    case "CONFIRMED":
      return (
        <>
          <Chip condensed theme="info" className={styles.status}>
            {text}
          </Chip>
          {/*<Tooltip content={"Send Password Reset"} offset={6}>*/}
          {/*  <button*/}
          {/*    onClick={(e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      userService.resetUserPassword(user);*/}
          {/*    }}*/}
          {/*    className={cx(styles.reset, styles.icon)}*/}
          {/*  >*/}
          {/*    <IconResetLock />*/}
          {/*  </button>*/}
          {/*</Tooltip>*/}
        </>
      );
    default:
      return (
        <Chip condensed theme="grey" className={styles.status}>
          {text}
        </Chip>
      );
    // return text;
  }
};

export default UsersList;
