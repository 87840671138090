import React from "react";
import cx from "classnames";
import ChartTooltip from "components/common/ui/charts/tooltip/ChartTooltip";
import { kebabCase } from "utils/format/stringUtils";
import { calcSeriesMax } from "utils/chartUtils";
import { RowChartBase } from "components/common/ui/charts/row/elements/RowBase";
import { RowChartProps } from "components/common/ui/charts/row/types";
import styles from "./RowChart.module.scss";
import { ResponsiveChartContainer } from "components/common/ui/charts/common/ChartContainer";

const RowChart = ({
  data,
  className,
  height,
  width,
  padding = 5,
  title,
  max,
  colorAccessor,
  theme = "sunset",
}: RowChartProps) => {
  // const [tooltipContent, setContent] = useState<IDataModel>({
  //   key: "",
  //   data: 0,
  //   metadata: {},
  // });
  const maxValue = calcSeriesMax(data, max);
  const rowHeight = height / data.length;

  return (
    <div className={cx(styles.root, className, styles[`${theme}Theme`])}>
      <ChartTooltip
        title={title || ""}
        items={data}
        colorAccessor={colorAccessor}
        theme={theme}
      >
        <svg width={width} height={height}>
          {data.map((d, i) => (
            <g
              // key={`g-${group.key}`}
              key={`g-${i}`}
              transform={`translate( 0, ${i * rowHeight})`}
              className={cx(
                styles.rowGroup,
                `RowChart_rowGroup-index-${i + 1}`
              )}
              // onMouseEnter={() => setContent(d)}
            >
              <rect width="100%" height={rowHeight} opacity="0" />
              <RowChartBase
                key={`row-${i}`}
                index={i}
                data={d}
                max={maxValue}
                className={`row-${kebabCase(d.key)}`}
                width={width}
                height={rowHeight - padding}
                y={0}
                colorAccessor={colorAccessor}
              />
            </g>
          ))}
        </svg>
      </ChartTooltip>
    </div>
  );
};

interface ResponsiveProps extends Omit<RowChartProps, "width" | "height"> {
  width?: number;
  height?: number;
}

export const ResponsiveRowChart = ({
  width: widthOverride,
  height: heightOverride,
  ...props
}: ResponsiveProps) => (
  <ResponsiveChartContainer height={heightOverride} width={widthOverride}>
    {/*@ts-ignore  - ResponsiveChartContainer will provide missing props*/}
    <RowChart {...props} />
  </ResponsiveChartContainer>
);

export default RowChart;
