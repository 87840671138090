import React, { useState } from "react";
import cx from "classnames";
import { ISchema, IValidationColumn } from "models/Schema";
import Accordian from "components/common/ui/accordian/Accordian";
import TextInput from "components/common/ui/form-elements/text-input/TextField";
import { fuzzyMatch } from "utils/filterUtils";
import Chip from "components/common/ui/chip/Chip";
import Timeline, {
  ActivityMarker,
} from "components/common/ui/timeline/Timeline";
import IconAsterisk from "components/common/icons/IconAsterisk";
import styles from "components/schema/detail/SchemaDetail.module.scss";
import {
  formatSchemaType,
  formatSchemaTypeCheck,
  formatSchemaValdiationStep,
  getErrorLevelProps,
} from "components/schema/detail/SchemaFormat";
import Button from "components/common/ui/button/Button";
import { IconExpandAllArrow } from "components/common/icons/IconExpandArrow";
import Select from "components/common/ui/select/Select";
import { IconProgressCircleWrap } from "components/common/icons/IconProgress";

interface Props {
  schema: ISchema;
}

const getColumns = (schema: ISchema, value: string, requiredOnly: boolean) =>
  Object.keys(schema).filter(
    (k) => fuzzyMatch(value, k) && (requiredOnly ? schema[k].required : true)
  );

export const SchemaDetail = ({ schema }: Props) => {
  const [value, setValue] = useState("");
  const [onlyRequired, setOnlyRequired] = useState("all");
  const [expandAll, setExpandAll] = useState(true);
  const columns = getColumns(schema, value, onlyRequired === "required");
  return (
    <div>
      <TextInput
        name="column-search"
        label="Search by Column name"
        value={value}
        onChange={(e: any) => setValue(e.target.value)}
        noInputError
      />
      <div className={styles.actions}>
        <Select
          name={"req-only"}
          value={onlyRequired}
          onSelect={(n, v) => setOnlyRequired(v)}
          label="Display"
          options={[
            { key: "all", label: "All Columns" },
            { key: "required", label: "Required Columns", color: "#ab195c" },
          ]}
          // multiSelect
          config={{
            inputChip: true,
            selectedOptionFormat: "chip",
            expandArrow: true,
            theme: "secondary",
            noInputError: true,
          }}
        />
        <div style={{ flexGrow: 1 }} />
        <Button
          variant="empty"
          theme="secondary"
          className={cx(styles.expandAll, expandAll && styles.expanded)}
          onClick={() => setExpandAll(!expandAll)}
          rounded
        >
          {expandAll ? "Collapse" : "Expand"} All
          <IconExpandAllArrow />
        </Button>
      </div>

      {columns.map((k) => (
        <SchemaField key={k} name={k} col={schema[k]} isOpen={expandAll} />
      ))}
    </div>
  );
};

const SchemaField = ({
  name,
  col,
  isOpen,
}: {
  name: string;
  col: IValidationColumn;
  isOpen: boolean;
}) => {
  const typeCheck = formatSchemaTypeCheck(
    col.type,
    col.treat_invalid_type_as,
    !col.treat_blank_value_as,
    col.format
  );
  return (
    <div className={styles.column}>
      <Accordian
        headerClassName={styles.header}
        header={
          <h4>
            {col.required && <IconAsterisk />}
            <span className={styles.name}>{name}</span>

            {col.required && <span className={styles.req}>Required</span>}
          </h4>
        }
        isOpen={isOpen}
      >
        <div className={styles.content}>
          <i>{col.description}</i>

          <div className={styles.row}>
            <span className={styles.attribute}>Type: </span>
            <Chip condensed theme="info">
              {formatSchemaType(col.type)}
            </Chip>
          </div>
          {(col.validation ||
            col.required ||
            col.treat_blank_value_as ||
            !!typeCheck) && (
            <>
              <div className={styles.attribute} style={{ marginBottom: 4 }}>
                Validation Steps:
              </div>
              <Timeline condensed>
                {col.required && (
                  <ActivityMarker
                    heading="Column Required"
                    description={
                      <>
                        A column named{" "}
                        <span className={styles.highlight}>{name}</span> must be
                        present in the uploaded file
                      </>
                    }
                    {...getErrorLevelProps.critical}
                  />
                )}
                {col.treat_blank_value_as ? (
                  <ActivityMarker
                    heading="No Blank Values"
                    description="Each cell in this column must contain a value"
                    {...getErrorLevelProps[col.treat_blank_value_as]}
                  />
                ) : (
                  <ActivityMarker
                    heading="Blank Values Allowed"
                    description="If a cell is blank (empty) then subsequent validation steps are skipped"
                    theme="grey"
                    icon={<IconProgressCircleWrap />}
                  />
                )}
                {!!typeCheck && <ActivityMarker {...typeCheck} />}
                {col.validation?.map((v) => (
                  <ActivityMarker {...formatSchemaValdiationStep(v)} />
                ))}
              </Timeline>
            </>
          )}
        </div>
      </Accordian>
    </div>
  );
};
