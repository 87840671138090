import React, { useState } from "react";
import { ICognitoUserAttributes } from "models/User";
import styles from "components/users/common/User.module.scss";
import UserDetailForm from "components/users/common/UserDetailForm";
import {
  getDefaultPermissions,
  permissionsOptions,
  permissionsTable,
} from "models/Permissions";
import { PermissionGroup } from "components/users/common/PermissionGroup";

interface Props {
  onSave: (attributes: ICognitoUserAttributes) => void;
  onCancel: () => void;
}

const CreateUser = ({ onSave, onCancel }: Props) => {
  const [roles, setRoles] = useState(getDefaultPermissions());
  const onSubmit = async (att: any) => {
    try {
      const newRoles = permissionsOptions.reduce((arr, p) => {
        return roles[p.value] ? [...arr, p.value] : arr;
      }, [] as string[]);
      await onSave({ ...att, roles: newRoles });
    } catch (e) {}
  };

  const updateRole = (key: string, value: boolean) => {
    setRoles({ ...roles, [key]: value });
  };
  const bulkUpdateRole = (newRoles: Record<string, boolean>) => {
    setRoles({ ...roles, ...newRoles });
  };

  return (
    <div className={styles.root}>
      <UserDetailForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        saveText={"Create User"}
        title={"User Details"}
      >
        <br />
        <hr />
        <h2>Permissions</h2>
        <div className={styles.permissionsTable}>
          {permissionsTable.map((section) => (
            <PermissionGroup
              key={section.label}
              section={section}
              editting={true}
              roles={roles}
              onChange={updateRole}
              onBulkChange={bulkUpdateRole}
            />
          ))}
        </div>
      </UserDetailForm>
    </div>
  );
};

export default CreateUser;
