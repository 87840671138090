import React from "react";
import { QueryMeasuresDataShape } from "components/insights/utils/measureUtils";
import { formatNumber, formatPercent } from "utils/format/numberUtils";
import Card from "components/common/ui/card/Card";
import { Headline, Highlight } from "components/insights/common/Headline";
import patientsImg from "./assets/patients.svg";
import referralsImg from "./assets/referrals.svg";
import contactsImg from "./assets/contacts.svg";
import deathsImg from "./assets/deaths.svg";
import ribbon from "./assets/ribbon.svg";
import Container from "components/common/layouts/Container";
import Button from "components/common/ui/button/Button";
import Meta from "components/common/Meta";
import styles from "./common/MeasureDetail.module.scss";
import { MeasureCard } from "components/insights/measures/MeasureCard";
import { chartPalettes, getChartColor } from "utils/chartColorUtils";

interface Props {
  data: QueryMeasuresDataShape;
  setTabState: (n: number) => void;
  period: string;
  showDataTables: boolean;
}

const SystemMeasureSummary = ({
  data,
  setTabState,
  showDataTables,
  period,
}: Props) => (
  <div>
    <Meta title={"System Measures"} />
    <Container>
      <p>
        The two main groupings are patient information and episodes of care, and
        patient contacts. This section allows an individual hospice to see how
        its volumes change over time and what demographic and clinical aspects
        are driving this. It also allows a deeper understanding of the
        relationships between the care being provided to different cohorts, the
        frequency of contact and the clinicians involved. <br /> <br />
        It supports individual hospices to know the trajectory of patients from
        referral to death, and plan improved care pathways.
        <br />
        <br />
        {/*The National view allows Hospice NZ to build on the yearly data they have historically had access to at a more frequent and granular level.*/}
      </p>
    </Container>
    <Card className={styles.summaryCard} shadow="soft">
      <div className={styles.period}>{period}</div>
      <Button
        className={styles.headlineContainer}
        variant="empty"
        onClick={() => setTabState(1)}
      >
        <img src={patientsImg} className={styles.headlineImg} alt="patients" />
        <Headline
          num={formatNumber(data.total.patients)}
          className={styles.headline}
          alwaysVertical
        >
          {/*people and their families and whanau were supported by <Highlight>{org.org_name}</Highlight>*/}
          Patients
        </Headline>
      </Button>

      <Button
        className={styles.headlineContainer}
        variant="empty"
        onClick={() => setTabState(2)}
      >
        <img
          src={referralsImg}
          className={styles.headlineImg}
          alt="referrals"
        />
        <Headline
          num={formatNumber(data.total.referrals)}
          className={styles.headline}
          alwaysVertical
        >
          New referrals
        </Headline>
      </Button>

      <Button
        className={styles.headlineContainer}
        variant="empty"
        onClick={() => setTabState(3)}
      >
        <img src={contactsImg} className={styles.headlineImg} alt="contacts" />
        <Headline
          num={formatNumber(data.total.contacts)}
          className={styles.headline}
          alwaysVertical
        >
          Contacts
        </Headline>
      </Button>

      <Button
        className={styles.headlineContainer}
        variant="empty"
        onClick={() => setTabState(4)}
      >
        <img src={deathsImg} className={styles.headlineImg} alt="deaths" />
        <Headline
          num={formatNumber(data.total.deaths)}
          className={styles.headline}
          alwaysVertical
        >
          Deaths
        </Headline>
      </Button>
      <img src={ribbon} className={styles.ribbon} alt="patients" />
    </Card>
    <Container>
      <h2>Services Provided</h2>
      <div className={styles.row}>
        <MeasureCard
          section={"Patients"}
          title="Principal Diagnosis"
          data={data["002"].data}
          showDataTable={showDataTables}
          colorAccessor={(d, i) =>
            d.metadata?.color || getChartColor(i, chartPalettes.Magma, 5)
          }
          description={
            <MeasureHeadline
              number={formatPercent(
                data["002"].data[0].data / (data.total.patients || 1)
              )}
              text={
                <>
                  of Patients had a <Highlight>Cancer diagnosis</Highlight>
                </>
              }
            />
          }
        />
        <MeasureCard
          section={"New Referrals"}
          title="Referral Source"
          data={data["009"].data}
          showDataTable={showDataTables}
          colorAccessor={(d, i) =>
            d.metadata?.color || getChartColor(i, chartPalettes.Mako, 4)
          }
          description={
            <MeasureHeadline
              number={formatPercent(
                data["009"].data[0].data / (data.total.referrals || 1)
              )}
              text={
                <>
                  of Referrals were from a{" "}
                  <Highlight>Hospital or Specialist</Highlight>
                </>
              }
            />
          }
        />
      </div>
      <div className={styles.row}>
        <MeasureCard
          section={"Contacts"}
          title="Contact Setting"
          data={data["028"].data}
          showDataTable={showDataTables}
          colorAccessor={(d, i) =>
            d.metadata?.color || getChartColor(i, chartPalettes.Viridis, 5)
          }
          description={
            <MeasureHeadline
              number={formatPercent(
                data["028"].data[0].data / (data.total.contacts || 1)
              )}
              text={
                <>
                  of Contacts were at the <Highlight>patient's home</Highlight>
                </>
              }
            />
          }
        />
        <MeasureCard
          section={"Deaths"}
          title="Place of Death"
          data={data["046"].data}
          showDataTable={showDataTables}
          theme="sunset"
          description={
            <MeasureHeadline
              number={formatPercent(
                (data["046"].data.find((d) => d.key === "Private residence")
                  ?.data || 1) / (data.total.deaths || 1)
              )}
              text={
                <>
                  of Deaths were at the <Highlight>patient's home</Highlight>
                </>
              }
            />
          }
        />
      </div>
      <h2>Who used Hospice Services</h2>
      <div className={styles.row}>
        <MeasureCard
          title={"Age"}
          section={"Current Patients"}
          data={data["003"].data}
          colorAccessor={(d, i) => getChartColor(i, chartPalettes.Viridis, 7)}
          showDataTable={showDataTables}
          description={
            <MeasureHeadline
              number={formatPercent(
                (data["003"].data[0].data +
                  data["003"].data[1].data +
                  data["003"].data[2].data) /
                  (data.total.patients || 1)
              )}
              text={
                <>
                  of Patients were <Highlight>under the age of 65</Highlight>
                </>
              }
            />
          }
        />
      </div>
      <div className={styles.row}>
        <MeasureCard
          title={"Ethnicity"}
          section={"Current Patients"}
          data={data["004"].data}
          chart="pie"
          colorAccessor={(d, i) => ["#413D7B", "#923390", "#ddd"][i]}
          showDataTable={showDataTables}
          showPieLabels={true}
          chartHeightOverride={350}
          description={
            <MeasureHeadline
              number={formatPercent(
                data["004"].data[0].data / (data.total.patients || 1)
              )}
              text={
                <>
                  of Patients <br className={styles.desktopBreak} />
                  identified as <Highlight>Māori</Highlight>
                </>
              }
            />
          }
        />
        <MeasureCard
          title={"Gender"}
          section={"Current Patients"}
          data={data["005"].data}
          chart="pie"
          colorAccessor={(d, i) => ["#4f3d7b", "#54a0dc", "#CD4C6E", "#bbb"][i]}
          showDataTable={showDataTables}
          showPieLabels={true}
          chartHeightOverride={350}
          description={
            <MeasureHeadline
              number={formatPercent(
                data["005"].data[0].data / (data.total.patients || 1)
              )}
              text={
                <>
                  of Patients <br className={styles.desktopBreak} />
                  identified as <Highlight>Male</Highlight>
                </>
              }
            />
          }
        />
        <MeasureCard
          title={"Living situation"}
          section={"Current Patients"}
          data={data["007"].data}
          chart="pie"
          colorAccessor={(d, i) => ["#413D7B", "#ddd"][i]}
          showDataTable={showDataTables}
          showPieLabels={true}
          chartHeightOverride={350}
          description={
            <MeasureHeadline
              number={formatPercent(
                data["007"].data[0].data / (data.total.patients || 1)
              )}
              text={
                <>
                  of Patients lived <br className={styles.desktopBreak} /> in{" "}
                  <Highlight>Residential Care</Highlight>
                </>
              }
            />
          }
        />
      </div>
    </Container>

    {/*  {data["002"].data.find((d) => (d.key = "Cancer"))?.data} of people using*/}
    {/*  hospice services had a cancer diagnosis*/}
  </div>
);

const MeasureHeadline = ({
  number,
  text,
}: {
  number: string | undefined;
  text: React.ReactNode;
}) => (
  <>
    <Headline num={number} size="small" className={styles.measureHeadline}>
      {text}
    </Headline>
    <hr className={styles.spacer} />
  </>
);

export default SystemMeasureSummary;
