import React from "react";
import cx from "classnames";
import InputFeedback, {
  TError,
} from "components/common/ui/form-elements/InputFeedback";
import {
  SkeletonBase,
  SkeletonText,
} from "components/common/ui/skeleton/Skeleton";
import styles from "components/common/ui/form-elements/text-input/TextField.module.scss";

export interface ITextInput {
  name: string;
  label: string;
  description?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  type?: "email" | "text" | "password" | "search" | "tel" | "url" | "date";
  error?: TError;
  placeholder?: string;
  hint?: string;
  alternative?: string;
  value?: string;
  defaultValue?: string;
  noInputError?: boolean;
  rounded?: boolean;
  condensed?: boolean;
  icon?: React.ComponentType<any>;
  [key: string]: any;
}

const TextInput = React.forwardRef(
  (
    {
      name,
      label,
      description,
      disabled,
      required,
      type = "text",
      className,
      error,
      placeholder,
      hint,
      alternative,
      value,
      defaultValue,
      noInputError,
      rounded,
      condensed,
      icon: Icon,
      ...rest
    }: ITextInput,
    ref: React.Ref<any>
  ) => {
    const defaultV: string | undefined =
      type === "date" && !!defaultValue
        ? new Date(defaultValue).toISOString().split("T")[0]
        : defaultValue;
    const props = defaultValue
      ? {
          defaultValue: defaultV,
        }
      : {};

    return (
      <div
        className={cx(
          className,
          error && styles.error,
          rounded && styles.rounded,
          condensed && styles.condensed,
          Icon && styles.iconInput
        )}
      >
        <label
          htmlFor={`text-input-${name}`}
          style={{ display: "block" }}
          className={styles.label}
          id={`text-input-label-${name}`}
        >
          {label}
          {label && description && <br />}
          {description && <span>{description}</span>}
        </label>

        <input
          id={`text-input-${name}`}
          type={type}
          name={name}
          value={value}
          disabled={disabled}
          className={styles.input}
          ref={ref}
          required={required}
          placeholder={placeholder}
          aria-labelledby={`text-input-label-${name}`}
          aria-describedby={error && `error-${name}-${error?.type}`}
          aria-invalid={!!error}
          tabIndex={disabled ? -1 : undefined}
          {...props}
          {...rest}
        />
        {Icon && (
          <span className={styles.icon}>
            <Icon />
          </span>
        )}
        {hint && <div className={styles.hint}>{hint}</div>}
        {!noInputError && (
          <InputFeedback error={error} visible={!!error} name={name} />
        )}
      </div>
    );
  }
);

interface ISkeleton extends Omit<ITextInput, "name" | "label"> {
  name?: string;
  label?: string;
}

export const SkeletonTextInput = ({
  className,
  skeletonClassName,
  rounded,
  condensed,
  icon: Icon,
  ...rest
}: ISkeleton) => (
  <div
    className={cx(
      styles.skeleton,
      rounded && styles.rounded,
      Icon && styles.iconInput,
      condensed && styles.condensed,
      className
    )}
  >
    <div className={styles.label}>
      <SkeletonText />
    </div>
    <SkeletonBase {...rest} className={cx(styles.input, skeletonClassName)} />
    {Icon && (
      <span className={styles.icon}>
        <Icon />
      </span>
    )}
  </div>
);

export default TextInput;
