import React from "react";
import cx from "classnames";
import {
  ISkeleton,
  SkeletonBase,
} from "components/common/ui/skeleton/Skeleton";
import styles from "./Chip.module.scss";

export interface IChip {
  theme?: ThemeVariants;
  variant?: FillVariants;
  className?: string;
  icon?: React.ReactNode;
  iconVariant?: FillVariants;
  iconClassName?: string;
  border?: "dark" | "light";
  style?: Record<string, any>;
  iconStyle?: Record<string, any>;
  onClick?: (e: any) => void;
  disabled?: boolean;
  condensed?: boolean;
  children: React.ReactNode;
}

const getVariant = (v?: string, prefix = "variant" as "icon" | "variant") =>
  v && styles[`${prefix}${v.charAt(0).toUpperCase() + v.slice(1)}`];

const Chip = ({
  children,
  theme = "default",
  variant = "lightFilled",
  border,
  icon,
  iconVariant,
  className,
  style,
  iconStyle,
  iconClassName,
  onClick,
  condensed = false,
  disabled = false,
}: IChip) => {
  const content = icon ? (
    <div className={styles.content}>
      <IconChip
        theme={theme}
        variant={iconVariant}
        className={iconClassName}
        style={iconStyle}
      >
        {icon}
      </IconChip>
      {children}
    </div>
  ) : (
    children
  );
  return onClick ? (
    <button
      className={cx(
        styles.chip,
        styles[theme],
        getVariant(variant),
        icon && styles.chipIcon,
        border && styles[`${border}Outline`],
        disabled && styles.disabled,
        condensed && styles.condensed,
        styles.clickable,
        className
      )}
      onClick={!disabled ? onClick : undefined}
      style={style}
    >
      {content}
    </button>
  ) : (
    <div
      className={cx(
        styles.chip,
        styles[theme],
        getVariant(variant),
        icon && styles.chipIcon,
        border && styles[`${border}Outline`],
        disabled && styles.disabled,
        condensed && styles.condensed,
        className
      )}
      style={style}
    >
      {content}
    </div>
  );
};

export interface IIconChip {
  theme?: ThemeVariants;
  variant?: FillVariants;
  className?: string;
  style?: Record<string, any>;
  children: React.ReactNode;
}

export const IconChip = ({
  theme = "default",
  variant = "filled",
  className,
  style,
  children,
}: IIconChip) => (
  <div
    className={cx(
      styles[theme],
      styles.icon,
      getVariant(variant, "icon"),
      className
    )}
    style={style}
  >
    {children}
  </div>
);

interface ChipProps extends ISkeleton {
  condensed?: boolean;
  width?: string | number;
  chipVariant?: FillVariants;
  skeletonClassName?: string;
  border?: "dark" | "light";
}

export const SkeletonChip = ({
  className,
  skeletonClassName,
  condensed,
  chipVariant = "lightFilled",
  border,
  width,
  children,
  ...rest
}: ChipProps) => (
  <div
    className={cx(
      styles.chip,
      styles.skeletonChip,
      getVariant(chipVariant),
      border && styles[`${border}Outline`],
      condensed && styles.condensed,
      className
    )}
  >
    {children}
    <SkeletonBase
      {...rest}
      className={cx(styles.skeleton, skeletonClassName)}
      style={{ width: width }}
    />
  </div>
);

export default Chip;
