import { ChartTheme, IDataModel } from "components/common/ui/charts/chart";
import Card from "components/common/ui/card/Card";
import styles from "components/insights/measures/common/MeasureDetail.module.scss";
import { ResponsiveRowChart } from "components/common/ui/charts/row/RowChart";
import React from "react";
import { DataSummaryTable } from "components/insights/common/DataSummaryTable";
import { DonutChart } from "components/common/ui/charts/pie/DonutChart";
import IconDonutChart from "components/common/icons/IconDonutChart";
import IconBarChart from "components/common/icons/IconBarChart";
import ConditionalLogicWrapper from "components/common/ui/form-elements/ConditionalLogicWrapper";

interface MeasureCardProps {
  section: string;
  title: string;
  description?: React.ReactNode;
  data: IDataModel[];
  theme?: ChartTheme;
  colorAccessor?: (d: IDataModel, i: number) => string;
  chart?: "row" | "pie";
  showDataTable?: boolean;
  showPieLabels?: boolean;
  chartHeightOverride?: number;
}

const defaultColorAccessor = (d: IDataModel) => d.metadata?.color || "#ccc";

export const MeasureCard = ({
  section,
  title,
  description,
  data,
  theme,
  colorAccessor,
  chart = "row",
  showDataTable = true,
  showPieLabels,
  chartHeightOverride,
}: MeasureCardProps) => (
  <Card shadow="soft" className={styles.rowCard}>
    <h3 className={styles.title}>{title}</h3>
    <p>{section}</p>
    {description}

    <div
      className={styles.chartWrapper}
      style={{ height: chartHeightOverride }}
    >
      {chart === "row" && (
        <ResponsiveRowChart
          data={data}
          theme={theme}
          colorAccessor={colorAccessor}
        />
      )}
      {chart === "pie" && (
        <DonutChart
          colorAccessor={colorAccessor || defaultColorAccessor}
          data={data}
          tooltip={{
            title: "",
            items: data,
          }}
          showLabel={showPieLabels}
        />
      )}
    </div>
    <ConditionalLogicWrapper
      value={`${showDataTable}`}
      logic={() => showDataTable}
    >
      <DataSummaryTable
        data={data}
        includeColor
        categoryTitle={title}
        colorAccessor={colorAccessor}
        theme={theme}
        dividerIcon={chart === "pie" ? <IconDonutChart /> : <IconBarChart />}
      />
    </ConditionalLogicWrapper>
  </Card>
);
