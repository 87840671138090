import React, { FC } from "react";
import cx from "classnames";

import styles from "components/common/icons/icon.module.scss";

interface TIcon {
  className?: string;
  color?: string;
  size?: string;
}
const createIcon = (content: React.ReactNode, name: string) => {
  let Icon: FC<TIcon> = (props) => (
    <svg
      className={cx(styles.root, props.className)}
      viewBox="0 0 24 24"
      style={{ fill: props.color }}
    >
      {content}
    </svg>
  );
  Icon.displayName = `${name}Icon`;

  return React.memo(Icon);
};

export default createIcon;
