import React from "react";
import { useDimensions } from "hooks/useDimensions";
import styles from "./ChartContainer.module.scss";

interface ResponsiveProps extends Omit<any, "width" | "height"> {
  width?: number;
  height?: number;
}

export const ResponsiveChartContainer = ({
  children,
  width: widthOverride,
  height: heightOverride,
  ...props
}: ResponsiveProps) => {
  const { observe, width, height } = useDimensions();
  const style = {
    width: widthOverride || "100%",
    height: heightOverride || "100%",
  };

  return (
    <div ref={observe} style={style} className={styles.root}>
      <div className={styles.container}>
        {/*{children({ ...props, width: width, height: height })}*/}
        {React.cloneElement(children, {
          ...props,
          width: width,
          height: height,
        })}
      </div>
    </div>
  );
};
