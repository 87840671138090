import React, { useState } from "react";
import cx from "classnames";
import { INestedDataModel } from "components/common/ui/charts/chart";
import ChartTooltip from "components/common/ui/charts/tooltip/ChartTooltip";
import styles from "./RowChart.module.scss";
import { kebabCase } from "utils/format/stringUtils";
import { useDimensions } from "hooks/useDimensions";
import { calcNestedSeriesMax } from "utils/chartUtils";
import { RowChartBase } from "components/common/ui/charts/row/elements/RowBase";
import { MultiseriesRowChartProps } from "components/common/ui/charts/row/types";

const getRowCoords = (
  rowHeight: number,
  offset: number,
  index: number,
  padding: number,
  overlap: boolean
) => ({
  height: overlap ? rowHeight * (1 - offset * index) : rowHeight,
  y: overlap ? rowHeight * index * (offset / 2) : (rowHeight + padding) * index,
});

const calcRowHeight = (
  gHeight: number,
  groupPadding: number,
  padding: number,
  overlap: boolean,
  numberOfRows = 1
) => {
  const effectiveHeight = gHeight - groupPadding;
  const paddedHeight = effectiveHeight - padding * numberOfRows;

  return overlap ? effectiveHeight : paddedHeight / numberOfRows;
};

const MultiseriesRowChart = ({
  data,
  className,
  height,
  width,
  offset = 0.2,
  padding = 0,
  groupPadding = 5,
  max,
  overlap = true,
  colorAccessor,
  theme = "sunset",
}: MultiseriesRowChartProps) => {
  const [tooltipContent, setContent] = useState<INestedDataModel>({
    key: "",
    data: [],
    metadata: {},
  });
  const maxValue = calcNestedSeriesMax(data, max);
  const gHeight = height / data.length;
  const rowHeight = calcRowHeight(
    gHeight,
    groupPadding,
    padding,
    overlap,
    data[0]?.data.length
  );
  return (
    <div className={cx(styles.root, className, styles[`${theme}Theme`])}>
      <ChartTooltip
        title={tooltipContent.metadata?.label || tooltipContent.key}
        items={tooltipContent.data}
        colorAccessor={colorAccessor}
        theme={theme}
      >
        <svg width={width} height={height}>
          {data.map((group, i) => (
            <g
              // key={`g-${group.key}`}
              key={`g-${i}`}
              transform={`translate( 0, ${i * gHeight})`}
              onMouseEnter={() => setContent(group)}
              className={`RowChart_rowGroup-index-${i + 1}`}
            >
              <rect width="100%" height={gHeight} opacity="0" />
              {group.data.map((d, j) => (
                <RowChartBase
                  key={`row-${j}`}
                  data={d}
                  max={maxValue}
                  width={width}
                  index={i}
                  className={`row-${kebabCase(d.key)}`}
                  colorAccessor={colorAccessor}
                  {...getRowCoords(rowHeight, offset, j, padding, overlap)}
                />
              ))}
            </g>
          ))}
        </svg>
      </ChartTooltip>
    </div>
  );
};

interface ResponsiveProps
  extends Omit<MultiseriesRowChartProps, "width" | "height"> {
  width?: number;
  height?: number;
}

export const ResponsiveMultiseriesRowChart = ({
  width: widthOverride,
  height: heightOverride,
  ...props
}: ResponsiveProps) => {
  const { observe, width, height } = useDimensions();
  const style = {
    width: widthOverride || "100%",
    height: heightOverride || "100%",
  };

  return (
    <div ref={observe} style={style} className={styles.responsiveContainer}>
      <MultiseriesRowChart {...props} width={width} height={height} />
    </div>
  );
};

export default MultiseriesRowChart;
