import { request } from "utils/http/Request";
import { getApiUrl, ORG_API } from "constants/paths";
import { createOrg, TOrg } from "models/Org";

export const fetchOneById = async (
  orgId: string,
  options?: Record<string, any>
): Promise<TOrg> => {
  const data = await request.get(getApiUrl(ORG_API, { orgId }), options);
  return createOrg(data);
};

export const fetchManyById = async (
  orgIds: string[],
  options?: Record<string, any>
) => {
  return Promise.all(orgIds.map((orgId) => fetchOneById(orgId, options)));
};
