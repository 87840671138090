import React from "react";
import styles from "components/insights/indicators/QualityIndicatorDetail.module.scss";
import Container from "components/common/layouts/Container";
import { LinearGauge } from "components/common/ui/charts/gauge/LinearGauge";
import {
  INDICATOR_TYPES,
  indicatorDefinitionMap,
} from "models/insights/QualityIndicators";
import { AGE_DEF, ETHNICITY_DEF, MALIGNANT_DEF } from "models/insights/common";
import { useSelector } from "react-redux";
import {
  currentOrgSelector,
  isAppLoadedSelector,
} from "store/selectors/core.selectors";
import { useGetQualIndicatorDetail } from "components/insights/hooks/useQualityIndicatorDetail";
import { IndicatorHeadline } from "components/insights/indicators/indicator-detail/qualityIndicatorHeadlines";
import { QualIndicatorHeadlineWave } from "components/insights/indicators/indicator-detail/QualIndicatorHeadlineWave";
import { DataSummaryTable } from "components/insights/common/DataSummaryTable";
import { QualityIndicatorChart } from "components/insights/indicators/indicator-detail/QualityIndicatorChart";
import { ChangeInPatientsHeadline } from "components/insights/indicators/indicator-detail/ChangeInPatientsHeadline";
import { insightsFiltersSelector } from "store/selectors/insights.selectors";

interface Props {
  orgId: string;
  // isLoading: boolean;
  indicatorKey: string;
}

const QualityIndicatorDetail = ({ indicatorKey, orgId }: Props) => {
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const filters = useSelector(insightsFiltersSelector);
  const org = useSelector(currentOrgSelector);
  const { isLoading, data } = useGetQualIndicatorDetail(
    indicatorKey,
    orgId,
    {
      org_region:
        filters.org_region === "region" ? org.org_region : filters.org_region,
      year_period: filters.period,
    },
    { enabled: isAppLoaded, staleTime: 0 }
  );
  const def = indicatorDefinitionMap[indicatorKey];

  return (
    <div className={styles.contentWrapper}>
      <Container className={styles.content}>
        <h2>{def.long_title}</h2>
        <p>{def.long_description}</p>
      </Container>
      <div className={styles.pageGauge}>
        <LinearGauge
          height={6}
          data={data?.total.byOrgType.org}
          // data={{ data: currentIndicator.length * 4, key: "test" }}
          // disabled
          rounded={false}
        />
      </div>

      <div className={styles.headlineContainer}>
        <Container className={styles.headline}>
          <div className={styles.headlineText}>
            <IndicatorHeadline
              indicator={indicatorKey}
              data={data?.total.byOrgType.org}
            />
            <DataSummaryTable
              data={data?.total.byOrgType.org}
              includeColor={def.type === INDICATOR_TYPES.MULTI}
            />
          </div>

          <div className={styles.headlineChart}>
            <QualityIndicatorChart
              headline
              isLoading={isLoading}
              type={def.type}
              title={def.short_title}
              data={data?.total}
            />
          </div>
        </Container>
        <QualIndicatorHeadlineWave className={styles.headlineWave} />
      </div>

      <div>
        <Container>
          <h2 className={styles.demoTitle}>Demographics</h2>
          <div className={styles.card}>
            <h3 className={styles.title}>Age</h3>
            <p>{def.short_description}</p>
            <div className={styles.chartsWrapper}>
              {Object.entries(AGE_DEF).map(([k, v], i) => (
                <div className={styles.chart2} key={k}>
                  <h4>{v}</h4>
                  {def.type !== INDICATOR_TYPES.MULTI && (
                    <span>
                      <ChangeInPatientsHeadline
                        org={data?.sixty_five_plus[i].data.byOrgType.org[0]}
                        cohort={
                          data?.sixty_five_plus[i].data.byOrgType.cohort[0]
                        }
                        arrow={indicatorKey === "002"}
                      />
                      {/*<QualityIndicatorTable*/}
                      {/*  categoryTitle="Source"*/}
                      {/*  unitTitle="Percent Change"*/}
                      {/*  keyAccessor={(d: IDataModel) =>*/}
                      {/*    d.metadata?.label || d.key*/}
                      {/*  }*/}
                      {/*  valueAccessor={(d: IDataModel) =>*/}
                      {/*    d.metadata?.value || d.data*/}
                      {/*  }*/}
                      {/*  data={*/}
                      {/*    data*/}
                      {/*      ? [*/}
                      {/*          data?.sixty_five_plus[i].data.byOrgType.org[0],*/}
                      {/*          data?.sixty_five_plus[i].data.byOrgType*/}
                      {/*            .cohort[0],*/}
                      {/*        ]*/}
                      {/*      : []*/}
                      {/*  }*/}
                      {/*/>*/}
                    </span>
                  )}
                  <QualityIndicatorChart
                    key={k}
                    isLoading={isLoading}
                    type={def.type}
                    title={v}
                    data={data?.sixty_five_plus[i].data}
                    max={data?.max.sixty_five_plus}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={styles.card}>
            <h3 className={styles.title}>Ethnicity</h3>
            <p>{def.short_description}</p>
            <div className={styles.chartsWrapper}>
              {Object.entries(ETHNICITY_DEF).map(([k, v], i) => (
                <div className={styles.chart} key={k}>
                  <h4>{v}</h4>
                  {def.type !== INDICATOR_TYPES.MULTI && (
                    <span>
                      <ChangeInPatientsHeadline
                        org={data?.ethnicity[i].data.byOrgType.org[0]}
                        cohort={data?.ethnicity[i].data.byOrgType.cohort[0]}
                        arrow={indicatorKey === "002"}
                      />
                    </span>
                  )}
                  <QualityIndicatorChart
                    key={k}
                    headline
                    isLoading={isLoading}
                    type={def.type}
                    title={v}
                    data={data?.ethnicity[i].data}
                    max={data?.max.ethnicity}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className={styles.card}>
            <h3 className={styles.title}>Malignant Conditions</h3>
            <p>{def.short_description}</p>
            <div className={styles.chartsWrapper}>
              {Object.entries(MALIGNANT_DEF).map(([k, v], i) => (
                <div className={styles.chart} key={k}>
                  <h4>{v}</h4>
                  {def.type !== INDICATOR_TYPES.MULTI && (
                    <span>
                      <ChangeInPatientsHeadline
                        org={data?.malignant[i].data.byOrgType.org[0]}
                        cohort={data?.malignant[i].data.byOrgType.cohort[0]}
                        arrow={indicatorKey === "002"}
                      />
                    </span>
                  )}
                  <QualityIndicatorChart
                    key={k}
                    isLoading={isLoading}
                    type={def.type}
                    title={v}
                    data={data?.malignant[i].data}
                    max={data?.max.malignant}
                  />
                </div>
              ))}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default QualityIndicatorDetail;
