import React, { useState } from "react";
import { formatUnixTime, minutesDiff } from "utils/format/dateUtils";
import { IUploadError, TUpload, UPLOAD_PERIOD_OPTIONS } from "models/Upload";
import { ISchema } from "models/Schema";
import { formatNumber } from "utils/format/numberUtils";
import { pascalCase } from "utils/format/stringUtils";
import Container from "components/common/layouts/Container";
import Accordian from "components/common/ui/accordian/Accordian";
import { UploadHistory } from "components/upload/detail/UploadHistory";
import { FileStatus } from "components/upload/detail/FileStatus";
import { ColumnStatus } from "components/upload/detail/UploadColumnStatus";
import {
  IconDanger,
  IconError,
  IconSuccess,
  IconWarning,
} from "components/common/icons/twoToneIcons";
import { IconProgressQuestion } from "components/common/icons/IconProgress";
import Chip from "components/common/ui/chip/Chip";
import Button from "components/common/ui/button/Button";
import Modal from "components/common/ui/modal/Modal";
import ErrorDefinitions from "components/upload/common/ErrorDefinitions";
import ActionButton from "components/common/ui/button/ActionButton";
import IconDelete, { IconRestore } from "components/common/icons/IconDelete";
import HintBox from "components/common/ui/hint-box/HintBox";
import Tooltip from "components/common/ui/tooltip/Tooltip";
import styles from "components/upload/detail/UploadDetail.module.scss";

const defaultPeriod = { key: "xxx", label: "Unknown" };

interface Props {
  upload: TUpload;
  schema: ISchema;
  onSelect: () => Promise<boolean>;
  onArchive: () => Promise<boolean>;
  children?: React.ReactNode;
}

export const UploadDetail = ({
  upload,
  schema,
  onSelect,
  onArchive,
}: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openArchiveModal, setArchiveModalOpen] = useState(false);
  const {
    original_filename,
    rows_scanned,
    errors_by_column,
    errors_by_level,
    columns_scanned,
    errors_total,
    perform_validation_completed,
    selected,
    period,
    year,
  } = upload;
  const progress = getProgress(upload);
  const status = getStatus(
    errors_by_level || {},
    rows_scanned,
    selected,
    progress === "inprogress"
  );

  const onClick = async () => {
    setSubmitting(true);
    if (status.theme !== "success") {
      setModalOpen(true);
      setArchiveModalOpen(false);
    } else {
      selectUpload();
    }
  };
  const closeModal = () => {
    setModalOpen(false);
    setSubmitting(false);
  };
  const selectUpload = async () => {
    setModalOpen(false);
    await onSelect();
    setSubmitting(false);
  };

  const archiveUpload = async () => {
    setSubmitting(true);
    setArchiveModalOpen(false);
    await onArchive();
    setSubmitting(false);
  };

  return (
    <div>
      <Container className={styles.header} spacing="large" spacingTopOnly>
        <div className={styles.headerText}>
          <h2>Upload Report</h2>
          <h1>{original_filename}</h1>
        </div>
        {/*<div className={styles.actions}>*/}
        {/*  <Chip {...status} variant="lightFilled">*/}
        {/*    <span className={styles.label}>Status:</span> {children}*/}
        {/*  </Chip>*/}
        {/*  {["warning", "info", "success"].includes(status.theme) && !selected && (*/}
        {/*    <Button theme="success" rounded onClick={onSelect}>*/}
        {/*      Set as Final for {period} {year}*/}
        {/*    </Button>*/}
        {/*  )}*/}
        {/*</div>*/}
      </Container>
      <div className={styles.fileDetail}>
        <Container>
          {selected && (
            <HintBox className={styles.selected} theme="success">
              <IconSuccess /> Final upload for {period} {year}
            </HintBox>
          )}
          {upload.archived && (
            <HintBox className={styles.selected} theme="danger">
              <IconDelete /> Deleted Upload
            </HintBox>
          )}
          <div className={styles.fileDetailWrapper}>
            <FileDetails {...upload} />
            <div className={styles.actions}>
              <div className={styles.row}>
                <Chip
                  theme={status.theme}
                  icon={status.icon}
                  variant="lightFilled"
                  // border="light"
                >
                  <span className={styles.statusLabel}>Status:</span>{" "}
                  {status.label}
                </Chip>
                {!selected && (
                  <Tooltip
                    content={
                      !upload.archived
                        ? "Delete Upload"
                        : "Restore Deleted Upload"
                    }
                    offset={8}
                  >
                    <ActionButton
                      rounded
                      theme={!upload.archived ? "danger" : "info"}
                      // variant="lightFilled"
                      icon={!upload.archived ? <IconDelete /> : <IconRestore />}
                      onClick={() => setArchiveModalOpen(true)}
                    />
                  </Tooltip>
                )}
              </div>
              {["warning", "info", "success"].includes(status.theme) &&
                !selected && (
                  <Button
                    theme="primary"
                    rounded
                    onClick={onClick}
                    submitting={submitting}
                  >
                    Select as Final for {period} {year}
                  </Button>
                )}
            </div>
          </div>
        </Container>

        <Container>
          <Accordian
            header={"Upload History:"}
            isOpen
            bottomBorder
            className={styles.timelineContainer}
            headerClassName={styles.timelineAccordian}
          >
            {upload.events && (
              <UploadHistory
                upload={upload}
                className={styles.timeline}
                progress={getProgress(upload)}
              />
            )}
          </Accordian>
        </Container>
      </div>

      {perform_validation_completed ? (
        <Container>
          <h2 className={styles.subheader}>Validation Report</h2>
          <FileStatus
            filename={original_filename}
            rows={rows_scanned}
            errors={errors_by_level}
            errorTotal={errors_total}
            orgId={upload.org_id}
            uploadId={upload.upload_id}
          />
          {rows_scanned > 0 && (
            <ColumnStatus
              schema={schema}
              columnsScanned={columns_scanned || []}
              errorsByColumn={errors_by_column}
            />
          )}
        </Container>
      ) : (
        <></>
      )}
      <Modal open={modalOpen} onClose={closeModal} className={styles.modal}>
        <div className={styles.content}>
          <h3>This file contains {formatNumber(errors_total)} issue(s)</h3>
          <>
            Are you sure you want to set this as the Final data for {period}{" "}
            {year}?
          </>
          <br /> <br />
          <ErrorDefinitions errors={errors_by_level} hideZero />
        </div>

        <div className={styles.actions}>
          <Button variant="empty" onClick={closeModal}>
            Cancel
          </Button>
          <Button rounded theme="success" onClick={selectUpload}>
            Set Upload as Final
          </Button>
        </div>
      </Modal>
      <ArchiveModal
        onArchive={archiveUpload}
        openArchiveModal={openArchiveModal}
        setArchiveModalOpen={setArchiveModalOpen}
        active={!upload.archived}
      />
    </div>
  );
};

const FileDetails = ({
  schema_name,
  period,
  year,
  created,
  uploaded_by_email,
}: Partial<TUpload>) => {
  const periodOption =
    UPLOAD_PERIOD_OPTIONS.find((k) => k.key === period) || defaultPeriod;
  return (
    <div>
      <div className={styles.row}>
        <div className={styles.label}>Schema:</div>
        <div className={styles.value}>{pascalCase(schema_name || "")}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Time Period:</div>
        <div className={styles.value}>
          {periodOption.label} {year}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Upload Date:</div>
        <div className={styles.value}>
          {formatUnixTime(+(created || 0), "do MMM yyyy 'at' p")}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Uploaded By:</div>
        <div className={styles.value}>{uploaded_by_email}</div>
      </div>
    </div>
  );
};

const ArchiveModal = ({
  onArchive,
  openArchiveModal,
  setArchiveModalOpen,
  active,
}: {
  active: boolean;
  openArchiveModal: boolean;
  setArchiveModalOpen: (s: boolean) => void;
  onArchive: () => Promise<void>;
}) => (
  <Modal
    open={openArchiveModal}
    onClose={() => setArchiveModalOpen(false)}
    className={styles.modal}
  >
    <div className={styles.modalContent}>
      <h1>{active ? "Delete" : "Restore"} Upload</h1>
      {active ? (
        <HintBox theme="danger">
          A deleted upload will be removed from the uploaded list and cannot be
          selected as the final upload for a given time period.
        </HintBox>
      ) : (
        <HintBox theme="info">
          This upload is currently marked as deleted. If this was by accident
          you can restore the upload to the normal status.
        </HintBox>
      )}
      <br />
      <br />
      Are you sure you want to {active ? "delete" : "restore"} this upload?
    </div>
    <div className={styles.actions}>
      <Button onClick={() => setArchiveModalOpen(false)} variant="empty">
        Cancel
      </Button>
      <Button onClick={onArchive} theme={active ? "danger" : "info"} rounded>
        {active ? "Delete" : "Restore"} Upload
      </Button>
    </div>
  </Modal>
);

const getStatus = (
  { warning = 0, error = 0, critical = 0 }: IUploadError,
  row_scanned?: number,
  selected?: boolean,
  inprogress?: boolean
): {
  theme: ThemeVariants;
  icon: React.ReactNode;
  label: React.ReactNode;
} => {
  if (selected) {
    return {
      theme: "success",
      icon: <IconSuccess />,
      label: "Final Upload",
    };
  }
  if (inprogress) {
    return {
      theme: "grey",
      icon: <IconProgressQuestion />,
      label: "In Progress",
    };
  }
  if (row_scanned === 0) {
    return {
      theme: "danger",
      icon: <IconDanger />,
      label: "No Data",
    };
  }
  switch (true) {
    case critical > 0:
      return {
        theme: "danger",
        icon: <IconDanger />,
        label: "Failed Validation",
      };

    case error > 0:
      return {
        theme: "warning",
        icon: <IconError />,
        label: "Success with Errors",
      };
    case warning > 0:
      return {
        theme: "info",
        icon: <IconWarning />,
        label: "Success with Warnings",
      };

    default:
      return {
        theme: "success",
        icon: <IconSuccess />,
        label: "Success",
      };
  }
};

const getProgress = (upload: TUpload) => {
  if (
    upload.events.find((e) => e.event_name === "Clean Up Validation Completed")
  ) {
    return "done";
  } else if (checkUploadExpired(upload.created)) {
    return "expired";
  }
  return "inprogress";
};

const checkUploadExpired = (created: number) => {
  return minutesDiff(created) > 20;
};
