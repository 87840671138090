import {
  MeasureKey,
  measureKeys,
  TMeasureItem,
} from "models/insights/SystemMeasures";
import { IDataModel } from "components/common/ui/charts/chart";
import { mapReduce } from "utils/common";
import { systemMeasureBucketsDef } from "models/insights/SystemMeasureBucketsDef";
import { formatNumber } from "utils/format/numberUtils";

export type TStandardQueryMeasure = {
  data: IDataModel[];
  max: number;
};

export type SankeyLinks = { source: string; target: string; value: number }[];

type TAlternativeQueryMeasures = {
  total: {
    patients: number;
    deaths: number;
    referrals: number;
    contacts: number;
  };

  // "008": nestedDimension;
  // "016": nestedDimension;
  // "027": nestedDimension;

  "033": IDataModel;
  "034": IDataModel;
  "035": IDataModel;
  "036": IDataModel;
  "037": IDataModel;
  "038": SankeyLinks;
};

type TStandard = Record<MeasureKey, TStandardQueryMeasure>;

export type QueryMeasuresDataShape = TAlternativeQueryMeasures & TStandard;
// {
//   // [key: Omit<MeasureKey,"033"|"034"|"035"|"037"|"038">]: TStandardQueryMeasure;
//   [key: Omit<MeasureKey,0|1>]: TStandardQueryMeasure;
// };

export const rollupMeasuresList = (
  data: Record<typeof measureKeys[number], TMeasureItem[] | null>
  // data: {[key:typeof measureKeys[number]]: TMeasureItem[] | null}
): QueryMeasuresDataShape => {
  const total = {
    patients:
      data["001"]?.find((d) => d.measure_key_1 === "all")?.measure_value || 0,
    deaths:
      data["020"]?.find((d) => d.measure_key_1 === "all")?.measure_value || 0,
    referrals:
      data["009"]?.find((d) => d.measure_key_1 === "all")?.measure_value || 0,
    contacts:
      data["028"]?.find((d) => d.measure_key_1 === "all")?.measure_value || 0,
  };

  return measureKeys.reduce(
    (record, k) => {
      const d = data[k];
      if (!d) {
        return record;
      }
      return {
        ...record,
        [k]: handleMeasure(k, d),
      };
    },
    { total } as QueryMeasuresDataShape
  );
};

const handleMeasure = (indicatorKey: MeasureKey, items: TMeasureItem[]) => {
  switch (indicatorKey) {
    case "033":
    case "034":
    case "035":
    case "036":
    case "037":
      const def = systemMeasureBucketsDef[indicatorKey][0];
      const n = items?.[0]?.measure_value || 0;
      return {
        key: indicatorKey,
        data: n,
        metadata: {
          group: def.label,
          label: def.label,
          color: def.color,
          value: formatNumber(n),
        },
      };
    case "038":
      return calculateMeasure038(items);
    default:
      return createFormattedMeasure(
        indicatorKey,
        items
      ) as TStandardQueryMeasure;

    // const buckets = systemMeasureBucketsDef[k];
    // buckets.map((k) => {});
    // return d.filter(filterOutAll).map((e) => {
    //   const key =
    //     e.measure_key_2 === ""
    //       ? e.measure_key_1
    //       : `${e.measure_key_1}::${e.measure_key_2}`;
    //   const metadata = {};
    //   return { key, data: e.measure_value, metadata: {} };
    // });
  }
};

const createFormattedMeasure = (measureKey: string, data: TMeasureItem[]) => {
  let buckets = systemMeasureBucketsDef[measureKey];
  const getKey = (d: TMeasureItem) =>
    d.measure_key_2 === ""
      ? d.measure_key_1
      : `${d.measure_key_1}::${d.measure_key_2}`;
  let keyAccessor = getKey;

  if (buckets[0].group) {
    const def = mapReduce(buckets, "key", "group");

    // get unique array of group buckets, but preserve original order
    buckets = Array.from(
      new Map(
        buckets.map((b, i) => [
          b.group,
          {
            index: i,
            key: b.group || b.key,
            label: b.group || b.label,
            color: b.color,
          },
        ])
      ).values()
    );

    keyAccessor = (d: TMeasureItem) => def[getKey(d)];
  }

  const rolled = mapReduce(
    data,
    keyAccessor,
    // (d, i, k, m) => (m[k] ? [...m[k], d] : [d])
    (d, i, k, m) => (m[k] ? m[k] : 0) + d.measure_value
    // (d) => d.measure_value || 0
  );

  return {
    max: 0,
    data: buckets.map((b) => {
      const v = rolled[b.key] || 0;
      return {
        key: b.key,
        data: v,
        metadata: {
          color: b.color,
          group: b.label,
          label: b.label,
          value: formatNumber(v),
        },
      };
    }),
  };
};

// rollupInsightsList({
//   org: data.org,
//   all_orgs: data.all_orgs,
//   keys: indicatorKeys,
//   keyProp: "indicator_key",
//   valueProp: "indicator_value",
// });

const filterOutAll = (d: TMeasureItem) =>
  d.measure_key_1 !== "all" && d.measure_key_2 !== "all";

const calculateMeasure038 = (data: TMeasureItem[]): SankeyLinks =>
  data.filter(filterOutAll).map((d) => ({
    source: `pre::${d.measure_key_1}`,
    target: `post::${d.measure_key_2}`,
    value: d.measure_value,
  }));
