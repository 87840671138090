import React from "react";
import cx from "classnames";
import styles from "components/insights/common/Headline.module.scss";

interface Props extends DefaultProps {
  num?: string;
  alwaysVertical?: boolean;
  size?: "big" | "normal" | "small";
}

export const Headline = ({
  num,
  children,
  className,
  alwaysVertical,
  size = "big",
  ...props
}: Props) => (
  <div
    className={cx(
      styles.headline,
      className,
      alwaysVertical && styles.vertical,
      size && styles[`${size}Size`]
    )}
    {...props}
  >
    <div className={styles.bigNumber}>{num}</div>{" "}
    <div className={styles.description}>{children}</div>
  </div>
);

export const Highlight = ({ children, className, ...props }: DefaultProps) => (
  <span className={cx(className, styles.highlight)} {...props}>
    {children}
  </span>
);
