import React from "react";
import cx from "classnames";
import { TPermissionGroup } from "models/Permissions";
import Chip from "components/common/ui/chip/Chip";
import Accordian from "components/common/ui/accordian/Accordian";
import ToggleSwitch from "components/common/ui/form-elements/toggle-switch/ToggleSwitch";
import IconCheck from "components/common/icons/IconCheck";
import IconCancel from "components/common/icons/IconCancel";
import styles from "components/users/common/User.module.scss";

interface Props {
  section: TPermissionGroup;
  editting: boolean;
  roles: Record<string, boolean>;
  onChange: (key: string, value: boolean) => void;
  onBulkChange: (roles: Record<string, boolean>) => void;
}

export const PermissionGroup = ({
  section,
  editting = false,
  roles,
  onChange,
  onBulkChange,
}: Props) => {
  const activePermissions = section.options.reduce(
    (count, option) => (roles[option.value] ? count + 1 : count),
    0
  );
  const togglePermissions = (active: boolean) => {
    onBulkChange(
      section.options.reduce(
        (r, o) => (o.readonly ? r : { ...r, [o.value]: !active }),
        {}
      )
    );
  };

  const header = (
    <div className={styles.headerContent}>
      <div className={styles.headerTextContainer}>
        <h4 className={styles.title}>
          {section.label}
          {section.readonly && <ReadOnlyBadge editting={editting} />}
        </h4>
        {section.description && (
          <div className={styles.permissionDescription}>
            {section.description}
          </div>
        )}
      </div>
      <div className={styles.headerActions}>
        {editting && !section.readonly && (
          <ToggleSwitch
            name={section.label}
            checked={activePermissions > 0}
            onChange={() => togglePermissions(activePermissions > 0)}
            disabled={section.readonly}
            className={styles.toggle}
          />
        )}

        <Chip
          className={styles.counts}
          theme={
            activePermissions > 0
              ? activePermissions === section.options.length
                ? "info"
                : "warning"
              : "default"
          }
          variant="lightFilled"
        >
          {activePermissions} <span>&nbsp;of {section.options.length}</span>
        </Chip>
      </div>
    </div>
  );

  return activePermissions > 0 || (editting && !section.readonly) ? (
    <Accordian
      header={header}
      headerClassName={styles.permissionSectionHeader}
      className={styles.sectionContent}
    >
      <div>
        {section.options.map((option) => {
          const active = roles[option.value];
          return editting || !option.readonly || active ? (
            <div
              key={option.value}
              className={cx(styles.permissionDetail, active && styles.active)}
            >
              <div>
                <h5 className={styles.label}>
                  {option.label}{" "}
                  {option.readonly && <ReadOnlyBadge editting={editting} />}
                </h5>
                <div className={styles.description}>{option.description}</div>
              </div>
              {editting ? (
                <ToggleSwitch
                  name={option.value}
                  checked={active}
                  onChange={() => onChange(option.value, !active)}
                  disabled={section.readonly || option.readonly}
                />
              ) : active ? (
                <IconCheck className={styles.icon} />
              ) : (
                <IconCancel className={styles.icon} />
              )}
            </div>
          ) : (
            <></>
          );
        })}
      </div>
    </Accordian>
  ) : (
    <></>
  );
};

const ReadOnlyBadge = ({ editting }: { editting: boolean }) =>
  editting ? (
    <Chip className={styles.readonlyChip} condensed theme="grey">
      Read Only
    </Chip>
  ) : (
    <></>
  );
