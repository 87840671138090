import React from "react";
import cx from "classnames";
import styles from "./PageHeading.module.scss";

interface Props extends Omit<DefaultProps, "children"> {
  variant?: ThemeVariants;
  darker?: boolean;
  children: React.ReactNode;
}

const PageHeading = ({
  className,
  variant = "default",
  darker,
  children,
  ...props
}: Props) => (
  <h1
    className={cx(
      styles.root,
      darker && styles.darker,
      variant && styles[`theme-${variant}`],
      className
    )}
    children={children}
    {...props}
  />
);

export default PageHeading;
