import React from "react";
import { IDataModel } from "components/common/ui/charts/chart";
import { MeasureCard } from "components/insights/measures/MeasureCard";
import ribbon from "components/insights/measures/assets/ribbon.svg";
import Container from "components/common/layouts/Container";
import { chartPalettes, getChartColor } from "utils/chartColorUtils";
import styles from "./MeasureDetail.module.scss";

interface IDataMap {
  referralSource?: IDataModel[];
  placeOfDeath?: IDataModel[];
  diagnosis: IDataModel[];
  age: IDataModel[];
  ethnicity: IDataModel[];
  livingSituation: IDataModel[];
  rurality: IDataModel[];
  gender: IDataModel[];
  akps?: IDataModel[];
  phase?: IDataModel[];
  ipos?: IDataModel[];
}
interface Props {
  headline: React.ReactNode;
  unit: string;
  data: IDataMap;
  showDataTables?: boolean;
}

const SystemMeasureDetail = ({
  headline,
  unit,
  data,
  showDataTables = true,
}: Props) => {
  return (
    <div>
      {/*<Card className={styles.card} shadow="soft">*/}
      <div className={styles.card}>
        <Container>
          <div className={styles.headlineContainer}>{headline}</div>
        </Container>
        <img src={ribbon} className={styles.ribbon} alt="ribbon" />
      </div>
      <Container>
        <div className={styles.row}>
          {data.referralSource && (
            <MeasureCard
              title={"Referral source"}
              section={unit}
              data={data.referralSource}
              showDataTable={showDataTables}
            />
          )}
          {data.placeOfDeath && (
            <MeasureCard
              title={"Place of Death"}
              section={unit}
              data={data.placeOfDeath}
              showDataTable={showDataTables}
            />
          )}

          <MeasureCard
            title={"Principal diagnosis"}
            section={unit}
            data={data.diagnosis}
            theme="ocean"
            showDataTable={showDataTables}
          />
        </div>
        <h2>Demographics</h2>

        <div className={styles.row}>
          <MeasureCard
            title={"Age"}
            section={unit}
            data={data.age}
            colorAccessor={(d, i) => getChartColor(i, chartPalettes.Viridis, 7)}
            showDataTable={showDataTables}
          />
          <MeasureCard
            title={"Ethnicity"}
            section={unit}
            data={data.ethnicity}
            chart="pie"
            colorAccessor={(d, i) => ["#413D7B", "#923390", "#ddd"][i]}
            showDataTable={showDataTables}
          />
        </div>

        <div className={styles.row}>
          <MeasureCard
            title={"Living situation"}
            section={unit}
            data={data.livingSituation}
            chart="pie"
            colorAccessor={(d, i) => ["#413D7B", "#ddd"][i]}
            showDataTable={showDataTables}
          />
          <MeasureCard
            title={"Rurality"}
            section={unit}
            data={data.rurality}
            chart="pie"
            colorAccessor={(d, i) =>
              ["#86dc93", "#413D7B", "#bbb", "#525252"][i]
            }
            showDataTable={showDataTables}
          />
          <MeasureCard
            title={"Gender"}
            section={unit}
            data={data.gender}
            chart="pie"
            colorAccessor={(d, i) =>
              ["#4f3d7b", "#54a0dc", "#CD4C6E", "#bbb"][i]
            }
            showDataTable={showDataTables}
          />
        </div>
        {data.akps && (
          <>
            <h2>Outcome Tools</h2>
            <div className={styles.row}>
              <MeasureCard
                title={"AKPS"}
                section={
                  unit === "Deaths" ? "Last completed before death" : unit
                }
                data={data.akps}
                showDataTable={showDataTables}
              />
              {data.phase && (
                <MeasureCard
                  title={"Phase of Illness"}
                  section={
                    unit === "Deaths" ? "Last completed before death" : unit
                  }
                  data={data.phase}
                  theme="ocean"
                  showDataTable={showDataTables}
                />
              )}
              {data.ipos && (
                <MeasureCard
                  title={"IPOS"}
                  section={
                    unit === "Deaths" ? "Last completed before death" : unit
                  }
                  data={data.ipos}
                  showDataTable={showDataTables}
                />
              )}
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default SystemMeasureDetail;
