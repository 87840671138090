import React, { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { useHasChanged } from "hooks/useHasChanged";
import { coreService } from "services/CoreService";

interface Props {
  paramKey: string;
  state: any;
  setState: (state: any) => void;
  loaded?: boolean;
}

const SyncParamState = React.memo(
  ({ paramKey, state, setState, loaded = true }: Props) => {
    const history = useHistory();
    const { params, path } = useRouteMatch() as { path: string; params: any };
    const param = params[paramKey];
    const hasParamChanged = useHasChanged(param);
    const hasStateChanged = useHasChanged(state);

    useEffect(() => {
      if (param !== state && loaded) {
        if (hasParamChanged) {
          setState(param);
        } else if (hasStateChanged) {
          history.push({
            pathname: coreService.getRoute(
              { path: path },
              { ...params, [paramKey]: state }
            ),
            search: history.location.search,
          });
        }
      }
    }, [
      param,
      state,
      setState,
      loaded,
      hasParamChanged,
      hasStateChanged,
      path,
      params,
      history,
      paramKey,
    ]);

    return null;
  }
);

export default SyncParamState;
