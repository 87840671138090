import React from "react";
import Meta from "components/common/Meta";
import { useSelector } from "react-redux";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import UserDetail from "components/users/admin/detail/UserDetail";
import { canEditUsers } from "utils/authUtils";
import { PERMISSIONS } from "models/Permissions";
import { TUser } from "models/User";
import { authService } from "services/AuthService";
import { userService } from "services/UserService";
import { coreService } from "services/CoreService";
import {
  currentOrgIdSelector,
  currentUserSelector,
} from "store/selectors/core.selectors";
import { permissionsSelector } from "store/selectors/auth.selectors";

const ViewMyUserDetail = () => {
  const currentUserPermissions = useSelector(permissionsSelector);
  const user = useSelector(currentUserSelector);
  const orgId = useSelector(currentOrgIdSelector);

  const onUpdateAttributes = async (attributes: Partial<TUser>) => {
    await authService.updateCurrentUserAttributes(attributes);
    return await coreService.getCurrentUser();
  };
  const onUpdatePermission = async (permissions: PERMISSIONS[]) => {
    if (canEditUsers(currentUserPermissions)) {
      await userService.saveDetail(
        { id: user.id, roles: permissions },
        user,
        orgId
      );
      await coreService.getCurrentUser();
    }
  };

  return (
    <AppContentContainer spacing="none">
      <Meta title="My details" />
      <UserDetail
        user={user}
        canChangeUserAttributes={true}
        canChangePermissions={canEditUsers(currentUserPermissions)}
        onUpdateAttributes={onUpdateAttributes}
        onUpdatePermission={onUpdatePermission}
      />
    </AppContentContainer>
  );
};

export default ViewMyUserDetail;
