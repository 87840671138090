import * as Sentry from "@sentry/react";
import { SENTRY_DSN, SENTRY_RELSEASE } from "constants/site";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import { Store } from "redux";
import { IState } from "store";
import { TUser } from "models/User";

const logErrorToSentry = process.env.NODE_ENV !== "development";
const history = createBrowserHistory();

export const errorInit = () => {
  let envSpecificOptions =
    process.env.NODE_ENV === "production"
      ? {}
      : {
          beforeBreadcrumb(breadcrumb: any) {
            //Ignore console logs in development to prevent the payload from getting too large
            return breadcrumb.category === "console" ? null : breadcrumb;
          },
        };

  if (logErrorToSentry) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: process.env.REACT_APP_ENVIRONMENT_SLUG,
      release: SENTRY_RELSEASE,
      autoSessionTracking: true,
      normalizeDepth: 8,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
      tracesSampleRate: 1.0,
      ...envSpecificOptions,
    });
  }
};

export const setErrorUser = (user?: Partial<TUser>) => {
  if (user && user?.id !== "") {
    Sentry.setUser(user);
  } else {
    Sentry.configureScope((scope) => scope.setUser(null));
  }
};

export const captureError = (e: Error) => {
  if (logErrorToSentry) {
    Sentry.captureException(e);
  }
};

export const logFeedback = (eventId: string|null) => {
  if (logErrorToSentry && eventId) {
    Sentry.showReportDialog({ eventId: eventId });
  }
};
export const errorMiddleware = (store: Store<IState>) => {
  Sentry.addGlobalEventProcessor((event) => {
    const state = store.getState();
    const user = state.app.core.user || {};
    const logState = {
      layout: state.app.layout,
    };

    return {
      ...event,
      extra: {
        ...event.extra,
        "redux:state": logState,
      },
      user: {
        ...event.user,
        ...user,
      },
    };
  });

  return (next: any) => (action: any) => {
    Sentry.addBreadcrumb({
      category: "redux-action",
      message: action.type,
    });

    return next(action);
  };
};

const scrubListEntity = (
  entity: {
    detail: {
      [key: string]: any;
    };
    list: {
      items: any[];
      [key: string]: any;
    };
    [key: string]: any;
  },
  detailKey: string,
  idKey: string
) => {
  const {
    detail,
    list: { items, ...listState },
    ...rest
  } = entity;

  const scrubbedDetail = detail[detailKey]
    ? {
        [detailKey]: {
          [idKey]: detail[detailKey][idKey],
        },
      }
    : null;

  return {
    detail: scrubbedDetail,
    list: {
      items: items.length > 0 ? "[supressed]" : null,
      ...listState,
    },
    ...rest,
  };
};

const stateTransformer = (state: any) => {
  const { app, ...rest } = state;
  // Transform the state to remove sensitive information before logging to Sentry
  const { currentUser, auth, users, ...appState } = app;
  const scrubbedUsersList = scrubListEntity(users, "user", "id");
  return {
    ...rest,
    app: {
      ...appState,
      users: scrubbedUsersList,
    },
  };
};

export const errorStoreEnhancer = Sentry.createReduxEnhancer({
  stateTransformer,
  actionTransformer: (action) => {
    //remove state from redux.action breadcrumb
    const { state, ...rest } = action;
    return { ...rest };
  },
});
