import React from "react";
import createIcon from "components/common/icons/createIcon";

const IconArchive = createIcon(
  <React.Fragment>
    <path
      fill="currentColor"
      d="M21 3H3V9H21V3M19 7H5V5H19V7M14.5 11C14.78 11 15 11.22 15 11.5V13H9V11.5C9 11.22 9.22 11 9.5 11H14.5M18 13.09V10H20V13.09C19.67 13.04 19.34 13 19 13C18.66 13 18.33 13.04 18 13.09M13 19C13 19.7 13.13 20.37 13.35 21H4V10H6V19H13M22.5 17.25L17.75 22L15 19L16.16 17.84L17.75 19.43L21.34 15.84L22.5 17.25Z"
    />
  </React.Fragment>,
  "Archive"
);

export default IconArchive;
