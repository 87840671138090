import React from "react";
import cx from "classnames";
import LinkWrapper from "components/common/ui/link-wrapper/LinkWrapper";
import Button, { SkeletonButton } from "components/common/ui/button/Button";
import { TUser } from "models/User";
import { TRoute } from "constants/routes";
import { verifyPermisisons } from "utils/authUtils";
import { coreService } from "services/CoreService";
import { SkeletonText } from "components/common/ui/skeleton/Skeleton";
import SkeletonLoader from "components/common/ui/skeleton/SkeletonLoader";
import { useSelector } from "react-redux";
import { permissionsSelector } from "store/selectors/auth.selectors";
import { isAppLoadedSelector } from "store/selectors/core.selectors";
import styles from "./HomeCard.module.scss";

interface Props extends DefaultProps {
  title: string;
  description?: string;
  route: TRoute;
  href?: string;
  user?: TUser;
  img?: string;
  col?: number;
  fadeOverlay?: boolean;
  svg?: React.ComponentType<any>;
  // bgImg?: string;
}

const HomeCard = ({
  title,
  description,
  route,
  href,
  img,
  svg: SvgComponent,
  className,
  fadeOverlay,
  col = 6,
  ...rest
}: Props) => {
  const isLoaded = useSelector(isAppLoadedSelector);
  const permissions = useSelector(permissionsSelector);
  const linkProps = href ? { href: href } : { to: coreService.getRoute(route) };
  return verifyPermisisons(permissions, route.requiredRole) ? (
    !isLoaded ? (
      <HomeCardSkeleton col={col} />
    ) : (
      <LinkWrapper
        {...linkProps}
        className={cx(styles.root, className, styles[`col-${col}`])}
      >
        <div className={styles.card} {...rest}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
          <Button variant="outline" theme="primary" className={styles.button}>
            view
          </Button>
          <div
            className={cx(styles.background, fadeOverlay && styles.fadeOverlay)}
          >
            {img && (
              <img
                src={img}
                className={styles.backgroundImage}
                alt={`${title}-background`}
              />
            )}
            {SvgComponent && (
              <SvgComponent className={styles.backgroundIllustration} />
            )}
          </div>
        </div>
      </LinkWrapper>
    )
  ) : (
    <></>
  );
};

interface ICardSkeleton {
  col: number;
}

const HomeCardSkeleton = ({ col }: ICardSkeleton) => (
  <div className={cx(styles.root, styles.skeleton, styles[`col-${col}`])}>
    <div className={styles.card}>
      <div className={styles.title}>
        <SkeletonText swipe="none" />
      </div>
      <div className={styles.description}>
        <SkeletonText lines={2} swipe="none" />
        <SkeletonText style={{ width: "50%" }} swipe="none" />
      </div>
      <SkeletonButton
        className={styles.button}
        buttonVariant="outline"
        swipe="none"
      >
        view
      </SkeletonButton>
    </div>
    <SkeletonLoader variant="inverse" className={styles.swipe} />
  </div>
);

export const HomeCardContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => <div className={cx(styles.wrapper, className)}>{children}</div>;

export default HomeCard;
