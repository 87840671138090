import { QueryClient } from "react-query";
import { layoutService } from "services/LayoutService";

export function queryErrorHandler(error: unknown): void {
  layoutService.error(error, "Error: Unable to load data");
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30 * 1000, //global default is 30 seconds
      onError: queryErrorHandler,
    },
  },
  // queryCache: new QueryCache({
  //   onError: (error: unknown, query: Query) => {
  //     console.log({ error, query });
  //     if (
  //       query.state.data !== undefined ||
  //       "isAxiosError" in (error as RequestError)
  //     ) {
  //       layoutService.error(error);
  //     }
  //   },
  // }),
});
