import React from "react";
import { QueryMeasuresDataShape } from "components/insights/utils/measureUtils";
import { formatNumber } from "utils/format/numberUtils";
import { useSelector } from "hooks/useSelector";
import { currentOrgSelector } from "store/selectors/core.selectors";
import { Headline, Highlight } from "components/insights/common/Headline";
import SystemMeasureDetail from "components/insights/measures/common/MeasureDetail";
import referralsImg from "./assets/referrals.svg";
import styles from "components/insights/measures/common/MeasureDetail.module.scss";
import Meta from "components/common/Meta";

interface Props {
  data: QueryMeasuresDataShape;
  period: string;
  showDataTables: boolean;
}
const sectionName = "New Referrals";

const SystemMeasureReferrals = ({ data, period, showDataTables }: Props) => {
  const org = useSelector(currentOrgSelector);
  const dataMap = {
    referralSource: data["009"].data,
    diagnosis: data["010"].data,
    age: data["011"].data,
    ethnicity: data["012"].data,
    gender: data["013"].data,
    rurality: data["014"].data,
    livingSituation: data["015"].data,
    akps: data["017"].data,
    phase: data["019"].data,
    ipos: data["018"].data,
  };
  return (
    <>
      <Meta title={"System Measures - Referrals"} />
      <SystemMeasureDetail
        headline={
          <>
            <img
              src={referralsImg}
              className={styles.headlineImg}
              alt="patients"
            />
            <Headline
              num={formatNumber(data.total.referrals)}
              // className={styles.headline}
            >
              new referrals to <Highlight>{org.org_name}</Highlight> in{" "}
              <Highlight>{period}</Highlight>
            </Headline>
          </>
        }
        unit={sectionName}
        data={dataMap}
        showDataTables={showDataTables}
      />
    </>
  );
};

export default SystemMeasureReferrals;
