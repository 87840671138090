import React from "react";
import cx from "classnames";
import styles from "./MiniCard.module.scss";
import LinkWrapper, {
  TLinkWrapper,
} from "components/common/ui/link-wrapper/LinkWrapper";

interface Props extends DefaultProps {
  width?: number | string;
  height?: number | string;
}

const MiniCard = ({ children, className, style, width, height }: Props) => (
  <div
    className={cx(styles.root, className)}
    style={{ width: width, height, ...style }}
  >
    {children}
  </div>
);

interface LinkProps extends Props, Omit<TLinkWrapper, "children"> {}

export const MiniCardWithLink = ({
  href,
  to,
  target,
  download,
  ...props
}: LinkProps) => (
  <LinkWrapper to={to} href={href} target={target} download={download}>
    <MiniCard {...props} />
  </LinkWrapper>
);

export default MiniCard;
