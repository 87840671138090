import React from "react";
import cx from "classnames";
import InputFeedback, {
  TError,
} from "components/common/ui/form-elements/InputFeedback";
import IconCancel from "components/common/icons/IconCancel";
import IconCheck from "components/common/icons/IconCheck";
import styles from "components/common/ui/form-elements/toggle-switch/ToggleSwitch.module.scss";

interface Props {
  name: string;
  label?: string;
  className?: string;
  error?: TError;
  disabled?: boolean;
  required?: boolean;
  checked?: boolean;
  value?: string;
  onChange?: (e: any) => void;
  defaultChecked?: boolean;
  successIcon?: React.ReactNode;
  cancelIcon?: React.ReactNode;
}

const ToggleSwitch = React.forwardRef(
  (
    {
      name,
      label,
      disabled,
      className,
      error,
      checked,
      successIcon,
      cancelIcon,
      ...rest
    }: Props,
    ref: React.Ref<any>
  ) => {
    const handleChange = () => {
      rest.onChange && rest.onChange(!checked);
    };
    const controlledProps =
      checked !== undefined ? { checked: checked, onChange: handleChange } : {};

    return (
      <>
        <label
          className={cx(
            className,
            styles.root,
            disabled && styles.disabled,
            !!error && styles.error
          )}
        >
          {label && <span className={styles.label}>{label}</span>}
          <input
            id={`toggle-input-${name}`}
            ref={ref}
            type="checkbox"
            name={name}
            disabled={disabled}
            className={cx(styles.input)}
            aria-describedby={error && `error-${name}-${error?.type}`}
            aria-invalid={!!error}
            tabIndex={disabled ? -1 : undefined}
            {...rest}
            {...controlledProps}
          />
          <Toggle
            disabled={disabled}
            successIcon={successIcon}
            cancelIcon={cancelIcon}
          />
        </label>
        <InputFeedback error={error} visible={!!error} name={name} />
      </>
    );
  }
);

interface IToggleProps {
  disabled?: boolean;
  successIcon?: React.ReactNode;
  cancelIcon?: React.ReactNode;
}

const Toggle = ({
  disabled,
  successIcon = <IconCheck />,
  cancelIcon = <IconCancel />,
}: IToggleProps) => (
  <div className={styles.toggle}>
    <div className={styles.toggleInner}>
      <div className={cx(styles.indicator, disabled && styles.disabled)}>
        {successIcon}
        {cancelIcon}
      </div>
    </div>
  </div>
);

export default ToggleSwitch;
