import React from "react";
import { Hub } from "aws-amplify";
import { ConfirmSignIn } from "aws-amplify-react";
import Meta from "components/common/Meta";
import { CLIENT_NAME } from "constants/site";
import AuthLayout from "components/common/layouts/AuthLayout";
import MfaChallengeForm from "components/auth/forms/MfaChallengeForm";
import styles from "components/auth/forms/Forms.module.scss";
import { Auth } from "@aws-amplify/auth";
import { isAuthError } from "utils/catchErrorUtils";

const exportDefault = {};
export default exportDefault;

interface IProps {
  [key: string]: any;
}

export class CustomConfirmSignIn extends ConfirmSignIn {
  confirmSignInError: null | string = null;
  boundHubAuthListener: (result: any) => void = () => {};

  constructor(props: IProps) {
    super(props);
    this._validAuthStates = ["confirmSignIn"];
  }

  hubAuthListener(result: any) {
    if (!result || !result.payload) {
      return;
    }
    switch (result.payload.event) {
      case "confirmSignIn_failure":
        this.confirmSignInError =
          result.payload.data && result.payload.data.message;
        this.setState({ ...this.state });
        return;
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.boundHubAuthListener = this.hubAuthListener.bind(this);
    Hub.listen("auth", this.boundHubAuthListener);
  }

  componentWillUnmount() {
    Hub.remove("auth", this.boundHubAuthListener);
  }

  async handleFormSubmit() {
    // this.confirm(new Event("ConfirmSignIn"));
    const user = this.props.authData;
    const { code } = this.inputs;
    const mfaType =
      user.challengeName === "SOFTWARE_TOKEN_MFA" ? "SOFTWARE_TOKEN_MFA" : null;

    if (!Auth || typeof Auth.confirmSignIn !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }

    try {
      this.confirmSignInError = null;
      await Auth.confirmSignIn(user, `${code}`, mfaType);
      this.checkContact(user);
    } catch (e) {
      if (!!e && isAuthError(e)) {
        // @ts-ignore  for some strange reason TS thinks this can be `null` even though !!e excludes `null`
        this.confirmSignInError = e.message;
        this.error(e);
      }
      this.setState({ ...this.state });
    }
  }

  showComponent(theme: any) {
    const { authData } = this.props;
    const phone = authData?.challengeParam?.CODE_DELIVERY_DESTINATION;
    return (
      <AuthLayout variant="data">
        <Meta title={"Authentication Token"} />
        <h1>{CLIENT_NAME}</h1>
        <p>
          We've sent a verification code to{" "}
          {phone ? (
            <>
              your number: &nbsp;{" "}
              <span className={styles.contact}>{phone}</span>
            </>
          ) : (
            "your phone number"
          )}
          <br />
          <br />
          Please enter the code in the box below:
        </p>
        <MfaChallengeForm
          onSubmit={this.handleFormSubmit.bind(this)}
          values={this.inputs}
          errorMessage={this.confirmSignInError}
          changeState={this.changeState}
        />
      </AuthLayout>
    );
  }
}
