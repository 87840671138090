import Tooltip from "components/common/ui/tooltip/Tooltip";
import React from "react";
import {
  IconDanger,
  IconError,
  IconWarning,
} from "components/common/icons/twoToneIcons";
import styles from "./IconHeaders.module.scss";

const errorTypes = [
  { name: "Warning", icon: <IconWarning /> },
  { name: "Error", icon: <IconError /> },
  { name: "Critical", icon: <IconDanger /> },
];

export const IconHeaders = () => (
  <div className={styles.errors}>
    {errorTypes.map((t) => (
      <Tooltip
        content={t.name}
        arrow={false}
        className={styles[`tip${t.name}`]}
        key={t.name}
      >
        <div className={styles.errorIcon}>{t.icon}</div>
      </Tooltip>
    ))}
  </div>
);
