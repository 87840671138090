import React, { useRef, useState } from "react";
import cx from "classnames";
import InputFeedback, {
  TError,
} from "components/common/ui/form-elements/InputFeedback";
import IconExpandArrow from "components/common/icons/IconExpandArrow";
import styles from "./Select.module.scss";
import inputStyles from "components/common/ui/form-elements/text-input/TextField.module.scss";
import SelectWrapper from "components/common/ui/select/SelectWrapper";
import SelectMenu from "components/common/ui/select/SelectMenu";
import ActiveOptionChip from "./ActiveOptionChip";
import { IFacetOption } from "models/Dimension";
import { SelectOption } from "components/common/ui/select/SelectOption";
import Chip, { SkeletonChip } from "components/common/ui/chip/Chip";

export interface ISelecInputConfig {
  inputChip?: boolean;
  menuOffset?: number;
  menuWidth?: "fit-content" | "parent" | number;
  selectedOptionFormat?: "chip" | "text";
  expandArrow?: boolean;
  noInputError?: boolean;
  theme?: ThemeVariants;
}

export interface ISelectProps {
  name: string;
  value: string | string[] | null;
  onSelect: (
    name: string,
    value: any,
    config?: { shouldValidate?: boolean; shouldDirty?: boolean }
  ) => void;
  label: string;
  description?: string;
  multiSelect?: boolean;
  options?: IFacetOption[];
  className?: string;
  inputClassName?: string;
  menuClassName?: string;
  disabled?: boolean;
  placeholder?: string;
  error?: TError;
  defaultValue?: string;
  children?: React.ReactNode;
  config?: ISelecInputConfig;

  // [key: string]: any;
}

const checkOptionActive = (selected: string | string[], option: IFacetOption) =>
  Array.isArray(selected)
    ? selected.includes(option.key)
    : selected === option.key;

const Select = React.forwardRef(
  (
    {
      name,
      value,
      onSelect,
      label,
      description,
      multiSelect,
      options,
      className,
      inputClassName,
      menuClassName,
      disabled,
      placeholder = "Select an option",
      error,
      defaultValue: defV,
      children,
      config = {},
      ...rest
    }: ISelectProps,
    ref: React.Ref<any>
  ) => {
    const [state, setStateFun] = useState({
      isOpened: false,
      isFocused: false,
      highlight: -1,
    });
    const [highlight, setHighlight] = useState<number | string>(-1);
    const wrapperElementRef = useRef<HTMLDivElement>(null);
    const selected = value || "";
    const selectedChipStyle =
      config?.selectedOptionFormat === "chip" || multiSelect;

    const selectedOptions = options
      ? options.filter((d) => checkOptionActive(selected, d))
      : Array.isArray(selected)
      ? selected
      : [];
    const props = defV
      ? {
          defaultValue: defV,
        }
      : {};

    const setState = (newState: Record<string, any>) => {
      setStateFun((s) => ({ ...s, ...newState }));
    };
    const onSelectionBlur = () => {
      setState({
        isFocused: false,
      });
    };

    const onSelectionClick = () => {
      // setHighlight(-1);
      setStateFun((s) => ({
        isOpened: !s.isOpened,
        isFocused: !s.isFocused,
        highlight: -1,
      }));
    };
    const onSelectionFocus = () => {
      setState({
        isFocused: true,
      });
    };

    const ariaProps = {
      role: "combobox",
      "aria-controls": `select-${name}-results`,
      "aria-expanded": state.isOpened,
      "aria-selected": state.isFocused,
      "aria-haspopup": true,
      "aria-owns": `select-${name}-results`,
      "aria-activedescendant":
        state.isOpened && highlight !== -1
          ? `select-${name}-result-${highlight}`
          : undefined,
    };
    const labelEle = (
      <label
        htmlFor={`select-input-${name}`}
        className={inputStyles.label}
        id={`select-input-label-${name}`}
      >
        {label}
        {label && description && <br />}
        {description && <span>{description}</span>}
      </label>
    );

    return (
      <>
        <SelectWrapper
          name={name}
          selected={selected}
          onSelect={onSelect}
          isFocused={state.isFocused}
          isOpened={state.isOpened}
          // highlight={highlight}
          highlight={state.highlight}
          setHighlight={setHighlight}
          setState={setState}
          multiSelect={multiSelect}
          wrapRef={wrapperElementRef}
        >
          <div
            className={cx(
              className,
              styles.root,
              error && inputStyles.error,
              config?.inputChip && styles.chip,
              selectedChipStyle && styles.selectedChip,
              multiSelect && styles.multiSelect,
              (config?.expandArrow === undefined
                ? !!multiSelect
                : !config.expandArrow) && styles.hideExpandArrow
            )}
            {...ariaProps}
            ref={wrapperElementRef}
          >
            {!config?.inputChip && labelEle}

            <div
              className={cx(
                inputClassName,
                styles.inputWrapper,
                disabled && styles.disabled,
                (state.isFocused || state.isOpened) && styles.focus
              )}
            >
              <SelectMenu
                name={name}
                isOpened={state.isOpened}
                className={menuClassName}
                width={config?.menuWidth}
                toggle={onSelectionClick}
                offset={config?.menuOffset}
              >
                {children}
                {options &&
                  options.map((d) => (
                    <SelectOption key={d.key} value={d.key}>
                      {checkOptionActive(selected, d) ? (
                        <ActiveOptionChip
                          id={d.key}
                          {...d}
                          cancelIcon={!!multiSelect}
                          theme={config?.theme}
                        />
                      ) : (
                        <Chip icon={d.icon} condensed variant={"empty"}>
                          {d.label || d.key}
                        </Chip>
                      )}
                    </SelectOption>
                  ))}
              </SelectMenu>
              {config?.inputChip && labelEle}
              {selectedChipStyle && (
                <ActiveOptions
                  name={name}
                  options={selectedOptions}
                  onSelect={onSelect}
                  multiSelect={!!multiSelect}
                  theme={config?.theme}
                />
              )}
              {config?.inputChip && selected.length === 0 && (
                <div className={styles.placeholder}>{placeholder}</div>
              )}
              <input
                id={`select-input-${name}`}
                type="text"
                readOnly
                name={name}
                value={selected}
                disabled={disabled}
                className={cx(styles.input)}
                ref={ref}
                placeholder={!config?.inputChip ? placeholder : ""}
                aria-labelledby={`select-input-label-${name}`}
                aria-describedby={error && `error-${name}-${error?.type}`}
                aria-invalid={!!error}
                tabIndex={disabled ? -1 : undefined}
                {...props}
                {...rest}
                // onClick={onSelectionClick}
                onBlur={onSelectionBlur}
                onFocus={onSelectionFocus}
              />
              <IconExpandArrow className={styles.expand} />
            </div>
          </div>
        </SelectWrapper>

        {!config?.noInputError && (
          <InputFeedback error={error} visible={!!error} name={name} />
        )}
      </>
    );
  }
);

interface ISkeletonSelect {
  children?: React.ReactNode;
  className?: string;
}
export const SkeletonSelect = ({ children, className }: ISkeletonSelect) => (
  <SkeletonChip className={cx(styles.skeleton, className)}>
    {children || <span className={styles.padder}>&nbsp;</span>}
  </SkeletonChip>
);

const ActiveOptions = ({
  options,
  name,
  onSelect,
  multiSelect = true,
  theme,
}: {
  options: (string | IFacetOption)[];
  name: string;
  onSelect: (name: string, value: string) => void;
  multiSelect?: boolean;
  theme?: ThemeVariants;
}) => {
  return (
    <div
      className={cx(
        styles.activeChipContainer,
        !multiSelect && styles.singleSelect
      )}
    >
      {options.map((d) => {
        const s = typeof d === "string" ? { key: d } : d;
        const props = multiSelect
          ? {
              onClick: () => onSelect(name, s.key),
              cancelIcon: true,
            }
          : {};
        return (
          <ActiveOptionChip
            className={styles.activeChip}
            id={s.key}
            theme={theme}
            {...s}
            {...props}
          />
        );
      })}
    </div>
  );
};

export default Select;
