import React from "react";
import createIcon from "components/common/icons/createIcon";

const IconResendEmail = createIcon(
  <React.Fragment>
    <path d="M12.63 2C18.16 2 22.64 6.5 22.64 12C22.64 17.5 18.16 22 12.63 22C9.12 22 6.05 20.18 4.26 17.43L5.84 16.18C7.25 18.47 9.76 20 12.64 20C14.7617 20 16.7966 19.1571 18.2969 17.6569C19.7971 16.1566 20.64 14.1217 20.64 12C20.64 9.87827 19.7971 7.84344 18.2969 6.34315C16.7966 4.84285 14.7617 4 12.64 4C8.56 4 5.2 7.06 4.71 11H7.47L3.73 14.73L0 11H2.69C3.19 5.95 7.45 2 12.63 2Z" />
    <path d="M17 10L13 12.5L9 10V9L13 11.5L17 9V10ZM17 8H9C8.445 8 8 8.445 8 9V15C8 15.2652 8.10536 15.5196 8.29289 15.7071C8.48043 15.8946 8.73478 16 9 16H17C17.2652 16 17.5196 15.8946 17.7071 15.7071C17.8946 15.5196 18 15.2652 18 15V9C18 8.445 17.55 8 17 8Z" />
  </React.Fragment>,
  "Warning"
);

export default IconResendEmail;
