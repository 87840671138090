import { appGetState, appNextState, appUpdateState } from "store";
import { getSchemas } from "api/UploadApi";
import { layoutService } from "services/LayoutService";
import { pascalCase } from "utils/format/stringUtils";
import { updateBuckets } from "utils/filterUtils";
import { IDimension, TBucket } from "models/Dimension";
import { getSchemaVersions } from "components/schema/utils/validationUtils";
import { ISchemaDimensions } from "models/Schema";
import { verifyPermisisons } from "utils/authUtils";
import { SCHEMA_DETAIL } from "constants/routes";

const comment = (m: string) => `SchemaService::${m}`;

class SchemaService {
  private appGetState = appGetState;
  private appNextState = appNextState;
  private appUpdateState = appUpdateState;

  public getSchemaDetail = async () => {
    window.logger.group(comment("getSchemaDetail"));
    const { isLoaded, detail } = this.appGetState().schema;
    if (isLoaded) {
      return detail;
    }
    await this.loadSchema();
    window.logger.groupEnd();
    return this.appGetState().schema.detail;
  };

  public loadSchema = async () => {
    const permissions = this.appGetState().auth.permissions;
    if (!verifyPermisisons(permissions, SCHEMA_DETAIL.requiredRole)) {
      return false;
    }

    this.setIsLoading(true);
    try {
      const schemas = await getSchemas();
      await this.appUpdateState((s) => {
        s.schema.detail = schemas;
      }, comment("loadSchema"));
      await this.resetVersionDimension();
      this.setIsLoading(false, true);
      return true;
    } catch (e) {
      layoutService.error(e, "Error: Unable to load Schemas");
      this.setIsLoading(false);
      return false;
    }
  };

  public resetNameDimension = async (schemas: string[]) => {
    const { options, buckets } = this.getCurrentDimensions().schema_name;
    if (
      options.length === schemas.length &&
      options.every((o, i) => o.key === schemas[i])
    ) {
      // no difference is schemas available
      return;
    }

    const newOptions = schemas.map((k) => ({
      key: k,
      label: pascalCase(k),
    }));

    const bucket =
      typeof buckets === "string" && schemas.includes(buckets)
        ? buckets
        : schemas[0];

    await this.appUpdateState((s) => {
      s.schema.dimensions.schema_name.options = newOptions;
      s.schema.dimensions.schema_name.buckets = bucket;
    }, comment("resetSchemaNames"));
    await this.resetVersionDimension();
    return;
  };

  public resetVersionDimension = async () => {
    const state = this.appGetState().schema;
    const {
      detail,
      dimensions: {
        schema_name: { buckets },
        version: versionState,
      },
    } = state;

    if (typeof buckets === "string") {
      const schema = detail[buckets];
      if (schema) {
        const versions = getSchemaVersions(schema).map((v) => ({
          key: v,
          label: `Version ${v} `,
        }));
        const stateContainsNewVersion = versions.find(
          (v) => v.key === versionState.buckets
        );
        const versionBucket = stateContainsNewVersion
          ? versionState.buckets
          : versions[0].key;
        await this.appUpdateState((s) => {
          s.schema.dimensions.version.buckets = versionBucket;
          s.schema.dimensions.version.options = versions;
        }, comment("resetVersions"));
      }
    }
  };

  public setFilter = async ({
    key,
    bucket,
  }: {
    key: keyof ISchemaDimensions;
    bucket: TBucket | null;
  }) => {
    const currentDimensions = this.getCurrentDimensions();
    const dimension = currentDimensions[key];
    if (dimension) {
      // window.logger.group(comment("setFilter"));
      await this.updateDimensionFilter({ bucket, dimension });
      if (dimension.key === "schema_name") {
        await this.resetVersionDimension();
      }
      // window.logger.groupEnd()
    }
    return this.getCurrentDimensions();
  };

  public setManyFilters = async (
    filters: (
      | {
          key: keyof ISchemaDimensions;
          bucket: TBucket | null;
        }
      | undefined
    )[]
  ) => {
    const currentDimensions = this.getCurrentDimensions();
    if (filters.length > 0) {
      window.logger.group(comment("setManyFilters"));
      this.setIsLoading(true);

      //Update `schema_name` dimension first
      const schema_name = filters.find((f) => f && f.key === "schema_name");
      if (schema_name) {
        await this.updateDimensionFilter({
          bucket: schema_name.bucket,
          dimension: currentDimensions["schema_name"],
        });
      }

      //then update the others
      for (let i = 0; i < filters.length; i++) {
        const f = filters[i];
        if (f && f.key !== "schema_name") {
          const dimension = currentDimensions[f.key];
          await this.updateDimensionFilter({ bucket: f.bucket, dimension });
        }
      }
      window.logger.groupEnd();
    }

    return this.getCurrentDimensions();
  };

  private setIsLoading = (isLoading: boolean, isLoaded?: boolean): void => {
    this.appUpdateState((s) => {
      s.schema.isLoading = isLoading;
      if (isLoaded !== undefined) {
        s.schema.isLoaded = isLoaded;
      }
    }, comment("setIsLoading"));
  };

  private updateDimensionFilter = ({
    bucket,
    dimension,
  }: {
    bucket?: TBucket | null;
    dimension?: IDimension;
  }) => {
    if (dimension) {
      const newBuckets = updateBuckets(bucket, dimension);
      this.appUpdateState(
        (s) =>
          (s.schema.dimensions[dimension.key] = {
            ...dimension,
            buckets: newBuckets,
          }),
        comment("updateDimensionFilter")
      );
      return newBuckets;
    }
    return false;
  };

  private getCurrentDimensions = () => {
    const {
      schema: { dimensions },
    } = this.appGetState();
    return dimensions;
  };
}

const schemaService = new SchemaService();
export { schemaService };
