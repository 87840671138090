import React from "react";
import createIcon from "components/common/icons/createIcon";

export const IconProgressComplete = createIcon(
  <React.Fragment>
    <path
      fill="currentColor"
      d="M13,2.03V2.05L13,4.05C17.39,4.59 20.5,8.58 19.96,12.97C19.5,16.61 16.64,19.5 13,19.93V21.93C18.5,21.38 22.5,16.5 21.95,11C21.5,6.25 17.73,2.5 13,2.03M11,2.06C9.05,2.25 7.19,3 5.67,4.26L7.1,5.74C8.22,4.84 9.57,4.26 11,4.06V2.06M4.26,5.67C3,7.19 2.25,9.04 2.05,11H4.05C4.24,9.58 4.8,8.23 5.69,7.1L4.26,5.67M15.5,8.5L10.62,13.38L8.5,11.26L7.44,12.32L10.62,15.5L16.56,9.56L15.5,8.5M2.06,13C2.26,14.96 3.03,16.81 4.27,18.33L5.69,16.9C4.81,15.77 4.24,14.42 4.06,13H2.06M7.1,18.37L5.67,19.74C7.18,21 9.04,21.79 11,22V20C9.58,19.82 8.23,19.25 7.1,18.37Z"
    />{" "}
  </React.Fragment>,
  "ProgressComplete"
);
export const IconProgressUpload = createIcon(
  <React.Fragment>
    <path
      fill="currentColor"
      d="M13,2.03C17.73,2.5 21.5,6.25 21.95,11C22.5,16.5 18.5,21.38 13,21.93V19.93C16.64,19.5 19.5,16.61 19.96,12.97C20.5,8.58 17.39,4.59 13,4.05V2.05L13,2.03M11,2.06V4.06C9.57,4.26 8.22,4.84 7.1,5.74L5.67,4.26C7.19,3 9.05,2.25 11,2.06M4.26,5.67L5.69,7.1C4.8,8.23 4.24,9.58 4.05,11H2.05C2.25,9.04 3,7.19 4.26,5.67M2.06,13H4.06C4.24,14.42 4.81,15.77 5.69,16.9L4.27,18.33C3.03,16.81 2.26,14.96 2.06,13M7.1,18.37C8.23,19.25 9.58,19.82 11,20V22C9.04,21.79 7.18,21 5.67,19.74L7.1,18.37M12,7.5L7.5,12H11V16H13V12H16.5L12,7.5Z"
    />
  </React.Fragment>,
  "ProgressUpload"
);
export const IconProgressDownload = createIcon(
  <React.Fragment>
    <path
      fill="currentColor"
      d="M13,2.03C17.73,2.5 21.5,6.25 21.95,11C22.5,16.5 18.5,21.38 13,21.93V19.93C16.64,19.5 19.5,16.61 19.96,12.97C20.5,8.58 17.39,4.59 13,4.05V2.05L13,2.03M11,2.06V4.06C9.57,4.26 8.22,4.84 7.1,5.74L5.67,4.26C7.19,3 9.05,2.25 11,2.06M4.26,5.67L5.69,7.1C4.8,8.23 4.24,9.58 4.05,11H2.05C2.25,9.04 3,7.19 4.26,5.67M2.06,13H4.06C4.24,14.42 4.81,15.77 5.69,16.9L4.27,18.33C3.03,16.81 2.26,14.96 2.06,13M7.1,18.37C8.23,19.25 9.58,19.82 11,20V22C9.04,21.79 7.18,21 5.67,19.74L7.1,18.37M12,16.5L7.5,12H11V8H13V12H16.5L12,16.5Z"
    />
  </React.Fragment>,
  "ProgressDownload"
);
export const IconProgressWrench = createIcon(
  <React.Fragment>
    <path
      fill="currentColor"
      d="M13,2.03V2.05L13,4.05C17.39,4.59 20.5,8.58 19.96,12.97C19.5,16.61 16.64,19.5 13,19.93V21.93C18.5,21.38 22.5,16.5 21.95,11C21.5,6.25 17.73,2.5 13,2.03M11,2.06C9.05,2.25 7.19,3 5.67,4.26L7.1,5.74C8.22,4.84 9.57,4.26 11,4.06V2.06M4.26,5.67C3,7.19 2.25,9.04 2.05,11H4.05C4.24,9.58 4.8,8.23 5.69,7.1L4.26,5.67M2.06,13C2.26,14.96 3.03,16.81 4.27,18.33L5.69,16.9C4.81,15.77 4.24,14.42 4.06,13H2.06M7.1,18.37L5.67,19.74C7.18,21 9.04,21.79 11,22V20C9.58,19.82 8.23,19.25 7.1,18.37M16.82,15.19L12.71,11.08C13.12,10.04 12.89,8.82 12.03,7.97C11.13,7.06 9.78,6.88 8.69,7.38L10.63,9.32L9.28,10.68L7.29,8.73C6.75,9.82 7,11.17 7.88,12.08C8.74,12.94 9.96,13.16 11,12.76L15.11,16.86C15.29,17.05 15.56,17.05 15.74,16.86L16.78,15.83C17,15.65 17,15.33 16.82,15.19Z"
    />
  </React.Fragment>,
  "ProgressWrench"
);

export const IconProgressStar = createIcon(
  <React.Fragment>
    <path
      fill="currentColor"
      d="M13 2V4C17.39 4.54 20.5 8.53 19.96 12.92C19.5 16.56 16.64 19.43 13 19.88V21.88C18.5 21.28 22.45 16.34 21.85 10.85C21.33 6.19 17.66 2.5 13 2M11 2C9.04 2.18 7.19 2.95 5.67 4.2L7.1 5.74C8.22 4.84 9.57 4.26 11 4.06V2.06M4.26 5.67C3 7.19 2.24 9.04 2.05 11H4.05C4.24 9.58 4.8 8.23 5.69 7.1L4.26 5.67M2.06 13C2.26 14.96 3.03 16.81 4.27 18.33L5.69 16.9C4.81 15.77 4.24 14.42 4.06 13H2.06M7.06 18.37L5.67 19.74C7.18 21 9.04 21.79 11 22V20C9.58 19.82 8.23 19.25 7.1 18.37H7.06M8.58 16.8L9.5 12.91L6.5 10.33L10.45 9.96L12 6.35L13.55 10L17.5 10.33L14.5 12.91L15.42 16.8L12 14.74L8.58 16.8"
    />
  </React.Fragment>,
  "ProgressStar"
);

export const IconProgressQuestion = createIcon(
  <React.Fragment>
    <path
      fill="currentColor"
      d="M13 2.03V4.05C17.39 4.59 20.5 8.58 19.96 12.97C19.5 16.61 16.64 19.5 13 19.93V21.93C18.5 21.38 22.5 16.5 21.95 11C21.5 6.25 17.73 2.5 13 2.03M11 2.06C9.05 2.25 7.19 3 5.67 4.26L7.1 5.74C8.22 4.84 9.57 4.26 11 4.06V2.06M4.26 5.67C3 7.19 2.25 9.04 2.05 11H4.05C4.24 9.58 4.8 8.23 5.69 7.1L4.26 5.67M2.06 13C2.26 14.96 3.03 16.81 4.27 18.33L5.69 16.9C4.81 15.77 4.24 14.42 4.06 13H2.06M7.1 18.37L5.67 19.74C7.18 21 9.04 21.79 11 22V20C9.58 19.82 8.23 19.25 7.1 18.37M20 4H44M13 18H11V16H13V18M13 15H11C11 11.75 14 12 14 10C14 8.9 13.1 8 12 8S10 8.9 10 10H8C8 7.79 9.79 6 12 6S16 7.79 16 10C16 12.5 13 12.75 13 15Z"
    />
  </React.Fragment>,
  "ProgressQuestion"
);

export const IconProgressEdit = createIcon(
  <React.Fragment>
    <path
      fill="currentColor"
      d="M15.84 10.2L14.83 11.21L12.76 9.18L13.77 8.16C13.97 7.95 14.31 7.94 14.55 8.16L15.84 9.41C16.05 9.62 16.06 9.96 15.84 10.2M8 13.91L12.17 9.72L14.24 11.8L10.08 16H8V13.91M13 2V4C17.39 4.54 20.5 8.53 19.96 12.92C19.5 16.56 16.64 19.43 13 19.88V21.88C18.5 21.28 22.45 16.34 21.85 10.85C21.33 6.19 17.66 2.5 13 2M11 2C9.04 2.18 7.19 2.95 5.67 4.2L7.1 5.74C8.22 4.84 9.57 4.26 11 4.06V2.06M4.26 5.67C3 7.19 2.24 9.04 2.05 11H4.05C4.24 9.58 4.8 8.23 5.69 7.1L4.26 5.67M2.06 13C2.26 14.96 3.03 16.81 4.27 18.33L5.69 16.9C4.81 15.77 4.24 14.42 4.06 13H2.06M7.06 18.37L5.67 19.74C7.18 21 9.04 21.79 11 22V20C9.58 19.82 8.23 19.25 7.1 18.37H7.06Z"
    />
  </React.Fragment>,
  "ProgressEdit"
);

export const IconProgressCancel = createIcon(
  <React.Fragment>
    <path
      fill="currentColor"
      d="M13 2.03V4.05C17.39 4.59 20.5 8.58 19.96 12.97C19.5 16.61 16.64 19.5 13 19.93V21.93C18.5 21.38 22.5 16.5 21.95 11C21.5 6.25 17.73 2.5 13 2.03M11 2.06C9.05 2.25 7.19 3 5.67 4.26L7.1 5.74C8.22 4.84 9.57 4.26 11 4.06V2.06M4.26 5.67C3 7.19 2.25 9.04 2.05 11H4.05C4.24 9.58 4.8 8.23 5.69 7.1L4.26 5.67M2.06 13C2.26 14.96 3.03 16.81 4.27 18.33L5.69 16.9C4.81 15.77 4.24 14.42 4.06 13H2.06M7.1 18.37L5.67 19.74C7.18 21 9.04 21.79 11 22V20C9.58 19.82 8.23 19.25 7.1 18.37M14.59 8L12 10.59L9.41 8L8 9.41L10.59 12L8 14.59L9.41 16L12 13.41L14.59 16L16 14.59L13.41 12L16 9.41L14.59 8Z"
    />
  </React.Fragment>,
  "ProgressCancel"
);

export const IconProgressClock = createIcon(
  <React.Fragment>
    <path
      d="M13 2.03003C17.73 2.50003 21.5 6.25003 21.95 11C22.5 16.5 18.5 21.38 13 21.93V19.93C16.64 19.5 19.5 16.61 19.96 12.97C20.5 8.58003 17.39 4.59003 13 4.05003V2.05003V2.03003ZM11 2.06003V4.06003C9.57 4.26003 8.22 4.84003 7.1 5.74003L5.67 4.26003C7.19 3.00003 9.05 2.25003 11 2.06003ZM4.26 5.67003L5.69 7.10003C4.8 8.23003 4.24 9.58003 4.05 11H2.05C2.25 9.04003 3 7.19003 4.26 5.67003ZM2.06 13H4.06C4.24 14.42 4.81 15.77 5.69 16.9L4.27 18.33C3.03 16.81 2.26 14.96 2.06 13ZM7.1 18.37C8.23 19.25 9.58 19.82 11 20V22C9.04 21.79 7.18 21 5.67 19.74L7.1 18.37Z"
      fill="currentColor"
    />
    <path
      d="M12.5 7V12.25L17 14.92L16.25 16.15L11 13V7H12.5Z"
      fill="currentColor"
    />
  </React.Fragment>,
  "ProgressClock"
);

export const IconProgressCircleWrap = createIcon(
  <React.Fragment>
    <path
      fill="currentColor"
      d="M13 2.03003C17.73 2.50003 21.5 6.25003 21.95 11C22.5 16.5 18.5 21.38 13 21.93V19.93C16.64 19.5 19.5 16.61 19.96 12.97C20.5 8.58003 17.39 4.59003 13 4.05003V2.05003V2.03003ZM11 2.06003V4.06003C9.57 4.26003 8.22 4.84003 7.1 5.74003L5.67 4.26003C7.19 3.00003 9.05 2.25003 11 2.06003ZM4.26 5.67003L5.69 7.10003C4.8 8.23003 4.24 9.58003 4.05 11H2.05C2.25 9.04003 3 7.19003 4.26 5.67003ZM2.06 13H4.06C4.24 14.42 4.81 15.77 5.69 16.9L4.27 18.33C3.03 16.81 2.26 14.96 2.06 13ZM7.1 18.37C8.23 19.25 9.58 19.82 11 20V22C9.04 21.79 7.18 21 5.67 19.74L7.1 18.37Z"
    />
  </React.Fragment>,
  "ProgressCircleWrap"
);

export default IconProgressWrench;
