import React, { useEffect } from "react";
import Meta from "components/common/Meta";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { useParams } from "react-router";
import { uploadService } from "services/UploadService";
import { useSelector } from "react-redux";
import { UploadDetail } from "components/upload/detail/UploadDetail";
import { minutesDiff } from "utils/format/dateUtils";
import { VIEW_UPLOADS } from "constants/routes";
import { coreService } from "services/CoreService";
import { schemaDetailSelector } from "store/selectors/schema.selectors";
import {
  uploadDetailSelector,
  uploadIsLoadingSelector,
} from "store/selectors/uploads.selectors";

const fetchUpload = (id: string, orgId: string) => {
  uploadService.loadDetail(id, orgId, true);
};

const ViewUploadDetail = () => {
  const { orgId, uploadId } = useParams<{ orgId: string; uploadId: string }>();
  const schemas = useSelector(schemaDetailSelector);
  const detail = useSelector(uploadDetailSelector);
  const isLoading = useSelector(uploadIsLoadingSelector);
  const schema = schemas?.[detail.schema_name]?.[detail.schema_version] || {};
  const final = !!detail.events.find(
    (e) => e.event_name === "Clean Up Validation Completed"
  );
  const onSelect = async () => {
    await uploadService.selectUpload(uploadId, orgId);
    await uploadService.loadDetail(uploadId, orgId);
    return true;
  };

  const onArchive = async () => {
    await uploadService.archiveUpload(uploadId, !detail.archived);
    await uploadService.loadDetail(uploadId, orgId);
    return true;
  };

  useEffect(() => {
    uploadService.loadDetail(uploadId, orgId);
  }, [uploadId, orgId]);

  useEffect(() => {
    let interval = setInterval(() => {}, 100000);
    if (
      detail.upload_id !== "" &&
      !isLoading &&
      !final &&
      minutesDiff(detail.created) < 20
    ) {
      interval = setInterval(
        () => fetchUpload(detail.upload_id, detail.org_id),
        2000
      );
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [detail, isLoading, final]);

  return (
    <AppContentContainer
      spacing="none"
      backButton={{
        to: coreService.getRoute(VIEW_UPLOADS, { orgId }),
        label: "Back to Uploads",
      }}
    >
      <Meta title={"Upload Detail"} />
      <UploadDetail
        upload={detail}
        schema={schema}
        onSelect={onSelect}
        onArchive={onArchive}
      />
    </AppContentContainer>
  );
};

export default ViewUploadDetail;
