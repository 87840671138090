import React, { FC, useMemo } from "react";
import classNames from "classnames";
import { pie } from "d3-shape";
import { CloneElement } from "rdk";
import {
  ChartShallowDataShape,
  PieArcSeriesProps,
  ChartContainer,
  ChartContainerChildProps,
  PieChartProps,
  PieArcLabel,
} from "reaviz";
import { ComparisonPieArcSeries } from "components/common/ui/charts/pie/ComparisonPieArcSeries";
import ChartTooltip, {
  IChartTooltipProps,
} from "components/common/ui/charts/tooltip/ChartTooltip";
import { ResponsiveChartContainer } from "components/common/ui/charts/common/ChartContainer";

export interface IPieChartProps extends PieChartProps {
  colorAccessor: (data: any, index: number, active?: any[]) => string;
  tooltip?: Omit<IChartTooltipProps, "children">;
  arcWidth?: number;
  cornerRadius?: number;
  padAngle?: number;
  padRadius?: number;
  showLabel?: boolean;
}

export const DonutChart: FC<IPieChartProps> = ({
  id,
  className,
  displayAllLabels,
  data = [],
  arcWidth = 0.35,
  padAngle = 0.02,
  cornerRadius = 2,
  padRadius = 100,
  showLabel,
  margins = 10,
  colorAccessor,
  series,
  tooltip,
}) => {
  const getData = useMemo(() => {
    const pieLayout = pie<void, ChartShallowDataShape>().value(
      (d: ChartShallowDataShape) => Number(d.data)
    );
    pieLayout.sort(null);
    return pieLayout(data);
  }, [data]);
  const s = series || (
    <ComparisonPieArcSeries
      cornerRadius={cornerRadius}
      padAngle={padAngle}
      arcWidth={arcWidth}
      label={
        showLabel ? (
          <PieArcLabel format={(d) => d.metadata?.label || d.key} />
        ) : null
      }
      padRadius={padRadius}
    />
  );

  const chart = (
    <ResponsiveChartContainer>
      <ChartContainer
        id={id}
        margins={margins}
        xAxisVisible={false}
        yAxisVisible={false}
        center={true}
        className={classNames(className)}
      >
        {({ chartWidth, chartHeight }: ChartContainerChildProps) => (
          <g className="main-pie">
            <CloneElement<PieArcSeriesProps>
              element={s}
              data={getData}
              height={chartHeight}
              width={chartWidth}
              displayAllLabels={displayAllLabels}
              colorScheme={colorAccessor}
            />
          </g>
        )}
      </ChartContainer>
    </ResponsiveChartContainer>
  );

  return tooltip ? (
    <ChartTooltip {...tooltip} colorAccessor={colorAccessor}>
      <span>{chart}</span>
    </ChartTooltip>
  ) : (
    chart
  );
};
