import React from "react";
import createIcon from "components/common/icons/createIcon";
const opacity = 0.25;
export const IconEye = createIcon(
  <React.Fragment>
    <path
      opacity={opacity}
      d="M3.18005 12C4.83005 8.63 8.21005 6.5 12.0001 6.5C15.7901 6.5 19.1701 8.63 20.8201 12C19.1701 15.37 15.8001 17.5 12.0001 17.5C8.20005 17.5 4.83005 15.37 3.18005 12ZM7.50004 12C7.50004 14.48 9.52004 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.51999 14.48 7.49999 12 7.49999C9.52004 7.49999 7.50004 9.51999 7.50004 12Z"
      fill="currentColor"
    />
    <path
      d="M1 12C2.73 7.61 7 4.5 12 4.5C17 4.5 21.27 7.61 23 12C21.27 16.39 17 19.5 12 19.5C7 19.5 2.73 16.39 1 12ZM3.17999 12C4.82999 15.37 8.20999 17.5 12 17.5C15.79 17.5 19.17 15.37 20.82 12C19.17 8.63001 15.79 6.50001 12 6.50001C8.20999 6.50001 4.82999 8.63001 3.17999 12Z"
      fill="currentColor"
    />
    <path
      d="M7.5 12C7.5 9.52 9.52 7.5 12 7.5C14.48 7.5 16.5 9.52 16.5 12C16.5 14.48 14.48 16.5 12 16.5C9.52 16.5 7.5 14.48 7.5 12ZM9.49999 12C9.49999 13.38 10.62 14.5 12 14.5C13.38 14.5 14.5 13.38 14.5 12C14.5 10.62 13.38 9.50001 12 9.50001C10.62 9.50001 9.49999 10.62 9.49999 12Z"
      fill="currentColor"
    />
  </React.Fragment>,
  "Eye"
);

export const IconMarker = createIcon(
  <React.Fragment>
    <path
      opacity={opacity}
      d="M12 4C14.76 4 17 6.24 17 9C17 11.88 14.12 16.19 12 18.88C9.92 16.21 7 11.85 7 9C7 6.24 9.24 4 12 4ZM12 6.5C10.62 6.5 9.5 7.62 9.5 9C9.5 10.38 10.62 11.5 12 11.5C13.38 11.5 14.5 10.38 14.5 9C14.5 7.62 13.38 6.5 12 6.5Z"
      fill="currentColor"
    />
    <path
      d="M12 2C15.87 2 19 5.13 19 9C19 14.25 12 22 12 22C12 22 5 14.25 5 9C5 5.13 8.13 2 12 2ZM17 9C17 6.24 14.76 4 12 4C9.24 4 7 6.24 7 9C7 11.85 9.92 16.21 12 18.88C14.12 16.19 17 11.88 17 9Z"
      fill="currentColor"
    />
    <path
      d="M12 11.5C13.3807 11.5 14.5 10.3807 14.5 9C14.5 7.61929 13.3807 6.5 12 6.5C10.6193 6.5 9.5 7.61929 9.5 9C9.5 10.3807 10.6193 11.5 12 11.5Z"
      fill="currentColor"
    />
  </React.Fragment>,
  "Marker"
);

export const IconPhone = createIcon(
  <React.Fragment>
    <path
      opacity={opacity}
      d="M19 17.47C18.12 17.4 17.25 17.25 16.4 17.02L15.21 18.21C16.41 18.62 17.69 18.88 19.01 18.96V17.47H19Z"
      fill="currentColor"
    />
    <path
      opacity={opacity}
      d="M6.98998 7.59C6.74998 6.76 6.59998 5.89 6.53998 5H5.03998C5.12998 6.32 5.38998 7.59 5.78998 8.8L6.98998 7.59Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      d="M3 4C3 13.39 10.61 21 20 21C20.55 21 21 20.55 21 20V16.51C21 15.96 20.55 15.51 20 15.51C18.76 15.51 17.55 15.31 16.43 14.94C16.33 14.9 16.22 14.89 16.12 14.89C15.86 14.89 15.61 14.99 15.41 15.18L13.21 17.38C10.38 15.93 8.06 13.62 6.62 10.79L8.82 8.59C9.1 8.31 9.18 7.92 9.07 7.57C8.7 6.45 8.5 5.25 8.5 4C8.5 3.45 8.05 3 7.5 3H4C3.45 3 3 3.45 3 4ZM16.4 17.02C17.25 17.26 18.12 17.41 19 17.47V18.96C17.68 18.87 16.41 18.61 15.2 18.21L16.4 17.02ZM6.99 7.59C6.75 6.76 6.6 5.89 6.54 5H5.03C5.12 6.32 5.38 7.59 5.79 8.79L6.99 7.59Z"
      fill="currentColor"
    />
  </React.Fragment>,
  "Phone"
);

export const IconEmail = createIcon(
  <React.Fragment>
    <path opacity={opacity} d="M20 8L12 13L4 8V18H20V8Z" fill="currentColor" />
    <path opacity={opacity} d="M20 6H4L12 10.99L20 6Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      d="M20 20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20ZM20 6L12 10.99L4 6H20ZM12 13L4 8V18H20V8L12 13Z"
      fill="currentColor"
    />
  </React.Fragment>,
  "Phone"
);

export const IconWarning = createIcon(
  <React.Fragment>
    <path
      opacity={opacity}
      d="M12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4ZM11 17V15H13V17H11ZM11 7V13H13V7H11Z"
      fill="currentColor"
    />
    <path
      d="M2 12C2 6.48 6.47 2 11.99 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 11.99 22C6.47 22 2 17.52 2 12ZM4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12Z"
      fill="currentColor"
    />
    <path d="M11 15H13V17H11V15Z" fill="currentColor" />
    <path d="M11 7H13V13H11V7Z" fill="currentColor" />
  </React.Fragment>,
  "Warning"
);

export const IconDanger = createIcon(
  <React.Fragment>
    <path
      d="M8.27 3L3 8.27V15.73L8.27 21H15.73C17.5 19.24 21 15.73 21 15.73V8.27L15.73 3H8.27Z"
      fill="currentColor"
      opacity={opacity}
    />
    <path
      d="M7.85556 2L2 7.85556V16.1444L7.85556 22H16.1444C18.1111 20.0444 22 16.1444 22 16.1444V7.85556L16.1444 2H7.85556ZM8.77778 4.22222H15.2222L19.7778 8.77778V15.2222L15.2222 19.7778H8.77778L4.22222 15.2222V8.77778L8.77778 4.22222Z"
      fill="currentColor"
    />
    <path
      d="M14.872 8L12 10.872L9.128 8L8 9.128L10.872 12L8 14.872L9.128 16L12 13.128L14.872 16L16 14.872L13.128 12L16 9.128L14.872 8Z"
      fill="currentColor"
    />
  </React.Fragment>,
  "Danger"
);

export const IconError = createIcon(
  <React.Fragment>
    <path
      d="M12.2095 2.3122C12.0924 2.19501 11.9024 2.19501 11.7852 2.3122L2.31192 11.7878C2.19479 11.905 2.19479 12.0949 2.31192 12.212L11.7852 21.6877C11.9024 21.8049 12.0924 21.8049 12.2095 21.6877L21.6829 12.212C21.8 12.0949 21.8 11.905 21.6829 11.7878L12.2095 2.3122Z"
      opacity={opacity}
      fill="currentColor"
    />
    <path
      d="M12 1C11.4501 1 10.9003 1.209 10.4494 1.649L1.65159 10.4489C0.782804 11.3069 0.782804 12.6929 1.65159 13.5509L10.4494 22.3509C11.3072 23.2199 12.6928 23.2199 13.5506 22.3509L22.3484 13.5509C23.2172 12.6929 23.2172 11.3069 22.3484 10.4489L13.5506 1.649C13.0997 1.209 12.5499 1 12 1ZM12 3.19999L20.7978 11.9999L12 20.7999L3.2022 11.9999L12 3.19999Z"
      fill="currentColor"
    />
    <path d="M11 15H13V17H11V15Z" fill="currentColor" />
    <path d="M11 7H13V13H11V7Z" fill="currentColor" />
  </React.Fragment>,
  "Error"
);
export const IconSuccess = createIcon(
  <React.Fragment>
    <path
      d="M12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4Z"
      fill="currentColor"
      opacity={opacity}
    />
    <path
      d="M2 12C2 6.48 6.47 2 11.99 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 11.99 22C6.47 22 2 17.52 2 12ZM4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12Z"
      fill="currentColor"
    />
    <path
      d="M10.8996 13.4772L8.66364 11.1239L7.3 12.5728L10.8996 16.3L17.3 9.71198L15.9364 8.3L10.8996 13.4772Z"
      fill="currentColor"
    />
  </React.Fragment>,
  "Success"
);

export const IconSettings = createIcon(
  <React.Fragment>
    <path
      opacity={opacity}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.58 7.39L19.28 8.6L18.18 9.44L17.29 10.14L17.43 11.27C17.46 11.57 17.48 11.79 17.48 12C17.48 12.21 17.47 12.42 17.43 12.73L17.29 13.86L18.18 14.56L19.26 15.4L18.56 16.61L17.29 16.1L16.25 15.68L15.35 16.36C14.92 16.68 14.51 16.92 14.1 17.09L13.04 17.52L12.88 18.65L12.69 20H11.3L11.11 18.64L10.95 17.51L9.88997 17.08C9.45997 16.91 9.04997 16.67 8.65997 16.37L7.74997 15.67L6.68997 16.1L5.41997 16.61L4.71997 15.4L5.79997 14.56L6.68997 13.86L6.54997 12.73C6.51997 12.43 6.49997 12.2 6.49997 12C6.49997 11.8 6.51997 11.57 6.54997 11.27L6.68997 10.14L5.79997 9.44L4.71997 8.6L5.41997 7.39L6.68997 7.91L7.73997 8.33L8.63997 7.65C9.06997 7.33 9.47997 7.09 9.88997 6.92L10.95 6.48L11.11 5.35L11.3 4H12.7L12.89 5.36L13.05 6.49L14.11 6.92C14.54 7.09 14.95 7.33 15.34 7.63L16.25 8.33L17.31 7.9L18.58 7.39ZM7.99997 12C7.99997 14.21 9.78997 16 12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8C9.78997 8 7.99997 9.79 7.99997 12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 12C19.5 12.34 19.47 12.66 19.43 12.98L21.54 14.63C21.73 14.78 21.78 15.05 21.66 15.27L19.66 18.73C19.57 18.89 19.4 18.98 19.23 18.98C19.17 18.98 19.11 18.97 19.05 18.95L16.56 17.95C16.04 18.34 15.48 18.68 14.87 18.93L14.49 21.58C14.46 21.82 14.25 22 14 22H9.99996C9.74996 22 9.53996 21.82 9.50996 21.58L9.12996 18.93C8.51996 18.68 7.95996 18.35 7.43996 17.95L4.94996 18.95C4.89996 18.97 4.83996 18.98 4.77996 18.98C4.59996 18.98 4.42996 18.89 4.33996 18.73L2.33996 15.27C2.21996 15.05 2.26996 14.78 2.45996 14.63L4.56996 12.98C4.52996 12.66 4.49996 12.33 4.49996 12C4.49996 11.67 4.52996 11.34 4.56996 11.02L2.45996 9.37C2.26996 9.22 2.20996 8.95 2.33996 8.73L4.33996 5.27C4.42996 5.11 4.59996 5.02 4.76996 5.02C4.82996 5.02 4.88996 5.03 4.94996 5.05L7.43996 6.05C7.95996 5.66 8.51996 5.32 9.12996 5.07L9.50996 2.42C9.53996 2.18 9.74996 2 9.99996 2H14C14.25 2 14.46 2.18 14.49 2.42L14.87 5.07C15.48 5.32 16.04 5.65 16.56 6.05L19.05 5.05C19.1 5.03 19.16 5.02 19.22 5.02C19.4 5.02 19.57 5.11 19.66 5.27L21.66 8.73C21.78 8.95 21.73 9.22 21.54 9.37L19.43 11.02C19.47 11.34 19.5 11.66 19.5 12ZM17.5 12C17.5 11.79 17.49 11.58 17.45 11.27L17.31 10.14L18.2 9.44L19.27 8.59L18.57 7.38L17.3 7.89L16.24 8.32L15.33 7.62C14.93 7.32 14.53 7.09 14.1 6.91L13.04 6.48L12.88 5.35L12.69 4H11.3L11.1 5.35L10.94 6.48L9.87996 6.91C9.46996 7.08 9.05996 7.32 8.62996 7.64L7.72996 8.32L6.68996 7.9L5.41996 7.39L4.71996 8.6L5.79996 9.44L6.68996 10.14L6.54996 11.27C6.51996 11.57 6.49996 11.8 6.49996 12C6.49996 12.2 6.51996 12.43 6.54996 12.74L6.68996 13.87L5.79996 14.57L4.71996 15.41L5.41996 16.62L6.68996 16.11L7.74996 15.68L8.65996 16.38C9.05996 16.68 9.45996 16.91 9.88996 17.09L10.95 17.52L11.11 18.65L11.3 20H12.7L12.9 18.65L13.06 17.52L14.12 17.09C14.53 16.92 14.94 16.68 15.37 16.36L16.27 15.68L17.31 16.1L18.58 16.61L19.28 15.4L18.2 14.56L17.31 13.86L17.45 12.73C17.48 12.43 17.5 12.21 17.5 12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 12C8 9.79 9.79 8 12 8C14.21 8 16 9.79 16 12C16 14.21 14.21 16 12 16C9.79 16 8 14.21 8 12ZM10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10C10.9 10 10 10.9 10 12Z"
      fill="currentColor"
    />
  </React.Fragment>,
  "Settings"
);

export const IconUser = createIcon(
  <React.Fragment>
    <path
      opacity={opacity}
      d="M12 10C13.1046 10 14 9.10457 14 8C14 6.89543 13.1046 6 12 6C10.8954 6 10 6.89543 10 8C10 9.10457 10.8954 10 12 10Z"
      fill="currentColor"
    />
    <path
      opacity={opacity}
      d="M12 15C9.3 15 6.2 16.29 6 17.01V18H18V17C17.8 16.29 14.7 15 12 15Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C16 10.21 14.21 12 12 12C9.79 12 8 10.21 8 8C8 5.79 9.79 4 12 4C14.21 4 16 5.79 16 8ZM14 8C14 6.9 13.1 6 12 6C10.9 6 10 6.9 10 8C10 9.1 10.9 10 12 10C13.1 10 14 9.1 14 8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 17C4 14.34 9.33 13 12 13C14.67 13 20 14.34 20 17V20H4V17ZM6 17.01V18H18V17C17.8 16.29 14.7 15 12 15C9.3 15 6.2 16.29 6 17.01Z"
      fill="currentColor"
    />
  </React.Fragment>,
  "User"
);

export const IconOrg = createIcon(
  <React.Fragment>
    <path
      opacity={opacity}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 11H14V13H12V15H14V17H12V19H20V9H12V11ZM16 11H18V13H16V11ZM18 15H16V17H18V15Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7H22V21H2V3H12V7ZM4 19H6V17H4V19ZM6 15H4V13H6V15ZM4 11H6V9H4V11ZM6 7H4V5H6V7ZM8 19H10V17H8V19ZM10 15H8V13H10V15ZM8 11H10V9H8V11ZM10 7H8V5H10V7ZM12 19H20V9H12V11H14V13H12V15H14V17H12V19Z"
      fill="currentColor"
    />
    <path d="M16 11H18V13H16V11Z" fill="currentColor" />
    <path d="M16 15H18V17H16V15Z" fill="currentColor" />
  </React.Fragment>,
  "User"
);
