import { appGetState, appNextState, appUpdateState } from "store";
import {
  fetchMany,
  saveUpload,
  fetchOneById,
  selectUpload,
  fetchSelected,
  archiveUpload,
} from "api/UploadApi";
import { layoutService } from "services/LayoutService";
import {
  createConfig,
  IFile,
  IUploadConfig,
  createUpload,
  TUpload,
  TUploadFilter,
  IUploadDimensions,
} from "models/Upload";
import { updateBuckets } from "utils/filterUtils";
import { IDimension, ISetBucket, TBucket } from "models/Dimension";
import { schemaService } from "services/SchemaService";

const extractFiltersFromDimensions = (dim: IUploadDimensions) => {
  return Object.values(dim).reduce(
    (o, d) => ({ ...o, [d.key]: d.buckets }),
    {}
  ) as TUploadFilter;
};

const comment = (m: string) => `UploadService::${m}`;

class UploadService {
  private appGetState = appGetState;
  private appNextState = appNextState;
  private appUpdateState = appUpdateState;

  public loadDetail = async (
    id: string,
    orgId: string,
    forceRefresh?: boolean
  ) => {
    window.logger.group(comment("loadDetail"));
    this.setIsLoading(true);
    if (this.appGetState().uploads.detail.upload_id !== id || forceRefresh) {
      try {
        if (!forceRefresh) {
          this.clearDetail();
        }
        const upload = await fetchOneById(id, orgId);
        this.setUploadDetails(upload);
      } catch (e) {
        layoutService.error(e, "Error: Failed to find Upload");
      }
      this.setIsLoading(false);
    } else {
      this.setIsLoading(false);
    }
    window.logger.groupEnd();
  };

  public loadList = async () => {
    this.setIsLoading(true);
    const dim = this.getCurrentDimensions();
    const orgId = this.getCurrentOrg();

    try {
      const { items, total } = await fetchMany(
        extractFiltersFromDimensions(dim),
        orgId
      );
      const selected = await this.loadSelectedUploads();
      await this.appUpdateState((s) => {
        s.uploads.list.items = items;
        s.uploads.list.total = total;
        s.uploads.selected = selected;
      }, comment("loadList"));
    } catch (e) {
      layoutService.error(e, "Error: Unable to load Uploads list");
    }

    this.setIsLoading(false);
  };

  public saveUpload = async (
    file: IFile,
    config: IUploadConfig,
    orgId: string
  ) => {
    try {
      const upload = await saveUpload(file, createConfig(config, file), orgId);
      layoutService.addToast("File was successfully uploaded!", {
        theme: "success",
      });
      this.clearList();
      return upload;
    } catch (e) {
      layoutService.error(e, `Error uploading ${file.path || "File"}`);
    }
    return false;
  };

  public selectUpload = async (uploadId: string, orgId: string) => {
    try {
      await selectUpload(uploadId, orgId);
      layoutService.addToast("Upload was successfully selected!", {
        theme: "success",
      });
      this.clearDetail();
      this.clearSelected();
      return true;
    } catch (e) {
      layoutService.error(e, `Error selecting Upload"}`);
    }
    return false;
  };

  public archiveUpload = async (uploadId: string, archive: boolean) => {
    const orgId = this.getCurrentOrg();
    try {
      await archiveUpload(uploadId, archive, orgId);
      layoutService.addToast(
        `Upload was successfully ${archive ? "deleted" : "restored"}!`,
        {
          theme: "success",
        }
      );
      this.clearDetail();
      this.clearSelected();
      return true;
    } catch (e) {
      layoutService.error(
        e,
        `Error: unable to ${archive ? "delete" : "restore"} Upload"}`
      );
    }
    return false;
  };

  public setFilter = async ({ key, bucket }: ISetBucket) => {
    const currentDimensions = this.getCurrentDimensions();
    const dimension = currentDimensions[key];
    if (dimension) {
      window.logger.group(comment("setFilter"));
      await this.updateDimensionFilter({ bucket, dimension });
      window.logger.groupEnd();
    }
    return this.getCurrentDimensions();
  };

  public setFilterAndRefresh = async ({ key, bucket }: ISetBucket) => {
    const dim = await this.setFilter({ key, bucket });
    await this.loadList();
    return dim;
  };

  public setManyFilters = async (
    filters: (ISetBucket | undefined)[]
  ) => {
    const currentDimensions = this.getCurrentDimensions();
    if (filters.length > 0) {
      window.logger.group(comment("setManyFilters"));
      this.setIsLoading(true);
      for (let i = 0; i < filters.length; i++) {
        const f = filters[i];
        if (f) {
          const dimension = currentDimensions[f.key];
          await this.updateDimensionFilter({ bucket: f.bucket, dimension });
        }
      }
      window.logger.groupEnd();
    }

    return this.getCurrentDimensions();
  };

  public setManyFiltersAndRefresh = async (
    filters: (ISetBucket | undefined)[],
    refreshData?: boolean
  ) => {
    if (filters.length > 0) {
      this.setIsLoading(true);
      await this.setManyFilters(filters);
      if (refreshData) {
        await this.loadList();
      }
    }
    return this.getCurrentDimensions();
  };


  private setIsLoading = (isLoading: boolean): void => {
    this.appUpdateState(
      (s) => (s.uploads.isLoading = isLoading),
      comment("setIsLoading")
    );
  };

  private setUploadDetails = (upload: TUpload): void => {
    this.appUpdateState(
      (s) => (s.uploads.detail = upload),
      comment("setUploadDetails")
    );
  };

  private clearDetail = () => {
    this.setUploadDetails(createUpload());
  };
  private clearList = () => {
    this.appUpdateState((s) => {
      s.uploads.list.items = [];
      s.uploads.list.total = 0;
      s.uploads.selected = {};
    }, comment("clearList"));
  };
  private clearSelected = () => {
    this.appUpdateState((s) => {
      s.uploads.selected = {};
    }, comment("clearSelected"));
  };

  private updateDimensionFilter = async ({
    bucket,
    dimension,
  }: {
    bucket?: TBucket | null;
    dimension?: IDimension;
  }) => {
    if (dimension) {
      if (dimension.key === "schema_name") {
        await schemaService.setFilter({
          key: dimension.key,
          bucket: bucket || null,
        });
        return {};
      }
      const newBuckets = updateBuckets(bucket, dimension);
      this.appUpdateState(
        (s) =>
          (s.uploads.list.dimensions[dimension.key] = {
            ...dimension,
            buckets: newBuckets,
          }),
        comment("updateDimensionFilter")
      );
      return newBuckets;
    }
    return false;
  };

  private getCurrentDimensions = () => {
    const {
      uploads: {
        list: { dimensions },
      },
      schema: {
        dimensions: { schema_name },
      },
    } = this.appGetState();
    return { ...dimensions, schema_name } as IUploadDimensions;
  };

  private getCurrentOrg = () => {
    const {
      core: { org },
    } = this.appGetState();
    return org.org_id;
  };

  private loadSelectedUploads = async () => {
    const dim = this.getCurrentDimensions();
    const orgId = this.getCurrentOrg();
    try {
      return await fetchSelected(extractFiltersFromDimensions(dim), orgId);
      // await this.appUpdateState((s) => {
      //   s.uploads.selected = selected;
      // }, comment("loadSelectedUploads"));
    } catch (e) {
      layoutService.error(e, "Error: Unable to load selected uploads");
    }
    return {};
  };
}

const uploadService = new UploadService();
export { uploadService };
