import * as React from "react";
import { SITE_LONG_NAME } from "constants/site";
import { useSelector } from "react-redux";
import { currentOrgNameSelector } from "store/selectors/core.selectors";

interface TMeta {
  name?: string;
  title?: string;
}

const Meta = React.memo(({ name, title }: TMeta) => {
  const org = useSelector(currentOrgNameSelector);
  React.useEffect(() => {
    document.title = `${title ? `${title} | ` : ""}${name || SITE_LONG_NAME}${
      org && ` - ${org}`
    }`;
  }, [name, title, org]);

  return null;
});

export default Meta;
