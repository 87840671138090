import {
  IDataModel,
  INestedDataModel,
} from "components/common/ui/charts/chart";

export const calcNestedSeriesMax = (
  data: INestedDataModel[],
  maxOverride?: number
) =>
  maxOverride ||
  Math.max(
    ...data.reduce((m, d) => [...m, calcSeriesMax(d.data)], [] as number[])
  );

export const calcSeriesMax = (data: IDataModel[], maxOverride?: number) =>
  maxOverride || Math.max(...data.map((e) => e.data || 0));
export const calcDimensionMax = () => {};

export const defaultColorAccessor = (d: IDataModel) => d.metadata?.color;
