import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { TUser } from "models/User";
import TextInput from "components/common/ui/form-elements/text-input/TextField";
import Button from "components/common/ui/button/Button";
import { getSchema } from "utils/formUtils";
import PhoneTextInput from "components/common/ui/form-elements/text-input/PhoneTextField";
import { phone as phoneSchema } from "components/auth/forms/questionSchema";
import ToggleSwitch from "components/common/ui/form-elements/toggle-switch/ToggleSwitch";
import IconMfa from "components/common/icons/IconMfa.tsx";
import { IconChip } from "components/common/ui/chip/Chip";
import styles from "components/users/common/User.module.scss";

const email = Yup.string()
  .required("Please enter an email address")
  .email("Please enter a valid email address");
const firstname = Yup.string().required("Please enter a firstname");
const surname = Yup.string().required("Please enter a surname");
const phone = phoneSchema.required("Please enter a mobile phone number");
const schema = getSchema({ email, firstname, surname, phone });

const phonePrefix = "+64";

interface Props {
  user?: TUser;
  onSubmit: (attributes: Partial<TUser>) => void;
  onCancel: (attributes: Partial<TUser>) => void;
  title?: string;
  saveText?: string;
  children?: React.ReactNode;
}

const UserDetailForm = ({
  user,
  onSubmit,
  onCancel,
  title = "Edit User Details",
  saveText = "Update User",
  children,
}: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const defaultSettings = user
    ? {
        defaultValues: {
          email: user.email,
          firstname: user.firstname,
          surname: user.surname,
          phone: user.phone.replace(phonePrefix, ""),
          mfa_enabled: user.mfa_enabled,
        },
      }
    : {
        defaultValues: {
          mfa_enabled: true,
        },
      };
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    mode: "onBlur",
    resolver: schema,
    ...defaultSettings,
  });
  const handleSave = async (attributes: Partial<TUser>) => {
    setSubmitting(true);
    const { phone, ...rest } = attributes;
    const fixedPhone = `${phonePrefix}${phone}`;
    await onSubmit({ ...rest, phone: fixedPhone });
    setSubmitting(false);
  };

  return (
    <>
      <h2>{title}</h2>
      <form onSubmit={handleSubmit(handleSave)} noValidate>
        <TextInput
          {...register("firstname")}
          label="Firstname"
          error={errors["firstname"]}
        />
        <TextInput
          {...register("surname")}
          label="Surname"
          name="surname"
          error={errors["surname"]}
        />
        <TextInput
          label="Email"
          {...register("email")}
          error={errors["email"]}
        />
        <PhoneTextInput
          label="Mobile phone number"
          {...register("phone")}
          prefix={phonePrefix}
          error={errors["phone"]}
        />
        <div className={styles.mfaSwitch}>
          <IconChip variant="empty" theme="default">
            <IconMfa />
          </IconChip>
          <ToggleSwitch
            label={"Enable Two-Step Verification"}
            {...register("mfa_enabled")}
            error={errors["mfa_enabled"]}
          />
        </div>

        {children}
        <div className={styles.actions}>
          <Button variant="empty" onClick={onCancel}>
            Cancel
          </Button>
          <Button theme="success" rounded type="submit" submitting={submitting}>
            {saveText}
          </Button>
        </div>
      </form>
    </>
  );
};

export default UserDetailForm;
