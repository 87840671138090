import { DIMENSION_MATCH, IDimension, IDimensions } from "models/Dimension";

export enum SCHEMA_DATA_TYPE {
  STRING = "synergia::type::string",
  NUMBER = "synergia::type::number",
  DATE = "synergia::type::date",
  DATETIME = "synergia::type::datetime",
  TIME = "synergia::type::time",
  NHI = "synergia::type::nhi",
}

export enum VALIDATION_TYPES {
  ALLOWED_VALUES = "allowed_values",
  MIN_LENGTH = "min_length",
  MAX_LENGTH = "max_length",
  MIN_VALUE = "min_value",
  MAX_VALUE = "max_value",
}

export enum ERROR_LEVELS {
  WARNING = "warning",
  ERROR = "error",
  CRITICAL = "critical",
}

export const DEFAULT_TYPE_CHECK_ERROR_LEVEL = ERROR_LEVELS.CRITICAL;

type TStringValidationRules =
  | VALIDATION_TYPES.ALLOWED_VALUES
  | VALIDATION_TYPES.MIN_LENGTH
  | VALIDATION_TYPES.MAX_LENGTH;

type TNumberValidationRules =
  | VALIDATION_TYPES.MIN_VALUE
  | VALIDATION_TYPES.MAX_VALUE;

interface IBaseValidation {
  required?: boolean;
  treat_blank_value_as?: ERROR_LEVELS;
  treat_invalid_type_as?: ERROR_LEVELS;
  description?: string;
  format?: string; // special case for date & datetime format
}

// interface IDateValidation extends Pick<IBaseValidation, "format"> {
//   format: string; // required for date & datetime format
// }

//
// interface IArrayValidation {
//   name: "allowed_values";
//   value: string[];
//   level: IErrorLevel;
// }
// interface ILengthValidation {
//   name: "min_length" | "max_length";
//   value: number;
//   level: ERROR_LEVELS;
// }

// export interface IStringValidation extends IBaseValidation {
//   type: "synergia::type::string";
//   validation: (IArrayValidation | ILengthValidation)[];
// }
//
// interface INumberValidation extends IBaseValidation {
//   type: "synergia::type::number";
//   validation: {
//     name: TNumberValidationRules;
//     value: number;
//     level: ERROR_LEVELS;
//   }[];
// }
// interface IDateValidation extends IBaseValidation {
//   type: "synergia::type::date";
//   validation: {
//     name: TDateValidationRules;
//     value: string;
//     level: ERROR_LEVELS;
//   }[];
// }
//
// interface INhiValidation extends IBaseValidation {
//   type: "synergia::type::nhi";
//   validation?: [];
// }

export interface IValidationRule {
  name: TStringValidationRules | TNumberValidationRules;
  value: string | number | string[];
  level: ERROR_LEVELS;
}

export interface IValidationColumn extends IBaseValidation {
  type: SCHEMA_DATA_TYPE;
  validation?: IValidationRule[];
}

export interface ISchema {
  [column: string]: IValidationColumn;
}

export interface ISchemas {
  [schema_name: string]: {
    [schema_version: string]: ISchema;
  };
}

export interface ISchemaDimensions extends IDimensions {
  schema_name: IDimension;
  version: IDimension;
}

// export const schemaDimensions: IDimensions = {
export const schemaDimensions: ISchemaDimensions = {
  schema_name: {
    key: "schema_name",
    label: "Schema",
    options: [],
    buckets: null,
    match: DIMENSION_MATCH.EXACT,
  },
  version: {
    key: "version",
    label: "Version",
    options: [],
    buckets: null,
    match: DIMENSION_MATCH.EXACT,
  },
};
