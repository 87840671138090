import React, { useEffect } from "react";
import Meta from "components/common/Meta";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { useParams } from "react-router";
import { uploadService } from "services/UploadService";
import { useSelector } from "react-redux";
import Container from "components/common/layouts/Container";
import { CreateUploadButton } from "components/upload/list/CreateUploadButton";
import { UploadsList } from "components/upload/list/UploadsList";
import styles from "components/users/admin/list/UsersList.module.scss";
import { useHasChanged } from "hooks/useHasChanged";
import { isAppLoadedSelector } from "store/selectors/core.selectors";
import { schemaIsLoadedSelector } from "store/selectors/schema.selectors";

const ViewUploadsList = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const isSchemaLoaded = useSelector(schemaIsLoadedSelector);
  const refresh = useHasChanged(isAppLoaded && isSchemaLoaded);
  const orgChanged = useHasChanged(orgId);

  useEffect(() => {
    // Wait until user's org is loaded
    if (isAppLoaded) {
      // if query is empty (no URL search params) or orgId has changed
      if (refresh || orgChanged) {
        uploadService.loadList();
      }
    }
  }, [isAppLoaded, refresh, orgChanged]);
  return (
    <AppContentContainer spacing="none">
      <Meta title={"Upload List"} />
      <Container spacing={"extra"} spacingTopOnly>
        <div className={styles.header}>
          <h1>Uploads</h1>
          <CreateUploadButton orgId={orgId} />
        </div>
      </Container>
      <UploadsList
        setActive={uploadService.setFilterAndRefresh}
        setManyActive={uploadService.setManyFiltersAndRefresh}
        isAppLoaded={isAppLoaded && isSchemaLoaded}
      />
    </AppContentContainer>
  );
};

export default ViewUploadsList;
