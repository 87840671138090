import React from "react";
import cx from "classnames";
import LinkWrapper from "components/common/ui/link-wrapper/LinkWrapper";
import styles from "./ResponsiveTable.module.scss";

interface Props extends DefaultProps {
  to?: string;
  wrapperStyle?: TObjectAny;
}

const DefaultRow = ({ children, wrapperStyle, to, ...rest }: Props) => {
  const content = (
    <RowWrapper style={wrapperStyle}>
      <div role="row" {...rest}>
        {children}
      </div>
    </RowWrapper>
  );
  return to ? <LinkWrapper to={to}>{content}</LinkWrapper> : <>{content}</>;
};

const RowWrapper = ({ className, ...rest }: DefaultProps) => (
  <div className={cx(styles.rowWrapper, className)} {...rest} />
);

export default DefaultRow;
