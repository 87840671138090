import * as React from "react";
import {
  COMMUNITY_SUPPORTS_EXTERNAL_URL,
  PRIVATE_ROUTE_DETAILS as ROUTES,
  TRoute,
} from "constants/routes";
import IconHome from "components/common/icons/IconHome";
import IconSettings from "components/common/icons/IconSettings";
import IconUpload from "components/common/icons/IconUpload";
import IconScroll from "components/common/icons/IconScroll";
import IconMedReport from "components/common/icons/IconMedReport";
import IconGauge from "components/common/icons/IconGauge";
import IconLineChart from "components/common/icons/IconLineChart";
import IconProgress from "components/common/icons/IconProgress";

export interface TSidebarNavItem {
  name: string;
  icon: any;
  // to?: string;
  href?: string;
  target?: string;
  // requiredRole?: string | string[];
  route?: TRoute;
}

export interface TSidebarNavSection {
  sectionName?: string;
  items: TSidebarNavItem[];
}

export type TSidebarNav = TSidebarNavSection[];

export const sidebarNav: TSidebarNav = [
  {
    items: [
      {
        name: "Home",
        icon: <IconHome />,
        route: ROUTES.HOME,
      },
    ],
  },
  {
    sectionName: "Insights Dashboard",
    items: [
      {
        name: "System Measures",
        icon: <IconLineChart />,
        route: ROUTES.SYSTEM_MEASURES,
      },
      {
        name: "Quality Indicators",
        icon: <IconGauge />,
        route: ROUTES.QUALITY_INDICATORS,
      },
    ],
  },
  {
    sectionName: "Community Supports",
    items: [
      {
        name: "Community Supports",
        icon: <IconMedReport />,
        route: ROUTES.COMMUNITY_SUPPORTS,
        href: COMMUNITY_SUPPORTS_EXTERNAL_URL,
      },
    ],
  },
  {
    sectionName: "Data Portal",
    items: [
      {
        name: "Uploads",
        icon: <IconUpload />,
        route: ROUTES.VIEW_UPLOADS,
      },
      {
        name: "Schemas",
        icon: <IconScroll />,
        route: ROUTES.SCHEMA_DETAIL,
      },
    ],
  },
  {
    sectionName: "Admin",
    items: [
      {
        name: "Admin Users",
        icon: <IconSettings />,
        route: ROUTES.ADMIN_USERS,
      },
      {
        name: "Admin Uploads",
        icon: <IconProgress />,
        route: ROUTES.ADMIN_UPLOADS,
      },
    ],
  },
];
