import * as Yup from "yup";

export const username = Yup.string()
  .required("Please enter your email address")
  .email("Please enter a valid email address");

// const validation = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,\-.\/:;<=>?@\[\]^_`{\|}~\\])(?=.{10,}))|(?=.{16,})/; //Password lowercase, Uppercase, Number, Special, 16+ characters
// const validation = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~\\]))/; //Password lowercase, Uppercase, Number, Special, 16+ characters

export const password = Yup.string()
  .required("Please enter a password")
  .test("password-strength", "Password must be stronger", function () {
    const str = this.parent["password-strength"];
    return str ? str > 2 : true; // Slightly hacky workaround to check password-strength score inside password
  })
  // .matches(validation, "Password must meet minimum complexity criteria")
  .min(10, "Password must be at least 10 characters long");

export const passwordMatch = Yup.string()
  .required("Passwords must match")
  .oneOf([Yup.ref("password"), null], "Passwords must match");

export const phone = Yup.string()
  .trim()
  .matches(
    /(^[2])(\d{7,9}$)/g,
    "Please enter a valid mobile phone number. Remember to remove the first zero e.g. '0215556666' becomes '215556666'"
  );

export const mfaCode = Yup.string()
  .matches(/^[0-9]*$/, "The verification code should only contain numbers")
  .required("Please enter your verification code");
export const resetCode = Yup.string().required("Please enter a reset code");

// export const passwordStrength = Yup.number().min(
//   3,
//   "A Stronger password is required"
// );

export const verificationCode = Yup.string().required(
  "Please enter your verification code"
);
export const contact = Yup.string().required(
  "Please select a verification option"
);
