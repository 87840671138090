import { request } from "utils/http/Request";
import { USERS, getApiUrl, USER, CURRENT_USER } from "constants/paths";
import { TUser, map, toServerObject } from "models/User";
import { TBucket } from "models/Dimension";

export const fetchCurrentUser = async (): Promise<TUser> => {
  const data = await request.get(getApiUrl(CURRENT_USER, {}));
  data.Permissions = data.permissions;
  return map(data as any);
};

export const fetchMany = async (
  { user_status, ...filter }: Record<string, TBucket>,
  orgId: string
) => {
  if (user_status) {
    filter["cognito:user_status"] = user_status;
  }
  const data = await request.get(getApiUrl(USERS, { orgId }), filter);
  const total = data.total || 0;
  const items = (data.Users as any[]) ? data.Users.map(map) : [];
  return { items, total };
};

export const fetchOneById = async (
  userId: string,
  orgId: string
): Promise<TUser> => {
  const data = await request.get(getApiUrl(USER, { orgId, userId }));
  return map(data as any);
};

export const save = async (
  user: Partial<TUser>,
  oldUser: TUser,
  orgId: string
): Promise<TUser> => {
  if (user.id) {
    await request.put(
      getApiUrl(USER, { orgId, userId: user.id }),
      toServerObject(user, "email")
    );
    return { ...oldUser, ...user };
  }
  const newUser = await request.post(
    getApiUrl(USERS, { orgId }),
    toServerObject(user, "username")
  );
  return { ...map(newUser as any), ...user };
};

export const resendInvite = async (
  user: Partial<TUser>,
  orgId: string
): Promise<boolean> => {
  await request.post(getApiUrl(USERS, { orgId }), {
    username: user.email,
    resend: true,
  });
  return true;
};
export const resetPassword = async (
  user: TUser,
  orgId: string
): Promise<TUser> => {
  await request.put(getApiUrl(USER, { orgId, userId: user.id }), {
    // username: user.email,
    reset_password: true,
  });
  return { ...user, status: "RESET_REQUIRED" };
};

export const enableUser = async (
  user: TUser,
  active: boolean,
  orgId: string
): Promise<TUser> => {
  await request.put(getApiUrl(USER, { orgId, userId: user.id }), {
    // username: user.email,
    active: active,
  });
  return { ...user, active };
};
