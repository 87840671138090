import React from "react";
interface Props {
  className?: string;
  phone: string;
}

// export const formatPhoneNumber = (phone: string) =>
//   `(${phone.slice(0, 3)}) ${phone.slice(3, 5)} ${phone.slice(
//     5,
//     8
//   )} ${phone.slice(8)}`;

export const formatPhoneNumber = (phone: string) => {
  const cleaned = phone
    .trim()
    .replace(/[()\s-]/g, "")
    .replace("+64", "0");

  //Landline
  if (cleaned.match(/^(03|04|06|07|09)\d{7}$/g)) {
    return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 5)} ${cleaned.slice(
      5
    )}`;
  }
  //Mobile
  if (cleaned.match(/^(021|022|025|027|028|029)\d{6,8}$/g)) {
    return `${cleaned.slice(0, 3)} ${cleaned.slice(3, 6)} ${cleaned.slice(6)}`;
  }
  //0800 etc number
  if (cleaned.match(/(0508|0800|0900)\d{5,8}$/g)) {
    return `${cleaned.slice(0, 4)} ${cleaned.slice(4, 7)} ${cleaned.slice(7)}`;
  }
  //Fallback landline
  if (cleaned.length === 7) {
    return `${cleaned.slice(0, 3)} ${cleaned.slice(3)}`;
  }

  return phone;
  // const pattern = /^(\((03|04|06|07|09)\)\d{7})|(\((021|022|025|027|028|029)\)\d{6,8})|((0508|0800|0900)\d{5,8})$/g
};

const FormattedPhoneNumber = ({ phone, className }: Props) => (
  <span>{formatPhoneNumber(phone)}</span>
);

export default FormattedPhoneNumber;
