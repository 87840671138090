import React, { Suspense, useCallback, useState } from "react";
import styles from "components/auth/forms/PasswordStrength/PasswordStrength.module.scss";
import PasswordTextInput from "components/common/ui/form-elements/text-input/PasswordTextField";
import { IPasswordFeedback } from "components/auth/forms/PasswordStrength/password-strength";
import cx from "classnames";
import InputFeedback from "components/common/ui/form-elements/InputFeedback";
import { ITextInput } from "components/common/ui/form-elements/text-input/TextField";
import {
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form/dist/types/form";

const LazyPwdStrength = React.lazy(
  () =>
    import("components/auth/forms/PasswordStrength/__PasswordStrengthCheck__")
);

const levels = ["Very Weak", "Weak", "Medium", "Strong", "Very Strong"];

interface Props extends ITextInput {
  password: string;
  strengthName?: string;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  trigger: (name: string) => void;
}
const PasswordStrength = ({
  name,
  password,
  label,
  description,
  noInputError,
  strengthName = "password-strength",
  register,
  setValue,
  trigger,
  ...props
}: Props) => {
  const [state, setState] = useState<IPasswordFeedback>({
    score: 0,
  });
  const onUpdate = useCallback(
    (f: IPasswordFeedback) => {
      setState(f);
      setValue(strengthName, f.score);
      trigger(name);
    },
    [name, trigger, setValue, strengthName]
  );
  return (
    <div
      className={cx(
        styles.root,
        styles[`score-${state?.score}`],
        !!props.error && styles.error
      )}
    >
      <label
        htmlFor={`text-input-${name}`}
        style={{ display: "block" }}
        className={styles.label}
        id={`text-input-label-${name}`}
      >
        {label}
        {label && description && <br />}
        {description && <span>{description}</span>}
      </label>
      <div className={styles.passwordWrapper}>
        <PasswordTextInput
          {...props}
          {...register(name)}
          className={styles.input}
          noInputError
        />
        <div className={styles.container}>
          <div className={styles.bar} />
        </div>
        <div className={styles.strength}>
          {state?.score > -1 ? levels[state?.score] : ""}
        </div>
        {/*<button className={styles.help}>Help</button>*/}
      </div>
      {!noInputError && (
        <>
          <InputFeedback
            name={name}
            error={props.error}
            visible={!!props.error}
            classNames={styles.errorFeedback}
          />
        </>
      )}

      <InputFeedback
        classNames={styles.feedback}
        name={name}
        error={
          state.feedback?.warning
            ? {
                type: "strength",
                message: state.feedback?.warning,
              }
            : undefined
        }
        visible={!!state.feedback?.warning}
      />
      {state.feedback?.suggestions?.map((s) => {
        return (
          s !== "Add more words that are less common." && (
            <InputFeedback
              key={s}
              classNames={styles.suggestion}
              name={name}
              error={{ type: "suggestion", message: s }}
              visible={true}
            />
          )
        );
      })}

      <input
        {...register(strengthName, { valueAsNumber: true })}
        type="number"
        className={styles.strengthInput}
        aria-hidden="true"
        tabIndex={-1}
      />

      <Suspense fallback={<div>Loading...</div>}>
        <LazyPwdStrength onUpdate={onUpdate} password={password} />
      </Suspense>
    </div>
  );
};

export default PasswordStrength;
