import React from "react";
import { useSelector } from "react-redux";
import { IconOrg } from "components/common/icons/twoToneIcons";
import { TOrg } from "models/Org";
import AppSettingsMenu from "./AppSettingsMenu";
import { coreService } from "services/CoreService";
import SyncParamState from "components/common/ui/sync-url-state/SyncParamState";
import {
  currentOrgSelector,
  isAppLoadedSelector,
  userOrgsSelector,
} from "store/selectors/core.selectors";
import styles from "./AppSettingsMenu.module.scss";

interface Props {
  userOrgs: TOrg[];
}

const SwitchOrg = () => {
  const userOrgs = useSelector(userOrgsSelector);
  if (userOrgs.length > 1) {
    return <MultiOrgMenu userOrgs={userOrgs} />;
  }
  return <></>;
};

const MultiOrgMenu = ({ userOrgs }: Props) => {
  const { org_id, org_name } = useSelector(currentOrgSelector);
  const isAppLoaded = useSelector(isAppLoadedSelector);

  const setCurrentOrg = async (org_id: string) => {
    const newOrg = userOrgs.find((o) => o.org_id === org_id);
    if (newOrg) {
      coreService.setCurrentOrg(newOrg);
    }
  };

  const options = userOrgs
    .filter((o) => o.org_id !== org_id)
    .map((org) => ({
      key: org.org_id,
      children: org.org_name,
      onClick: () => setCurrentOrg(org.org_id),
    }));

  return (
    <AppSettingsMenu
      options={options}
      aria-label={"Organisation Settings"}
      header={<Header name={org_name} />}
    >
      <IconOrg />
      <SyncParamState
        paramKey="orgId"
        state={org_id}
        setState={setCurrentOrg}
        loaded={isAppLoaded}
      />
    </AppSettingsMenu>
  );
};

const Header = ({ name }: { name: string }) => (
  <>
    <div className={styles.header}>
      <IconOrg className={styles.icon} />
      <div className={styles.title}>{name}</div>
    </div>
    <div className={styles.menuSection}>SWITCH ORGANISATION</div>
  </>
);

export default SwitchOrg;
