import React from "react";
import cx from "classnames";
import styles from "./ToggleIconButton.module.scss";
import Tooltip from "components/common/ui/tooltip/Tooltip";

interface Props extends DefaultProps {
  toggled: boolean;
  setToggle: (t: boolean) => void;
  onIcon: React.ReactNode;
  offIcon: React.ReactNode;
  tooltipContent: React.ReactNode;
}

const ToggleIconButton = ({
  toggled,
  setToggle,
  offIcon,
  onIcon,
  tooltipContent,
}: Props) => (
  <Tooltip content={tooltipContent}>
    <div className={cx(styles.toggle, toggled && styles.activeToggle)}>
      <button onClick={() => setToggle(false)}>{offIcon}</button>
      <button onClick={() => setToggle(true)}>{onIcon}</button>
    </div>
  </Tooltip>
);

export default ToggleIconButton;
