import { request } from "utils/http/Request";
import {
  getApiUrl,
  INDICATOR_DETAIL,
  INDICATOR_LIST,
  SYSTEM_MEASURE_LIST,
} from "constants/paths";
import {
  QueryIndicatorsDataShape,
  rollupIndicatorDetail,
  rollupIndicatorsList,
} from "components/insights/utils/indicatorUtils";
import {
  QueryMeasuresDataShape,
  rollupMeasuresList,
} from "components/insights/utils/measureUtils";

export const fetchIndicatorById = async (
  indicatorId: string,
  orgId: string,
  filter?: Record<string, any>
): Promise<any> => {
  return await request.get(
    getApiUrl(INDICATOR_DETAIL, { orgId, indicatorId }),
    filter
  );
};

export const fetchManyIndicators = async (
  orgId: string,
  filter?: Record<string, any>
): Promise<any> => {
  return await request.get(getApiUrl(INDICATOR_LIST, { orgId }), filter);
};

const defaultIndicatorListParams = {
  org_region: "all",
  ethnicity: "all",
  sixty_five_plus: "all",
  malignant: "all",
};

export const getQualityIndicatorList = async (
  orgId: string,
  params: Record<string, any>
): Promise<QueryIndicatorsDataShape> => {
  try {
    const rawData = await fetchManyIndicators(orgId, {
      ...defaultIndicatorListParams,
      ...params,
    });
    return rollupIndicatorsList(rawData);
  } catch (e) {
    throw e;
  }
};

export const getIndicatorDetail = async (
  indicatorId: string,
  orgId: string,
  params: Record<string, any>
) => {
  try {
    const raw = await fetchIndicatorById(indicatorId, orgId, params);

    return rollupIndicatorDetail(
      raw,
      indicatorId

      // ["ethnicity", "sixty_five_plus", "malignant"],
      // params
    );
  } catch (e) {
    throw e;
  }
};

export const fetchManyMeasures = async (
  orgId: string,
  filter?: Record<string, any>
): Promise<any> => {
  return await request.get(getApiUrl(SYSTEM_MEASURE_LIST, { orgId }), filter);
};

export const getSystemMeasuresList = async (
  orgId: string,
  params: Record<string, any>
): Promise<QueryMeasuresDataShape> => {
  try {
    const rawData = await fetchManyMeasures(orgId, {
      ...defaultIndicatorListParams,
      ...params,
    });
    return rollupMeasuresList(rawData.org);
  } catch (e) {
    throw e;
  }
};
