import { otherChartColor } from "utils/chartColorUtils";
import {
  akpsBuckets,
  deliverySettingsBuckets,
  iposBuckets,
  phaseBuckets,
  principalDiagnosisBuckets,
} from "models/insights/common";

export interface IIndicatorBucketDef {
  key: string;
  label: string;
  color?: string;
  disabled?: boolean;
  group?: string;
  index?: number;
}

export const qualityIndicatorBucketsDef = {
  "001": [
    { key: "true", label: "New Referrals" },
    { key: "false", label: "Existing patients", disabled: true },
  ],
  "002": [
    { key: "patient_alive", label: "patient_alive" },
    { key: "patient_died", label: "patient_died" },
    { key: "referral_admitted", label: "referral_admitted" },
    {
      key: "referral_admitted_and_patient_died",
      label: "referral_admitted_and_patient_died",
    },
  ],
  "003": [
    { key: "true", label: "Iwi Recorded" },
    { key: "false", label: "No Iwi Recorded", disabled: true },
  ],
  "004": [
    { key: "0-1", label: "0-1 days" },
    { key: "2-3", label: "2-3 days" },
    { key: "4-6", label: "4-6 days" },
    { key: "7+", label: "7+ days" },
    { key: "TBD", label: "No contacts recorded", color: otherChartColor() },
  ],
  "005": akpsBuckets,
  "006": phaseBuckets,
  "007": [
    { key: "true", label: "At least 1 Counselling session" },
    { key: "false", label: "No Counselling sessions", disabled: true },
  ],
  "008": deliverySettingsBuckets,
  "009": [
    { key: "true", label: "At least 1 IPOS assessment" },
    { key: "false", label: "No IPOS assessment", disabled: true },
  ],
  "010": principalDiagnosisBuckets,
  "011": [
    { key: "0-1", label: "0-1 days" },
    { key: "2-7", label: "2-7 days" },
    { key: "8-13", label: "8-13 days" },
    { key: "14-29", label: "14-29 days" },
    { key: "30+", label: "30+ days" },
  ],
  "012": akpsBuckets,
  "013": phaseBuckets,
  "014": iposBuckets,
  "015": [
    { key: "0-1", label: "0-1 months" },
    { key: "2-4", label: "2-4 months" },
    { key: "5-11", label: "5-11 months" },
    { key: "12-18", label: "12-18 months" },
    { key: "19-24", label: "19-24 months" },
    { key: "25+", label: "25+ months" },
  ],
  "016": [
    {
      key: "Private residence (including unit in retirement village)",
      label: "Private residence (including unit in retirement village)",
      group: "Private residence",
    },
    {
      key: "Hospice Inpatient Unit",
      label: "Hospice Inpatient Unit",
      group: "Hospice IPU",
    },
    {
      key: "Residential aged care, high level care (hospital level care)",
      label: "Residential aged care, high level care (hospital level care)",
      group: "Residential aged care",
    },
    {
      key: "Residential aged care, low level care (level 2 rest home)",
      label: "Residential aged care, low level care (level 2 rest home)",
      group: "Residential aged care",
    },
    {
      key: "Public hospital",
      label: "Public hospital",
      group: "Public hospital",
    },
    {
      key: "Correctional Facility",
      label: "Correctional Facility",
      group: "Other",
    },
    { key: "Other", label: "Other", group: "Other", color: otherChartColor() },
    {
      key: "missing",
      label: "Unknown",
      color: otherChartColor(1),
      group: "Not Recorded",
    },
  ],
  "017": [
    { key: "true", label: "Living in RC who die there" },
    { key: "false", label: "Died elsewhere", disabled: true },
  ],

  "019": [
    { key: "alive", label: "Alive" },
    { key: "died", label: "Died" },
    {
      key: "died_in_prev_four_year_periods",
      label: "Died in the previous 4y periods",
    },
  ],
  "020": [
    { key: "true", label: "Family only after death" },
    { key: "false", label: "Care delivered to patient", disabled: true },
  ],
  "021": [
    {
      key: "1",
      label: "1 session",
    },
    {
      key: "2",
      label: "2 sessions",
    },
    {
      key: "3",
      label: "3 sessions",
    },
    {
      key: "4",
      label: "4 sessions",
    },
    {
      key: "5+",
      label: "5 or more sessions",
    },
  ],
} as Record<string, IIndicatorBucketDef[]>;
