import React from "react";
import styles from "components/insights/indicators/indicator-detail/ChangeInPatientsSankey.module.scss";
import { Sankey, SankeyLink, SankeyNode } from "reaviz";

export const ChangeInPatientsSankey = ({
  edges = [],
  cohortEdges = [],
  headline,
}: {
  edges?: { source: string; target: string; value: number }[];
  cohortEdges?: { source: string; target: string; value: number }[];
  headline?: boolean;
}) => (
  <div className={styles.sankeyWrapper}>
    {cohortEdges.length > 0 && (
      <Sankey
        className={styles.sankey}
        // height={320}
        colorScheme={["#ccc", "#ccc", "#ccc", "#ccc"]}
        nodes={[
          { id: "old", title: "Cohort - Existing Patients" },
          { id: "new", title: "Cohort - New Referrals" },
          { id: "alive", title: "Cohort - Active Patients" },
          { id: "deaths", title: "Cohort - Deaths" },
        ].map((node, i) => (
          <SankeyNode
            key={`node-${i}`}
            {...node}
            label={<></>}
            // onClick={() => onNodeClick(node.title)}
          />
        ))}
        links={cohortEdges.map((link: any, i: number) => (
          <SankeyLink
            key={`link-${i}`}
            {...link}
            className={styles.sankeyLink}
            gradient={false}
            // color={["#8BDAB2", "#F27D63", "#8BDAB2", "#F27D63"][i]}
            opacity={(active) => (active ? 0.8 : 0.4)}
            tooltip={<></>}
          />
        ))}
      />
    )}

    {edges.length > 0 ? (
      <Sankey
        className={styles.sankey}
        // margins={[0,20,0,20]}
        // height={320}
        colorScheme={
          headline
            ? // ? ["#0076c4", "#5F1B7A", "#1ccea7", "#e7215c"]
              ["#923390", "#C14E83", "#F79C70", "#FFC969"]
            : ["#21d5a5", "#33cb65", "#109ED2", "#1d72dc"]
          // : ["#0a51bb", "#37659E", "#22A884", "#1AC7AC"]
        }
        // colorScheme={["#402159", "#433EAD", "#6ECE58", "#D44842"]}
        nodes={[
          { id: "old", title: "Existing Patients" },
          { id: "new", title: "New Referrals" },
          { id: "alive", title: "Active Patients" },
          { id: "deaths", title: "Deaths" },
        ].map((node, i) => (
          <SankeyNode
            key={`node-${i}`}
            {...node}
            // onClick={() => onNodeClick(node.title)}
          />
        ))}
        links={edges.map((link: any, i: number) => (
          <SankeyLink
            key={`link-${i}`}
            {...link}
            className={styles.sankeyLink}
            // gradient={false}
            // color={["#8BDAB2", "#F27D63", "#8BDAB2", "#F27D63"][i]}
            opacity={(active) => (active ? 0.8 : 0.4)}
          />
        ))}
      />
    ) : (
      <div className={styles.noData}>No Data</div>
    )}
  </div>
);
