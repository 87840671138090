import { TBucket } from "models/Dimension";

export const queryKeyFactory = (key: string) => {
  const queryKeys = {
    all: [key] as const,
    allLists: () => [...queryKeys.all, "list"] as const,
    list: (orgId: string, filters?: Record<string, TBucket>) =>
      filters
        ? ([...queryKeys.allLists(), orgId, filters] as const)
        : ([...queryKeys.allLists(), orgId] as const),
    allDetails: () => [...queryKeys.all, "detail"] as const,
    allOrgDetails: (orgId: string) =>
      [...queryKeys.allDetails(), orgId] as const,
    detail: (id: string, orgId?: string, filters?: Record<string, TBucket>) => {
      if (orgId) {
        return filters
          ? ([...queryKeys.allOrgDetails(orgId), id, filters] as const)
          : ([...queryKeys.allOrgDetails(orgId), id] as const);
      } else {
        return filters
          ? ([...queryKeys.allDetails(), id, filters] as const)
          : ([...queryKeys.allDetails(), id] as const);
      }
    },
  };

  return queryKeys;
};
