import React from "react";
import cx from "classnames";
import styles from "components/insights/common/DataSummaryTable.module.scss";
import { ChartTheme, IDataModel } from "components/common/ui/charts/chart";
import IconGauge from "components/common/icons/IconGauge";
import { defaultColorAccessor } from "utils/chartUtils";

interface Props extends DefaultProps {
  data?: IDataModel[];
  valueAccessor?: (d: IDataModel) => string | number;
  keyAccessor?: (d: IDataModel) => string;
  colorAccessor?: (d: IDataModel, index: number) => string | undefined;
  includeColor?: boolean;
  categoryTitle?: string;
  unitTitle?: string;
  dividerIcon?: React.ReactNode;
  theme?: ChartTheme;
}

export const DataSummaryTable = ({
  data,
  keyAccessor = defaultKeyAccessor,
  valueAccessor = defaultValueAccessor,
  colorAccessor = defaultColorAccessor,
  includeColor,
  categoryTitle = "Category",
  unitTitle = "Number",
  dividerIcon = <IconGauge />,
  className,
  theme = "sunset",
  ...props
}: Props) => (
  <div
    className={cx(className, styles.root, styles[`${theme}Theme`])}
    {...props}
  >
    {/*<div className={styles.divider}>*/}
    {/*  <IconGauge />*/}
    {/*</div>*/}
    {data && (
      <ul className={styles.table}>
        <li className={styles.header}>
          <span>{categoryTitle}</span>
          {dividerIcon}
          <span>{unitTitle}</span>
        </li>

        {data.map((d, i) => (
          <li className={styles.row} key={d.key}>
            <span>
              {includeColor && (
                <span
                  className={cx(styles.dot, styles[`dotIndex-${i}`])}
                  style={{ color: colorAccessor(d, i) }}
                />
              )}
              {keyAccessor(d)}
            </span>
            <span>{valueAccessor(d)}</span>
          </li>
        ))}
      </ul>
    )}
  </div>
);

const defaultValueAccessor = (d: IDataModel) => d.metadata?.numerator || d.data;
const defaultKeyAccessor = (d: IDataModel) => d.metadata?.group || d.key;
