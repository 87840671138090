import { RouterState } from "connected-react-router";
import {
  TUser,
  create as createUser,
  TUserDictionary,
  userListDimensions,
} from "models/User";
import { TBreadcrumb } from "services/LayoutService";
import { IToastStateElement } from "components/common/ui/toast/Toast";
import { ISchemas, schemaDimensions } from "models/Schema";
import { createUpload, TUpload, uploadDimensions } from "models/Upload";
import { createOrg } from "models/Org";
import { PERMISSIONS } from "models/Permissions";
import { DEFAULT_YEAR_PERIOD } from "models/Period";

export const initialState = {
  auth: {
    isLoading: false,
    loggedIn: false,
    error: null as AuthError,
    id: "" as string,
    permissions: [] as PERMISSIONS[],
  },
  core: {
    isAppLoaded: false as boolean,
    user: createUser({}),
    org: createOrg({}),
    error: null as null | Error,
  },
  layout: {
    isLoading: true,
    isExpanded: false,
    isMobileOpened: false,
    isHeaderLarge: false,
    isDesktop: false,
    breadcrumbs: [] as TBreadcrumb[],
    dialog: {
      open: false,
      title: "",
      content: "",
      cancel: true,
      actionLabel: "",
      action: () => {},
    },
    toasts: [] as IToastStateElement[],
  },
  users: {
    isLoading: false,
    globalDictionary: {} as TUserDictionary,
    list: {
      items: [] as TUser[],
      total: 0,
      dimensions: userListDimensions,
    },
    detail: {
      user: createUser({}),
    },
  },
  uploads: {
    isLoading: false,
    detail: createUpload(),
    list: {
      items: [] as TUpload[],
      total: 0,
      dimensions: uploadDimensions,
    },
    selected: {} as Record<string, TUpload>,
  },
  schema: {
    isLoaded: false,
    isLoading: false,
    detail: {} as ISchemas,
    dimensions: schemaDimensions,
  },
  insights: {
    period: DEFAULT_YEAR_PERIOD,
    org_region: "all",
  },
};

export type IAppState = typeof initialState;
export interface IState {
  app: IAppState;
  internal: TObjectAny;
  router: RouterState;
}
