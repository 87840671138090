import Select from "components/common/ui/select/Select";
import { YEAR_PERIOD_OPTIONS } from "models/Period";
import React from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { insightsFiltersSelector } from "store/selectors/insights.selectors";
import { appUpdateState } from "store";
import styles from "components/insights/common/InsightsFilters.module.scss";
import { currentOrgSelector } from "store/selectors/core.selectors";

const config = {
  inputChip: true,
  selectedOptionFormat: "chip" as const,
  expandArrow: true,
  noInputError: true,
};

export const InsightsFilters = (props: {
  reverse?: boolean;
  hideRegionFilter?: boolean;
  children?: React.ReactNode;
}) => {
  const filters = useSelector(insightsFiltersSelector);
  const org = useSelector(currentOrgSelector);
  const updateFilter = (name: "period" | "org_region", value: string) => {
    appUpdateState(
      (s) => (s.insights[name] = value),
      "Insights::updateInsightsFilter"
    );
  };
  return (
    <div className={cx(styles.root, props.reverse && styles.reverse)}>
      {props.children}
      {!props.hideRegionFilter && (
        <Select
          name="cohort"
          value={filters.org_region}
          onSelect={(name, value) => updateFilter("org_region", value)}
          label="Comparison Cohort"
          config={config}
          options={[
            { key: "all", label: "National Hospices" },
            { key: "region", label: `${org.org_region} Hospices` },
          ]}
        />
      )}

      <Select
        name="period"
        value={filters.period}
        onSelect={(name, value) => updateFilter("period", value)}
        label="Period"
        config={config}
        options={YEAR_PERIOD_OPTIONS}
      />
    </div>
  );
};
