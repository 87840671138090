import { IState } from "store/index";

export const uploadDetailSelector = (state: IState) => state.app.uploads.detail;


export const uploadListSelector = (state: IState) => state.app.uploads.list.items;
export const uploadDimSelector = (state: IState) =>
  state.app.uploads.list.dimensions;

export const uploadSelectedSelector = (state: IState) => state.app.uploads.selected;

export const uploadIsLoadingSelector = (state: IState) =>
  state.app.uploads.isLoading;

