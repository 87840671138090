type MapReduce<Datum> = (
  collection: Datum[],
  keyAccessor?: string | KeyAccessor<Datum>,
  reducer?: string | Reducer<Datum>
) => any;

type KeyByMapReduce<Datum> = (
  collection: Datum[] | Record<string, Datum>,
  keyAccessor?: string | KeyAccessor<Datum>,
  reducer?: string | Reducer<Datum>
) => any;

export type KeyAccessor<Datum> = (
  d: Datum,
  i: number,
  map: TObjectAny
) => string | number;

export type Reducer<Datum> = (
  d: Datum,
  i: number,
  key: string,
  map: TObjectAny
) => any;

export const keyBy: KeyByMapReduce<any> = (collection = [], key, reducer) =>
  Array.isArray(collection)
    ? mapReduce(collection, key, reducer)
    : mapReduce(Object.values(collection), key, reducer);

export const mapReduce: MapReduce<any> = (
  collection,
  keyAccessor,
  reducer = defaultReducer
) =>
  (collection || []).reduce((m, x, i) => {
    const k = keyAccessor
      ? typeof keyAccessor === "string"
        ? x[keyAccessor]
        : keyAccessor(x, i, m)
      : x;

    const v = typeof reducer === "string" ? x[reducer] : reducer(x, i, k, m);

    return {
      ...m,
      [k]: v,
    };
  }, {});

const defaultReducer: Reducer<any> = (d) => d;

export const cleanOrderedLabel = (label: string | number): string => {
  const s = `${label}`;
  let parts = s.split("@");
  if (parts.length === 1) {
    return s;
  }
  parts.shift();
  return parts.join("@");
};

export const generateUEID = () => {
  let first: string | number = (Math.random() * 46656) | 0;
  let second: string | number = (Math.random() * 46656) | 0;
  first = ("000" + first.toString(36)).slice(-3);
  second = ("000" + second.toString(36)).slice(-3);
  return first + second;
};

export const generateTimestampID = (id?: string, frequency: number = 1500) =>
  `${id || generateUEID()}::${Math.round(Date.now() / frequency)}`; // generates a different timestamp approx every 1.5-2 seconds
