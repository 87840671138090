import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ISchemaDimensions, ISchemas } from "models/Schema";
import ListFilters from "components/common/ui/filter/ListFilters";
import Container from "components/common/layouts/Container";
import { SchemaDetail } from "components/schema/detail/SchemaDetail";
import Meta from "components/common/Meta";
import Accordian from "components/common/ui/accordian/Accordian";
import ErrorDefinitions from "components/upload/common/ErrorDefinitions";
import Button from "components/common/ui/button/Button";
import { schemaService } from "services/SchemaService";
import {
  schemaDetailSelector,
  schemaDimSelector,
} from "store/selectors/schema.selectors";
import styles from "components/schema/detail/SchemaPage.module.scss";

const getSchema = (schemas: ISchemas, dim: ISchemaDimensions) => {
  const name = Array.isArray(dim.schema_name.buckets)
    ? dim.schema_name.buckets[0]
    : dim.schema_name.buckets || "";
  const version = Array.isArray(dim.version.buckets)
    ? dim.version.buckets[0]
    : dim.version.buckets || "";
  return schemas[name][version];
};

export const SchemaPage = () => {
  const [expandDef, setExpandDef] = useState(true);
  const schemas = useSelector(schemaDetailSelector);
  const dimensions = useSelector(schemaDimSelector);

  const currentSchemaName =
    dimensions.schema_name.options.find(
      (k) => k.key === dimensions.schema_name.buckets
    )?.label || "";
  const currentSchema = getSchema(schemas, dimensions);

  return (
    <div>
      <>
        <Meta
          title={`${currentSchemaName} Schema - v${dimensions.version.buckets}`}
        />
        <Container
          spacing={"extra"}
          spacingTopOnly
          className={styles.headerContainer}
        >
          <div className={styles.pageHeader}>
            <div>
              <h1>{currentSchemaName}</h1>
              <h2>Schema Definition</h2>
            </div>
            <div className={styles.flex}>
              <ListFilters
                className={styles.filters}
                filters={["schema_name", "version"]}
                dimensions={dimensions}
                setActive={schemaService.setFilter}
                setManyActive={schemaService.setManyFilters}
              />
              <Button
                onClick={() => setExpandDef(!expandDef)}
                variant="empty"
                theme="secondary"
                rounded
              >
                {expandDef ? "Hide" : "Show"} Error definitions
              </Button>
            </div>
          </div>
          <Accordian isOpen={expandDef}>
            <ErrorDefinitions />
          </Accordian>
        </Container>

        <Container spacing={"none"}>
          {currentSchema && <SchemaDetail schema={currentSchema} />}
        </Container>
      </>
    </div>
  );
};
