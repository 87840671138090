import React from "react";
import { useSelector } from "react-redux";
import { currentOrgSelector } from "store/selectors/core.selectors";
import { IDataModel } from "components/common/ui/charts/chart";
import { formatNumber, formatPercent } from "utils/format/numberUtils";
import { Headline, Highlight } from "components/insights/common/Headline";

interface Props extends DefaultProps {
  indicator: string;
  data?: IDataModel[];
}

export const IndicatorHeadline = ({ indicator, data }: Props) => {
  const org = useSelector(currentOrgSelector);
  if (!data) {
    return <></>;
  }
  let d;

  switch (indicator) {
    case "001":
      d = data.find((k) => k.key === "true::hospice");
      return (
        <Headline num={formatNumber(d?.metadata?.numerator || 0)}>
          patients were new referrals that quarter, representing{" "}
          <Highlight>{d?.metadata?.value}</Highlight> of the patients at{" "}
          <Highlight>{org.org_name}</Highlight>
        </Headline>
      );

    case "002":
      d = data.find((k) => k.key === "002::hospice");
      return (
        <Headline num={data[0].metadata?.value}>
          change in total patients from the previous quarter, being cared for at{" "}
          <Highlight>{org.org_name}</Highlight>
        </Headline>
      );

    case "003":
      d = data.find((k) => k.key === "true::hospice");
      return (
        <Headline num={formatNumber(d?.metadata?.numerator || 0)}>
          of Māori patients have their Iwi recorded, which is{" "}
          <Highlight>{d?.metadata?.value}</Highlight> of the total Māori
          patients at <Highlight>{org.org_name}</Highlight>
        </Headline>
      );

    case "004":
      d = data.find((k) => k.key === "0-1::hospice");
      return (
        <Headline num={formatNumber(d?.metadata?.numerator || 0)}>
          patients who are contacted within 1 day of referral at{" "}
          <Highlight>{org.org_name}</Highlight>
        </Headline>
      );

    case "005":
      d = data.find((k) => k.key === "40-50::hospice");
      return (
        <Headline num={formatNumber(d?.metadata?.numerator || 0)}>
          patients had an initial AKPS score of 40 or 50, which was{" "}
          <Highlight>{d?.metadata?.value}</Highlight> of all new patients at{" "}
          <Highlight>{org.org_name}</Highlight>
        </Headline>
      );

    case "006":
      d = data.find((k) => k.key === "Stable::hospice");
      return (
        <Headline num={formatNumber(d?.metadata?.numerator || 0)}>
          {" "}
          patients had an initial Phase of Illness which was stable, which was{" "}
          <Highlight>{d?.metadata?.value}</Highlight> of all new patients at{" "}
          <Highlight>{org.org_name}</Highlight>
        </Headline>
      );

    case "007":
      d = data.find((k) => k.key === "true::hospice");
      return (
        <Headline num={d?.metadata?.value}>
          of patients at <Highlight>{org.org_name}</Highlight> had a counselling
          session within the last year.
        </Headline>
      );

    case "008":
      d = data.find((k) => k.key === "Private residence::hospice");
      return (
        <Headline num={formatNumber(d?.metadata?.numerator || 0)}>
          {" "}
          contacts were provided at home by{" "}
          <Highlight>{org.org_name}</Highlight>, which was{" "}
          <Highlight>{d?.metadata?.value}</Highlight> of all contacts in that
          quarter.
        </Headline>
      );

    case "009":
      d = data.find((k) => k.key === "true::hospice");
      return (
        <Headline num={d?.metadata?.value}>
          of patients at <Highlight>{org.org_name}</Highlight> had more than one
          IPOS symptom score recorded
        </Headline>
      );

    case "010":
      d = data.filter((k) => k.key !== "Cancer::hospice");
      return (
        <Headline
          num={formatNumber(
            d.reduce((n, x) => n + (x.metadata?.numerator || 0), 0)
          )}
        >
          {" "}
          patients whose primary clinical condition was{" "}
          <Highlight>non-cancer</Highlight> had an Inpatient Unit attendance at{" "}
          <Highlight>{org.org_name}</Highlight>, which was{" "}
          <Highlight>
            {formatPercent(d.reduce((n, x) => n + (x.data || 0), 0))}
          </Highlight>{" "}
          of all attendances.
        </Headline>
      );

    case "011":
      return (
        <Headline
          num={formatPercent(
            (data.find((k) => k.key === "0-1::hospice")?.data || 0) +
              (data.find((k) => k.key === "2-7::hospice")?.data || 0)
          )}
        >
          of patients had IPU attendances shorter than 8 days at{" "}
          <Highlight>{org.org_name}</Highlight>.
        </Headline>
      );

    case "012":
      return (
        <Headline
          num={formatNumber(
            (data.find((k) => k.key === "0-10::hospice")?.metadata?.numerator ||
              0) +
              (data.find((k) => k.key === "20-30::hospice")?.metadata
                ?.numerator || 0)
          )}
        >
          patients had an initial AKPS score of 0 to 30 on admission to IPU,
          which was{" "}
          <Highlight>
            {formatPercent(
              (data.find((k) => k.key === "0-10::hospice")?.data || 0) +
                (data.find((k) => k.key === "20-30::hospice")?.data || 0)
            )}
          </Highlight>{" "}
          of all admissions at <Highlight>{org.org_name}</Highlight>
        </Headline>
      );

    case "013":
      d = data.find((k) => k.key === "Unstable::hospice");
      return (
        <Headline num={formatNumber(d?.metadata?.numerator || 0)}>
          {" "}
          patients had an initial Phase of Illness entering IPU which was
          unstable, which was <Highlight>{d?.metadata?.value}</Highlight> of all
          admissions at <Highlight>{org.org_name}</Highlight>
        </Headline>
      );

    case "014":
      return (
        <Headline
          num={formatNumber(
            (data.find((k) => k.key === "21-30::hospice")?.metadata
              ?.numerator || 0) +
              (data.find((k) => k.key === "31-40::hospice")?.metadata
                ?.numerator || 0)
          )}
        >
          {" "}
          patients had an initial IPOS Score of 21 or greater, which was{" "}
          <Highlight>
            {formatPercent(
              (data.find((k) => k.key === "21-30::hospice")?.data || 0) +
                (data.find((k) => k.key === "31-40::hospice")?.data || 0)
            )}
          </Highlight>{" "}
          of all admissions at <Highlight>{org.org_name}</Highlight>
        </Headline>
      );

    case "015":
      d = data.find((k) => k.key === "true::hospice");
      return (
        <Headline
          num={formatNumber(
            (data.find((k) => k.key === "12-18::hospice")?.metadata
              ?.numerator || 0) +
              (data.find((k) => k.key === "19-24::hospice")?.metadata
                ?.numerator || 0) +
              (data.find((k) => k.key === "25+::hospice")?.metadata
                ?.numerator || 0)
          )}
        >
          {" "}
          patients spent longer than 12 months with{" "}
          <Highlight>{org.org_name}</Highlight>, which was{" "}
          <Highlight>
            {formatPercent(
              (data.find((k) => k.key === "12-18::hospice")?.data || 0) +
                (data.find((k) => k.key === "19-24::hospice")?.data || 0) +
                (data.find((k) => k.key === "25+::hospice")?.data || 0)
            )}
          </Highlight>{" "}
          of the total patients
        </Headline>
      );

    case "016":
      d = data.find((k) => k.key === "Private residence::hospice");
      return (
        <Headline num={formatNumber(d?.metadata?.numerator || 0)}>
          {" "}
          patients died at home who were cared for by{" "}
          <Highlight>{org.org_name}</Highlight>, which was{" "}
          <Highlight>{d?.metadata?.value}</Highlight> of the total deaths in the
          quarter
        </Headline>
      );

    case "017":
      d = data.find((k) => k.key === "true::hospice");
      return (
        <Headline num={formatNumber(d?.metadata?.numerator || 0)}>
          {" "}
          patients indicated their preferred place of death in{" "}
          <Highlight>{org.org_name}</Highlight> catchment, which is{" "}
          <Highlight>{d?.metadata?.value}</Highlight> of the total
        </Headline>
      );

    case "019":
      d = data.find((k) => k.key === "true::hospice");
      return (
        <Headline num={formatNumber(d?.metadata?.numerator || 0)}>
          {" "}
          patients cared for by <Highlight>{org.org_name}</Highlight> died in
          the last year, which was <Highlight>{d?.metadata?.value}</Highlight>{" "}
          of the total.
        </Headline>
      );

    case "020":
      d = data.find((k) => k.key === "true::hospice");
      return (
        <Headline num={d?.metadata?.value}>
          of the work at <Highlight>{org.org_name}</Highlight> is with families
          of those who have already died.
        </Headline>
      );

    //TODO: Currently undercounts 5+ (treats them all as 5) - need to update if API gives additional info
    case "021":
      d = data.map((d) => d.metadata?.numerator || 0);
      return (
        <Headline
          num={formatNumber(
            d.reduce((n, x, i) => n + x * (i + 1), 0) /
              d.reduce((n, x) => n + x, 0),
            1
          )}
        >
          the average number of counsellling and grief sessions provided to
          families after death for <Highlight>{org.org_name}</Highlight>
        </Headline>
      );

    default:
      return <></>;
  }
};
