import { verifyPermisisons } from "utils/authUtils";

export enum PERMISSIONS {
  VIEW_ORG = "can_read_org",

  // Data Portal
  VIEW_UPLOADS = "can_read_org_uploads",
  CREATE_UPLOADS = "can_write_org_uploads",

  // CSP portal
  CSP_VIEW_ACTIVITY = "can_read_org_cs_activities",
  CSP_EDIT_ACTIVITY = "can_write_org_cs_activities",
  CSP_VIEW_FACILITIES = "can_read_cs_facilities",
  CSP_EDIT_FACILITIES = "can_write_cs_facilities",

  // Insights Dashboard
  INSIGHTS_VIEW_PIPELINES = "can_read_data_pipeline_jobs",
  INSIGHTS_VIEW_QI = "can_read_org_quality_indicators",
  INSIGHTS_VIEW_SYSTEM_VOLUME = "can_read_org_system_volume_measures",

  // Admin permissions
  LIST_USERS = "can_read_org_users",
  EDIT_USERS = "can_write_org_users",
  EDIT_ORG = "can_write_org",

  // Super user permissions
  SUPERUSER_VIEW_ORGS = "can_read_all_orgs",
  SUPERUSER_EDIT_ORGS = "can_write_all_orgs",
  SUPERUSER_VIEW_USERS = "can_read_all_users",
  SUPERUSER_EDIT_USERS = "can_write_all_users",

  SUPERUSER_VIEW_UPLOADS = "can_read_all_uploads",
  SUPERUSER_CREATE_UPLOADS = "can_write_all_uploads",
  SUPERUSER_EDIT_SCHEMA = "can_write_schema",

  SUPERUSER_VIEW_ACTIVITY = "can_read_all_cs_activities",
  SUPERUSER_EDIT_ACTIVITY = "can_write_all_cs_activities",

  SUPERUSER_INSIGHTS_QI = "can_read_all_quality_indicators",
  SUPERUSER_INSIGHTS_SYSTEM_VOLUME = "can_read_all_system_volume_measures",
}

export type TRequiredPermission = PERMISSIONS | PERMISSIONS[];
interface TPermissionOption {
  label: string;
  description: string;
  default?: boolean;
  readonly?: boolean;
}
export interface TPermissionOptionArrayItem extends TPermissionOption {
  value: PERMISSIONS;
}
export interface TPermissionGroup {
  label: string;
  description?: string;
  options: TPermissionOptionArrayItem[];
  readonly?: boolean;
}

//---------------------------------

const options = {
  [PERMISSIONS.VIEW_ORG]: {
    label: "View Organisation Details",
    description: "View general information about your organisation.",
    default: true,
  },
  [PERMISSIONS.LIST_USERS]: {
    label: "List Users",
    description:
      "View a list of usernames and emails within your organisation.",
  },
  [PERMISSIONS.EDIT_USERS]: {
    label: "Manage Users",
    description:
      "Create new users, and assign permissions to existing users within your hospice.",
  },
  [PERMISSIONS.VIEW_UPLOADS]: {
    label: "View Uploads",
    description:
      "View uploads for your organisation, including the viewing the detailed upload reports",
  },
  [PERMISSIONS.CREATE_UPLOADS]: {
    label: "Create Uploads",
    description: "Upload new datasets for your organisation.",
  },

  [PERMISSIONS.EDIT_ORG]: {
    label: "Edit Organisation details",
    description:
      "Edit key information about your organisation, such as the name.",
  },

  [PERMISSIONS.EDIT_ORG]: {
    label: "Edit Organisation details",
    description:
      "Edit key information about your organisation, such as the name.",
  },

  [PERMISSIONS.INSIGHTS_VIEW_QI]: {
    label: "Quality Indicators",
    description: "View the Quality Indicators section in the Insights Portal",
  },

  [PERMISSIONS.INSIGHTS_VIEW_SYSTEM_VOLUME]: {
    label: "System Measures",
    description: "View the System Measures section in the Insights Portal",
  },

  // [PERMISSIONS.INSIGHTS_VIEW_PIPELINES]: {
  //   label: "View data pipeline status",
  //   description: "View the state of the data pipeline for the Insights Portal",
  //   readonly: true,
  // },

  [PERMISSIONS.CSP_VIEW_ACTIVITY]: {
    label: "View Activity",
    description:
      "View a list of existing Community Services Activity for your hospice",
  },

  [PERMISSIONS.CSP_EDIT_ACTIVITY]: {
    label: "Manage Activity",
    description:
      "Create new Community Services Activity for your hospice, as well as editing existing activity",
  },

  [PERMISSIONS.CSP_VIEW_FACILITIES]: {
    label: "View Facilities",
    description:
      "When creating Community Services Activity, this allows you to view a list of facilities to choose from",
  },

  [PERMISSIONS.CSP_EDIT_FACILITIES]: {
    label: "Create Facilities",
    description:
      "When creating Community Services Activity, this allows you to create a new facility, if it doesn't exist in the facilities list",
  },

  [PERMISSIONS.SUPERUSER_VIEW_ORGS]: {
    label: "View All Hospices",
    description: "View & access all hospice's information ",
    readonly: true,
  },
  [PERMISSIONS.SUPERUSER_EDIT_ORGS]: {
    label: "Edit All Hospices",
    description: "Edit all hospices information ",
    readonly: true,
  },
  [PERMISSIONS.SUPERUSER_VIEW_USERS]: {
    label: "View All Users",
    description: "View a list of all users in the Data Commons",
    readonly: true,
  },
  [PERMISSIONS.SUPERUSER_EDIT_USERS]: {
    label: "Manage All Users",
    description:
      "Create new users in any hospice, and manage any existing users across the Data Commons",
    readonly: true,
  },

  [PERMISSIONS.SUPERUSER_VIEW_UPLOADS]: {
    label: "View All Uploads",
    description: "View uploads in any hospice",
    readonly: true,
  },
  [PERMISSIONS.SUPERUSER_CREATE_UPLOADS]: {
    label: "Create Uploads",
    description: "Create an upload in any hospice",
    readonly: true,
  },

  [PERMISSIONS.SUPERUSER_INSIGHTS_QI]: {
    label: "Quality Indicators - All Hospices",
    description:
      "View the Quality Indicators section for all hospices in the Insights Portal",
    readonly: true,
  },
  [PERMISSIONS.SUPERUSER_INSIGHTS_SYSTEM_VOLUME]: {
    label: "Quality Indicators - All Hospices",
    description:
      "View the System Measures section for all hospices in the Insights Portal",
    readonly: true,
  },

  [PERMISSIONS.SUPERUSER_VIEW_ACTIVITY]: {
    label: "Create All Activity",
    description: "View CSP activity in any hospice",
    readonly: true,
  },
  [PERMISSIONS.SUPERUSER_EDIT_ACTIVITY]: {
    label: "Manage All Activity",
    description: "Create and manage CSP activity in any hospice",
    readonly: true,
  },

  [PERMISSIONS.SUPERUSER_EDIT_SCHEMA]: {
    label: "Edit Schema",
    description: "Create new versions of Schema",
    readonly: true,
  },
} as Record<PERMISSIONS, TPermissionOption>;

export const permissionsTable = [
  {
    label: "Default",
    description:
      "General permissions used in many parts of the platform - it is highly recommended that all users have these permissions.",
    options: [getPermission(PERMISSIONS.VIEW_ORG)],
  },

  {
    label: "Insights Dashboard",
    description: "Grants access to the Insights Dashboard",
    options: [
      getPermission(PERMISSIONS.INSIGHTS_VIEW_QI),
      getPermission(PERMISSIONS.INSIGHTS_VIEW_SYSTEM_VOLUME),
      // getPermission(PERMISSIONS.INSIGHTS_VIEW_PIPELINES),
    ],
  },

  // {
  //   label: "Insights Dashboard - All Hospices",
  //   description:
  //     "Grants access to the views across all hospices in the Insights Dashboard",
  //   options: [
  //     getPermission(PERMISSIONS.SUPERUSER_INSIGHTS_QI),
  //     getPermission(PERMISSIONS.SUPERUSER_INSIGHTS_SYSTEM_VOLUME),
  //   ],
  // },
  {
    label: "Community Service Portal",
    description:
      "Grants access to the CSP - including viewing activity for your hospice and adding new activity",
    options: [
      getPermission(PERMISSIONS.CSP_VIEW_ACTIVITY),
      getPermission(PERMISSIONS.CSP_EDIT_ACTIVITY),
      getPermission(PERMISSIONS.CSP_VIEW_FACILITIES),
      getPermission(PERMISSIONS.CSP_EDIT_FACILITIES),
    ],
  },
  {
    label: "Upload Portal",
    description:
      "Grants access the Upload Portal - including creating & viewing the uploads for a hospice",
    options: [
      getPermission(PERMISSIONS.VIEW_UPLOADS),
      getPermission(PERMISSIONS.CREATE_UPLOADS),
    ],
  },
  {
    label: "Hospice Admin",
    description:
      "Grants access to the Admin section where hospice users can be created and managed",

    options: [
      getPermission(PERMISSIONS.LIST_USERS),
      getPermission(PERMISSIONS.EDIT_USERS),
      getPermission(PERMISSIONS.EDIT_ORG),
    ],
  },
  {
    label: "Super Admin",
    description:
      "Special user type that grants access to all sections & all hospices, regardless of what hospice they're part of. This is managed by Synergia & is not modifiable via the Data Commons platform.",

    options: [
      getPermission(PERMISSIONS.SUPERUSER_VIEW_ORGS),
      getPermission(PERMISSIONS.SUPERUSER_EDIT_ORGS),
      getPermission(PERMISSIONS.SUPERUSER_VIEW_USERS),
      getPermission(PERMISSIONS.SUPERUSER_EDIT_USERS),
      getPermission(PERMISSIONS.SUPERUSER_INSIGHTS_QI),
      getPermission(PERMISSIONS.SUPERUSER_INSIGHTS_SYSTEM_VOLUME),
      getPermission(PERMISSIONS.SUPERUSER_VIEW_UPLOADS),
      getPermission(PERMISSIONS.SUPERUSER_CREATE_UPLOADS),
      // getPermission(PERMISSIONS.SUPERUSER_EDIT_SCHEMA),
      getPermission(PERMISSIONS.SUPERUSER_VIEW_ACTIVITY),
      getPermission(PERMISSIONS.SUPERUSER_EDIT_ACTIVITY),
    ],
    readonly: true,
  },
];

export const permissionsOptions = Object.values(PERMISSIONS).map((o) =>
  getPermission(o)
);

//---------------------------------

function getPermission(option: PERMISSIONS): TPermissionOptionArrayItem {
  return { value: option, ...options[option] };
}

export const getDefaultPermissions = (
  roles?: PERMISSIONS[]
): Record<string, boolean> => {
  return permissionsOptions.reduce(
    (obj, p) => ({
      ...obj,
      [p.value]: roles ? verifyPermisisons(roles, p.value) : p.default,
    }),
    {}
  );
};

export const stripReadOnlyPermissions = (permissions?: PERMISSIONS[]) =>
  permissions && permissions.filter((p) => !options[p].readonly);
