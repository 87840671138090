import { keyBy } from "utils/common";

const defaultMeasure = {
  job_id: "" as string,

  org_id: "" as string,
  org_region: "" as string,
  year_period: "" as string,

  measure_category: "" as string,

  measure_column_1: "" as string,
  measure_key_1: "" as string,
  measure_column_2: "" as string,
  measure_key_2: "" as string,

  measure_value: 0 as number,
};

export type TMeasureItem = typeof defaultMeasure;

export const measureKeys = [
  "001",
  "002",
  "003",
  "004",
  "005",
  "006",
  "007",
  "008",
  "009",
  "010",
  "011",
  "012",
  "013",
  "014",
  "015",
  "016",
  "017",
  "018",
  "019",
  "020",
  "021",
  "022",
  "023",
  "024",
  "025",
  "026",
  "027",
  "028",
  "029",
  "030",
  "031",
  "032",
  "033",
  "034",
  "035",
  "036",
  "037",
  "038",
  "044",
  "045",
  "046",
] as const;

export type MeasureKey = typeof measureKeys[number];

export enum MEASURE_SECTION_KEYS {
  SUMMARY = "",
  PATIENTS = "patients",
  FLOW = "flow",
  REFERRALS = "referrals",

  DEATHS = "deaths",
  CONTACTS = "contacts",
}

export const measureSections = [
  {
    name: "Summary",
    slug: MEASURE_SECTION_KEYS.SUMMARY,
    // indicators: ["001", "002", "003"],
  },
  {
    name: "Patients",
    slug: MEASURE_SECTION_KEYS.PATIENTS,
    // indicators: ["001", "002", "003"],
  },
  // {
  //   name: "Flow",
  //   slug: MEASURE_SECTION_KEYS.FLOW,
  //   // indicators: ["004", "005", "006"],
  // },
  {
    name: "Referrals",
    slug: MEASURE_SECTION_KEYS.REFERRALS,
    // indicators: ["007", "008", "009"],
  },
  {
    name: "Contacts",
    slug: MEASURE_SECTION_KEYS.CONTACTS,
    // indicators: ["015", "016", "017", "019", "020", "021"],
  },
  {
    name: "Deaths",
    slug: MEASURE_SECTION_KEYS.DEATHS,
    // indicators: ["010", "011", "012", "013", "014"],
  },
];

export type MeasureSection = typeof measureSections[number] & { index: number };

export const measureSectionsMap: Record<string, MeasureSection> = keyBy(
  measureSections,
  "slug",
  (d, index) => ({ ...d, index })
);
