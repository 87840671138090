import { useQuery } from "react-query";
import { qualityIndicatorQueryKeys } from "queries/constants";
import { getQualityIndicatorList } from "api/InsightsApi";
import { QueryOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";

export const useGetQualIndicatorsList = (
  orgId: string,
  params = {} as Record<string, any>,
  options?: QueryOptions
) => {
  const { addErrorToast } = useToast();
  return useQuery(
    qualityIndicatorQueryKeys.list(orgId, params),
    () => getQualityIndicatorList(orgId, params),
    {
      onError: (e) =>
        addErrorToast(e, "Unable to load Quality Indicators", {
          id: qualityIndicatorQueryKeys.list(orgId).join("::"),
        }),
      ...options,
    }
  );
};
