import React from "react";
import cx from "classnames";
import styles from "./MiniLoader.module.scss";

interface Props {
  className?: string;
  variant?: "default" | "alt";
}
const MiniLoader = ({ className, variant = "default" }: Props) => (
  <div className={cx(styles.dot, styles[variant], className)}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default MiniLoader;
