import React from "react";
import { IUploadError } from "models/Upload";
import { formatNumber } from "utils/format/numberUtils";
import { getErrorReport } from "api/UploadApi";
import Button from "components/common/ui/button/Button";
import ErrorDefinitions, {
  ErrorSuccessDefinition,
  NoRowsScanned,
} from "components/upload/common/ErrorDefinitions";
import styles from "components/upload/detail/UploadDetail.module.scss";

export const FileStatus = ({
  rows,
  errors,
  errorTotal,
  orgId,
  uploadId,
  filename,
}: {
  rows: number;
  errors: IUploadError;
  errorTotal: number;
  orgId: string;
  uploadId: string;
  filename: string;
}) => (
  <div>
    <div className={styles.fileStatusHeader}>
      <div className={styles.rowScanned}>
        <div>{formatNumber(rows)}</div> Rows scanned in file
      </div>
      {errorTotal !== 0 && rows > 0 && (
        <Button
          onClick={() => getErrorReport(uploadId, orgId, filename)}
          rounded
          theme="secondary"
          variant="outline"
        >
          Download Detailed Report
        </Button>
      )}
    </div>
    {rows > 0 ? (
      errorTotal !== 0 ? (
        <ErrorDefinitions errors={errors} />
      ) : (
        <ErrorSuccessDefinition />
      )
    ) : (
      <NoRowsScanned />
    )}
  </div>
);
