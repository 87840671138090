import React from "react";
import TextInput, {
  ITextInput,
} from "components/common/ui/form-elements/text-input/TextField";
import styles from "components/common/ui/form-elements/text-input/PhoneTextField.module.scss";

interface IPasswordWrap extends ITextInput {
  prefix?: string;
  component: typeof TextInput;
}

const PhoneTextWrapper = React.forwardRef(
  (
    { component: Component, prefix = "+64", ...props }: IPasswordWrap,
    ref: React.Ref<any>
  ) => (
    <div className={styles.phoneWrapper}>
      <div className={styles.prefix}>{prefix}</div>
      <Component {...props} type="tel" ref={ref} />
    </div>
  )
);

export const PhoneTextInput = React.forwardRef(
  (props: ITextInput, ref: React.Ref<any>) => (
    <PhoneTextWrapper component={TextInput} {...props} ref={ref} />
  )
);

export default PhoneTextInput;
