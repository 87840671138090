import React, { useEffect } from "react";
import { useHistory } from "react-router";
import qs from "query-string";
import { useHasChanged } from "hooks/useHasChanged";
import { TBucket } from "models/Dimension";
import { logger } from "utils/Logger";
import { useSelector } from "react-redux";
import { IState } from "store";

interface Props {
  queryKeys: string[];
  state: Record<string, TBucket>;
  setState: (state: Record<string, TBucket>) => void;
  loaded?: boolean;
  pauseSync?: boolean;
}

const updateParams = (params: any) => {
  const prms = qs.stringify(params, { arrayFormat: "comma" });
  return prms.length > 0 ? `?${prms.toString()}` : "";
};

// Checks if there is a difference in query & state for a give set of keys
const isDiff = (
  keys: string[],
  query: Record<string, any>,
  state: Record<string, any>
) =>
  !keys.every((k) => {
    const q = query[k];
    const s = state[k];
    if (Array.isArray(q) && Array.isArray(s)) {
      return q.every((x, i) => x === s[i]);
    }
    return q === s;
  });

const stateToString = (keys: string[], state: Record<string, any>) =>
  keys.reduce((s, k) => s + `|${k}::${state[k]}|`, "");

const getUpdates = (
  keys: string[],
  original: Record<string, any>,
  changed: Record<string, any>
) =>
  keys.reduce((q, k) => {
    return { ...q, [k]: changed[k] };
    //
    // if (original[k] != changed[k]) {
    //   return { ...q, [k]: changed[k] || original[k] };
    // }
    // return q;
  }, {});

const SyncUrlQueryState = React.memo(
  ({ queryKeys, state, setState, pauseSync = false }: Props) => {
    const history = useHistory();
    const queryState = useSelector((s: IState) => s.router.location.query);
    const hasSearchChanged = useHasChanged(
      stateToString(queryKeys, queryState)
    );
    const hasStateChanged = useHasChanged(stateToString(queryKeys, state));
    const isStateDiffQuery = isDiff(queryKeys, queryState, state);

    useEffect(() => {
      if (
        (hasStateChanged || hasSearchChanged) &&
        isStateDiffQuery &&
        !pauseSync
      ) {
        const newState = getUpdates(queryKeys, state, queryState);
        const blankQ = !Object.values(newState).some((x) => x !== undefined);
        if (
          hasSearchChanged && // when query/search changes update the state (for getting state from URL)
          !blankQ //Skip if query is blank
        ) {
          logger.info("SEARCH CHANGED", {
            state,
            queryState,
            newState: { ...state, ...newState },
          });
          setState({ ...state, ...newState });
        } else if (hasStateChanged) {
          //update params on state change
          const q = {
            ...queryState,
            ...getUpdates(queryKeys, queryState, state),
          };
          logger.info("STATE CHANGED", {
            state,
            queryState,
            newQuery: q,
          });

          // If initial query was blank, then replace the current url without creating browser history
          if (blankQ) {
            history.replace({
              search: updateParams(q),
            });
            // if not blank, then create a new browser history log
          } else {
            history.push({
              search: updateParams(q),
            });
          }
        }
      }
    }, [
      hasStateChanged,
      hasSearchChanged,
      history,
      queryState,
      isStateDiffQuery,
      state,
      pauseSync,
      queryKeys,
      setState,
    ]);

    return null;
  }
);

export default SyncUrlQueryState;
