import React from "react";
import Meta from "components/common/Meta";
import { useSelector } from "react-redux";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { SchemaPage } from "components/schema/detail/SchemaPage";
import { schemaIsLoadedSelector } from "store/selectors/schema.selectors";

const ViewSchemaDetail = () => {
  const isLoaded = useSelector(schemaIsLoadedSelector);

  return (
    <AppContentContainer spacing="none">
      <Meta title={"Schema Definitions"} />
      {isLoaded && <SchemaPage />}
    </AppContentContainer>
  );
};

export default ViewSchemaDetail;
