import React from "react";

export const QualIndicatorHeadlineWave = (props: DefaultProps) => (
  <svg
    width="100%"
    height="164"
    viewBox="0 0 2045 164"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2045 1.97421V0H0V105.1C54.3837 111.927 101.678 118.404 145.407 124.393C317.656 147.984 434.604 164 711.713 164C917.751 157.857 1109.69 122.715 1295.52 88.6911C1404.92 68.6612 1512.21 49.0191 1619 35.9098C1845.27 8.13404 1953 2.94306 2045 1.97421Z"
      fill="currentColor"
    />
  </svg>
);
