import React, { useMemo } from "react";
import { VIEW_UPLOADS } from "constants/routes";
import {
  IconDanger,
  IconError,
  IconWarning,
  IconSuccess,
} from "components/common/icons/twoToneIcons";
import ListFilters from "components/common/ui/filter/ListFilters";
import Container from "components/common/layouts/Container";
import Accordian from "components/common/ui/accordian/Accordian";
import { pascalCase } from "utils/format/stringUtils";
import { coreService } from "services/CoreService";
import { TSetActiveBucket, TSetManyBucketsActive } from "models/Dimension";
import { TUpload, uploadDimensions } from "models/Upload";
import { TOrg } from "models/Org";
import Chip from "components/common/ui/chip/Chip";
import Button from "components/common/ui/button/Button";
import styles from "components/upload/admin-list/AdminUploadsList.module.scss";

interface Props {
  children?: React.ReactNode;
  setActive: TSetActiveBucket;
  setManyActive: TSetManyBucketsActive;
  isAppLoaded: boolean;
  loading: boolean;
  orgs: TOrg[];
  dimensions: typeof uploadDimensions;
}

export const AdminUploadsList = ({
  setActive,
  setManyActive,
  isAppLoaded,
  loading,
  orgs,
  dimensions,
}: Props) => {
  const updating = loading || !isAppLoaded;
  const data = useMemo(
    () => orgs.sort((a, b) => a.org_name.localeCompare(b.org_name)),
    [orgs]
  );

  return (
    <>
      <Container spacing={"none"}>
        <ListFilters
          filters={["period", "year"]}
          dimensions={{ ...dimensions }}
          setActive={setActive}
          setManyActive={setManyActive}
          isLoading={!isAppLoaded}
          isUpdating={true}
        />
      </Container>
      {updating ? (
        <Container>Loading...</Container>
      ) : (
        <Container spacing={"none"} className={styles.orgList}>
          {data.map((d) => (
            <Accordian
              header={<Header org={d} />}
              key={d.org_id}
              className={styles.orgRow}
              headerClassName={styles.orgRowHeader}
            >
              <div className={styles.schemaContent}>
                {d.schemas.map((s) => (
                  <div key={s} className={styles.schemaRow}>
                    <b>{pascalCase(s)}:</b>

                    {d.selected[s] ? (
                      getUploadStatus(d.selected[s])
                    ) : (
                      <Chip className={styles.noUploads}>
                        No uploads selected
                      </Chip>
                    )}
                    <Button
                      variant="empty"
                      theme="primary"
                      to={coreService.getRoute(
                        VIEW_UPLOADS,
                        {
                          uploadId: d.selected[s]?.id,
                          orgId: d.org_id,
                        },
                        {
                          period: dimensions?.period?.buckets,
                          schema_name: s,
                          year: dimensions?.year?.buckets,
                        }
                      )}
                    >
                      View Uploads
                    </Button>
                  </div>
                ))}
              </div>
            </Accordian>
          ))}
        </Container>
      )}
    </>
  );
};

const Header = ({ org }: { org: TOrg }) => (
  <>
    <b>{org.org_name}</b>
    {getOverallStatus(org.selected, org.schemas)}
  </>
);

const getOverallStatus = (selected: Record<string, any>, schemas: string[]) => {
  if (!selected || Object.keys(selected).length === 0) {
    return (
      <Chip condensed theme="danger" icon={<IconDanger />}>
        No uploads selected
      </Chip>
    );
  }
  const successful_uploads = Object.keys(selected).length;
  if (successful_uploads >= schemas.length) {
    return (
      <Chip condensed theme="success" icon={<IconSuccess />}>
        Uploads Complete
      </Chip>
    );
  }

  return (
    <Chip condensed theme="warning" icon={<IconError />}>
      {successful_uploads} of {schemas.length} uploads selected
    </Chip>
  );
};

const getUploadStatus = (upload: TUpload) => {
  if (upload.errors_by_level?.critical) {
    return (
      <Chip theme="danger" icon={<IconDanger />}>
        Complete with critical issues
      </Chip>
    );
  }
  if (upload.errors_by_level?.error) {
    return (
      <Chip theme="warning" icon={<IconError />}>
        Complete with errors
      </Chip>
    );
  }
  if (upload.errors_by_level?.warning) {
    return (
      <Chip theme="info" icon={<IconWarning />}>
        Complete with warnings
      </Chip>
    );
  }

  return (
    <Chip theme="success" icon={<IconSuccess />}>
      Complete with no errors
    </Chip>
  );
};
