import React from "react";
import cx from "classnames";
import styles from "./HintBox.module.scss";

interface Props {
  className?: string;
  theme?: ThemeVariants;
  children: any;
  spacing?: "none" | "small" | "normal" | "large" | "extra" | "fullscreen";
}

const HintBox = ({
  children,
  className,
  spacing = "normal",
  theme = "default",
}: Props) => (
  <div
    className={cx(
      className,
      styles.container,
      spacing && styles[`spacing-${spacing}`],
      theme && styles[theme]
    )}
  >
    {children}
  </div>
);

export default HintBox;
