import React from "react";
import createIcon from "components/common/icons/createIcon";

const IconAsterisk = createIcon(
  <React.Fragment>
    <path
      fill="currentColor"
      d="M10.1742 2.09204C10.1657 2.00394 10.235 1.9277 10.3235 1.9277H13.8383C13.9268 1.9277 13.9961 2.00394 13.9876 2.09204L13.2764 9.50001C13.264 9.62966 13.4113 9.71248 13.5156 9.63444L19.3065 5.30064C19.3793 5.24619 19.4834 5.26844 19.5275 5.34788L21.2886 8.51736C21.3308 8.59328 21.2999 8.68904 21.2213 8.72598L14.6961 11.7919C14.5811 11.846 14.5811 12.0095 14.6961 12.0635L21.2213 15.1294C21.2999 15.1664 21.3308 15.2621 21.2886 15.338L19.5275 18.5075C19.4834 18.587 19.3793 18.6092 19.3065 18.5548L13.5156 14.221C13.4113 14.1429 13.264 14.2257 13.2764 14.3554L13.9876 21.7634C13.9961 21.8515 13.9268 21.9277 13.8383 21.9277H10.3235C10.235 21.9277 10.1657 21.8515 10.1742 21.7634L10.8854 14.3554C10.8978 14.2257 10.7504 14.1429 10.6462 14.221L4.85524 18.5548C4.78247 18.6092 4.67839 18.587 4.63424 18.5075L2.87316 15.338C2.83098 15.2621 2.86188 15.1664 2.94049 15.1294L9.46572 12.0635C9.58067 12.0095 9.58067 11.846 9.46572 11.7919L2.94049 8.72598C2.86188 8.68904 2.83098 8.59328 2.87316 8.51736L4.63424 5.34788C4.67839 5.26844 4.78248 5.24619 4.85524 5.30064L10.6462 9.63444C10.7504 9.71248 10.8978 9.62966 10.8854 9.50001L10.1742 2.09204Z"
    />
  </React.Fragment>,
  "Asterisk"
);

export default IconAsterisk;
