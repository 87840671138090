import React from "react";
import { useForm } from "react-hook-form";
import HeroButton from "components/common/ui/hero-button/HeroButton";
import { getSchema } from "utils/formUtils";
import TextInput from "components/common/ui/form-elements/text-input/TextField";
import { mfaCode } from "components/auth/forms/questionSchema";
import styles from "components/auth/forms/Forms.module.scss";

const schema = getSchema({ code: mfaCode });

interface Props {
  onSubmit: () => void;
  values: any;
  errorMessage: string | null;
  changeState: (state: string) => void;
}

const cleanError = (e: string) => {
  switch (e) {
    case "Invalid code or auth state for the user.":
      return "Invalid verification code";

    default:
      return e;
  }
};

const MfaChallengeForm = ({
  onSubmit,
  values,
  errorMessage,
  changeState,
}: Props) => {
  let defaultValues = {
    code: values.code || "",
  };
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    mode: "onBlur",
    resolver: schema,
    defaultValues: defaultValues,
  });
  const submit = (data: any) => {
    values.code = data.code;
    onSubmit();
  };

  return (
    <form
      onSubmit={handleSubmit(submit)}
      aria-label="enter multi-factor authentication code"
      noValidate
    >
      {errorMessage && (
        <div className={styles.error}>Error: {cleanError(errorMessage)}</div>
      )}

      <TextInput
        {...register("code")}
        label="Verification Code"
        error={errors["code"]}
      />

      <div className={styles.formActions}>
        <HeroButton type="submit">Submit Verification Code</HeroButton>
        <button onClick={() => changeState("signIn")} className="link">
          Back to Sign in
        </button>
      </div>
    </form>
  );
};

export default MfaChallengeForm;
