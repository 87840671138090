import React, { createContext, RefObject, useContext, useMemo } from "react";
import { Keyboard } from "components/common/ui/form-elements/keyboard/Keyboard";
import useConstant from "use-constant";
import { useClickOutside } from "hooks/useClickOutside";

interface ISelectState {
  isOpened: boolean;
  isFocused: boolean;
  highlight: number | string;
}

interface Props {
  name: string;
  selected: string | string[];
  onSelect: (
    name: string,
    value: any,
    config?: { shouldValidate?: boolean; shouldDirty?: boolean }
  ) => void;
  isOpened: boolean;
  isFocused: boolean;
  highlight: number | string;
  setHighlight: (highlight: number | string) => void;
  setState: (newState: Partial<ISelectState>) => void;
  multiSelect?: boolean;
  wrapRef: RefObject<any>;
  children: React.ReactElement;
}

const initialSelectState = {
  name: "",
  highlight: -1 as string | number,
  active: "" as string | string[],
  options: [] as string[],
};
export const SelectState = createContext<[typeof initialSelectState, any, any]>(
  [initialSelectState, undefined, undefined]
);

const SelectWrapper = React.memo(
  ({
    name,
    selected,
    onSelect,
    isOpened,
    isFocused,
    highlight,
    setState,
    multiSelect = false,
    wrapRef,
    children,
  }: Props) => {
    const optionKeys = useConstant(() => [] as string[]);
    // const wrapperElementRef = useRef<HTMLDivElement>(null);

    const onChange = (v: string | number) => {
      if (v !== -1) {
        onSelect(name, v);
      }
    };
    const onHover = (h: string | number) => setState({ highlight: h });
    const handleClickOutside = () => {
      if (isFocused && !isOpened) {
        setState({
          isOpened: false,
          isFocused: false,
          highlight: -1,
        });
      }
    };

    const onOptionClick = (value: string) => {
      if (!multiSelect) {
        setState({
          isOpened: false,
          isFocused: true,
        });
      }
      onChange(value);
    };
    const onTab = () => {
      if (isOpened) {
        setState({
          isOpened: false,
          isFocused: true,
          highlight: -1,
        });
      }
    };
    const onEnter = () => {
      if (isOpened) {
        onChange(highlight);
        if (!multiSelect) {
          onTab();
        }
      } else {
        setState({
          // highlight:selected,
          isOpened: true,
        });
      }
    };
    const onEsc = () => {
      setState({
        isOpened: false,
        isFocused: false,
      });
    };
    const onUp = () => {
      if (isOpened) {
        const prevIndex = optionKeys.indexOf(`${highlight}`);
        const nextIndex =
          prevIndex - 1 >= 0 ? prevIndex - 1 : optionKeys.length - 1;
        setState({
          highlight: optionKeys[nextIndex],
        });
      } else {
        setState({
          isOpened: true,
        });
      }
    };
    const onDown = () => {
      if (isOpened) {
        const prevIndex = optionKeys.indexOf(`${highlight}`);
        const nextIndex =
          prevIndex + 1 !== optionKeys.length ? prevIndex + 1 : 0;
        setState({
          highlight: optionKeys[nextIndex],
        });
      } else {
        setState({
          isOpened: true,
        });
      }
    };
    useClickOutside(wrapRef, handleClickOutside);

    return (
      <>
        <SelectState.Provider
          value={[
            {
              name,
              active: selected,
              highlight,
              options: optionKeys,
            },
            onOptionClick,
            onHover,
          ]}
        >
          <Keyboard
            onTab={onTab}
            onEnter={onEnter}
            onEsc={onEsc}
            onUp={onUp}
            onDown={onDown}
            onSpace={onEnter}
            preventDefaultKeys={["onEnter", "onEsc", "onUp", "onDown", "space"]}
          >
            {children}
          </Keyboard>
        </SelectState.Provider>
      </>
    );
  }
);

export const useSelectOptionState = (key: string) => {
  const [{ name, active, highlight, options }, onClickState, onHoverState] =
    useContext(SelectState);
  useConstant(() => {
    options.push(key);
  });

  const onClick = useConstant(() => () => {
    onClickState(key);
  });

  const onHover = useConstant(() => () => {
    onHoverState(key);
  });

  return useMemo(() => {
    return {
      name,
      isActive: active === key,
      isHighlighted: highlight === key,
      onClick,
      onHover,
    };
  }, [name, active, highlight, onClick, onHover, key]);
};

export default SelectWrapper;
