import React from "react";
import cx from "classnames";
import { IDataModel } from "components/common/ui/charts/chart";
import styles from "components/insights/indicators/indicator-detail/ChangeInPatientsHeadline.module.scss";

export const ChangeInPatientsHeadline = ({
  org,
  cohort,
  arrow,
}: {
  org?: IDataModel;
  cohort?: IDataModel;
  arrow?: boolean;
}) => (
  <div className={cx(styles.root, arrow && styles.arrow)}>
    <div>
      <div className={styles.label}>Hospice</div>
      <div
        className={cx(
          styles.bigNum,
          org && org?.data > 0 && styles.positive,
          org && org?.data < 0 && styles.negative
        )}
      >
        {org?.metadata?.value || <span>No Data</span>}
      </div>
    </div>
    <div>
      <div className={styles.label}>Cohort</div>
      <div
        className={cx(
          styles.smallNumber,
          cohort && cohort?.data > 0 && styles.positive,
          cohort && cohort?.data < 0 && styles.negative
        )}
      >
        {cohort?.metadata?.value || <span>No Data</span>}
      </div>
    </div>
  </div>
);
