import { PERMISSIONS, TRequiredPermission } from "models/Permissions";

export enum ROUTE_PARAMS {
  ORG = "orgId",
  USER = "id",
  UPLOAD = "uploadId",
  INDICATOR = "indicatorId",
  INDICATOR_SECTION = "indicatorSection",
  SYSTEM_MEASURE = "systemMeasureId",
}

export type TRouteParamOptions = Partial<Record<ROUTE_PARAMS, string>>;

//------------------------------------------------------------------------------
const ROOT = `/:${ROUTE_PARAMS.ORG}`;
const DATA_PORTAL = `${ROOT}/data`;
const INSIGHTS = `${ROOT}/insights`;
const ADMIN_ROOT = `${ROOT}/admin`;

// export const HOME = { path: `${ROOT}/` };
export const HOME = { path: `/` };

export const ADMIN_USERS = {
  path: `${ADMIN_ROOT}/users`,
  requiredRole: [PERMISSIONS.LIST_USERS, PERMISSIONS.SUPERUSER_VIEW_USERS],
};
export const ADMIN_CREATE_USER = {
  path: `${ADMIN_USERS.path}/create`,
  requiredRole: [PERMISSIONS.EDIT_USERS, PERMISSIONS.SUPERUSER_EDIT_USERS],
};
export const ADMIN_USER_DETAIL = {
  path: `${ADMIN_USERS.path}/detail/:${ROUTE_PARAMS.USER}`,
  requiredRole: ADMIN_USERS.requiredRole,
};

export const MY_USER = { path: "/my-details" };

export const VIEW_UPLOADS = {
  path: `${DATA_PORTAL}/upload`,
  requiredRole: [PERMISSIONS.VIEW_UPLOADS, PERMISSIONS.SUPERUSER_VIEW_UPLOADS],
};

export const UPLOAD_DETAIL = {
  path: `${VIEW_UPLOADS.path}/detail/:${ROUTE_PARAMS.UPLOAD}`,
  requiredRole: VIEW_UPLOADS.requiredRole,
};

export const CREATE_UPLOAD = {
  path: `${VIEW_UPLOADS.path}/new`,
  requiredRole: [
    PERMISSIONS.CREATE_UPLOADS,
    PERMISSIONS.SUPERUSER_CREATE_UPLOADS,
  ],
};

export const SCHEMA_DETAIL = {
  path: `${DATA_PORTAL}/schema`,
  requiredRole: [
    ...VIEW_UPLOADS.requiredRole,
    PERMISSIONS.SUPERUSER_EDIT_SCHEMA,
  ],
};

export const QUALITY_INDICATORS = {
  path: `${INSIGHTS}/quality-indicators`,
  exact: true,
  requiredRole: [
    PERMISSIONS.INSIGHTS_VIEW_QI,
    PERMISSIONS.SUPERUSER_INSIGHTS_QI,
  ],
};
export const QUALITY_INDICATORS_DETAIL = {
  path: `${QUALITY_INDICATORS.path}/:${ROUTE_PARAMS.INDICATOR_SECTION}`,
  requiredRole: QUALITY_INDICATORS.requiredRole,
};

export const SYSTEM_MEASURES = {
  path: `${INSIGHTS}/system-measures`,
  requiredRole: [
    PERMISSIONS.INSIGHTS_VIEW_SYSTEM_VOLUME,
    PERMISSIONS.SUPERUSER_INSIGHTS_SYSTEM_VOLUME,
  ],
};

export const ADMIN_UPLOADS = {
  path: `/admin/uploads`,
  requiredRole: [PERMISSIONS.SUPERUSER_VIEW_UPLOADS],
};

export const COMMUNITY_SUPPORTS_EXTERNAL_URL =
  "https://csp.hospice.synergia.dev";

export const COMMUNITY_SUPPORTS = {
  // path:"https://csp.hospice.synergia.dev",
  path: "",
  requiredRole: [
    PERMISSIONS.CSP_VIEW_ACTIVITY,
    PERMISSIONS.SUPERUSER_VIEW_ACTIVITY,
  ],
};

export const TESTING = { path: `${ROOT}/testing` };

//------------------------------------------------------------------------------
export type TRoute = {
  path: string;
  href?: string;
  requiredRole?: TRequiredPermission;
};
export const PRIVATE_ROUTE_DETAILS = {
  HOME,
  VIEW_UPLOADS,
  UPLOAD_DETAIL,
  CREATE_UPLOAD,
  ADMIN_USERS,
  ADMIN_CREATE_USER,
  ADMIN_USER_DETAIL,
  MY_USER,
  SCHEMA_DETAIL,
  QUALITY_INDICATORS,
  QUALITY_INDICATORS_DETAIL,
  SYSTEM_MEASURES,
  COMMUNITY_SUPPORTS,
  ADMIN_UPLOADS,
  TESTING,
} as Record<string, TRoute>;

export const PRIVATE_ROUTES = Object.keys(PRIVATE_ROUTE_DETAILS).reduce(
  (o, k) => {
    return { ...o, [k]: PRIVATE_ROUTE_DETAILS[k].path };
  },
  {}
) as Record<string, string>;

export default { ...PRIVATE_ROUTES } as Record<string, string>;
