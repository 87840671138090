import React from "react";
import {
  DEFAULT_TYPE_CHECK_ERROR_LEVEL,
  ERROR_LEVELS,
  IValidationRule,
  SCHEMA_DATA_TYPE,
  VALIDATION_TYPES,
} from "models/Schema";
import Chip from "components/common/ui/chip/Chip";
import styles from "components/schema/detail/SchemaDetail.module.scss";
import { pascalCase } from "utils/format/stringUtils";
import {
  IconDanger,
  IconError,
  IconWarning,
} from "components/common/icons/twoToneIcons";

export const formatSchemaType = (t: SCHEMA_DATA_TYPE) => {
  switch (t) {
    case SCHEMA_DATA_TYPE.DATETIME:
      return "DateTime";
    case SCHEMA_DATA_TYPE.NHI:
      return "NHI";
    case SCHEMA_DATA_TYPE.STRING:
      return "Text String";
    default:
      return pascalCase(t.replace("synergia::type::", ""));
  }
};

export const formatSchemaValdiationStep = (s: IValidationRule) => {
  const commonProps = {
    key: s.name,
    ...getErrorLevelProps[s.level],
  };
  switch (s.name) {
    case VALIDATION_TYPES.ALLOWED_VALUES:
      if (Array.isArray(s.value)) {
        return {
          ...commonProps,
          heading: "Allowed Values",
          description: (
            <>
              Only the following values are allowed for the cells in this
              column:
              <div className={styles.allowedValues}>
                {s.value.map((v) => (
                  <Chip key={v} condensed theme="grey">
                    {v}
                  </Chip>
                ))}
              </div>
            </>
          ),
        };
      }
      return {
        ...commonProps,
        heading: "Allowed Values",
        description: (
          <>
            Only the following values are allowed for this column:
            <div>
              <Chip condensed>{s.value}</Chip>
            </div>
          </>
        ),
      };

    case VALIDATION_TYPES.MAX_VALUE:
      return {
        ...commonProps,
        heading: "Maximum Value",
        description: (
          <>
            The numbers in this column must be <b>less than or equal</b> to{" "}
            <span className={styles.highlight}>{s.value}</span>
          </>
        ),
      };
    case VALIDATION_TYPES.MIN_VALUE:
      return {
        ...commonProps,
        heading: "Minimum Value",
        description: (
          <>
            The numbers in this column must be <b>greater than or equal</b> to{" "}
            <span className={styles.highlight}>{s.value}</span>
          </>
        ),
      };

    case VALIDATION_TYPES.MAX_LENGTH:
      return {
        ...commonProps,
        heading: "Maximum Length",
        description: (
          <>
            The text in this column must have{" "}
            <span className={styles.highlight}>{s.value}</span> or <b>fewer</b>{" "}
            characters
          </>
        ),
      };

    case VALIDATION_TYPES.MIN_LENGTH:
      return {
        ...commonProps,
        heading: "Minimum Length",
        description: (
          <>
            The text in this column must have{" "}
            <span className={styles.highlight}>{s.value}</span> or <b>more</b>{" "}
            characters
          </>
        ),
      };

    default:
      return {
        ...commonProps,
        heading: s.name,
        description: s.value,
      };
  }
};

export const displayErrorType = (type: SCHEMA_DATA_TYPE) =>
  [
    SCHEMA_DATA_TYPE.NUMBER,
    SCHEMA_DATA_TYPE.DATE,
    SCHEMA_DATA_TYPE.DATETIME,
    SCHEMA_DATA_TYPE.TIME,
    SCHEMA_DATA_TYPE.NHI,
  ].includes(type);

export const getErrorLevelProps = {
  [ERROR_LEVELS.WARNING]: {
    theme: "info",
    icon: <IconWarning />,
  },
  [ERROR_LEVELS.ERROR]: {
    theme: "warning",
    icon: <IconError />,
  },
  [ERROR_LEVELS.CRITICAL]: {
    theme: "danger",
    icon: <IconDanger />,
  },
} as Record<ERROR_LEVELS, { icon: React.ReactNode; theme: ThemeVariants }>;

export const formatSchemaTypeCheck = (
  type: SCHEMA_DATA_TYPE,
  level = DEFAULT_TYPE_CHECK_ERROR_LEVEL,
  ignoreBlanks: boolean,
  typeFormat?: string
) => {
  if (!displayErrorType(type)) {
    return;
  }
  const commonProps = {
    key: type,
    ...getErrorLevelProps[level],
  };
  switch (type) {
    case SCHEMA_DATA_TYPE.NHI:
      return {
        ...commonProps,
        heading: "NHI Validation",
        description: "The cells in this column must contain valid NHI numbers",
      };
    case SCHEMA_DATA_TYPE.DATE:
    case SCHEMA_DATA_TYPE.DATETIME:
    case SCHEMA_DATA_TYPE.TIME:
      if (typeFormat) {
        const format = prettyDateFormat(typeFormat);
        return {
          ...commonProps,
          heading:
            type === SCHEMA_DATA_TYPE.TIME ? "Time Format" : "Date Format",
          description: (
            <>
              The {type === SCHEMA_DATA_TYPE.TIME ? "times" : "dates"} in this
              column must adhere to the '{format.label}' format, i.e.{" "}
              <span className={styles.highlight}>{format.formatted}</span>{" "}
              <br />
              For example -{" "}
              <b>
                <i>'{format.example}'</i>
              </b>
            </>
          ),
        };
      }
  }

  return {
    ...commonProps,
    heading: "Type Check",
    description: (
      <>
        Each cell {ignoreBlanks && "that isn't empty "} must match the{" "}
        <span className={styles.highlight}>{formatSchemaType(type)}</span> type
      </>
    ),
  };
};

const prettyDateFormat = (s: string) => {
  let format = {
    label: s,
    formatted: s,
    example: s,
  };
  const items = [
    { key: "%Y", label: "Year", formatted: "YYYY", example: "2019" },
    { key: "%m", label: "Month", formatted: "MM", example: "03" },
    { key: "%d", label: "Day", formatted: "DD", example: "21" },
    { key: "%H", label: "Hour", formatted: "hh", example: "16" },
    { key: "%M", label: "Minute", formatted: "mm", example: "04" },
    { key: "%S", label: "Second", formatted: "ss", example: "58" },
  ];
  items.forEach((i) => {
    format.label = format.label.replace(i.key, i.label);
    format.formatted = format.formatted.replace(i.key, i.formatted);
    format.example = format.example.replace(i.key, i.example);
  });

  return format;
};
