import { IFile } from "models/Upload";
import { parse } from "papaparse";

export const parseFirstXRows = (file: IFile, rows = 10) =>
  new Promise((resolve, reject) => {
    const chunk = [] as string[][];

    return parse(file, {
      // header: true,
      step: (row: any, parser: any) => {
        if (chunk.length < rows + 1) {
          chunk.push(row.data);
        } else {
          parser.abort();
        }
      },
      complete: () => {
        resolve(chunk);
      },
      error: function (error) {
        reject(error);
      },
    });
  });