import React from "react";
import Meta from "components/common/Meta";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import {
  currentOrgSelector,
  isAppLoadedSelector,
} from "store/selectors/core.selectors";
import { useGetQualIndicatorsList } from "components/insights/hooks/useQualityIndicatorsList";
import {
  indicatorDefinitionMap,
  indicatorSections,
} from "models/insights/QualityIndicators";
import { coreService } from "services/CoreService";
import { QUALITY_INDICATORS_DETAIL } from "constants/routes";
import PageHeading from "components/common/ui/typography/PageHeading";
import Accordian from "components/common/ui/accordian/Accordian";
import { MiniCardWithLink } from "components/common/ui/card/MiniCard";
import { QualityIndicatorChart } from "components/insights/indicators/indicator-detail/QualityIndicatorChart";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import { insightsFiltersSelector } from "store/selectors/insights.selectors";
import { InsightsFilters } from "components/insights/common/InsightsFilters";
import styles from "components/insights/indicators/QualityIndicatorList.module.scss";

const ViewInsightsQualityIndicators = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const filters = useSelector(insightsFiltersSelector);
  const org = useSelector(currentOrgSelector);

  const { isLoading, data } = useGetQualIndicatorsList(
    orgId,
    {
      year_period: filters.period,
      org_region:
        filters.org_region === "region" ? org.org_region : filters.org_region,
    },
    { enabled: isAppLoaded }
  );

  return (
    <AppContentContainer
      maxWidth={true}
      spacing="extra"
      backgroundColor="light-grey"
    >
      <Meta title={"Quality Indicators"} />
      <PageHeading darker>Quality Indicators</PageHeading>
      <p>
        The Quality Indicators give an overall view of the care being delivered
        at an individual hospice. <br />
        The indicators were developed by a working group in 2019, and then
        refined in 2020 as part of the analysis of the data provided into the
        Commons by the hospices <br />
        Within each indicator, a hospice can analyse itself for meaningful
        subsets, including age, ethnicity, and primary clinical condition.{" "}
        <br />A hospice can also compare and contrast itself to the regional and
        national picture, and how the indicators have changed over time. <br />
        The National view allows Hospice NZ, or other national providers, to see
        the average across each indicator and an overall appreciation of the
        hospice care being provided.
      </p>
      <InsightsFilters reverse />
      {indicatorSections.map((section) => (
        <Accordian
          header={<h2>{section.name}</h2>}
          isOpen
          bottomBorder
          key={section.slug}
        >
          <div className={styles.sectionContent}>
            {section.indicators.map((key) => {
              const indicator = indicatorDefinitionMap[key];
              return (
                <MiniCardWithLink
                  key={key}
                  className={`key-${key}`}
                  width={320}
                  style={{ minHeight: 310 }}
                  to={`${coreService.getRoute(QUALITY_INDICATORS_DETAIL, {
                    indicatorSection: section.slug,
                  })}#${key}`}
                  // style={{ minHeight: 600 }}
                >
                  <h5 className={styles.indicatorTitle}>
                    {indicator.long_title}
                  </h5>
                  <div className={styles.chartWrapper}>
                    {!isLoading ? (
                      data &&
                      data[key] && (
                        <QualityIndicatorChart
                          // headline
                          isLoading={isLoading}
                          type={indicator.type}
                          title={indicator.short_title}
                          data={data[key]}
                          height={200}
                          width={300}
                        />
                      )
                    ) : (
                      // (indicator.type === INDICATOR_TYPES.MULTI ? (
                      //   <MultiseriesRowChart
                      //     data={data[key].byIndicatorKey}
                      //     height={200}
                      //     width={300}
                      //   />
                      // ) : (
                      //   <ComparisonPieChart
                      //     height={200}
                      //     width={300}
                      //     data={data[key].byOrgType.org}
                      //     comparisonData={data[key].byOrgType.cohort}
                      //     colorAccessor={(d) => d.metadata?.color || "#ccc"}
                      //     tooltip={{
                      //       title: indicator.short_title,
                      //       items: data[key].byIndicatorKey[0].data, //true values
                      //     }}
                      //   />
                      // ))
                      <MiniLoader />
                    )}
                  </div>
                </MiniCardWithLink>
              );
            })}
          </div>
        </Accordian>
      ))}
    </AppContentContainer>
  );
};

export default ViewInsightsQualityIndicators;
