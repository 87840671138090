import axios from "axios";
import { request } from "utils/http/Request";
import fileDownload from "js-file-download";
import {
  IFile,
  IUploadConfig,
  createUpload,
  TUpload,
  TUploadFilter,
} from "models/Upload";
import {
  getApiUrl,
  UPLOADS,
  SCHEMA,
  UPLOAD_DETAIL,
  UPLOAD_ERROR_REPORT,
} from "constants/paths";
import { ISchemas } from "models/Schema";
import {
  fetchOneById as fetchSelectedFromOrg,
  fetchManyById as fetchManyOrgsById,
} from "./OrgApi";

export const getSchemas = async () => (await request.get(SCHEMA)) as ISchemas;

export const fetchOneById = async (
  uploadId: string,
  orgId: string
): Promise<TUpload> => {
  const data = await request.get(getApiUrl(UPLOAD_DETAIL, { orgId, uploadId }));
  return createUpload(data as any);
};

export const fetchMany = async (filter: TUploadFilter, orgId: string) => {
  const data = await request.get(getApiUrl(UPLOADS, { orgId }), filter);
  const total = data.count || 0;
  const items = (data.uploads as any[]) ? data.uploads : [];
  return { items, total };
};

export const fetchSelectedAcrossOrgs = async (
  filter: TUploadFilter,
  orgs: string[]
) => {
  return await fetchManyOrgsById(orgs, filter);
};

export const fetchSelected = async (filter: TUploadFilter, orgId: string) => {
  const data = await fetchSelectedFromOrg(orgId, filter);
  return data.selected;
};

export const saveUpload = async (
  file: IFile,
  config: IUploadConfig,
  orgId: string
) => {
  const signedUrl = await request.post(`${getApiUrl(UPLOADS, { orgId })}`, {
    ...config,
    year: +config.year,
  });
  const data = await axios.put(signedUrl.presigned_url, file, {
    headers: {
      contentType: file.type,
    },
  });
  return { id: signedUrl.upload_id, data };
};

export const selectUpload = async (
  uploadId: string,
  orgId: string
): Promise<boolean> => {
  await request.put(getApiUrl(UPLOAD_DETAIL, { orgId, uploadId }), {
    selected: true,
  });
  return true;
};

export const getErrorReport = async (
  uploadId: string,
  orgId: string,
  filename: string
) => {
  const signedUrl = await request.get(
    getApiUrl(UPLOAD_ERROR_REPORT, { orgId, uploadId })
  );
  return await axios
    .get(signedUrl.link, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(
        res.data,
        `Error Report - ${filename.replace(".csv", "")}.csv`
      );
    });
};

export const archiveUpload = async (
  uploadId: string,
  archive: boolean,
  orgId: string
): Promise<boolean> => {
  await request.put(getApiUrl(UPLOAD_DETAIL, { orgId, uploadId }), {
    archived: archive,
  });
  return true;
};
