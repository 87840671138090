import React, { useEffect, useState } from "react";
import Meta from "components/common/Meta";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import Button from "components/common/ui/button/Button";
import Modal from "components/common/ui/modal/Modal";
import { useToast } from "components/common/ui/toast/useToast";
import { useOnMount } from "hooks/useOnMount";
import FileUpload, {
  FILE_TYPES,
} from "components/common/ui/file-upload/FileUpload";
import { IFile } from "models/Upload";
import { parseFirstXRows } from "utils/uploadUtils";

const ViewTest = () => {
  const [open, setOpen] = useState(false);
  const { addToast } = useToast();
  const [files, setFiles] = useState([] as IFile[]);
  const [table, setTable] = useState([] as string[][]);

  const addFile = (f: IFile[]) => {
    setFiles(f);
  };

  useEffect(() => {
    const getFileChunk = async () => {
      const chunk = (await parseFirstXRows(files[0], 2)) as string[][];
      setTable(chunk);
    };
    if (files[0]) {
      getFileChunk();
    }
  }, [files]);
  // console.log(table);
  useOnMount(() => {});
  return (
    <AppContentContainer spacing="extra" maxWidth={true}>
      <Meta title={"Testing"} />
      <h1>Testing</h1>
      <h4>Woop! Woop</h4>
      <Button onClick={() => setOpen(true)}>Open Modal</Button>
      <Button
        onClick={() =>
          addToast({
            content: "TESTING TESTING TESTING TESTING TESTING TESTING TESTING",
            options: {
              theme: "success",
              autoDismiss: false,
            },
          })
        }
      >
        Add Toast
      </Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        This is a modal
      </Modal>
      <FileUpload
        onChange={addFile}
        multiple={false}
        mimeType={FILE_TYPES.csv}
      />
      {table.length > 0 && (
        <table>
          {table.map((r, i) => (
            <tr>{r.map((c) => (i === 0 ? <th>{c}</th> : <td>{c}</td>))}</tr>
          ))}
        </table>
      )}
    </AppContentContainer>
  );
};

export default ViewTest;
