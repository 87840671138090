import React from "react";
import cx from "classnames";
import styles from "./LinearGauge.module.scss";
import { IDataModel } from "components/common/ui/charts/chart";
import { motion } from "framer-motion";

interface ILinearGaugeProps extends DefaultProps {
  // colorScheme?: (d: IDataModel) => string;
  outerBarColor?: string;
  disabled?: boolean;
  rounded?: boolean;
  data?: IDataModel[];
  max?: number;
  width?: number;
  height?: number;
}

export const LinearGauge = ({
  // colorScheme,
  outerBarColor,
  disabled,
  rounded = true,
  data,
  className,
  max,
  width,
  height,
  style,
}: ILinearGaugeProps) => {
  const sumValue = max || data?.reduce((sum, d) => sum + d.data, 0) || 1;
  return (
    <div
      className={cx(
        className,
        styles.root,
        disabled && styles.disabled,
        rounded && styles.rounded
      )}
      style={{
        width,
        height,
        backgroundColor: outerBarColor,
        ...style,
      }}
    >
      {data &&
        data.map((d) => {
          const w = Math.max(0, Math.min((d.data / sumValue) * 100, 100));
          return (
            <motion.div
              key={`gauge-bar-${d.key}`}
              className={styles.bar}
              initial={{ width: 0 }}
              animate={{
                width: `${w}%`,
                transition: {
                  duration: 0.2,
                },
              }}
              style={{
                background: (!d.metadata?.disabled && d.metadata?.color) || "",
              }}
            />
          );
        })}
    </div>
  );
};
