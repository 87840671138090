import React from "react";
import Meta from "components/common/Meta";
import { QueryMeasuresDataShape } from "components/insights/utils/measureUtils";
import { formatNumber } from "utils/format/numberUtils";
import { useSelector } from "hooks/useSelector";
import { currentOrgSelector } from "store/selectors/core.selectors";
import { Headline, Highlight } from "components/insights/common/Headline";
import Card from "components/common/ui/card/Card";
import { MeasureCard } from "components/insights/measures/MeasureCard";
import contactsImg from "./assets/contacts.svg";
import ribbon from "components/insights/measures/assets/ribbon.svg";
import Container from "components/common/layouts/Container";
import { chartPalettes, getChartColor } from "utils/chartColorUtils";
import styles from "./common/MeasureDetail.module.scss";

interface Props {
  data: QueryMeasuresDataShape;
  period: string;
  showDataTables: boolean;
}
const unit = "Contacts";

const SystemMeasureContacts = ({ data, period, showDataTables }: Props) => {
  const org = useSelector(currentOrgSelector);
  return (
    <div>
      <Meta title={"System Measures - Contacts"} />
      <div className={styles.card}>
        <Container>
          <div className={styles.headlineContainer}>
            <img
              src={contactsImg}
              className={styles.headlineImg}
              alt="contacts"
            />
            <Headline
              num={formatNumber(data.total.contacts)}
              // className={styles.headline}
            >
              patient contacts at <Highlight>{org.org_name}</Highlight> in{" "}
              <Highlight>{period}</Highlight>
            </Headline>
          </div>
        </Container>

        <img src={ribbon} className={styles.ribbon} alt="ribbon" />
      </div>
      <Container>
        <div className={styles.row}>
          <MeasureCard
            title={"Setting"}
            section={unit}
            data={data["028"].data}
            showDataTable={showDataTables}
          />
          <MeasureCard
            title={"Recipient"}
            section={unit}
            data={data["029"].data}
            theme="ocean"
            showDataTable={showDataTables}
          />
        </div>

        <div className={styles.row}>
          <MeasureCard
            title={"Purpose"}
            section={unit}
            data={data["030"].data}
            theme="ocean"
            showDataTable={showDataTables}
          />
          <MeasureCard
            title={"Provider"}
            section={unit}
            data={data["031"].data}
            showDataTable={showDataTables}
          />
          <MeasureCard
            title={"Method"}
            section={unit}
            data={data["032"].data}
            theme="ocean"
            showDataTable={showDataTables}
            colorAccessor={(d, i) =>
              d.metadata?.color || getChartColor(i, chartPalettes.Mako, 4)
            }
          />
        </div>

        <Card className={styles.card} shadow="soft">
          <Headline
            num={formatNumber(data["036"].data)}
            alwaysVertical
            // className={styles.headline}
          >
            AKPS scores recorded
          </Headline>
          <Headline
            num={formatNumber(data["035"].data)}
            alwaysVertical
            // className={styles.headline}
          >
            Phase scores recorded
          </Headline>
          <Headline
            num={formatNumber(data["033"].data)}
            alwaysVertical
            // className={styles.headline}
          >
            IPOS scores recorded
          </Headline>
        </Card>
        <br />
      </Container>
    </div>
  );
};

export default SystemMeasureContacts;
