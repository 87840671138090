import { appGetState, appNextState, appUpdateState } from "store";
import { initialState } from "store/state";
import { IToastOptions } from "components/common/ui/toast/Toast";
import { generateTimestampID, generateUEID } from "utils/common";
import React from "react";
import { catchRequestErrorMessage } from "utils/catchErrorUtils";

export interface TBreadcrumb {
  label: string;
  url?: string;
}

const ROOT_BREADCRUMB = { label: "Lighthouse", url: "/" };
const ROOT_BREADCRUMB_SHORT = { label: "LH", url: "/" };

const comment = (m: string) => `LayoutService::${m}`;

class LayoutService {
  private appGetState = appGetState;
  private appNextState = appNextState;
  private appUpdateState = appUpdateState;

  /**
   */
  public expandSidebarToggle = (isExpanded: boolean) => {
    this.appUpdateState(
      (s) => (s.layout.isExpanded = isExpanded),
      comment("expandSidebarToggle")
    );
  };

  public expandMobileSidebarToggle = (isMobileOpened: boolean) => {
    this.appUpdateState(
      (s) => (s.layout.isMobileOpened = isMobileOpened),
      comment("expandMobileSidebarToggle")
    );
  };

  /**
   */
  public setHeaderLarge = (isHeaderLarge: boolean) => {
    this.appUpdateState(
      (s) => (s.layout.isHeaderLarge = isHeaderLarge),
      comment("setHeaderLarge")
    );
  };
  public setDesktopLayout = (setDesktopLayout: boolean) => {
    this.appUpdateState(
      (s) => (s.layout.isDesktop = setDesktopLayout),
      comment("setDesktopLayout")
    );
  };

  /**
   */
  public setBreadcrumbs = (
    items: TBreadcrumb[],
    includeRoot: boolean = true
  ) => {
    const root = includeRoot
      ? items.length
        ? ROOT_BREADCRUMB_SHORT
        : ROOT_BREADCRUMB
      : null;
    const breadcrumbs: TBreadcrumb[] = root ? [root, ...items] : [...items];
    this.appUpdateState(
      (s) => (s.layout.breadcrumbs = breadcrumbs),
      comment("setBreadcrumbs")
    );
  };

  /**
   */
  public confirm = (
    title: string,
    content: any,
    actionLabel: string = "OK",
    action: () => void = () => {},
    cancel: boolean = true
  ) => {
    this.appUpdateState((s) => {
      s.layout.dialog = {
        title,
        content,
        actionLabel,
        action,
        cancel,
        open: true,
      };
    }, comment("confirm"));
  };

  /**
   */
  public openDialog = () => {
    this.appUpdateState(
      (s) => (s.layout.dialog = { ...s.layout.dialog, open: true }),
      comment("openDialog")
    );
  };

  /**
   */
  public closeDialog = () => {
    this.appUpdateState(
      (s) => (s.layout.dialog = { ...initialState.layout.dialog }),
      comment("closeDialog")
    );
  };

  /**
   */
  public addToast = (
    content: string | React.ReactNode,
    { id, timeStampId = true, ...options }: IToastOptions = {}
  ) => {
    const genId = id
      ? timeStampId
        ? generateTimestampID(id)
        : id
      : generateUEID();
    const toasts = this.appGetState().layout.toasts;

    //exit if toast exists
    if (toasts.filter((t) => t.id === genId).length) {
      return;
    }
    this.appUpdateState(
      (s) =>
        (s.layout.toasts = [...toasts, { id: genId, content, ...options }]),
      comment("toastAdded")
    );
  };

  public removeToast = (id: string) => {
    const oldToasts = this.appGetState().layout.toasts;
    if (!oldToasts.filter((t) => t.id === id).length) {
      return;
    }
    this.appUpdateState(
      (s) => (s.layout.toasts = oldToasts.filter((t) => t.id !== id)),
      comment("toastRemoved")
    );
  };

  public error = (
    e: RequestError | unknown,
    defaultMessage = "Error",
    theme = "danger" as ThemeVariants,
    options?: IToastOptions
  ) => {
    this.addToast(catchRequestErrorMessage(e, defaultMessage), {
      autoDismiss: false,
      ...options,
      theme,
    });
  };

  public setIsLoading = (isLoading: boolean): void => {
    this.appUpdateState(
      (s) => (s.layout.isLoading = isLoading),
      comment("setIsLoading")
    );
  };
}

const layoutService = new LayoutService();
export { layoutService };
