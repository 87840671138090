import React from "react";
import createIcon from "components/common/icons/createIcon";

const IconBarChart = createIcon(
  <React.Fragment>
    <path d="M3,22V8H7V22H3M10,22V2H14V22H10M17,22V14H21V22H17Z" />
  </React.Fragment>,
  "BarChart"
);

export default IconBarChart;
