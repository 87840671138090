import { shallowEqual, useSelector as reduxUseSelector } from "react-redux";
import { IState } from "store";
import { TRouteParamOptions } from "constants/routes";
import { setRouteParams } from "utils/routeUtils";

export const useSelector: <TSelected = unknown>(
  selector: (state: IState) => TSelected
) => TSelected = (selector) => reduxUseSelector(selector, shallowEqual);

export const useRouteSelector = (
  url: string | undefined,
  match?: TRouteParamOptions
) =>
  reduxUseSelector((s: IState) => {
    const user = s.app.core.user.id;
    const org = s.app.core.org.org_id;
    return url ? setRouteParams(url, { orgId: org, id: user }, match) : "";
  }, shallowEqual);
