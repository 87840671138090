import React from "react";
import cx from "classnames";
import { motion } from "framer-motion";
import { IRowBase } from "../types";
import styles from "components/common/ui/charts/row/RowChart.module.scss";
import { defaultColorAccessor } from "utils/chartUtils";

const transition = {
  duration: 0.2,
};

export const RowChartBase = ({
  data,
  className,
  height,
  width,
  max,
  y,
  labelPadding,
  hideLabels,
  style,
  index,
  colorAccessor = defaultColorAccessor,
  ...props
}: IRowBase) => {
  const normalisedValue = (data.data || 0) / (max || 1);
  // const w = Math.max(0, Math.min(normalisedValue * 100, 100));
  const w = width * normalisedValue;
  const h = Math.max(0, height);
  const c = colorAccessor(data, index);
  const fill = c ? { fill: c, ...style } : style;

  return (
    <>
      <motion.rect
        className={cx(styles.rowBar, styles[`rowIndex-${index}`], className)}
        style={fill}
        rx={5}
        ry={5}
        initial={{
          y: y,
          width: 0,
          height: h,
        }}
        animate={{
          width: w,
          height: h,
          y: y,
          transition,
        }}
        {...props}
      />
      {!hideLabels && !data.metadata?.disabled && (
        <Label
          width={w}
          height={h + y * 2}
          padding={labelPadding}
          text={data.metadata?.group || data.metadata?.label || data.key}
        />
      )}
    </>
  );
};

interface ILabel {
  text: string;
  height: number;
  width: number;
  padding?: number;
}

const Label = ({ text, width, height, padding = 8 }: ILabel) => {
  const outsideLabel = text.length * 10 + padding > width;
  const x = outsideLabel ? width + padding : width - padding;
  const y = height / 2;

  return (
    <motion.text
      className={cx(styles.rowLabel, outsideLabel && styles.outsideLabel)}
      initial={{
        y,
        x: 0,
      }}
      animate={{
        x,
        y,
        transition,
      }}
    >
      {text}
    </motion.text>
  );
};
