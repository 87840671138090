import React from "react";
import { useForm } from "react-hook-form";
import TextInput from "components/common/ui/form-elements/text-input/TextField";
import HeroButton from "components/common/ui/hero-button/HeroButton";
import { getSchema } from "utils/formUtils";
import { username } from "components/auth/forms/questionSchema";
import styles from "components/auth/forms/Forms.module.scss";

const cleanError = (e: string) => {
  switch (e) {
    case "Username/client id combination not found.":
      return "User does not exist.";

    case "User does not exist.":
      return "User does not exist.";

    default:
      return e;
  }
};

const schema = getSchema({ username });

interface Props {
  onSubmit: () => void;
  values: any;
  errorMessage: string | null;
  changeState: (state: string) => void;
}

const ForgotPasswordForm = ({
  onSubmit,
  values,
  errorMessage,
  changeState,
}: Props) => {
  let defaultValues = {
    username: values.username || "",
  };
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    mode: "onBlur",
    resolver: schema,
    defaultValues: defaultValues,
  });
  const submit = (data: any) => {
    values.username = data.username;
    onSubmit();
  };

  return (
    <form
      onSubmit={handleSubmit(submit)}
      aria-label="forgot password"
      noValidate
    >
      {errorMessage && (
        <div className={styles.error}>Error: {cleanError(errorMessage)}</div>
      )}
      <TextInput
        {...register("username")}
        label="Email"
        error={errors["username"]}
      />

      <div className={styles.formActions}>
        <HeroButton type="submit" theme="info">
          Send Reset Code
        </HeroButton>
        <button onClick={() => changeState("signIn")} className="link">
          Back to Sign in
        </button>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
