import React from "react";

/***
 * DIMENSION_MATCH
 *
 * Each type of dimension match has two components to them depending on the type of filter (facet)
  and what's the format of the matched property in the source data. The basic structure of the match
  definition looks like this:
 *
 *    `facet_value` -> `source_data_type`
 *
 * The 'facet_value' determines how the filters should be applied to the data, while
  the 'source_data_type' describes what the actual data looks like.
 *
 * The `source_data_type` can be in two formats:
 *     - `string` - the desired property is represented as a single string e.g. { gender : "male" }
 *      - `array` - the desired property is represented as a nested array e.g. { ethnicity : ["maori" , "asian"] }
 *
 * The `filter_value` can be one of three formats:
 *    - 'exact' - the `facet_value` is a single string. It must either exactly match the value of a 'string' `source_data_type`
                 or must exactly match one of the strings present in an `array` source_data_type
 *     - 'facet' - the `facet_value` is an array of strings. At least one of the values in the facet_array must match
                  the string in the `source_data`, or must match one of the strings in an `array` of string in the source
 *     - 'fuzzy' - rather than an exact match, a fuzzy `facet_value` does a fancy fuzzy matching function to match string `facet_value`
                  to string `source_data`
 */

export enum DIMENSION_MATCH {
  EXACT = "exact->string", //Match a string to source data string
  EXACT_ARRAY = "exact->array", //Match a string to one item in source data array
  FACET = "facet->string", //Match an facet array of strings to source data string
  FACET_ARRAY = "facet->array", //Match an facet array of strings to source data array
  FUZZY = "fuzzy->string", //Fuzzy match a string to source data string
}

export interface IFacetOption {
  key: string;
  icon?: React.ReactNode;
  label?: string;
  color?: string;
}

export type TBucket = string | string[] | null;

export interface IDimension {
  key: string;
  label?: string;
  options: IFacetOption[];
  buckets: TBucket;
  match?: DIMENSION_MATCH;
}

export interface IDimensions {
  [key: string]: IDimension;
}

export interface ISetBucket {
  key: string;
  bucket: TBucket;
}

export type TSetActiveBucket = ({
  key,
  bucket,
}: ISetBucket) => Promise<IDimensions>;

export type TSetManyBucketsActive = (
  filters: (ISetBucket | undefined)[],
  refreshData?: boolean
) => void;

export const getDimensionObject = (dimensions: IDimension[]) => {
  return dimensions.reduce((obj, f) => {
    return { ...obj, [f.key]: f };
  }, {} as IDimensions);
};
