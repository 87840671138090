import React, { useEffect, useState } from "react";
import Meta from "components/common/Meta";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import styles from "components/insights/indicators/viewQualityIndicatorDetail.module.scss";
import { useParams, useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import {
  currentOrgSelector,
  isAppLoadedSelector,
} from "store/selectors/core.selectors";
import QualityIndicatorDetail from "components/insights/indicators/QualityIndicatorDetail";
import Container from "components/common/layouts/Container";
import { QUALITY_INDICATORS_DETAIL } from "constants/routes";
import { coreService } from "services/CoreService";
import {
  indicatorDefinitionMap,
  indicatorSectionsMap,
} from "models/insights/QualityIndicators";
import { useGetQualIndicatorsList } from "components/insights/hooks/useQualityIndicatorsList";
import { LinearGauge } from "components/common/ui/charts/gauge/LinearGauge";
import { Tab, TabList, Tabs } from "components/common/ui/tabs/Tabs";
import PageHeading from "components/common/ui/typography/PageHeading";
import { insightsFiltersSelector } from "store/selectors/insights.selectors";
import { InsightsFilters } from "components/insights/common/InsightsFilters";

const ViewInsightsQualityIndicatorsDetail = () => {
  const [tabState, setTabs] = useState<number>(-1);
  const filters = useSelector(insightsFiltersSelector);
  const org = useSelector(currentOrgSelector);
  const history = useHistory();
  const { hash } = useLocation();
  const { orgId, indicatorSection } =
    useParams<{ orgId: string; indicatorSection: string }>();
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const indicatorId = hash.replace("#", "");
  const section = indicatorSectionsMap[indicatorSection];

  const { data } = useGetQualIndicatorsList(
    orgId,
    {
      year_period: filters.period,
      org_region:
        filters.org_region === "region" ? org.org_region : filters.org_region,
    },
    { enabled: isAppLoaded }
  );

  const setHashState = async (newState: number) => {
    //always get the fresh version of currentIndex within setIndicatorState
    setTabs((s) => {
      if (newState !== s) {
        const newHash = section.indicators[newState];
        history.push(
          `${coreService.getRoute(QUALITY_INDICATORS_DETAIL, {
            orgId,
            indicatorSection,
          })}#${newHash}`
        );
      }
      return s;
    });
  };

  useEffect(() => {
    if (indicatorId) {
      const currentIndex = section.indicators.indexOf(indicatorId);
      setTabs(currentIndex);
    } else {
      //if no indicator hash, then reroute to first indicator in section
      history.replace(
        `${coreService.getRoute(QUALITY_INDICATORS_DETAIL, {
          orgId,
          indicatorSection,
        })}#${section.indicators[0]}`
      );
    }
  }, [indicatorId, section.indicators, indicatorSection, orgId, history]);

  return (
    <AppContentContainer
      maxWidth={false}
      spacing="none"
      backgroundColor="light-grey"
      className={styles.root}
    >
      <Meta title={"Quality Indicators"} />
      <Container spacing="extra" spacingTopOnly>
        <PageHeading>{section.name}</PageHeading>
      </Container>
      <Tabs state={[tabState, setHashState]}>
        <TabList
          contentClassName={styles.tabContent}
          decorator="none"
          tabBackground
        >
          {section &&
            section.indicators.map((i) => (
              <Tab key={i} className={styles.tab}>
                {indicatorDefinitionMap[i].short_title}
                <LinearGauge
                  width={100}
                  height={6}
                  data={data && data[i].byOrgType.org}
                  disabled
                  className={styles.gauge}
                />
              </Tab>
            ))}
        </TabList>
        <div className={styles.actions}>
          <Container spacing="none">
            <InsightsFilters />
          </Container>
        </div>

        {indicatorId && (
          <QualityIndicatorDetail
            indicatorKey={indicatorId}
            orgId={orgId}
            // orgData={data?.[indicatorId].byOrgType.org}
          />
        )}
      </Tabs>
    </AppContentContainer>
  );
};

export default ViewInsightsQualityIndicatorsDetail;
