import React from "react";
import { Route, Switch } from "react-router-dom";
import { PRIVATE_ROUTES, PRIVATE_ROUTE_DETAILS } from "constants/routes";
import Home from "components/home/viewHome";
import NotFound from "components/app/viewNotFound";
import ViewNotAllowed from "components/app/viewNotAllowed";
import { AnimatePresence } from "framer-motion";
import AppLayout from "components/common/layouts/AppLayout";
import RestrictedRoute from "components/auth/RestrictedRoute";
import AuthWrapper from "components/auth/AuthWrapper";
import ViewAdminUsersList from "components/users/admin/viewAdminUsersList";
import ViewMyUserDetail from "components/users/viewMyUserDetails";
import ViewUserDetail from "components/users/admin/viewUserDetails";
import ViewAdminCreateUser from "components/users/admin/viewAdminCreateUser";
import ViewTest from "components/app/viewTest";
import ViewCreateUpload from "components/upload/viewCreateUpload";
import ViewUploadsList from "components/upload/viewUploadsList";
import ViewUploadDetail from "components/upload/viewUploadDetail";
import ViewSchemaDetail from "components/schema/viewSchema";
import ViewInsightsQualityIndicators from "components/insights/viewInsightsQualityIndicators";
import ViewAdminUploadsList from "components/upload/viewAdminUploadsList";
import ViewInsightsQualityIndicatorsDetail from "components/insights/viewInsightsQualityIndicatorsDetail";
import ViewInsightsSystemMeasures from "components/insights/viewInsightsSystemMeasurses";

const PrivateRoutes = (props: { location: any }) => (
  <AuthWrapper>
    <AppLayout>
      <AnimatePresence exitBeforeEnter>
        <Switch location={props.location} key={props.location.pathname}>
          <Route exact path={PRIVATE_ROUTES.HOME} component={Home} />
          <Route exact path={PRIVATE_ROUTES.TESTING} component={ViewTest} />

          <RestrictedRoute
            exact
            path={PRIVATE_ROUTES.QUALITY_INDICATORS}
            component={ViewInsightsQualityIndicators}
            requiredRole={PRIVATE_ROUTE_DETAILS.QUALITY_INDICATORS.requiredRole}
          />
          <RestrictedRoute
            exact
            path={PRIVATE_ROUTES.QUALITY_INDICATORS_DETAIL}
            component={ViewInsightsQualityIndicatorsDetail}
            requiredRole={
              PRIVATE_ROUTE_DETAILS.QUALITY_INDICATORS_DETAIL.requiredRole
            }
          />
          <RestrictedRoute
            exact
            path={PRIVATE_ROUTES.SYSTEM_MEASURES}
            component={ViewInsightsSystemMeasures}
            requiredRole={PRIVATE_ROUTE_DETAILS.SYSTEM_MEASURES.requiredRole}
          />
          <RestrictedRoute
            exact
            path={PRIVATE_ROUTES.VIEW_UPLOADS}
            component={ViewUploadsList}
            requiredRole={PRIVATE_ROUTE_DETAILS.VIEW_UPLOADS.requiredRole}
          />
          <RestrictedRoute
            exact
            path={PRIVATE_ROUTES.UPLOAD_DETAIL}
            component={ViewUploadDetail}
            requiredRole={PRIVATE_ROUTE_DETAILS.UPLOAD_DETAIL.requiredRole}
          />
          <RestrictedRoute
            exact
            path={PRIVATE_ROUTES.CREATE_UPLOAD}
            component={ViewCreateUpload}
            requiredRole={PRIVATE_ROUTE_DETAILS.CREATE_UPLOAD.requiredRole}
          />
          <RestrictedRoute
            exact
            path={PRIVATE_ROUTES.SCHEMA_DETAIL}
            component={ViewSchemaDetail}
            requiredRole={PRIVATE_ROUTE_DETAILS.SCHEMA_DETAIL.requiredRole}
          />

          <Route
            exact
            path={PRIVATE_ROUTE_DETAILS.MY_USER.path}
            component={ViewMyUserDetail}
          />
          <RestrictedRoute
            exact
            path={PRIVATE_ROUTE_DETAILS.ADMIN_USERS.path}
            component={ViewAdminUsersList}
            requiredRole={PRIVATE_ROUTE_DETAILS.ADMIN_USERS.requiredRole}
          />
          <RestrictedRoute
            exact
            path={PRIVATE_ROUTE_DETAILS.ADMIN_USER_DETAIL.path}
            component={ViewUserDetail}
            requiredRole={PRIVATE_ROUTE_DETAILS.ADMIN_USER_DETAIL.requiredRole}
          />
          <RestrictedRoute
            exact
            path={PRIVATE_ROUTE_DETAILS.ADMIN_CREATE_USER.path}
            component={ViewAdminCreateUser}
            requiredRole={PRIVATE_ROUTE_DETAILS.ADMIN_CREATE_USER.requiredRole}
          />
          <RestrictedRoute
            exact
            path={PRIVATE_ROUTE_DETAILS.ADMIN_UPLOADS.path}
            component={ViewAdminUploadsList}
            requiredRole={PRIVATE_ROUTE_DETAILS.ADMIN_UPLOADS.requiredRole}
          />
        </Switch>
      </AnimatePresence>
    </AppLayout>
  </AuthWrapper>
);

const Routes = () => (
  <Switch>
    <Route
      exact
      path={Object.values(PRIVATE_ROUTES)}
      key={"app"}
      component={PrivateRoutes}
    />
    <Route
      exact
      path={"/forbidden"}
      component={ViewNotAllowed}
      key={"forbidden"}
    />
    <Route component={NotFound} key={"notfound"} />
  </Switch>
);

export default Routes;
