import React from "react";
import { Hub } from "aws-amplify";
import { SignIn } from "aws-amplify-react";
import { ISignInProps } from "aws-amplify-react/lib/Auth/SignIn";
import Meta from "components/common/Meta";
import SignInForm from "components/auth/forms/SignInForm";
import { CLIENT_NAME, SITE_NAME } from "constants/site";
import AuthLayout from "components/common/layouts/AuthLayout";
import { authService } from "services/AuthService";

const exportDefault = {};
export default exportDefault;

interface IProps extends ISignInProps {
  match: { params: { userId: string } };
}

export class CustomSignIn extends SignIn {
  signInError = null;
  boundHubAuthListener: (result: any) => void = () => {};

  constructor(props: IProps) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
  }

  hubAuthListener(result: any) {
    if (!result || !result.payload) {
      return;
    }
    switch (result.payload.event) {
      case "signIn_failure":
        this.signInError = result.payload.data && result.payload.data.message;
        this.setState({ ...this.state });
        return;
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.boundHubAuthListener = this.hubAuthListener.bind(this);
    Hub.listen("auth", this.boundHubAuthListener);
  }

  componentWillUnmount() {
    Hub.remove("auth", this.boundHubAuthListener);
  }

  handleFormSubmit() {
    // Hacky fix to reset Auth error on sign-in
    authService.resetAuthError();
    this.signIn(new Event("Sign In"));
  }
  handleChange(e: any) {
    this.handleInputChange(e);
  }

  showComponent(theme: any) {
    return (
      <AuthLayout>
        <Meta title={"Login"} />
        <h2 style={clientStyle}>{CLIENT_NAME}</h2>
        <h1>{SITE_NAME}</h1>
        <p>To access the platform, please login below</p>
        <SignInForm
          onInputChange={this.handleChange.bind(this)}
          onSubmit={this.handleFormSubmit.bind(this)}
          values={this.inputs}
          errorMessage={this.signInError}
          changeState={this.changeState}
        />
      </AuthLayout>
    );
  }
}
const clientStyle = {
  color: "#ababab",
  margin: "0 0 -6px 0",
  fontWeight: 600,
};
