import React from "react";
import { IQueryIndicator } from "components/insights/utils/indicatorUtils";
import { INDICATOR_TYPES } from "models/insights/QualityIndicators";
import { ResponsiveMultiseriesRowChart } from "components/common/ui/charts/row/MultiseriesRowChart";
import { ComparisonPieChart } from "components/common/ui/charts/pie/ComparisonPieChart";
import { ChangeInPatientsSankey } from "components/insights/indicators/indicator-detail/ChangeInPatientsSankey";

interface IChart {
  isLoading: boolean;
  data?: IQueryIndicator;
  type?: INDICATOR_TYPES;
  title: string;
  width?: number;
  height?: number;
  max?: number;
  headline?: boolean;
}

export const QualityIndicatorChart = ({
  width,
  height,
  isLoading,
  data,
  type,
  title,
  max,
  headline,
}: IChart) => {
  if (!isLoading && data) {
    switch (type) {
      case INDICATOR_TYPES.MULTI:
        return (
          <ResponsiveMultiseriesRowChart
            data={data.byIndicatorKey}
            height={height}
            width={width}
            max={max}
          />
        );
      case INDICATOR_TYPES.SINGLE:
        return (
          <ComparisonPieChart
            height={height || 200}
            width={width || 300}
            data={data.byOrgType.org}
            comparisonData={data.byOrgType.cohort}
            colorAccessor={(d) => d.metadata?.color || "#ccc"}
            tooltip={{
              title: title,
              items: data.byIndicatorKey[0].data, //true values
            }}
          />
        );
      case INDICATOR_TYPES.CHANGE:
        return (
          <ChangeInPatientsSankey
            edges={data?.byOrgType.org[0]?.metadata?.edges}
            cohortEdges={data?.byOrgType.cohort[0]?.metadata?.edges}
            headline={headline}
          />
        );
    }
  }
  return <></>;
};
