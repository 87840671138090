import React from "react";
import Meta from "components/common/Meta";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { IFile, IUploadConfig } from "models/Upload";
import { useHistory, useParams } from "react-router";
import { uploadService } from "services/UploadService";
import { useSelector } from "react-redux";
import { CreateUpload } from "components/upload/create-upload/CreateUpload";
import { UPLOAD_DETAIL, VIEW_UPLOADS } from "constants/routes";
import { coreService } from "services/CoreService";
import {
  schemaDetailSelector,
  schemaDimSelector,
  schemaIsLoadedSelector,
} from "store/selectors/schema.selectors";
import { uploadDimSelector } from "store/selectors/uploads.selectors";

const ViewCreateUpload = () => {
  const history = useHistory();
  const { orgId } = useParams<{ orgId: string }>();
  const schemaDim = useSelector(schemaDimSelector);
  const detail = useSelector(schemaDetailSelector);
  const isLoaded = useSelector(schemaIsLoadedSelector);
  const uploadDim = useSelector(uploadDimSelector);

  const submitUpload = async (file: IFile, config: IUploadConfig) => {
    const upload = await uploadService.saveUpload(file, config, orgId);
    if (upload && upload.id) {
      history.push(
        coreService.getRoute(UPLOAD_DETAIL, { orgId, uploadId: upload.id })
      );
    }
    return true;
  };

  return (
    <AppContentContainer
      spacing="extra"
      maxWidth={true}
      backButton={{
        to: coreService.getRoute(VIEW_UPLOADS),
        label: "Back to Uploads",
      }}
    >
      <Meta title={"Upload Data"} />
      <h1>Upload Data</h1>
      {isLoaded && (
        <CreateUpload
          submitUpload={submitUpload}
          schemas={detail}
          dimensions={{ ...schemaDim, ...uploadDim }}
        />
      )}
    </AppContentContainer>
  );
};

export default ViewCreateUpload;
