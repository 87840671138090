import React from "react";
import cx from "classnames";
import LinkWrapper, {
  TLinkWrapper,
} from "components/common/ui/link-wrapper/LinkWrapper";
import Container, { ContainerProps } from "components/common/layouts/Container";
import styles from "./Card.module.scss";

interface Props extends ContainerProps {
  shadow?: "normal" | "soft" | "none";
}

const Card = ({
  children,
  spacing = "none",
  shadow = "normal",
  className,
  ...props
}: Props) => (
  <Container
    className={cx(styles.card, className, styles[`${shadow}Shadow`])}
    {...props}
  >
    {children}
  </Container>
);

interface LinkProps extends Props, Omit<TLinkWrapper, "children"> {}

export const CardWithLink = ({
  href,
  to,
  target,
  download,
  ...props
}: LinkProps) => (
  <LinkWrapper
    to={to}
    href={href}
    target={target}
    download={download}
    className={styles.link}
  >
    <Card {...props} />
  </LinkWrapper>
);

export default Card;
