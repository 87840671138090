import {
  format as dateFnsFormat,
  formatDistance,
  formatDistanceToNow,
  differenceInDays,
  differenceInMinutes,
  fromUnixTime,
} from "date-fns";

export const convertUnixTimeToDate = (unixTimestamp: number) => {
  return fromUnixTime(unixTimestamp / 1000000000);
};

export const formatDate = (date: Date, format = "do MMMM yyyy") => {
  try {
    return dateFnsFormat(date, format);
  } catch (e){
    return "Invalid Date"
  }

};

export const formatUnixTime = (
  unixTimestamp: number,
  format = "do MMM yyyy"
) => {
  return formatDate(convertUnixTimeToDate(unixTimestamp), format);
};

export const unixTimeDifference = (
  unixTimestamp: number,
  targetDate: number | Date
) => {
  const target =
    typeof targetDate === "number"
      ? convertUnixTimeToDate(targetDate)
      : targetDate;

  return formatDistance(convertUnixTimeToDate(unixTimestamp), target);
};

export const unixTimeDiffToNow = (unixTimestamp: number) => {
  return formatDistanceToNow(convertUnixTimeToDate(unixTimestamp));
};

export const withinLastXDays = (unixTimestamp: number, numberOfDays = 2) => {
  const d = convertUnixTimeToDate(unixTimestamp);
  const diff = differenceInDays(new Date(), d);
  return diff < numberOfDays;
};

export const daysDiff = (
  unixTimestamp: number,
  targetDate: number | Date = new Date()
) => {
  const target =
    typeof targetDate === "number"
      ? convertUnixTimeToDate(targetDate)
      : targetDate;
  const d = convertUnixTimeToDate(unixTimestamp);
  return differenceInDays(target, d);
};

export const minutesDiff = (
  unixTimestamp: number,
  targetDate: number | Date = new Date()
) => {
  const target =
    typeof targetDate === "number"
      ? convertUnixTimeToDate(targetDate)
      : targetDate;
  const d = convertUnixTimeToDate(unixTimestamp);
  return differenceInMinutes(target, d);
};
