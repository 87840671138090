import React from "react";
import createIcon from "components/common/icons/createIcon";

const IconCheck = createIcon(
  <React.Fragment>
    <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
  </React.Fragment>,
  "Error"
);

export default IconCheck;
