import { queryKeyFactory } from "queries/utils";

export enum QUERY_KEYS {
  USERS = "users",

  UPLOADS = "uploads",

  SCHEMA = "schema",

  QUALITY_INDICATORS = "insights.qualityIndicators",
  SYSTEM_MEASURES = "insights.systemMeasures",
}

export const qualityIndicatorQueryKeys = queryKeyFactory(
  QUERY_KEYS.QUALITY_INDICATORS
);
export const systemMeasureQueryKeys = queryKeyFactory(
  QUERY_KEYS.SYSTEM_MEASURES
);
