import { format, getYear, subQuarters } from "date-fns";
import { IFacetOption } from "models/Dimension";

export type TPeriod = typeof PERIOD[number];

const lastQuarter = subQuarters(new Date(), 1);
export const DEFAULT_QUARTER = format(lastQuarter, "QQQ") as TPeriod;
export const DEFAULT_YEAR = `${getYear(lastQuarter)}` as string;
const MIN_YEAR = 2020;

const calcYearOptions = () => {
  let options = [];
  for (let i = MIN_YEAR; i <= getYear(new Date()); i++) {
    options.push(`${i}`);
  }
  return options;
};

export const PERIOD_OPTIONS = [
  { key: "Q1", label: "January - March" },
  { key: "Q2", label: "April - June" },
  { key: "Q3", label: "July - September" },
  { key: "Q4", label: "October - December" },
];

// export const UPLOAD_PERIOD = ["Q1", "Q2", "Q3", "Q4"] as const;
export const PERIOD = PERIOD_OPTIONS.map((m) => m.key);
export const YEAR = calcYearOptions();

export const DEFAULT_YEAR_PERIOD = `${DEFAULT_YEAR}-${DEFAULT_QUARTER}`;
export const YEAR_PERIOD_OPTIONS = YEAR.reduce(
  (o, y) => [
    ...o,
    ...PERIOD_OPTIONS.map((p) => ({
      key: `${y}-${p.key}`,
      label: `${p.label} ${y}`,
    })),
  ],
  [] as IFacetOption[]
).reverse();
