const initialData = {
  org_id: "",
  org_name: "",
  schemas: [] as string[],
  created: 0,
  updated: 0,
  created_by_email: "",
  created_by_id: "",
  created_by_username: "",
  selected: {} as Record<string, any>,
  org_region: "",
};

export type TOrg = typeof initialData;

export const createOrg = (org: Partial<TOrg>): TOrg => ({
  ...initialData,
  ...org,
});
