export const flushCache = () => {
  localStorage.clear();
};

// const validateCache = () => {
//   const localVersion = localStorage.getItem("version");
//   const buildVersion = process.env.REACT_APP_VERSION;
//   if (localVersion !== buildVersion) {
//     flushCache();
//     localStorage.setItem("version", buildVersion || "");
//   }
//   return localVersion !== buildVersion;
// };

//Checks if an old version of the survey is stored locally
export const getCachedCurrentOrg = () => {
  const cachedData = localStorage.getItem("core::currentOrgId");
  if (cachedData) {
    return cachedData;
  }
  return undefined;
};

export const setCachedCurrentOrg = (orgId: string) => {
  localStorage.setItem("core::currentOrgId", orgId);
};
