import React from "react";
// import cx from "classnames";
// import styles from "./ContentWaves.module.scss";

interface Props {
  className?: string;
}

const ContentWaves = ({ className }: Props) => (
  <svg
    width="100%"
    height="40%"
    viewBox="0 0 1754 818"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    className={className}
  >
    <path
      d="M1754 643.037V818H0V729.317C193.87 767.713 395.952 800.442 642.088 800.442C941.187 800.442 1182.08 752.112 1421.83 704.013C1531 682.112 1639.92 660.258 1754 643.037Z"
      fill="#5AC8AE"
    />
    <path
      opacity="0.4"
      d="M1754 739.82V818H0V0.00585938C369.126 11.4012 619.097 192.867 867.512 373.204C1120.84 557.105 1372.55 739.833 1747.35 739.833C1749.57 739.833 1751.79 739.829 1754 739.82Z"
      fill="#CDF1E8"
    />
    <path
      opacity="0.6"
      d="M1277.51 818H0V330.615C279.043 368.005 488.047 476.803 695.962 585.033C875.762 678.629 1054.75 771.8 1277.51 818Z"
      fill="#5AC8AE"
    />
    <path
      opacity="0.7"
      d="M1754 697.107V818H0V535.692C99.6937 558.666 192.14 585.275 284.365 611.82C529.864 682.483 773.797 752.694 1148.7 752.694C1409.59 752.694 1596.47 728.615 1754 697.107Z"
      fill="#215273"
    />
  </svg>
);

export default ContentWaves;
