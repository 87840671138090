import React from "react";
import createIcon from "components/common/icons/createIcon";

const IconMedReport = createIcon(
  <React.Fragment>
    <path d="M4 2V22H20V2H4ZM6 5.99467H8V3.99467H10V5.99467H12V7.99467H10V9.99467H8V7.99467H6V5.99467ZM18 19.9947H6V17.9947H18V19.9947ZM18 15.9947H6V13.9947H18V15.9947ZM18 11.9947H12.9973V9.99467H18V11.9947Z" />
  </React.Fragment>,
  "MedReport"
);

export default IconMedReport;
