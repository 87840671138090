import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHasChanged } from "hooks/useHasChanged";
import Meta from "components/common/Meta";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { uploadService } from "services/UploadService";
import Container from "components/common/layouts/Container";
import { isAppLoadedSelector } from "store/selectors/core.selectors";
import { schemaIsLoadedSelector } from "store/selectors/schema.selectors";
import { IState } from "store";
import { IUploadDimensions, TUploadFilter } from "models/Upload";
import { uploadDimSelector } from "store/selectors/uploads.selectors";
import { fetchManyById } from "api/OrgApi";
import { TOrg } from "models/Org";
import { AdminUploadsList } from "components/upload/admin-list/AdminUploadsList";
import { ISetBucket } from "models/Dimension";
import styles from "components/users/admin/list/UsersList.module.scss";

const extractFiltersFromDimensions = (dim: IUploadDimensions) => {
  return Object.values(dim).reduce(
    (o, d) => ({ ...o, [d.key]: d.buckets }),
    {}
  ) as TUploadFilter;
};

const ViewAdminUploadsList = () => {
  const [state, setState] = useState<TOrg[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const orgs = useSelector((state: IState) => state.app.core.user.orgs);
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const isSchemaLoaded = useSelector(schemaIsLoadedSelector);
  const dimensions = useSelector(uploadDimSelector);
  const refresh = useHasChanged(isAppLoaded && isSchemaLoaded);
  const filters = extractFiltersFromDimensions(dimensions);
  const orgIds = orgs.map((o) => o.org_id);

  const fetchOrgData = async (filter: TUploadFilter, orgs: string[]) => {
    setLoading(true);
    const data = await fetchManyById(orgs, filter);
    setState(data);
    setLoading(false);
  };
  const setFilter = async (bucket: ISetBucket) => {
    setLoading(true);
    const dim = await uploadService.setFilter(bucket);
    await fetchOrgData(filters, orgIds);
    return dim;
  };
  const setManyFilters = async (buckets: (ISetBucket | undefined)[]) => {
    setLoading(true);
    await uploadService.setManyFilters(buckets);
    await fetchOrgData(filters, orgIds);
  };

  console.log({ state });

  useEffect(() => {
    // Wait until user's org is loaded
    if (isAppLoaded) {
      // if query is empty (no URL search params) or orgId has changed
      if (refresh) {
        fetchOrgData(filters, orgIds);
      }
    }
  }, [isAppLoaded, refresh, filters, orgIds]);
  return (
    <AppContentContainer spacing="none">
      <Meta title={"Admin Upload List"} />
      <Container spacing={"extra"} spacingTopOnly>
        <div className={styles.header}>
          <h1>Admin Uploads</h1>
        </div>
      </Container>
      <div>
        <AdminUploadsList
          isAppLoaded={isAppLoaded && isSchemaLoaded}
          loading={loading}
          orgs={state}
          dimensions={dimensions}
          setActive={setFilter}
          setManyActive={setManyFilters}
        />
      </div>
    </AppContentContainer>
  );
};

export default ViewAdminUploadsList;
