import chroma from "chroma-js";

export enum chartPalettes {
  PrplGrn = "purpleGreen",
  PrplYlw = "purpleToYellow",
  Magma = "magmaPalette",
  Viridis = "viridisPalette",
  Mako = "makoPalette",
}

const palettes = {
  [chartPalettes.PrplGrn]: [
    "#4139a8",
    "#424bb4",
    "#3f6bc0",
    "#3a8bc3",
    "#30acbd",
    "#1ac7ac",
  ],
  [chartPalettes.PrplYlw]: [
    "#6034a9",
    "#923390",
    "#c14e83",
    "#e27379",
    "#f79c70",
    "#ffc969",
  ],
  [chartPalettes.Magma]: [
    "#231465",
    "#5F1B7A",
    "#992D7A",
    "#CD4C6E",
    "#F27D63",
    "#FFC969",
  ],
  [chartPalettes.Viridis]: [
    "#3B2A57",
    "#414487",
    "#2A788E",
    "#22A884",
    "#7AD151",
    "#C7ED46",
  ],

  [chartPalettes.Mako]: [
    "#402159",
    "#413D7B",
    "#37659E",
    "#348FA7",
    "#40B7AD",
    "#8BDAB2",
  ],
};

export const getScale = (paletteName: chartPalettes, length = 5) =>
  chroma.scale(palettes[paletteName]).domain([0, length]).mode("lab");

export const getChartColor = (
  index: number,
  paletteName: chartPalettes,
  length?: number
) => getScale(paletteName, length)(index).hex();

export const otherChartColor = (index = 0) => ["#949494", "#525252"][index];
