import React from "react";
import { QueryMeasuresDataShape } from "components/insights/utils/measureUtils";
import { formatNumber } from "utils/format/numberUtils";
import { useSelector } from "hooks/useSelector";
import { currentOrgSelector } from "store/selectors/core.selectors";
import { Headline, Highlight } from "components/insights/common/Headline";
import SystemMeasureDetail from "components/insights/measures/common/MeasureDetail";
import deathsImg from "./assets/deaths.svg";
import styles from "components/insights/measures/common/MeasureDetail.module.scss";
import Meta from "components/common/Meta";

interface Props {
  data: QueryMeasuresDataShape;
  period: string;
  showDataTables: boolean;
}
const sectionName = "Deaths";

const SystemMeasureDeaths = ({ data, period, showDataTables }: Props) => {
  const org = useSelector(currentOrgSelector);
  const dataMap = {
    placeOfDeath: data["046"].data,
    diagnosis: data["021"].data,
    age: data["022"].data,
    ethnicity: data["023"].data,
    gender: data["024"].data,
    rurality: data["025"].data,
    livingSituation: data["026"].data,
    akps: data["044"].data,
    phase: data["045"].data,
  };
  return (
    <>
      <Meta title={"System Measures - Deaths"} />
      <SystemMeasureDetail
        headline={
          <>
            <img
              src={deathsImg}
              className={styles.headlineImg}
              alt="patients"
            />
            <Headline
              num={formatNumber(data.total.deaths)}
              // className={styles.headline}
            >
              deaths at <Highlight>{org.org_name}</Highlight> in{" "}
              <Highlight>{period}</Highlight>
            </Headline>
          </>
        }
        unit={sectionName}
        data={dataMap}
        showDataTables={showDataTables}
      />
    </>
  );
};

export default SystemMeasureDeaths;
