import { ISchema } from "models/Schema";

export const getSchemaVersions = (schema: {
  [schema_version: string]: ISchema;
}): string[] => {
  return Object.keys(schema).sort((a, b) => +b - +a) || [];
};

export const getLatestSchemaVersion = (schema: {
  [schema_version: string]: ISchema;
}) => {
  return getSchemaVersions(schema)[0] || "01";
};

export const validateAllowedValues = (
  value: string,
  allowed_values: string[]
) => {
  return allowed_values.includes(value);
};

// export const validateStringCell = (value: string, columnRules: IStringValidation) => {
//   // const errors = [];
//   columnRules.validation.map((rule) => {
//     switch (rule.name) {
//       case "allowed_values":
//         return validateAllowedValues(value, rule.value);
//       case "max_length":
//         return value.length < rule.value;
//       case "min_length":
//         return value.length > rule.value;
//       default:
//         return true;
//     }
//   });
// };
