import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const getSchema = (questions: Record<string, any>) => {
  return yupResolver(Yup.object().shape(questions));
};

export const conditionalFormLogic = (v: any, values: string[]) => {
  switch (typeof v) {
    case "boolean":
      return v;
    case "string":
      return values.some((c) => v.includes(c));
    case "object":
      if (Array.isArray(v) && v.length > 0) {
        return values.some((c) => v.includes(c));
      }
      return false;
    default:
      return false;
  }
};
