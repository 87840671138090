const ROOT_API = process.env.REACT_APP_API_URL;
const INSIGHTS_API = process.env.REACT_APP_INSIGHTS_API_URL;

enum API_PARAMS {
  USER = "userId",
  ORG = "orgId",
  UPLOAD = "uploadId",
  INDICATOR = "indicatorId",
  SYSTEM_MEASURE = "systemMeasureId",
}

export type TAPIParamOptions = Partial<Record<API_PARAMS, string>>;

export const CURRENT_USER = `${ROOT_API}/me`;

export const ORGS = `${ROOT_API}/orgs`;
export const ORG_API = `${ORGS}/:${API_PARAMS.ORG}`;

export const UPLOADS = `${ORG_API}/uploads`;
export const UPLOAD_DETAIL = `${ORG_API}/uploads/:${API_PARAMS.UPLOAD}`;
export const UPLOAD_ERROR_REPORT = `${UPLOAD_DETAIL}/errors`;

export const SCHEMA = `${ROOT_API}/schema`;

export const USERS = `${ORG_API}/users`;
export const USER = `${ORG_API}/users/:${API_PARAMS.USER}`;
export const USER_PERMISSONS = `${USER}/permissions`;

export const SUPER_UPLOADS = `${ROOT_API}/uploads`;

export const INSIGHTS_ORG_ROOT = `${INSIGHTS_API}/orgs/:${API_PARAMS.ORG}`;

export const INDICATOR_LIST = `${INSIGHTS_ORG_ROOT}/indicators`;
export const INDICATOR_DETAIL = `${INDICATOR_LIST}/:${API_PARAMS.INDICATOR}`;

export const SYSTEM_MEASURE_LIST = `${INSIGHTS_ORG_ROOT}/measures`;
export const SYSTEM_MEASURE_DETAIL = `${SYSTEM_MEASURE_LIST}/:${API_PARAMS.SYSTEM_MEASURE}`;

export const getApiUrl = (url: string, match: TAPIParamOptions) =>
  Object.values(API_PARAMS).reduce(
    (p, key) => p.replace(`:${key}`, match?.[key] || ""),
    url
  );
