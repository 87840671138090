import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { store, history } from "store";
import Meta from "components/common/Meta";
import { ToastProvider } from "components/common/ui/toast/Toast";
import { ErrorBoundary } from "components/common/ErrorBoundary";
import Routes from "components/Routes";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "queries/client";

const App: React.FC = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Meta />
      <ReactQueryDevtools />

      <ErrorBoundary>
        <ConnectedRouter history={history}>
          <ToastProvider>
            <Routes />
          </ToastProvider>
        </ConnectedRouter>
      </ErrorBoundary>
    </QueryClientProvider>
  </Provider>
);

export default App;
