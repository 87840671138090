import React from "react";
import { IUploadEvent, TUpload } from "models/Upload";
import Timeline, {
  IActivityMarker,
  ActivityMarker,
} from "components/common/ui/timeline/Timeline";
import IconCancel from "components/common/icons/IconCancel";
import IconCheck from "components/common/icons/IconCheck";
import IconArchive from "components/common/icons/IconArchive";
import {
  IconProgressWrench,
  IconProgressUpload,
  IconProgressComplete,
  IconProgressCircleWrap,
  IconProgressClock,
  IconProgressCancel,
} from "components/common/icons/IconProgress";
import IconWarning from "components/common/icons/IconWarning";
import { SUPPORT_EMAIL } from "constants/site";
import { formatNumber } from "utils/format/numberUtils";
import styles from "components/upload/detail/UploadHistory.module.scss";
import LinkWrapper from "components/common/ui/link-wrapper/LinkWrapper";
import { UPLOAD_DETAIL } from "constants/routes";
import { coreService } from "services/CoreService";

interface Props {
  upload: TUpload;
  progress: "inprogress" | "expired" | "done";
  className?: string;
}

export const UploadHistory = ({ upload, progress, className }: Props) => (
  <Timeline condensed className={className}>
    <ActivityMarker
      heading={"File Uploaded"}
      timestamp={upload.created}
      description={`File upload initiated by ${upload.uploaded_by_email}`}
      icon={<IconProgressUpload />}
    />
    {upload.events.map((e, i) => (
      <ActivityMarker
        key={`${e.event_name}|${e.event_at}`}
        {...formatEvent(e, upload, i)}
      />
    ))}
    <ProgressCheck upload={upload} progress={progress} />
  </Timeline>
);

const ProgressCheck = ({ upload, progress }: Props) => {
  if (progress === "expired") {
    return (
      <ActivityMarker
        heading="Error: Validation Timeout"
        description={
          <>
            Something has gone wrong with the file validation process. Please{" "}
            <a
              href={`mailto:${SUPPORT_EMAIL}?subject=Issue%20with%20upload%20${upload.upload_id}`}
            >
              contact support
            </a>
          </>
        }
        theme="danger"
        icon={<IconProgressCancel />}
      />
    );
  } else if (progress === "inprogress") {
    return (
      <ActivityMarker
        heading="Processing Upload"
        description="Validation in progress, please wait"
        icon={
          <>
            <IconProgressCircleWrap className={styles.loading} />
            <IconProgressClock className={styles.clock} />
          </>
        }
      />
    );
  }
  return <></>;
};

const formatEvent = (
  event: IUploadEvent,
  upload: TUpload,
  index: number
): IActivityMarker => {
  let e = {
    heading: event.event_name,
    timestamp: event.event_at,
  };
  switch (event.event_name) {
    case "Init Validation Completed":
      return {
        ...e,
        heading: "Validation Initiated",
        description: "File recieved and validation process underway.",
        icon: <IconProgressWrench />,
      };

    case "Perform Validation Completed":
      return {
        ...e,
        heading: "Validation Completed",
        description: `Validation process completed, with ${formatNumber(
          event.rows_scanned || 0
        )} row${
          event.rows_scanned && event.rows_scanned > 1 ? "s" : ""
        } scanned.`,
        icon: <IconProgressComplete />,
      };

    case "Handle Validation Pending Completed":
      if (upload.rows_scanned > 0) {
        const warning = upload.errors_total && upload.errors_total > 0;
        return {
          ...e,
          heading: `Validation ${warning ? "Partial " : ""}Success`,
          description: warning
            ? `Upload passed validation with ${formatNumber(
                upload.errors_total
              )} errors. File available for selection.`
            : "Upload successfully passed validation with no errors! File ready for selection.",
          icon: warning ? <IconWarning /> : <IconCheck />,
          theme: warning ? "warning" : "success",
        };
      }
      return {
        ...e,
        heading: "Validation Failure",
        icon: <IconCancel />,
        description:
          "No rows scanned in uploaded file. This usually indicates missing data.",
        theme: "danger",
      };

    case "Handle Validation Failure Completed":
      return {
        ...e,
        heading: "Validation Failure",
        icon: <IconCancel />,
        description: "Validation failed with Critical issue(s) found.",
        theme: "danger",
      };
    case "Clean Up Validation Completed":
      return {
        ...e,
        heading: "Clean-up & Backup",
        icon: <IconArchive />,
        theme: "grey",
        description:
          "Upload & validation process completed, raw datafile backed up & archived.",
      };
    case "Move From Pending To Selected Completed":
      const isCurrentSelectedEvent =
        upload.selected && index === upload.events.length - 1;
      return {
        ...e,
        heading: isCurrentSelectedEvent ? (
          <span className={styles.success}>Selected as Final Upload</span>
        ) : (
          "Previously set as Final"
        ),
        icon: <IconCheck />,
        theme: isCurrentSelectedEvent ? "success" : "grey",
        description: isCurrentSelectedEvent
          ? `Upload selected by ${event.triggered_by_email} as finalised data for ${upload.period} ${upload.year}`
          : `Upload was set by ${event.triggered_by_email} as final, but has been superseeded by another selection`,
      };
    case "Deselect previously selected complete":
      return {
        ...e,
        heading: "Upload Deselected",
        icon: <IconProgressCancel />,
        theme: "info",
        description: (
          <>
            Deselected by {event.triggered_by_email} in favour of{" "}
            <LinkWrapper
              to={coreService.getRoute(UPLOAD_DETAIL, {
                uploadId: event.deselected_with,
                orgId: upload.org_id,
              })}
            >
              alternative upload
            </LinkWrapper>
          </>
        ),
      };

    default:
      return e;
  }
};
