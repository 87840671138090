import { appGetState, appNextState, appUpdateState } from "store";
import { initialState } from "store/state";
import { getCognitoMfaStatus, toCognitoAttributes, TUser } from "models/User";
import { Auth } from "aws-amplify";
import { layoutService } from "services/LayoutService";
import { captureError } from "utils/errorUtils";
import { CognitoAccessToken } from "amazon-cognito-identity-js";
import { catchAuthError } from "utils/catchErrorUtils";

const comment = (m: string) => `AuthService::${m}`;

const extractAuthUser = (accessToken: CognitoAccessToken) => {
  const { payload } = accessToken;
  return {
    id: payload.sub || "",
    permissions: payload["cognito:groups"] || [],
  };
};

class AuthService {
  private appGetState = appGetState;
  private appNextState = appNextState;
  private appUpdateState = appUpdateState;

  /**
   */
  public async checkAuth() {
    try {
      await this.checkSession();
      this.appUpdateState((s) => (s.auth.loggedIn = true), comment("loggedIn"));
    } catch (e) {
      this.appUpdateState(
        (s) => (s.auth.loggedIn = false),
        comment("loggedOut")
      );
    }
  }

  public async checkSession() {
    return await Auth.currentSession();
  }

  /**
   * @return false or continue function
   */
  public async login(
    email: string,
    password: string
  ): Promise<boolean | (() => void)> {
    try {
      const user = await Auth.signIn(email, password);
      // this.appUpdateState((s) => (s.auth.user = user), comment("user"));
      if (user && !user.challengeName) {
        this.appUpdateState(
          (s) => (s.auth.error = null),
          comment("clearError")
        );
        return true;
      }
      return false;
    } catch (e) {
      this.appUpdateState(
        (s) => (s.auth.error = catchAuthError(e)),
        comment("authError")
      );
    }
    return false;
  }

  public async setAuthUser() {
    const {
      auth: { isLoading, error },
    } = this.appGetState();
    if (!isLoading) {
      this.setIsLoading(true);
      try {
        let accessToken = await Auth.currentSession().then((s) =>
          s.getAccessToken()
        );
        const attr = extractAuthUser(accessToken);
        if (error?.code === "user-info-error") {
          this.setAuthError(null);
        }
        this.appUpdateState(
          (s) => (s.auth = { ...s.auth, ...attr }),
          comment("setAuthUser")
        );
        this.setIsLoading(false);
        return true;
      } catch (e) {
        if (typeof e === "string") {
          this.setAuthError({ code: "user-info-error", message: e });
        }
      }
      this.setIsLoading(false);
    }
    return false;
  }

  public async forceUserRefresh() {
    return await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
  }

  public async changePassword(oldPassword: string, newPassword: string) {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, oldPassword, newPassword);
    } catch (e) {
      layoutService.error(e);
    }
  }

  public async completeNewPassword(password: string) {
    const user = await Auth.currentAuthenticatedUser();
    try {
      await Auth.completeNewPassword(user, password, {});
    } catch (e) {
      layoutService.error(e);
    }
  }

  public async updateCurrentUserAttributes(attributes: Partial<TUser>) {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      if (attributes.mfa_enabled !== undefined) {
        const newMFA = getCognitoMfaStatus(attributes.mfa_enabled);
        if (currentUser.preferredMFA !== newMFA) {
          await Auth.setPreferredMFA(currentUser, newMFA);
        }
      }
      await Auth.updateUserAttributes(
        currentUser,
        toCognitoAttributes(attributes)
      );
    } catch (e) {
      layoutService.error(e, "Error updating user");
    }
  }

  /**
   */
  // public async sendPasswordReset(email: string): Promise<boolean> {
  //   try {
  //     await resetPassword(createUser({ email }));
  //     return true;
  //   } catch (e) {
  //     return false;
  //   }
  // }

  /**
   */
  public async logout(): Promise<void> {
    await Auth.signOut();
    this.appNextState({ ...initialState }, comment("logout"));
    localStorage.clear();
  }

  public resetAuthError = () => {
    this.appUpdateState(
      (s) => (s.auth.error = null),
      comment("clearedAuthError")
    );
  };

  private setAuthError = (
    e: AuthError,
    defaultMessage = "Critcal Error: Unable to load User"
  ) => {
    if (e) {
      const err = { name: e.code, ...e };
      captureError(err);
      layoutService.error(err, defaultMessage);
      this.appUpdateState((s) => (s.auth.error = e), comment("setAuthError"));
    } else {
      this.resetAuthError();
    }
  };

  private setIsLoading = (isLoading: boolean): void => {
    this.appUpdateState(
      (s) => (s.auth.isLoading = isLoading),
      comment("setIsLoading")
    );
  };

  // public async getUserInfo(): Promise<boolean> {
  //   const {
  //     auth: { isLoading, error },
  //   } = this.appGetState();
  //   if (!isLoading) {
  //     this.setIsLoading(true);
  //     try {
  //       const authUser = await Auth.currentAuthenticatedUser({
  //         bypassCache: true,
  //       });
  //       const authSession = authUser.getSignInUserSession();
  //       const mfa = authUser.preferredMFA !== COGNITO_MFA_STATUS.NONE;
  //       const user = await extractUserDetails(authSession, mfa);
  //       if (error?.code === "user-info-error") {
  //         this.setAuthError(null);
  //       }
  //       this.appUpdateState((s) => (s.core.user = user), comment("userInfo"));
  //       this.setIsLoading(false);
  //       return true;
  //     } catch (e) {
  //       this.setAuthError({ code: "user-info-error", message: e });
  //     }
  //   }
  //   this.setIsLoading(false);
  //   return false;
  // }
}

const authService = new AuthService();
export { authService };
