import { PERMISSIONS, TRequiredPermission } from "models/Permissions";
import { IAppState } from "store/state";

export const verifyPermisisons = (
  permissions: PERMISSIONS[] = [],
  requiredRole?: TRequiredPermission
) =>
  !requiredRole ||
  (typeof requiredRole === "string" && permissions.includes(requiredRole)) ||
  (Array.isArray(requiredRole) &&
    (requiredRole.length === 0 ||
      requiredRole.some((r) => permissions.includes(r))));

export const canEditUsers = (permissions: PERMISSIONS[]) =>
  verifyPermisisons(permissions, [
    PERMISSIONS.EDIT_USERS,
    PERMISSIONS.SUPERUSER_EDIT_USERS,
  ]);

export const extractCurrentPermissions = (appState: IAppState) =>
  appState.core.user.roles && appState.core.user.roles?.length > 0
    ? appState.core.user.roles
    : appState.auth.permissions;
