import { chartPalettes } from "utils/chartColorUtils";
import { keyBy } from "utils/common";
import { AGE_DEF, MALIGNANT_DEF, ETHNICITY_DEF } from "models/insights/common";

const defaultIndicator = {
  job_id: "" as string,

  org_id: "" as string,
  org_region: "" as string,
  year_period: "" as string,

  indicator_column: "contact_deliverysetting_name" as string,
  indicator_id: "008" as typeof indicatorKeys[number],

  sixty_five_plus: "all" as keyof AGE_DEF,
  malignant: "all" as keyof MALIGNANT_DEF,
  ethnicity: "all" as keyof ETHNICITY_DEF,

  indicator_key: "all" as string,
  indicator_value: 0,
};

export type TIndicatorItem = typeof defaultIndicator;

export enum INDICATOR_TYPES {
  SINGLE = "single-percent",
  MULTI = "multiple-percent",
  CHANGE = "change-in-percent",
}

export const blankIndicatorDefinition = {
  id: "000" as string,
  short_title: "" as string,
  long_title: "" as string,
  short_description: "" as string,
  long_description: "" as string,
  section: "",
  type: "" as INDICATOR_TYPES | undefined,
  theme: chartPalettes.Magma as chartPalettes,
  // headline: THeadlineFunction;
};

export type TIndicatorDefinition = typeof blankIndicatorDefinition;

// export interface TIndicatorDefinition {
//   id: string;
//   short_title: string;
//   long_title: string;
//   short_description: string;
//   long_description: string;
//   section: string;
//   // headline: THeadlineFunction;
// }

export const indicatorDefinitionMap = {
  "001": {
    id: "001",
    short_title: "New Referrals",
    long_title: "Proportion which are new referrals ",
    short_description: "Proportion of volume which is new referrals ",
    long_description:
      "Proportion of a hospices current volume which is new referrals in that quarter ",
    section: "Patient is identified as palliative and requiring Hospice care",
    type: INDICATOR_TYPES.SINGLE,
    theme: chartPalettes.Magma,
  },
  "002": {
    id: "002",
    short_title: "Volume change",
    long_title: "Change in patient volumes",
    short_description: "Change in patients under hospice care",
    long_description:
      "Change in patients that a  Hospice is caring for in that quarter, adding in new referrals and subtracting deaths from the original number",
    section: "Patient is identified as palliative and requiring Hospice care",
    type: INDICATOR_TYPES.CHANGE,
    theme: chartPalettes.Magma,
  },
  "003": {
    id: "003",
    short_title: "Iwi",
    long_title: "Proportion with Iwi recorded",
    short_description: "Proportion which have their Iwi recorded",
    long_description:
      "Proportion of those identifying as Māori in that quarter which have their Iwi recorded",
    section: "Patient is identified as palliative and requiring Hospice care",
    type: INDICATOR_TYPES.SINGLE,
    theme: chartPalettes.Magma,
  },
  "004": {
    id: "004",
    short_title: "First Contact",
    long_title: "Time from referral to first contact",
    short_description: " Distribution of time from referral to first contact",
    long_description:
      " Number of days from referral to first contact (face to face or phone) in that quarter",
    section: "Initial contact with Hospice",
    type: INDICATOR_TYPES.MULTI,
    theme: chartPalettes.Viridis,
  },
  "005": {
    id: "005",
    short_title: "AKPS",
    long_title: "Initial AKPS distribution ",
    short_description: "Proportion with each AKPS on first assessment ",
    long_description:
      "Proportion with each AKPS on first assessment, grouped by meaningful AKPS scores  in that quarter",
    section: "Initial contact with Hospice",
    type: INDICATOR_TYPES.MULTI,
    theme: chartPalettes.Viridis,
  },
  "006": {
    id: "006",
    short_title: "Phase",
    long_title: "Initial Phase distribution ",
    short_description: "Proportion with each Phase on first assessment",
    long_description:
      "Proportion with each Phase on first assessment  in that quarter",
    section: "Initial contact with Hospice",
    type: INDICATOR_TYPES.MULTI,
    theme: chartPalettes.Viridis,
  },
  "007": {
    id: "007",
    short_title: "Counsellling",
    long_title: "Proportion with 1+ Counselling sessions ",
    short_description:
      "Proportion of alive patients that have at least one counselling session in the year",
    long_description:
      "Proportion of alive patients that have at least one counselling (family therapeutic) session in the year",
    section: "Subsequent contact(s) with Hospice",
    type: INDICATOR_TYPES.SINGLE,
    theme: chartPalettes.Magma,
  },
  "008": {
    id: "008",
    short_title: "Setting",
    long_title: "Delivery setting breakdown",
    short_description:
      "Proportion of contacts in each non IPU delivery setting",
    long_description:
      "Proportion of total contacts in each non IPU delivery setting in that quarter",
    section: "Subsequent contact(s) with Hospice",
    type: INDICATOR_TYPES.MULTI,
    theme: chartPalettes.Magma,
  },
  "009": {
    id: "009",
    short_title: "IPOS",
    long_title: "Proportion with 1+ IPOS assessments ",
    short_description:
      "Proportion of patients who have more than one IPOS done in the year",
    long_description:
      "Proportion of alive patients who have more than one IPOS done in the year",
    section: "Subsequent contact(s) with Hospice",
    type: INDICATOR_TYPES.SINGLE,
    theme: chartPalettes.Magma,
  },
  "010": {
    id: "010",
    short_title: "IPU Clinical",
    long_title: "Proportion of IPU for each primary  Clinical condition ",
    short_description:
      " Proportion of IPU for each primary clinical conditions  ",
    long_description:
      " Proportion of each primary clinical conditions for those with one or more IPU attendance in the quarter ",
    section: "IPU Admission to Hospice",
    type: INDICATOR_TYPES.MULTI,
    theme: chartPalettes.Magma,
  },
  "011": {
    id: "011",
    short_title: "IPU LOS",
    long_title: "Distribution of time in IPU",
    short_description: "Distribution of time for patients admitted to the IPU",
    long_description:
      "Distribution of time for patients admitted to the IPU, for the quarter",
    section: "IPU Admission to Hospice",
    type: INDICATOR_TYPES.MULTI,
    theme: chartPalettes.Magma,
  },
  "012": {
    id: "012",
    short_title: "IPU AKPS",
    long_title: "Proportion of IPU admissions with each AKPS ",
    short_description: "Distribution of  AKPS scores on admsision to IPU",
    long_description:
      "Distribution of  initial  AKPS  scores for those that have one or more admsisions to IPU  for the quarter",
    section: "IPU Admission to Hospice",
    type: INDICATOR_TYPES.MULTI,
    theme: chartPalettes.Magma,
  },
  "013": {
    id: "013",
    short_title: "IPU Phase",
    long_title: "Proportion of IPU admissions in each phase",
    short_description: "Distribution of  Phases on admsision to IPU",
    long_description:
      "Distribution of  iniitial Phases for those that have one or more admsision to IPU  for the quarter",
    section: "IPU Admission to Hospice",
    type: INDICATOR_TYPES.MULTI,
    theme: chartPalettes.Magma,
  },
  "014": {
    id: "014",
    short_title: "IPU IPOS",
    long_title: "Proportion of IPU admissions with each IPOS grouped score",
    short_description: "Distribution of  IPOS scores on admsision to IPU",
    long_description:
      "Distribution of  IPOS scores on admsision to IPU  for the quarter",
    section: "IPU Admission to Hospice",
    type: INDICATOR_TYPES.MULTI,
    theme: chartPalettes.Magma,
  },
  "015": {
    id: "015",
    short_title: "Care length",
    long_title: "Distribution of total time in Hospice care",
    short_description:
      "Proportion in each Length of time -  from referral to death",
    long_description:
      "Proportion of patients  in each length of time (months to years) -  from referral to death, in a rolling year",
    section: "Death of someone who has been involved with Hospice",
    type: INDICATOR_TYPES.MULTI,
    theme: chartPalettes.Magma,
  },
  "016": {
    id: "016",
    short_title: "Death setting",
    long_title: "Proportion which die in each setting",
    short_description: "Proportion which die in each setting",
    long_description:
      "Proportion which die in each setting of care in the quarter.",
    section: "Death of someone who has been involved with Hospice",
    type: INDICATOR_TYPES.MULTI,
    theme: chartPalettes.Magma,
  },
  "017": {
    id: "017",
    short_title: "RC death",
    long_title: "Proportion living in RC who also die there ",
    short_description: "Proportion who live in RC and die in RC",
    long_description:
      "Proportion who live in Residential Care and also die in Residential Care, in the quarter",
    section: "Death of someone who has been involved with Hospice",
    type: INDICATOR_TYPES.SINGLE,
    theme: chartPalettes.Magma,
  },
  // "018": {
  //   id: "018",
  //   short_title: "Preferred death",
  //   long_title: "Proportion with a  preferred place of death",
  //   short_description:
  //     "Proportion who have their preferred place of death captured",
  //   long_description:
  //     "Proportion who have their preferred place of death captured, in the year",
  //   section: "Death of someone who has been involved with Hospice",
  //   type: INDICATOR_TYPES.SINGLE,
  // theme: chartPalettes.Magma
  // },
  "019": {
    id: "019",
    short_title: "Death rate",
    long_title: "Proportion who died in the year",
    short_description: "Proportion of patients who died in the year",
    long_description:
      "Proportion of patients who began the year alive and died in the next 12 months",
    section: "Death of someone who has been involved with Hospice",
    type: INDICATOR_TYPES.SINGLE,
    theme: chartPalettes.Magma,
  },
  "020": {
    id: "020",
    short_title: "Family only",
    long_title: "Proportion with Family only after death",
    short_description: "Proportion of care to the family only after death",
    long_description:
      "Proportion of care deliverd by the hospice which is to the family only (patient has died)  over a 12 month period. ",
    section: "Death of someone who has been involved with Hospice",
    type: INDICATOR_TYPES.SINGLE,
    theme: chartPalettes.Magma,
  },
  "021": {
    id: "021",
    short_title: "Post death care",
    long_title: "Family sessions after death",
    short_description: "Counselling sessions to the family after death.",
    long_description:
      "The number of counselling sessions to families for 12 months after death of the patient.",
    section: "Death of someone who has been involved with Hospice",
    type: INDICATOR_TYPES.MULTI,
    theme: chartPalettes.Magma,
  },
} as Record<string, TIndicatorDefinition>;

export const indicatorKeys = Object.keys(indicatorDefinitionMap);

export enum INDICATOR_SECTION_KEYS {
  PATIENT_CARE = "patient-care",
  CONTACTS = "initial-contacts",
  SUBSQUENT_CONTACTS = "subsequent-contacts",
  IPU = "ipu-admission",
  DEATHS = "deaths",
}

export const indicatorSections = [
  {
    name: "Patient is identified as palliative and requiring Hospice care",
    slug: INDICATOR_SECTION_KEYS.PATIENT_CARE,
    indicators: ["001", "002", "003"],
  },
  {
    name: "Initial contact with Hospice",
    slug: INDICATOR_SECTION_KEYS.CONTACTS,
    indicators: ["004", "005", "006"],
  },
  {
    name: "Subsequent contact(s) with Hospice",
    slug: INDICATOR_SECTION_KEYS.SUBSQUENT_CONTACTS,
    indicators: ["007", "008", "009"],
  },
  {
    name: "IPU Admission to Hospice",
    slug: INDICATOR_SECTION_KEYS.IPU,
    indicators: ["010", "011", "012", "013", "014"],
  },
  {
    name: "Death of someone who has been involved with Hospice",
    slug: INDICATOR_SECTION_KEYS.DEATHS,
    indicators: ["015", "016", "017", "019", "020", "021"],
  },
];

export const indicatorSectionsMap: Record<
  string,
  typeof indicatorSections[number]
> = keyBy(indicatorSections, "slug");
