import React from "react";
import createIcon from "components/common/icons/createIcon";

const IconHome = createIcon(
  <React.Fragment>
    <path d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" />
  </React.Fragment>,
  "EyeHidden"
);

export default IconHome;
