import { IState } from "store/index";

export const isAppLoadedSelector = (state: IState) =>
  state.app.core.isAppLoaded;

export const currentUserSelector = (state: IState) => state.app.core.user;
export const userOrgsSelector = (state: IState) => state.app.core.user.orgs;
export const currentOrgSelector = (state: IState) => state.app.core.org;

export const currentOrgIdSelector = (state: IState) =>
  state.app.core.org.org_id;
export const currentOrgNameSelector = (state: IState) =>
  state.app.core.org.org_name;
