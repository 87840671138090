import React from "react";
import cx from "classnames";
import { dimensionsToParamsFormat } from "utils/filterUtils";
import Select, {
  ISelecInputConfig,
  SkeletonSelect,
} from "components/common/ui/select/Select";
import {
  DIMENSION_MATCH,
  IDimension,
  IDimensions,
  TBucket,
  TSetActiveBucket,
  TSetManyBucketsActive,
} from "models/Dimension";
import SyncUrlQueryState from "components/common/ui/sync-url-state/SyncUrlQueryState";
import DebouncedTextInput from "components/common/ui/form-elements/text-input/DebouncedTextInput";
import { SkeletonTextInput } from "components/common/ui/form-elements/text-input/TextField";
import IconSearch from "components/common/icons/IconSearch";
import styles from "./ListFilter.module.scss";

const defaultConfig = {
  inputChip: true,
  selectedOptionFormat: "chip",
  expandArrow: true,
  noInputError: true,
} as ISelecInputConfig;

interface IFilterBase {
  filters: string[];
  dimensions: IDimensions;
  setActive: TSetActiveBucket;
  config?: ISelecInputConfig;
  className?: string;
  multiSelect?: boolean;
}

interface Props extends IFilterBase {
  setManyActive: TSetManyBucketsActive;
  isUpdating?: boolean;
  isLoading?: boolean;
}

const ListFilters = ({
  isLoading,
  setManyActive,
  isUpdating,
  ...rest
}: Props) => {
  const updateFromQueryURL = (dim: Record<string, TBucket>) => {
    setManyActive(
      Object.keys(dim).map((k) => ({ key: k, bucket: dim[k] })),
      !isLoading
    );
  };
  return (
    <>
      <SyncUrlQueryState
        queryKeys={rest.filters}
        state={dimensionsToParamsFormat(rest.dimensions)}
        setState={updateFromQueryURL}
        pauseSync={isUpdating}
      />
      {!isLoading ? (
        <ListFiltersBase {...rest} />
      ) : (
        <div className={cx(styles.root, rest.className)}>
          {rest.filters.map((f) => {
            const dim = rest.dimensions[f];
            return dim ? (
              <div key={f} className={styles.filter}>
                {dim.match === DIMENSION_MATCH.FUZZY ? (
                  <SkeletonTextInput
                    className={styles.wideFilter}
                    icon={IconSearch}
                    rounded
                    condensed
                  />
                ) : (
                  <SkeletonSelect />
                )}
              </div>
            ) : null;
          })}
        </div>
      )}
    </>
  );
};

const ListFiltersBase = ({
  filters,
  dimensions,
  setActive,
  config,
  className,
  multiSelect,
}: IFilterBase) => {
  const onFilter = async (key: string, value: string | null) => {
    const v = value ? value : null;
    await setActive({
      key: key,
      bucket: v,
    });
  };
  return (
    <div className={cx(styles.root, className)}>
      {filters.map((f) => {
        const dim = dimensions[f];
        return dim ? (
          <div key={f} className={styles.filter}>
            {dim.match === DIMENSION_MATCH.FUZZY ? (
              <DebouncedTextInput
                name={dim.key}
                label={dim.label || dim.key}
                value={getStringValue(dim)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onFilter(dim.key, e.target.value)
                }
                noInputError
                className={styles.wideFilter}
                debounceTimeout={200}
                rounded
                condensed
                icon={IconSearch}
              />
            ) : (
              <Select
                name={dim.key}
                value={dim.buckets}
                onSelect={onFilter}
                label={dim.label || dim.key}
                options={dim.options}
                multiSelect={isMultiSelect(dim.match, multiSelect)}
                config={{
                  ...defaultConfig,
                  ...config,
                }}
              />
            )}
          </div>
        ) : null;
      })}
    </div>
  );
};

const getStringValue = (d: IDimension) =>
  Array.isArray(d.buckets) ? d.buckets[0] : d.buckets || "";

const isMultiSelect = (match?: DIMENSION_MATCH, override?: boolean) =>
  override !== undefined
    ? override
    : match &&
      [DIMENSION_MATCH.FACET, DIMENSION_MATCH.FACET_ARRAY].includes(match);

export default ListFilters;
