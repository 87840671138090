import {
  DIMENSION_MATCH,
  IDimension,
  IDimensions,
  TBucket,
} from "models/Dimension";
import {
  PERIOD_OPTIONS,
  YEAR,
  DEFAULT_QUARTER as _DEFAULT_QUARTER,
  DEFAULT_YEAR as _DEFAULT_YEAR,
} from "models/Period";

export interface IFile extends File {
  path: string;
}

export interface IUploadEvent {
  event_at: number;
  event_name: string;
  execution_id: string;

  rows_scanned?: number;
  errors_by_level?: number;
  validation_failed?: boolean;
  pending?: boolean;
  archived?: boolean;

  errors_total?: number;
  columns_scanned?: string[];

  selected?: boolean;
  triggered_by_email?: string;
  deselected_with?: string;
}

const defaultConfig = {
  delimiter: "," as TUploadDelimiter,
  //NOTE: make sure code below keeps '\"' format (don't let prettier/eslint 'fix' it)
  //eslint-disable-next-line no-useless-escape
  quote_char: '\"' as TUploadQuote, //prettier-ignore
};

export const DEFAULT_QUARTER = _DEFAULT_QUARTER as TUploadPeriod;
export const DEFAULT_YEAR = _DEFAULT_YEAR as string;

export const UPLOAD_PERIOD_OPTIONS = PERIOD_OPTIONS;

// export const UPLOAD_PERIOD = ["Q1", "Q2", "Q3", "Q4"] as const;
export const UPLOAD_PERIOD = UPLOAD_PERIOD_OPTIONS.map((m) => m.key);
export const UPLOAD_YEAR = YEAR;
export const YEAR_OPTIONS = UPLOAD_YEAR.map((m) => ({ key: `${m}` }));

// export const YEAR_OPTIONS_MAP = YEAR_OPTIONS.map((m) => ({ key: m }))

export interface IUploadError {
  warning?: number;
  error?: number;
  critical?: number;
}

const defaultUpload = {
  upload_id: "" as string,
  org_id: "" as string,
  upload_bucket: "" as string,
  upload_key: "" as string,
  year: 0 as number,
  period: "" as TUploadPeriod,
  uploaded_by_id: "" as string,
  uploaded_by_username: "" as string,
  uploaded_by_email: "" as string,
  schema_name: "" as string,
  schema_version: "" as string,
  delimiter: defaultConfig.delimiter as TUploadDelimiter,
  quote_char: defaultConfig.quote_char as TUploadQuote,
  original_filename: "" as string,
  created: 0 as number,
  updated: 0 as number,
  events: [] as IUploadEvent[],

  rows_scanned: 0 as number,

  errors_total: 0 as number,
  errors_by_column: {} as Record<string, IUploadError>,
  columns_scanned: [] as string[],
  errors_by_level: {} as IUploadError,
  selected: false as boolean,
  init_validation_completed: 0 as number,
  perform_validation_completed: 0 as number,
  clean_up_validation_completed: 0 as number,
  handle_validation_failure_completed: 0 as number,
  archived: false as boolean,
};

export type TUpload = typeof defaultUpload;

//--------------------------------

export type TUploadPeriod = typeof UPLOAD_PERIOD[number];
export type TUploadYear = typeof UPLOAD_YEAR[number];
export type TUploadDelimiter = "," | "|";
//NOTE: make sure code below keeps '\"' format (don't let prettier/eslint 'fix' it)
//eslint-disable-next-line no-useless-escape
export type TUploadQuote = '\"' | "\'"; //prettier-ignore
export type TUploadFilter = Record<string, TBucket>;

export interface IUploadConfig {
  year: TUploadYear;
  period: TUploadPeriod;
  schema_name: string;
  schema_version: string;
  delimiter?: TUploadDelimiter;
  quote_char?: TUploadQuote;
}

export const createUpload = (upload?: Partial<TUpload>): TUpload => {
  //strip out undefined attributes to they can be replaced with defaults from defaultUpload
  const cleanedUpload = { ...upload };
  let k: keyof typeof cleanedUpload;
  for (k in cleanedUpload) {
    if (cleanedUpload[k] === undefined) {
      delete cleanedUpload[k];
    }
  }

  return {
    ...defaultUpload,
    ...cleanedUpload,
  };
};

export const createConfig = (config: IUploadConfig, file: IFile) => ({
  ...defaultConfig,
  ...config,
  original_filename: file.path,
});

export interface IUploadDimensions extends IDimensions {}

export const UPLOAD_FILTER_KEYS = ["schema_name", "period", "year"];

const UploadDimArray = [
  {
    key: "period",
    label: "Period",
    options: UPLOAD_PERIOD_OPTIONS,
    buckets: DEFAULT_QUARTER,
    match: DIMENSION_MATCH.EXACT,
  },
  {
    key: "year",
    label: "Year",
    options: YEAR_OPTIONS,
    buckets: DEFAULT_YEAR,
    match: DIMENSION_MATCH.EXACT,
  },
] as IDimension[];

export const uploadDimensions = UploadDimArray.reduce(
  (s, d) => ({ ...s, [d.key]: d }),
  {}
) as IUploadDimensions;
