// Modified from https://github.com/jeetiss/tabs
import React, { createContext, useState, useMemo, useContext } from "react";
import useConstant from "use-constant";
import { Tab } from "./elements/Tab";
import { TabList } from "./elements/TabList";
import { Panel } from "./elements/Panel";
import { PanelList } from "./elements/PanelList";

const defaultElements = {
  tabs: 0 as number,
  panels: 0 as number,
  tabRefs: [] as any[],
};
const initialState = 0 as number;

type TabState = [typeof initialState, any];
type ElementsState = typeof defaultElements;

export const TabsState = createContext<TabState>([initialState, undefined]);
export const TabElements = createContext<ElementsState>({ ...defaultElements });

interface TabsProps {
  state?: TabState;
  children: React.ReactNode;
}

export const Tabs = ({ state: outerState, children }: TabsProps) => {
  const innerState = useState(initialState);
  const elements = useConstant<ElementsState>(() => ({ ...defaultElements }));
  const state = outerState || innerState;

  return (
    <TabElements.Provider value={elements}>
      <TabsState.Provider value={state}>{children}</TabsState.Provider>
    </TabElements.Provider>
  );
};

export const useTabState = (ref: any) => {
  const [activeIndex, setActive] = useContext(TabsState);
  const elements = useContext(TabElements);

  const tabIndex = useConstant(() => {
    const currentIndex = elements.tabs;
    elements.tabs += 1;
    elements.tabRefs[currentIndex] = ref;

    return currentIndex;
  });

  const onClick = useConstant(() => () => {
    setActive(tabIndex);
  });

  return useMemo(
    () => ({
      isActive: activeIndex === tabIndex,
      onClick,
    }),
    [activeIndex, onClick, tabIndex]
  );
};

export const usePanelState = () => {
  const [activeIndex] = useContext(TabsState);
  const elements = useContext(TabElements);

  const panelIndex = useConstant(() => {
    const currentIndex = elements.panels;
    elements.panels += 1;

    return currentIndex;
  });

  return panelIndex === activeIndex;
};

export { Tab, Panel, PanelList, TabList };
