import React from "react";
import cx from "classnames";
import UserMenu from "components/common/layouts/appLayout/appSettingBar/UserMenu";
import SwitchOrg from "components/common/layouts/appLayout/appSettingBar/SwitchOrg";
import styles from "components/common/layouts/appLayout/appSettingBar/AppSettingBar.module.scss";

export interface IAppSettingBar {
  className?: string;
}

const AppSettingBar = React.memo(({ className }: IAppSettingBar) => {
  return (
    <div className={cx(styles.root, className)}>
      <SwitchOrg />
      <UserMenu />
    </div>
  );
});

export default AppSettingBar;
