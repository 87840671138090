import React, { useEffect } from "react";
import Meta from "components/common/Meta";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { userService } from "services/UserService";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import UsersList from "components/users/admin/list/UserList";
import Button from "components/common/ui/button/Button";
import { ADMIN_CREATE_USER } from "constants/routes";
import Container from "components/common/layouts/Container";
import { coreService } from "services/CoreService";
import {
  isLoadingSelector,
  userListDimSelector,
  userListSelector,
} from "store/selectors/users.selectors";
import { permissionsSelector } from "store/selectors/auth.selectors";
import { verifyPermisisons } from "utils/authUtils";
import { useHasChanged } from "hooks/useHasChanged";
import { isAppLoadedSelector } from "store/selectors/core.selectors";
import styles from "components/users/admin/list/UsersList.module.scss";

const ViewAdminUsersList = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const isLoading = useSelector(isLoadingSelector);
  const users = useSelector(userListSelector);
  const dimensions = useSelector(userListDimSelector);
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const permissions = useSelector(permissionsSelector);
  const canEdit = verifyPermisisons(
    permissions,
    ADMIN_CREATE_USER.requiredRole
  );
  const refresh = useHasChanged(isAppLoaded);
  const orgChanged = useHasChanged(orgId);

  useEffect(() => {
    // Wait until user's org is loaded
    if (isAppLoaded) {
      // if query is empty (no URL search params) or orgId has changed
      if (refresh || orgChanged) {
        userService.loadList();
      }
    }
  }, [isAppLoaded, refresh, orgChanged]);

  return (
    <AppContentContainer spacing="none">
      <Meta title={"Admin Users"} />
      <Container spacing={"extra"} spacingTopOnly>
        <div className={styles.header}>
          <h1>Manage Users</h1>
          {canEdit && (
            <Button
              rounded
              theme="primary"
              to={coreService.getRoute(ADMIN_CREATE_USER)}
            >
              Create New User
            </Button>
          )}
        </div>
      </Container>

      <UsersList
        users={users}
        dimensions={dimensions}
        setActive={userService.setDimensions}
        setManyActive={userService.setManyDimensions}
        isAppLoaded={isAppLoaded}
        loading={isLoading}
      />
    </AppContentContainer>
  );
};

export default ViewAdminUsersList;
