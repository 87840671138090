import { useQuery } from "react-query";
import { qualityIndicatorQueryKeys } from "queries/constants";
import { getIndicatorDetail } from "api/InsightsApi";
import { QueryOptions } from "queries/queries";
import { useToast } from "components/common/ui/toast/useToast";

export const useGetQualIndicatorDetail = (
  indicatorKey: string,
  orgId: string,
  params = {} as Record<string, any>,
  options?: QueryOptions
) => {
  const { addErrorToast } = useToast();
  return useQuery(
    qualityIndicatorQueryKeys.detail(indicatorKey, orgId, params),
    () => getIndicatorDetail(indicatorKey, orgId, params),
    {
      onError: (e) =>
        addErrorToast(e, "Unable to load Quality Indicator Detail", {
          id: qualityIndicatorQueryKeys.detail(indicatorKey, orgId).join("::"),
        }),
      ...options,
    }
  );
};
