import { DIMENSION_MATCH, IDimension, IDimensions, TBucket } from "models/Dimension";

export const updateBuckets = (bucket: any, dimension: IDimension) => {
  //if dim should only be single text string return bucket
  if (
    dimension.match &&
    [
      DIMENSION_MATCH.EXACT,
      DIMENSION_MATCH.EXACT_ARRAY,
      DIMENSION_MATCH.FUZZY,
    ].includes(dimension.match)
  ) {
    return bucket;
  }
  //if null clear all filters
  if (bucket === null) {
    return [];
    //if bucket is already an array, then just replace
  } else if (Array.isArray(bucket)) {
    return bucket;
  } else {
    let filters =
      dimension.buckets && Array.isArray(dimension.buckets)
        ? [...dimension.buckets]
        : [];
    const index = filters.indexOf(bucket);
    //add if key doesn't exist in filter, or remove if it does
    if (index === -1) {
      filters.push(bucket);
    } else {
      filters.splice(index, 1);
    }
    return filters;
  }
};

export const deepObjectAccessor = (obj: TObjectAny, keyString: string): any => {
  return keyString.split(".").reduce((o, k) => o?.[k], obj);
};

export const paginateData = ({
  data,
  next: possibleNext,
  limit,
}: {
  data: any[];
  next: number;
  limit: number;
}) => {
  const total = data.length;
  if (total === 0) {
    return {
      data: data,
      current: 0,
    };
  }
  const next = Math.max(possibleNext, 0);
  const start = next * limit;
  if (start <= total) {
    const end = Math.min(start + limit, total);
    const paginatedData = data.slice(start, end);
    return {
      data: paginatedData,
      current: next,
    };
  }
  return false;
};

// export const flattenDimensionData = (data: any[], dimensions: IDimension[]) => {
//   return data.map((d) => {
//     return dimensions.reduce((obj, k) => {
//       return { ...obj, [k.bitIndex]: deepObjectAccessor(d, k.key) };
//     }, {} as Record<string, any>);
//   });
// };

export const dimensionsToParamsFormat = (
  dimensions: IDimensions
) => {
  return Object.keys(dimensions)
    .filter((d) => {
      const b = dimensions[d].buckets || [];
      return b !== null && b.length > 0;
    })
    .reduce((r, d) => ({ ...r, [d]: dimensions[d].buckets }), {});
};

export const diffParamsAndDimensions = (
  dimensions: Record<string, TBucket>,
  params: Record<string, any>

) => {
  const dimKeys = Object.keys(dimensions).sort();
  const paramKeys = Object.keys(params).sort();
  const dimToRemove = dimKeys
    .filter((x) => !paramKeys.includes(x))
    .map((d) => ({ key: d, bucket: null }));


  const dimToAdd = paramKeys
    .filter((x) => !dimKeys.includes(x))
    .map((d) => ({ key: d, bucket: params[d] }));

  const diffIntersection = dimKeys
    .filter((a) => paramKeys.includes(a))
    .map((x) => {
      const d = dimensions[x] as string | string[];
      let p = params[x];
      if (typeof d === "string") {
        if (d !== p) {
          return { key: x, bucket: p };
        }
      } else {
        p = typeof p === "string" ? [p] : p;
        if (
          p.length !== d.length ||
          !d.every((val, index) => val === p[index])
        ) {
          return { key: x, bucket: p };
        }
      }
      return undefined;
    })
    .filter((x) => !!x);
  return [...dimToRemove, ...dimToAdd, ...diffIntersection];
};


export const fuzzyMatch = (match: string, value: string) => {
  const v = ("" + value).toLowerCase();
  const queryParts = match
    .toLowerCase()
    .replace(/[()]/g, "")
    .split(" ")
    .map((q) => q.trim());

  return queryParts.reduce((test, q) => {
    return v.includes(q) && test;
  }, true);
};

export const fuzzyMatchArray = (match: string, values: string[]) => {
  const p = match.toLowerCase();
  return values.reduce((testAll, query) => {
    const queryParts = query
      .toLowerCase()
      .replace(/[()]/g, "")
      .split(" ")
      .map((q) => q.trim());

    return queryParts.reduce((test, q) => {
      return p.indexOf(q) > -1 && test;
    }, true);
  }, true);
};