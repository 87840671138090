import {
  ageBandBuckets,
  ageByEthnicityBucktes,
  akpsBuckets,
  contactProviderBuckets,
  contactPurposeBuckets,
  deliverySettingsBuckets,
  ethnicityBuckets,
  genderBuckets,
  iposBuckets,
  livingSituationBuckets,
  phaseBuckets,
  principalDiagnosisBuckets,
  referralSourceBuckets,
  ruralityBuckets,
} from "models/insights/common";
import { otherChartColor } from "utils/chartColorUtils";

export interface IMeasureBucketDef {
  key: string;
  label: string;
  yLabel?: string;
  color?: string;
  disabled?: boolean;
  group?: string;
  index?: number;
}

export const systemMeasureBucketsDef = {
  "001": referralSourceBuckets,
  "002": principalDiagnosisBuckets,
  "003": ageBandBuckets,
  "004": ethnicityBuckets,
  "005": genderBuckets,
  "006": ruralityBuckets,
  "007": livingSituationBuckets,
  "008": ageByEthnicityBucktes,
  "009": referralSourceBuckets,
  "010": principalDiagnosisBuckets,
  "011": ageBandBuckets,
  "012": ethnicityBuckets,
  "013": genderBuckets,
  "014": ruralityBuckets,
  "015": livingSituationBuckets,
  "016": ageByEthnicityBucktes,
  "017": akpsBuckets,
  "018": iposBuckets,
  "019": phaseBuckets,
  "020": referralSourceBuckets,
  "021": principalDiagnosisBuckets,
  "022": ageBandBuckets,
  "023": ethnicityBuckets,
  "024": genderBuckets,
  "025": ruralityBuckets,
  "026": livingSituationBuckets,
  "027": ageByEthnicityBucktes,
  "028": deliverySettingsBuckets,
  "029": [
    { key: "Patient", label: "Patient" },
    {
      key: "Patient and carer(s), family, friends, whānau",
      label: "Patient and carer(s), family, friends, whānau",
    },
    {
      key: "Carer(s), family, friends, whānau",
      label: "Carer(s), family, friends, whānau",
    },
    {
      key: "Other professional(s)/service provider(s) only",
      label: "Other professional(s)/service provider(s) only",
    },
    {
      key: "Other recipient",
      label: "Other recipient",
    },
    { key: "Unknown", label: "Unknown", color: otherChartColor() },
  ],
  "030": contactPurposeBuckets,
  "031": contactProviderBuckets,
  "032": [
    { key: "Face-to-face", label: "Face-to-face" },
    { key: "Telephone", label: "Telephone" },
    {
      key: "Written (including email, txt)",
      label: "Written (including email, txt)",
    },
    { key: "Tele/video/web conference", label: "Tele/video/web conference" },
    { key: "Other", label: "Other", color: "#bbb" },
  ],
  "033": [{ key: "", label: "IPOS scores recorded" }],
  "034": [{ key: "", label: "CSNET scores recorded" }],
  "035": [{ key: "", label: "Phase scores recorded" }],
  "036": [{ key: "", label: "AKPS scores recorded" }],
  "037": [{ key: "", label: "All outcomes done" }],
  "038": [
    { key: "pre::patient_alive", label: "Alive" },
    { key: "pre::patient_died", label: "Died" },
    { key: "pre::referral_admitted", label: "Admitted" },
    {
      key: "pre::referral_admitted_and_patient_died",
      label: "Admitted and Died",
    },
    { key: "pre::post_death", label: "Post Death" },
    { key: "pre::missing", label: "Unknown" },

    { key: "post::patient_alive", label: "Alive" },
    { key: "post::patient_died", label: "Died" },
    { key: "post::referral_admitted", label: "Admitted" },
    {
      key: "post::referral_admitted_and_patient_died",
      label: "Admitted and Died",
    },
    { key: "post::post_death", label: "Post Death" },
    { key: "post::missing", label: "Unknown" },
  ],
  "044": akpsBuckets,
  "045": phaseBuckets,
  "046": [
    // Private residence
    {
      key: "Private residence (including unit in retirement village)",
      label: "Private residence (including unit in retirement village)",
      group: "Private residence",
    },
    // Residential aged care
    {
      key: "Residential aged care, high level care (hospital level care)",
      label: "Residential aged care, high level care (hospital level care)",
      group: "Residential aged care",
    },
    {
      key: "Residential aged care, low level care (level 2 rest home)",
      label: "Residential aged care, low level care (level 2 rest home)",
      group: "Residential aged care",
    },
    // Public hospital
    {
      key: "Public Hospital",
      label: "Public hospital",
      group: "Public hospital",
    },
    // Hospice IPU
    {
      key: "Hospice Inpatient Unit",
      label: "Hospice Inpatient Unit",
      group: "Hospice IPU",
    },
    // Private hospital
    {
      key: "Private Hospital",
      label: "Private hospital",
      group: "Private hospital",
    },
    // Other
    {
      key: "Correctional Facility",
      label: "Correctional Facility",
      group: "Other",
    },
    { key: "Other", label: "Other", group: "Other", color: otherChartColor() },
    {
      key: "missing",
      label: "Unknown",
      color: otherChartColor(1),
      group: "Not Recorded",
    },
  ],
} as Record<string, IMeasureBucketDef[]>;

export const measure038Nodes = [
  { id: "pre::patient_alive", title: "Alive" },
  { id: "pre::patient_died", title: "Died" },
  { id: "pre::referral_admitted", title: "Admitted" },
  {
    id: "pre::referral_admitted_and_patient_died",
    title: "Admitted and Died",
  },
  { id: "pre::post_death", title: "Post Death" },
  { id: "pre::missing", title: "Not in prev quarter data" },

  { id: "post::patient_alive", title: "Alive" },
  { id: "post::patient_died", title: "Died" },
  { id: "post::referral_admitted", title: "Admitted" },
  {
    id: "post::referral_admitted_and_patient_died",
    title: "Admitted and Died",
  },
  { id: "post::post_death", title: "Post Death" },
  { id: "post::missing", title: "Missing" },
];
