//modified from https://github.com/wellyshen/react-cool-dimensions

import { useCallback, useEffect, useRef, useState } from "react";
import throttle from "lodash.throttle";
import ResizeObserver from "resize-observer-polyfill";
import { usePrevious } from "hooks/useHasChanged";

interface State {
  readonly width: number;
  readonly height: number;
  readonly entry?: ResizeObserverEntry;
}

interface Observe<T> {
  (element?: T | null): void;
}
interface Event<T> extends State {
  readonly entry: ResizeObserverEntry;
  observe: Observe<T>;
  unobserve: () => void;
}

interface Return<T> extends Omit<Event<T>, "entry"> {
  entry?: ResizeObserverEntry;
}

const useDimensions = <T extends HTMLElement | null>(): Return<T> => {
  const [size, setSize] = useState<State>({
    width: 0,
    height: 0,
  });
  const ref = useRef<T>();
  const observerRef = useRef<ResizeObserver>();
  // const prevSizeRef = useRef<{ width?: number; height?: number }>({});
  const prevSizeRef = usePrevious<{ width?: number; height?: number }>(size);

  const unobserve = useCallback(() => {
    if (observerRef.current) observerRef.current.disconnect();
  }, []);

  const observe = useCallback<Observe<T>>(
    (element) => {
      if (element && element !== ref.current) {
        unobserve();
        ref.current = element;
      }
      if (observerRef.current && ref.current)
        observerRef.current.observe(ref.current as HTMLElement);
    },
    [unobserve]
  );

  useEffect(() => {
    let raf: number | null = null;
    observerRef.current = new ResizeObserver(
      throttle(([entry]: any) => {
        // ([entry]: any) => {
        raf = requestAnimationFrame(() => {
          const { contentBoxSize, contentRect } = entry;

          let boxSize = contentBoxSize;
          boxSize = Array.isArray(boxSize) ? boxSize[0] : boxSize;

          const width = boxSize ? boxSize.inlineSize : contentRect.width;
          const height = boxSize ? boxSize.blockSize : contentRect.height;

          if (
            // width === prevSizeRef.current.width &&
            // height === prevSizeRef.current.height
            width === prevSizeRef?.width &&
            height === prevSizeRef?.height
          )
            return;

          // prevSizeRef.current = { width, height };

          setSize({
            width,
            height,
            entry,
          });
        });
      }, 200)
      // }
    );

    observe();

    return () => {
      unobserve();
      if (raf) cancelAnimationFrame(raf);
    };
  }, [observe, unobserve]); // eslint-disable-line react-hooks/exhaustive-deps

  return { ...size, observe, unobserve };
};

export { useDimensions };
