import React from "react";
import cx from "classnames";
import MouseFollowToolTip from "components/common/ui/tooltip/MouseFollowTooltip";
import { ChartTheme, IDataModel } from "components/common/ui/charts/chart";
import styles from "./ChartTooltip.module.scss";
import { defaultColorAccessor } from "utils/chartUtils";

export interface IChartTooltipProps {
  title: string;
  items: IDataModel[];
  children: React.ReactElement;
  colorAccessor?: (d: IDataModel, index: number) => string | undefined;
  theme?: ChartTheme;
}

const ChartTooltip = ({
  title,
  items,
  theme = "sunset",
  children,
  colorAccessor = defaultColorAccessor,
}: IChartTooltipProps) => (
  <MouseFollowToolTip
    direction="right-center"
    className={cx(styles.root, styles[`${theme}Theme`])}
    content={
      <div>
        <h4 className={styles.title}>{title}</h4>
        <div className={styles.legend}>
          {items.map((d, i) => (
            <div className={styles.item} key={d.key}>
              <span
                style={{ color: colorAccessor(d, i) }}
                className={styles[`dot-${i}`]}
              />
              <div className={styles.label}> {d.metadata?.label || d.key}:</div>
              <div className={styles.value}>
                {d.metadata?.value || d.data || <i>No Data</i>}
              </div>
            </div>
          ))}
        </div>
      </div>
    }
  >
    {children}
  </MouseFollowToolTip>
);

export default ChartTooltip;
