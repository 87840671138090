import React, { useEffect, useState } from "react";
import Meta from "components/common/Meta";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { useHistory, useLocation, useParams } from "react-router";
import { useSelector } from "react-redux";
import { isAppLoadedSelector } from "store/selectors/core.selectors";
import { insightsFiltersSelector } from "store/selectors/insights.selectors";
import { useGetSystemMeasuresList } from "components/insights/hooks/useSystemMeasuresList";
import PageHeading from "components/common/ui/typography/PageHeading";
import {
  MEASURE_SECTION_KEYS,
  MeasureSection,
  measureSections,
  measureSectionsMap,
} from "models/insights/SystemMeasures";
import { YEAR_PERIOD_OPTIONS } from "models/Period";
import { coreService } from "services/CoreService";
import { SYSTEM_MEASURES } from "constants/routes";
import {
  Panel,
  PanelList,
  Tab,
  TabList,
  Tabs,
} from "components/common/ui/tabs/Tabs";
import Container from "components/common/layouts/Container";
import SystemMeasureSummary from "components/insights/measures/Summary";
import SystemMeasurePatients from "components/insights/measures/Patients";
import SystemMeasureReferrals from "components/insights/measures/Referrals";
import SystemMeasureDeaths from "components/insights/measures/Deaths";
import SystemMeasureContacts from "components/insights/measures/Contacts";
import MiniLoader from "components/common/ui/loader/MiniLoader";
import IconTable, { IconTableRemove } from "components/common/icons/IconTable";
import ToggleIconButton from "components/common/ui/button/toggle-icon-button/ToggleIconButton";
import { InsightsFilters } from "components/insights/common/InsightsFilters";
import styles from "components/insights/measures/SystemMeasureDetail.module.scss";

const initialState = {
  name: "Tab",
  slug: MEASURE_SECTION_KEYS.SUMMARY,
  index: 0,
};

const ViewInsightsSystemMeasures = () => {
  const [tabState, setTabs] = useState<MeasureSection>(initialState);
  const [showDataTables, setShowDataTables] = useState<boolean>(false);
  const history = useHistory();
  const { hash } = useLocation();
  const { orgId } = useParams<{ orgId: string }>();
  const isAppLoaded = useSelector(isAppLoadedSelector);
  const filters = useSelector(insightsFiltersSelector);
  const slug = hash.replace("#", "") as MEASURE_SECTION_KEYS;
  const period = YEAR_PERIOD_OPTIONS.find((d) => filters.period === d.key);

  const { isLoading, data } = useGetSystemMeasuresList(
    orgId,
    {
      year_period: filters.period,
    },
    { enabled: isAppLoaded }
  );

  const setHashState = async (newState: number) => {
    //always get the fresh version of currentIndex within setIndicatorState
    setTabs((s) => {
      if (newState !== s.index) {
        const newHash = measureSections[newState].slug;
        history.push(
          `${coreService.getRoute(SYSTEM_MEASURES, {
            orgId,
          })}#${newHash}`
        );
      }
      return s;
    });
  };

  useEffect(() => {
    const newSection = measureSectionsMap[slug];
    setTabs(newSection);
  }, [slug, orgId, history]);

  return (
    <AppContentContainer
      maxWidth={false}
      spacing="none"
      backgroundColor="light-grey"
      className={styles.root}
    >
      <Meta title={"System Measures"} />
      <Container spacing="extra" spacingTopOnly>
        <PageHeading darker>System Measures</PageHeading>
        <p>
          <b>
            The Systems Measures are the representation of key areas of data
            that a hospice provides.
          </b>{" "}
          {/*<br />*/}
          {/*The two main groupings are patient information and episodes of care,*/}
          {/*and patient contacts. This section allows an individual hospice to see*/}
          {/*how its volumes change over time and what demographic and clinical*/}
          {/*aspects are driving this. It also allows a deeper understanding of the*/}
          {/*relationships between the care being provided to different cohorts,*/}
          {/*the frequency of contact and the clinicians involved. <br /> <br />*/}
          {/*It supports individual hospices to know the trajectory of patients*/}
          {/*from referral to death, and plan improved care pathways.*/}
          {/*<br />*/}
          {/*The National view allows Hospice NZ to build on the yearly data they have historically had access to at a more frequent and granular level.*/}
        </p>
      </Container>
      <Tabs state={[tabState.index, setHashState]}>
        <TabList
          contentClassName={styles.tabContent}
          decorator="none"
          tabBackground
        >
          {measureSections.map((d) => (
            <Tab key={d.slug} className={styles.tab}>
              {d.name}
            </Tab>
          ))}
        </TabList>

        <div className={styles.contentWrapper}>
          <Container className={styles.actions} spacing="none">
            <InsightsFilters hideRegionFilter>
              <ToggleIconButton
                toggled={showDataTables}
                setToggle={setShowDataTables}
                onIcon={<IconTable />}
                offIcon={<IconTableRemove />}
                tooltipContent={`Show Data Tables`}
              />
            </InsightsFilters>
          </Container>

          {!isLoading ? (
            <PanelList>
              <Panel>
                {data && (
                  <SystemMeasureSummary
                    data={data}
                    setTabState={setHashState}
                    period={period?.label || ""}
                    showDataTables={showDataTables}
                  />
                )}
              </Panel>
              <Panel>
                {data && (
                  <SystemMeasurePatients
                    data={data}
                    period={period?.label || ""}
                    showDataTables={showDataTables}
                  />
                )}
              </Panel>
              <Panel>
                {data && (
                  <SystemMeasureReferrals
                    data={data}
                    period={period?.label || ""}
                    showDataTables={showDataTables}
                  />
                )}
              </Panel>
              <Panel>
                {data && (
                  <SystemMeasureContacts
                    data={data}
                    period={period?.label || ""}
                    showDataTables={showDataTables}
                  />
                )}
              </Panel>
              <Panel>
                {data && (
                  <SystemMeasureDeaths
                    data={data}
                    period={period?.label || ""}
                    showDataTables={showDataTables}
                  />
                )}
              </Panel>
            </PanelList>
          ) : (
            <MiniLoader className={styles.loader} />
          )}
        </div>
      </Tabs>
    </AppContentContainer>
  );
};

export default ViewInsightsSystemMeasures;
