import React from "react";
import Meta from "components/common/Meta";
import { useHistory, useParams } from "react-router";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import { create as blankUser, TUser } from "models/User";
import { userService } from "services/UserService";
import { ADMIN_USER_DETAIL, ADMIN_USERS } from "constants/routes";
import CreateUser from "components/users/admin/create-user/CreateUser";
import Container from "components/common/layouts/Container";
import { coreService } from "services/CoreService";

const ViewAdminCreateUser = () => {
  const history = useHistory();
  const { orgId } = useParams<{ orgId: string }>();

  const onSave = async (newUser: Partial<TUser>) => {
    const user = await userService.saveDetail(newUser, blankUser(), orgId);
    if (user.id) {
      history.push(
        coreService.getRoute(ADMIN_USER_DETAIL, { id: user.id, orgId })
      );
    }
  };
  const onCancel = () => {
    history.push(coreService.getRoute(ADMIN_USERS, { orgId }));
  };
  return (
    <AppContentContainer
      spacing="none"
      backButton={{
        to: coreService.getRoute(ADMIN_USERS, { orgId }),
        label: "Back to Users",
      }}
    >
      <Meta title="Create User" />
      <br />
      <br />
      <br />
      <Container>
        <h1>Create New User</h1>
        <CreateUser onSave={onSave} onCancel={onCancel} />
      </Container>
    </AppContentContainer>
  );
};

export default ViewAdminCreateUser;
