import React from "react";
import Meta from "components/common/Meta";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import {
  ADMIN_USERS,
  VIEW_UPLOADS,
  SYSTEM_MEASURES,
  COMMUNITY_SUPPORTS,
  COMMUNITY_SUPPORTS_EXTERNAL_URL,
} from "constants/routes";
import HomeCard, {
  HomeCardContainer,
} from "components/common/ui/card/HomeCard";
import InsightsIllustration from "components/common/images/InsightsIllustration";
import DataPortalIllustration from "components/common/images/DataPortalIllustration";
import CommunityServicesIllustration from "components/common/images/CommunityServicesIllustration";
import AdminIllustration from "components/common/images/AdminIllustration";
import styles from "components/home/Home.module.scss";
import hospiceLogo from "components/home/hospice-data-commons-logo.png";

const ViewHome = () => (
  <AppContentContainer
    maxWidth={true}
    spacing="extra"
    backgroundColor="light-grey"
  >
    <Meta title={"Home"} />
    <img
      src={hospiceLogo}
      alt="Hospice Data Commons"
      className={styles.clientLogo}
    />
    <p className={styles.pageBlurb}>
      Data and information underpin care and are crucial to improving services
      for patients, family and whānau with palliative care needs. Our vision is
      a platform that provides an opportunity for hospices to better understand
      service provision and outcomes of care, to allow hospices and Hospice NZ
      to make informed strategic decisions, and to enable Hospice NZ to advocate
      on behalf of hospices.
    </p>

    <HomeCardContainer>
      <HomeCard
        title={"Insights Dashboard"}
        description={
          "View System Measures and Quality Indicator reports for your hospice"
        }
        route={SYSTEM_MEASURES}
        col={12}
        svg={InsightsIllustration}
      />

      <HomeCard
        title={"Community Support Portal"}
        description={"Add Community Support activity for your hospice"}
        route={COMMUNITY_SUPPORTS}
        href={COMMUNITY_SUPPORTS_EXTERNAL_URL}
        svg={CommunityServicesIllustration}
      />

      <HomeCard
        title={"Data Portal"}
        description={"Upload hospice data for use in the Insights Dashboards"}
        route={VIEW_UPLOADS}
        svg={DataPortalIllustration}
      />

      <HomeCard
        title={"Admin"}
        description={"Create users and manage permissions"}
        route={ADMIN_USERS}
        svg={AdminIllustration}
      />
    </HomeCardContainer>
  </AppContentContainer>
);

export default ViewHome;
