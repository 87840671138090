import React from "react";
import cx from "classnames";
import IconCheck from "components/common/icons/IconCheck";
import { formatUnixTime } from "utils/format/dateUtils";
import Chip from "components/common/ui/chip/Chip";
import styles from "./Timeline.module.scss";

interface ITimeline {
  children?: React.ReactNode;
  markers?: IActivityMarker[];
  className?: string;
  condensed?: boolean;
}

export const Timeline = ({
  children,
  markers,
  className,
  condensed,
}: ITimeline) => (
  <ul className={cx(styles.timeline, condensed && styles.condensed, className)}>
    {children}
    {markers?.map((m) => (
      <ActivityMarker key={`${m.heading}|${m.timestamp}`} {...m} />
    ))}
  </ul>
);

export interface IActivityMarker {
  heading: React.ReactNode;
  timestamp?: number;
  icon?: React.ReactNode;
  description?: React.ReactNode;
  theme?: ThemeVariants;
  timeFormat?: string;
  className?: string;
  children?: React.ReactNode;
}

export const ActivityMarker = ({
  heading,
  timestamp,
  icon = <IconCheck />,
  description,
  theme,
  timeFormat = "pp '-' do MMM yyyy",
  className,
  children,
}: IActivityMarker) => (
  <li className={cx(styles.marker, className, theme && styles[theme])}>
    <div className={styles.iconContainer}>
      <span className={styles.icon}>{icon}</span>
    </div>
    <div className={styles.textContainer}>
      <div className={styles.header}>
        <h4 className={styles.heading}>{heading}</h4>

        {timestamp && (
          <Chip condensed variant="lightFilled" className={styles.timestamp}>
            {formatUnixTime(timestamp, timeFormat)}
          </Chip>
        )}
      </div>
      <div className={styles.description}>{description}</div>
      {children}
    </div>
  </li>
);

export default Timeline;
