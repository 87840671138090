import React from "react";
import cx from "classnames";
import styles from "./ActiveOptionChip.module.scss";
import Chip from "components/common/ui/chip/Chip";
import { IFacetOption } from "models/Dimension";
import IconCancel from "components/common/icons/IconCancel";

interface Props extends IFacetOption {
  onClick?: (key: string) => void;
  id: string;
  className?: string;
  cancelIcon?: boolean;
  theme?: ThemeVariants;
}

const ActiveOptionChip = React.memo(
  ({
    color,
    onClick,
    className,
    cancelIcon,
    theme = "primary",
    ...props
  }: Props) => {
    const styleProps = color
      ? {
          style: { color, background: color + 28 },
          iconStyle: { color, background: color + 28 },
        }
      : {};
    const clickProps = onClick ? { onClick: () => onClick(props.id) } : {};
    return (
      <Chip
        {...props}
        {...styleProps}
        className={cx(styles.root, className, cancelIcon && styles.cancel)}
        theme={theme}
        condensed
        {...clickProps}
      >
        {props.label || props.id}{" "}
        {cancelIcon && <IconCancel className={styles.iconCancel} />}
      </Chip>
    );
  }
);

export default ActiveOptionChip;
