import React, { FC, useMemo } from "react";
import classNames from "classnames";
import { pie } from "d3-shape";
import { CloneElement } from "rdk";
import {
  ChartShallowDataShape,
  PieArcSeriesProps,
  ChartContainer,
  ChartContainerChildProps,
  PieArc,
  PieChartProps,
} from "reaviz";
import { ColorSchemeType } from "reaviz/dist/src/common/color/helper";
import { ComparisonPieArcSeries } from "components/common/ui/charts/pie/ComparisonPieArcSeries";
import ChartTooltip, {
  IChartTooltipProps,
} from "components/common/ui/charts/tooltip/ChartTooltip";

export interface ComparisonPieChartProps extends PieChartProps {
  comparisonData?: ChartShallowDataShape[];
  colorAccessor: ColorSchemeType;
  tooltip?: Omit<IChartTooltipProps, "children">;
}

const emptyData = {
  endAngle: Math.PI * 2,
  startAngle: 0,
  data: { data: 0, key: "background1" },
  padAngle: 0,
  value: 0,
  index: 0,
};

export const ComparisonPieChart: FC<ComparisonPieChartProps> = ({
  id,
  width,
  height,
  className,
  displayAllLabels,
  data = [],
  comparisonData = [],
  margins = 10,
  colorAccessor,
  series = <ComparisonPieArcSeries />,
  tooltip,
}) => {
  const getData = useMemo(() => {
    const pieLayout = pie<void, ChartShallowDataShape>().value(
      (d: ChartShallowDataShape) => Number(d.data)
    );
    pieLayout.sort(null);

    // Explode sort doesn't work right...
    // if (!series?.props.explode) {
    //   pieLayout.sort(null);
    // }

    return pieLayout(data);
  }, [data]);

  const getComparisonData = useMemo(() => {
    const pieLayout = pie<void, ChartShallowDataShape>().value(
      (d: ChartShallowDataShape) => Number(d.data)
    );
    pieLayout.sort(null);

    // Explode sort doesn't work right...
    // if (!series?.props.explode) {
    //   pieLayout.sort(null);
    // }

    return pieLayout(comparisonData);
  }, [comparisonData]);

  const chart = (
    <ChartContainer
      id={id}
      width={width}
      height={height}
      margins={margins}
      xAxisVisible={false}
      yAxisVisible={false}
      center={true}
      className={classNames(className)}
    >
      {({ chartWidth, chartHeight }: ChartContainerChildProps) => (
        <>
          <g className="background-pie">
            <BackGroundPieArcs
              chartWidth={chartWidth}
              chartHeight={chartHeight}
            />
          </g>
          <g className="cohort-pie">
            <CloneElement<PieArcSeriesProps>
              element={series}
              data={getComparisonData}
              height={chartHeight}
              width={chartWidth}
              displayAllLabels={displayAllLabels}
              colorScheme={colorAccessor}
              arcWidth={0.15}
            />
          </g>

          <g className="main-pie">
            <CloneElement<PieArcSeriesProps>
              element={series}
              data={getData}
              height={chartHeight - chartHeight * 0.2}
              width={chartWidth - chartWidth * 0.2}
              displayAllLabels={displayAllLabels}
              colorScheme={colorAccessor}
            />
          </g>
        </>
      )}
    </ChartContainer>
  );

  return tooltip ? (
    <ChartTooltip {...tooltip}>
      <span>{chart}</span>
    </ChartTooltip>
  ) : (
    chart
  );
};

const BackGroundPieArcs = ({
  chartWidth,
  chartHeight,
}: {
  chartWidth: number;
  chartHeight: number;
}) => (
  <>
    <CloneElement<PieArcSeriesProps>
      element={
        <ComparisonPieArcSeries
          arc={<PieArc disabled cursor="inherit" tooltip={null} />}
        />
      }
      animated={false}
      data={[{ ...emptyData, data: { data: 0, key: "background-inner" } }]}
      height={chartHeight - chartHeight * 0.2}
      width={chartWidth - chartWidth * 0.2}
      colorScheme="#eee"
    />
    <CloneElement<PieArcSeriesProps>
      element={
        <ComparisonPieArcSeries arc={<PieArc disabled tooltip={null} />} />
      }
      data={[emptyData]}
      animated={false}
      height={chartHeight}
      width={chartWidth}
      colorScheme="#eee"
      arcWidth={0.15}
    />
  </>
);
