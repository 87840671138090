import React from "react";
import cx from "classnames";
import {
  IconDanger,
  IconError,
  IconSuccess,
} from "components/common/icons/twoToneIcons";
import { IUploadError } from "models/Upload";
import { ISchema } from "models/Schema";
import ResponsiveTable from "components/common/ui/table/Table";
import { IconHeaders } from "components/upload/common/IconHeaders";
import Accordian from "components/common/ui/accordian/Accordian";
import styles from "components/upload/detail/UploadDetail.module.scss";
import { IColumn } from "components/common/ui/table/utils";

export const ColumnStatus = ({
  schema,
  columnsScanned,
  errorsByColumn,
}: {
  schema: ISchema;
  columnsScanned: string[];
  errorsByColumn: Record<string, IUploadError>;
}) => {
  const schemaColumns = Object.keys(schema);
  const data = schemaColumns.map((c) => {
    const errors = errorsByColumn?.[c] || {};
    return {
      id: c,
      status: columnReqStatus(c, columnsScanned, schema[c].required),
      ...errors,
    };
  });
  const columns = [
    {
      id: "id",
      label: "Column Name",
    },
    {
      id: "status",
      label: "Row Status",
      width: 0.55,
      cell: (c, row) => (
        <div className={cx(styles.colStatus, styles[row.status.style])}>
          {row.status.icon} {row.status.label}
        </div>
      ),
    },
    {
      id: "errors",
      label: <IconHeaders />,
      cell: (c, row) => (
        <div className={styles.errors}>
          <span>{row.warning}</span>
          <span>{row.error}</span>
          <span>{row.critical}</span>
        </div>
      ),
    },
  ] as IColumn[];

  const rowKey = (r: Record<string, any>) => r.id;

  return (
    <Accordian
      // header={<h3>Column-level results</h3>}
      header={"Column-level results"}
      isOpen
      bottomBorder
      headerClassName={styles.headerAccordian}
    >
      <ResponsiveTable data={data} columns={columns} rowKeyAccessor={rowKey} />
    </Accordian>
  );
};

const columnReqStatus = (
  id: string,
  columns: string[],
  required: boolean = false
) => {
  return columns?.includes(id)
    ? {
        label: "Available",
        icon: <IconSuccess className={styles.successIcon} />,
        style: "success",
      }
    : required
    ? {
        label: "Required",
        icon: <IconDanger className={styles.dangerIcon} />,
        style: "critical",
      }
    : {
        label: "Missing",
        icon: <IconError className={styles.errorIcon} />,
        style: "error",
      };
};
