import React from "react";
import { Auth, Hub } from "aws-amplify";
import { ForgotPassword } from "aws-amplify-react";
import { IAuthPieceProps } from "aws-amplify-react/lib/Auth/AuthPiece";
import Meta from "components/common/Meta";
import AuthLayout from "components/common/layouts/AuthLayout";
import RequireNewPasswordForm from "components/auth/forms/RequireNewPasswordForm";
import { layoutService } from "services/LayoutService";
import { objectWithProperties } from "components/auth/utils/authUtils";
import { logger } from "utils/Logger";
import { isEmpty } from "@aws-amplify/core";

const exportDefault = {};
export default exportDefault;

export class CustomRequireNewPassword extends ForgotPassword {
  resetPasswordError = null;
  boundHubAuthListener: (result: any) => void = () => {};

  constructor(props: IAuthPieceProps) {
    super(props);
    this._validAuthStates = ["requireNewPassword"];
  }

  hubAuthListener(result: any) {
    if (!result || !result.payload) {
      return;
    }
    switch (result.payload.event) {
      case "requireNewPassword":
        this.resetPasswordError = null;
        return;
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.boundHubAuthListener = this.hubAuthListener.bind(this);
    Hub.listen("auth", this.boundHubAuthListener);
  }

  componentWillUnmount() {
    Hub.remove("auth", this.boundHubAuthListener);
  }
  async handleResetSubmit() {
    try {
      const user = this.props.authData;
      const { password } = this.inputs;
      const { requiredAttributes } = user.challengeParam;
      const attrs = objectWithProperties(this.inputs, requiredAttributes);
      await Auth.completeNewPassword(user, password, attrs).then((user) => {
        logger.debug("complete new password", user);
        if (user.challengeName === "SMS_MFA") {
          this.changeState("confirmSignIn", user);
        } else if (user.challengeName === "MFA_SETUP") {
          logger.debug("TOTP setup", user.challengeParam);
          this.changeState("TOTPSetup", user);
        } else {
          Auth.verifiedContact(user).then((data) => {
            if (!isEmpty(data.verified)) {
              this.changeState("signedIn", user);
            } else {
              user = Object.assign(user, data);
              this.changeState("verifyContact", user);
            }
          });
        }
      });
      layoutService.addToast("Password successfully changed!", {
        theme: "success",
      });
    } catch (e) {
      this.error(e);
    }
  }

  showComponent(theme: any) {
    return (
      <AuthLayout variant={"letter"}>
        <Meta title={"New Password"} />
        <h1>Create new password</h1>
        <p>Please define a new password to replace your old one.</p>
        <RequireNewPasswordForm
          onSubmit={this.handleResetSubmit.bind(this)}
          values={this.inputs}
          errorMessage={this.resetPasswordError}
          changeState={this.changeState}
        />
      </AuthLayout>
    );
  }
}
