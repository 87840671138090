import React from "react";
import { PieArc, PieArcProps, PieArcSeries, PieArcSeriesProps } from "reaviz";
import { pascalCase } from "utils/format/stringUtils";
import { formatPercent } from "utils/format/numberUtils";

interface IComparisonPieArcSeries extends Partial<PieArcSeriesProps> {
  tooltipContent?: (data: any, color: any) => any;
}
export const ComparisonPieArcSeries = ({
  tooltipContent,
  arcWidth = 0.5,
  cornerRadius = 5,
  label = null,
  ...props
}: IComparisonPieArcSeries) => (
  <PieArcSeries
    {...props}
    doughnut
    label={label}
    arcWidth={arcWidth}
    cornerRadius={cornerRadius}
    arc={props.arc || <ComparisonPieArc tooltipContent={tooltipContent} />}
  />
);

interface IComparisonPieArc extends Partial<PieArcProps> {
  tooltipContent?: (data: any, color: any) => any;
}

const ComparisonPieArc = ({
  tooltipContent = defaultTooltip,
  ...props
}: IComparisonPieArc) => (
  <PieArc
    disabled={props?.data?.data?.metadata?.disabled}
    tooltip={null}
    {...props}
    key={
      props.data?.data?.metadata
        ? `${props.data?.data?.metadata.group}-${props.data?.data.key}`
        : `${props.data?.data.key}` || ""
    }
  />
);

const defaultTooltip = (data: { x: string; y: number }) => {
  const labelParts = data.x.split("::");
  const label = pascalCase(labelParts[labelParts.length - 1]);
  return (
    <div>
      {label} {formatPercent(data.y)}
    </div>
  );
};
