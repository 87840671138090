import React from "react";
import cx from "classnames";
import { ERROR_LEVELS } from "models/Schema";
import {
  IconDanger,
  IconError,
  IconSuccess,
  IconWarning,
} from "components/common/icons/twoToneIcons";
import { IUploadError } from "models/Upload";
import { formatNumber } from "utils/format/numberUtils";
import styles from "components/upload/common/ErrorDefinitions.module.scss";

const errorTypes = [
  {
    key: ERROR_LEVELS.CRITICAL,
    name: "Critical Issues",
    icon: <IconDanger className={styles.icon} />,
    description:
      "Critical issues are essential to fix. Unless these issues are fixed, your data upload cannot be accepted.",
  },

  {
    key: ERROR_LEVELS.ERROR,
    name: "Errors",
    icon: <IconError className={styles.icon} />,
    description:
      "Errors are important to fix. If these issues aren’t fixed, your data and results will likely be incorrect.",
  },
  {
    key: ERROR_LEVELS.WARNING,
    name: "Warnings",
    icon: <IconWarning className={styles.icon} />,
    description:
      "Warnings normally have a lesser impact on the final results, but may indicate an underlying problem in the data",
  },

  // { name: "Success", icon: <IconSuccess className={styles.successIcon} /> },
] as { key: keyof IUploadError; [key: string]: any }[];

const ErrorDefinitions = ({
  errors,
  hideZero = false,
}: {
  errors?: IUploadError;
  hideZero?: boolean;
}) => (
  <div className={styles.fileErrorContainer}>
    {errorTypes.map((e) => {
      if (hideZero && !errors?.[e.key]) {
        return <></>;
      }
      return (
        <div
          key={e.key}
          className={cx(
            styles.fileErrorRow,
            styles[e.key],
            errors && !errors?.[e.key] && styles.noValue
          )}
        >
          <div className={styles.valueContainer}>
            {e.icon}
            {errors && (
              <div className={cx(styles.value)}>
                {formatNumber(errors?.[e.key] || 0)}
              </div>
            )}
          </div>
          <div className={styles.labelContainer}>
            <div className={styles.label}>{e.name}</div>
            <div className={styles.description}>{e.description}</div>
          </div>
        </div>
      );
    })}
  </div>
);

export const ErrorSuccessDefinition = () => (
  <div className={cx(styles.fileErrorContainer, styles.noErrors)}>
    {" "}
    <div className={cx(styles.fileErrorRow, styles.success)}>
      <IconSuccess className={styles.icon} />
      <div className={styles.labelContainer}>
        <div className={styles.label}> Success</div>
        <div className={styles.description}>
          {" "}
          No validation issues found in file!
        </div>
      </div>
    </div>
  </div>
);

export const NoRowsScanned = () => (
  <div className={cx(styles.fileErrorContainer, styles.noRows)}>
    <div className={cx(styles.fileErrorRow, styles.danger)}>
      <IconDanger className={styles.icon} />
      <div className={styles.labelContainer}>
        <div className={styles.label}>Critical: No Data</div>
        <div className={styles.description}>
          The uploaded file appeared to have no data, please check the source
          file
        </div>
      </div>
    </div>
  </div>
);

export default ErrorDefinitions;
