import React, { useState } from "react";
import { TUser, USER_STATUS } from "models/User";
import ConditionalLogicWrapper from "components/common/ui/form-elements/ConditionalLogicWrapper";
import Button from "components/common/ui/button/Button";
import {
  IconEmail,
  IconPhone,
  IconUser,
  IconOrg,
  IconDanger,
} from "components/common/icons/twoToneIcons";
import UserDetailForm from "components/users/common/UserDetailForm";
import Container from "components/common/layouts/Container";
import PermissionsTable from "components/users/common/PermissionsTable";
import { formatPhoneNumber } from "components/common/ui/form-elements/display-elements/FormattedPhoneNumber";
import Chip from "components/common/ui/chip/Chip";
import Tooltip from "components/common/ui/tooltip/Tooltip";
import { userService } from "services/UserService";
import IconResendEmail from "components/common/icons/IconResendEmail";
import IconResetLock from "components/common/icons/IconResetLock";
import ActionButton from "components/common/ui/button/ActionButton";
import IconMfa, { IconMfaDisabled } from "components/common/icons/IconMfa.tsx";
import { PERMISSIONS } from "models/Permissions";
import { IconRestore } from "components/common/icons/IconDelete";
import Modal from "components/common/ui/modal/Modal";
import HintBox from "components/common/ui/hint-box/HintBox";
import { IconSuspendUser } from "components/common/icons/IconUser";
import styles from "components/users/common/User.module.scss";

interface Props {
  user: TUser;
  canChangeUserAttributes: boolean;
  canChangePermissions: boolean;
  onUpdateAttributes: (attributes: Partial<TUser>) => void;
  onUpdatePermission: (permissions: PERMISSIONS[]) => void;
}

const UserDetail = ({
  user,
  canChangeUserAttributes = false,
  canChangePermissions = false,
  onUpdateAttributes,
  onUpdatePermission,
}: Props) => {
  const [edit, setEdit] = useState(false);
  const [openSuspendModal, setSuspendModalOpen] = useState(false);

  const clearChanges = () => {
    setEdit(false);
  };
  const onDetailUpdate = async (att: any) => {
    try {
      await onUpdateAttributes(att);
      setEdit(false);
    } catch (e) {}
  };

  const suspendUser = async () => {
    await userService.enableUser(user, !user.active);
    setSuspendModalOpen(false);
  };

  return (
    <div className={styles.root}>
      {!user.active && (
        <Container spacing="none" className={styles.deletedUser}>
          <h4>
            <IconDanger /> Suspended User
          </h4>
        </Container>
      )}
      <Container className={styles.pageHeader}>
        <IconUser className={styles.userIcon} />
        <div className={styles.userText}>
          <h1>
            {user.firstname} {user.surname}
          </h1>
          <div className={styles.item}>
            <IconEmail className={styles.icon} />
            <div className={styles.text}>
              {user.email ? (
                <a href={`mailto:${user.email}`}>{user.email}</a>
              ) : (
                <i className={styles.noValue}>No email</i>
              )}
            </div>
          </div>
          <div className={styles.item}>
            <IconPhone className={styles.icon} />
            <div className={styles.text}>
              {user.phone ? (
                <a href={`tel:${user.phone}`}>
                  {formatPhoneNumber(user.phone)}
                </a>
              ) : (
                <i className={styles.noValue}>No phone</i>
              )}
            </div>

            <Chip
              condensed
              className={styles.mfa}
              theme={user.mfa_enabled ? "success" : "danger"}
              icon={user.mfa_enabled ? <IconMfa /> : <IconMfaDisabled />}
            >
              Two-Step Verification :{" "}
              {user.mfa_enabled ? "ENABLED" : "DISABLED"}
            </Chip>
          </div>
          <div className={styles.item}>
            <IconOrg className={styles.icon} />
            {user.orgs.map((d) => (
              <Chip condensed theme="primary" className={styles.orgChip}>
                {d.org_name}
              </Chip>
            ))}
          </div>
        </div>
        <div className={styles.actionContainer}>
          <div className={styles.actions}>
            {getStatusAction(user, canChangeUserAttributes)}
          </div>
          <div className={styles.actions}>
            {canChangeUserAttributes && (
              <>
                <Button
                  onClick={() => setEdit(!edit)}
                  disabled={edit}
                  variant="outline"
                  rounded
                >
                  Edit Details
                </Button>
                <Tooltip
                  content={
                    user.active ? "Suspend User" : "Restore Suspended User"
                  }
                  offset={8}
                >
                  <ActionButton
                    icon={user.active ? <IconSuspendUser /> : <IconRestore />}
                    theme={user.active ? "danger" : "info"}
                    variant="lightFilled"
                    rounded
                    onClick={() => setSuspendModalOpen(true)}
                  />
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </Container>
      <hr />

      {canChangeUserAttributes && (
        <ConditionalLogicWrapper value={`${edit}`} logic={() => edit}>
          <Container>
            <UserDetailForm
              user={user}
              onSubmit={onDetailUpdate}
              onCancel={clearChanges}
            />
          </Container>
          <br />
          <hr />
        </ConditionalLogicWrapper>
      )}

      <Container>
        <PermissionsTable
          user={user}
          canEdit={canChangePermissions}
          onChange={onUpdatePermission}
        />
      </Container>
      <Modal open={openSuspendModal} onClose={() => setSuspendModalOpen(false)}>
        <div className={styles.modalContent}>
          <h1>{user.active ? "Supsend" : "Restore"} User</h1>
          {user.active ? (
            <HintBox theme="danger">
              A suspended user can't log into the Data Commons - their account
              is effectively disabled.
              <br />
              This process is reversible, and a suspended user can be restored
              in the future.
            </HintBox>
          ) : (
            <HintBox theme="success">
              A restored user will be able to log into the Data Commons platform
              again, and will retain all of their old permissions
            </HintBox>
          )}
          <br />
          <br />
          Are you sure you want to {user.active ? "supsend" : "restore"}{" "}
          <b>
            {user.firstname} {user.surname}
          </b>{" "}
          ({user.email})?
        </div>
        <div className={styles.actions}>
          <Button onClick={() => setSuspendModalOpen(false)} variant="empty">
            Cancel
          </Button>
          <Button
            onClick={suspendUser}
            theme={user.active ? "danger" : "success"}
            rounded
          >
            {user.active ? "Suspend" : "Restore"} User
          </Button>
        </div>
      </Modal>
    </div>
  );
};

const getStatusAction = (user: TUser, canChangeUserAttributes: boolean) => {
  if (!user.status) {
    return "";
  }
  const content = (
    <>
      <span>Status: </span>
      {USER_STATUS[user.status]}
    </>
  );
  switch (user.status) {
    case "FORCE_CHANGE_PASSWORD":
      return (
        <>
          <Chip theme="warning" className={styles.status}>
            {content}
          </Chip>

          {canChangeUserAttributes && (
            <Tooltip content={"Resend Invite Email"} offset={8}>
              <ActionButton
                icon={<IconResendEmail />}
                theme="warning"
                variant="lightFilled"
                rounded
                onClick={() => userService.resendInvitation(user)}
              />
            </Tooltip>
          )}
        </>
      );
    case "RESET_REQUIRED":
    case "CONFIRMED":
      return (
        <>
          <Chip
            theme={user.status === "CONFIRMED" ? "info" : "danger"}
            className={styles.status}
          >
            {content}
          </Chip>
          {canChangeUserAttributes && (
            <Tooltip content={"Send Password Reset"} offset={8}>
              <ActionButton
                icon={<IconResetLock />}
                theme="info"
                variant="lightFilled"
                rounded
                onClick={() => userService.resetUserPassword(user)}
              />
            </Tooltip>
          )}
        </>
      );
    default:
      return (
        <Chip theme="info" className={styles.status}>
          {content}
        </Chip>
      );
  }
};

export default UserDetail;
