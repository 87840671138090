import React from "react";
import { Auth, Hub } from "aws-amplify";
import { ForgotPassword } from "aws-amplify-react";
import { IAuthPieceProps } from "aws-amplify-react/lib/Auth/AuthPiece";
import Meta from "components/common/Meta";
import AuthLayout from "components/common/layouts/AuthLayout";
import RequireNewPasswordForm from "components/auth/forms/RequireNewPasswordForm";
import { layoutService } from "services/LayoutService";

const exportDefault = {};
export default exportDefault;

export class CustomResetPassword extends ForgotPassword {
  forgotPasswordError = null;
  resetPasswordError = null;
  boundHubAuthListener: (result: any) => void = () => {};

  constructor(props: IAuthPieceProps) {
    super(props);
    this._validAuthStates = ["resetPassword"];
  }

  hubAuthListener(result: any) {
    if (!result || !result.payload) {
      return;
    }
    switch (result.payload.event) {
      case "forgotPassword":
        this.forgotPasswordError = null;
        return;
      case "forgotPassword_failure":
        this.forgotPasswordError =
          result.payload.data && result.payload.data.message;
        this.setState({ ...this.state });
        return;
      case "forgotPasswordSubmit_failure":
        this.resetPasswordError =
          result.payload.data && result.payload.data.message;
        this.setState({ ...this.state });
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.boundHubAuthListener = this.hubAuthListener.bind(this);
    Hub.listen("auth", this.boundHubAuthListener);
  }

  componentWillUnmount() {
    Hub.remove("auth", this.boundHubAuthListener);
  }

  async handleResetSubmit() {
    try {
      await Auth.forgotPasswordSubmit(
        this.getUsernameFromInput(),
        this.inputs.code,
        this.inputs.password
      );
      layoutService.addToast(
        "Password successfully reset! \nPlease sign in using your new details",
        {
          theme: "success",
        }
      );
      this.changeState("signIn");
      this.setState({ delivery: null });
    } catch (e) {
      this.error(e);
    }
  }

  showComponent(theme: any) {
    return (
      <AuthLayout variant={"letter"}>
        <Meta title={"Reset Password"} />
        <h2>{"Reset Password"}</h2>
        <p>
          Please enter your email address, the code you received, and your new
          password
        </p>
        <RequireNewPasswordForm
          onSubmit={this.handleResetSubmit.bind(this)}
          values={this.inputs}
          errorMessage={this.resetPasswordError}
          changeState={this.changeState}
          isResetCode
          isFromEmailLink
        />
      </AuthLayout>
    );
  }
}
