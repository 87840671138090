import React from "react";
import { useSelector } from "react-redux";
import { currentOrgNameSelector } from "store/selectors/core.selectors";
import styles from "./CurrentAppOrg.module.scss";

const CurrentAppOrg = () => {
  const org_name = useSelector(currentOrgNameSelector);
  return <div className={styles.root}>{org_name}</div>;
};

export default CurrentAppOrg;
