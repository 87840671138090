import React, { useState } from "react";
import Button from "components/common/ui/button/Button";
import Select, { ISelecInputConfig } from "components/common/ui/select/Select";
import {
  DEFAULT_QUARTER,
  DEFAULT_YEAR,
  IFile,
  IUploadConfig,
  TUploadPeriod,
  TUploadYear,
  UPLOAD_PERIOD_OPTIONS,
  YEAR_OPTIONS,
} from "models/Upload";
import FileUpload, {
  FILE_TYPES,
} from "components/common/ui/file-upload/FileUpload";
import { ISchemas } from "models/Schema";
import { IDimensions } from "models/Dimension";
import { getLatestSchemaVersion } from "components/schema/utils/validationUtils";

interface Props {
  submitUpload: (file: IFile, config: IUploadConfig) => {};
  schemas: ISchemas;
  dimensions: IDimensions;
}

const selectConfig: ISelecInputConfig = { selectedOptionFormat: "chip" };

export const CreateUpload = ({ submitUpload, schemas, dimensions }: Props) => {
  const [files, setFiles] = useState([] as IFile[]);
  const [submitting, setSubmitting] = useState(false);

  // const [table, setTable] = useState([] as string[][]);  // const [table, setTable] = useState([] as string[][]);
  const [config, setConfig] = useState<IUploadConfig>({
    year: dimensions.year.buckets
      ? (dimensions.year.buckets as TUploadYear)
      : DEFAULT_YEAR,
    period: dimensions.period.buckets
      ? (dimensions.period.buckets as TUploadPeriod)
      : DEFAULT_QUARTER,
    schema_name: dimensions.schema_name.buckets as string,
    schema_version: "01",
  });
  const setValue = (name: string, value: any) => {
    setConfig((config) => ({
      ...config,
      [name]: value,
    }));
  };
  const addFile = (f: IFile[]) => {
    setFiles(f);
  };
  const onSubmit = async () => {
    setSubmitting(true);
    const version = getLatestSchemaVersion(schemas[config.schema_name]);
    await submitUpload(files[0], { ...config, schema_version: version });
    setSubmitting(false);
  };
  // useEffect(() => {
  //   const getFileChunk = async () => {
  //     const chunk = (await parseFirstXRows(files[0], 2)) as string[][];
  //     setTable(chunk);
  //   };
  //   if (files[0]) {
  //     getFileChunk();
  //   }
  // }, [files]);
  return (
    <>
      <Select
        name="schema_name"
        value={config.schema_name}
        onSelect={setValue}
        label="Select Schema"
        options={dimensions.schema_name.options}
        config={selectConfig}
      />
      <div>
        <Select
          name="period"
          value={config.period}
          onSelect={setValue}
          label="Select Period"
          options={UPLOAD_PERIOD_OPTIONS}
          config={selectConfig}
        />
        <Select
          name="year"
          value={config.year}
          onSelect={setValue}
          label="Select Year"
          options={YEAR_OPTIONS}
          config={selectConfig}
        />
      </div>

      <FileUpload
        onChange={addFile}
        multiple={false}
        mimeType={FILE_TYPES.csv}
      />
      {/*{table.length > 0 && (*/}
      {/*  <table>*/}
      {/*    {table.map((r, i) => (*/}
      {/*      <tr>{r.map((c) => (i === 0 ? <th>{c}</th> : <td>{c}</td>))}</tr>*/}
      {/*    ))}*/}
      {/*  </table>*/}
      {/*)}*/}
      <Button
        onClick={onSubmit}
        disabled={!files[0]}
        theme="primary"
        submitting={submitting}
      >
        Submit Upload
      </Button>
    </>
  );
};
