import { appGetState, appNextState, appUpdateState } from "store";
import {
  TUser,
  TUserDictionary,
  create,
  IUserListDimensions,
} from "models/User";
import {
  enableUser,
  fetchMany,
  fetchOneById,
  resendInvite,
  resetPassword,
  save,
} from "api/UserApi";
import { layoutService } from "services/LayoutService";
import { keyBy } from "utils/common";
import { IDimension, ISetBucket, TBucket } from "models/Dimension";
import { updateBuckets } from "utils/filterUtils";
import axios from "axios";

const extractFiltersFromDimensions = (dim: IUserListDimensions) => {
  return Object.values(dim).reduce(
    (o, d) => (d.buckets ? { ...o, [d.key]: d.buckets } : o),
    {}
  ) as Record<string, TBucket>;
};

const comment = (m: string) => `UserService::${m}`;

class UserService {
  private appGetState = appGetState;
  private appNextState = appNextState;
  private appUpdateState = appUpdateState;

  /**
   */
  public loadList = async () => {
    window.logger.group(comment("loadList"));
    this.setIsLoading(true);
    const orgId = this.getCurrentOrg();
    const dim = this.getCurrentDimensions();
    try {
      const { items, total } = await fetchMany(
        extractFiltersFromDimensions(dim),
        orgId
      );
      await this.appUpdateState((s) => {
        s.users.list.items = items;
        s.users.list.total = total;
      }, comment("loadList"));
    } catch (e) {}
    this.setIsLoading(false);
    window.logger.groupEnd();
  };

  /**
   */
  public loadManyToGlobalDictionary = async (
    userIds: string[],
    orgId: string
  ) => {
    const users = await Promise.all(
      userIds.map((userId) => fetchOneById(userId, orgId))
    );
    this.updateDictionary(keyBy(users, "id"));
  };

  /**
   */
  public clearList = () => {
    this.appUpdateState((s) => {
      s.users.list.items = [];
      s.users.list.total = 0;
    }, comment("clearList"));
  };

  /**
   */
  public clearDictionary = () => {
    this.appUpdateState((s) => {
      s.users.globalDictionary = {};
    }, comment("clearDictionary"));
  };

  public clearDetail = () => {
    this.setUserDetails(create());
  };

  /**
   */
  public loadDetail = async (id: string, orgId: string, override?: boolean) => {
    window.logger.group(comment("loadDetail"));
    this.setIsLoading(true);
    if (this.appGetState().users.detail.user.id !== id || override) {
      try {
        this.setUserDetails(create());
        const user = await fetchOneById(id, orgId);
        this.setUserDetails(user);
      } catch (e) {}
      this.setIsLoading(false);
    } else {
      this.setIsLoading(false);
    }
    window.logger.groupEnd();
  };

  /**
   */
  public saveDetail = async (
    user: Partial<TUser>,
    oldUser: TUser,
    orgId: string
  ): Promise<TUser> => {
    try {
      const nextUser = await save(user, oldUser, orgId);

      layoutService.addToast(
        user.id
          ? "User was successfully updated!"
          : "User was successfully created!",
        {
          theme: "success",
        }
      );
      this.clearList();
      this.clearDictionary();
      this.setUserDetails(nextUser);
      return nextUser;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 500) {
        layoutService.addToast(`Email ${user.email} is already taken`, {
          theme: "warning",
          delay: 6000,
        });
      } else {
        layoutService.error(e, "Error: Unable to save User Details");
      }

      return oldUser;
    }
  };

  public resendInvitation = async (user: Partial<TUser>) => {
    const orgId = this.getCurrentOrg();
    try {
      const success = await resendInvite(user, orgId);
      if (success) {
        layoutService.addToast(`Resent Activation email to ${user.email}`, {
          theme: "success",
        });
      } else {
        layoutService.error(
          {},
          `There was an issue resending an invitation to ${user.email}`,
          "warning"
        );
      }
    } catch (e) {
      layoutService.error(
        e,
        `There was an error resending an invitation to ${user.email}`
      );
    }
  };

  public resetUserPassword = async (user: TUser) => {
    const orgId = this.getCurrentOrg();
    try {
      const newUser = await resetPassword(user, orgId);
      layoutService.addToast(`Password Reset details sent to ${user.email}`, {
        theme: "success",
      });
      this.clearList();
      this.clearDictionary();
      this.setUserDetails(newUser);
    } catch (e) {
      layoutService.error(e, `Unable to reset password for ${user.email}`);
    }
  };

  public enableUser = async (user: TUser, active: boolean) => {
    const orgId = this.getCurrentOrg();
    try {
      const newUser = await enableUser(user, active, orgId);
      layoutService.addToast(
        `User successfully ${active ? "restored" : "suspended"}`,
        {
          theme: "success",
        }
      );
      this.clearList();
      this.setUserDetails(newUser);
    } catch (e) {
      layoutService.error(
        e,
        `Unable to ${active ? "restore" : "suspend"} user`
      );
    }
  };

  public setDimensions = async ({ key, bucket }: ISetBucket) => {
    const currentDimensions = this.getCurrentDimensions();
    const dimension = currentDimensions[key];
    if (dimension) {
      window.logger.group(comment("setFilter"));
      await this.updateDimensionFilter({ bucket, dimension });
      await this.loadList();
      window.logger.groupEnd();
    }
    return this.getCurrentDimensions();
  };

  public setManyDimensions = async (
    filters: (ISetBucket | undefined)[],
    refreshData?: boolean
  ) => {
    const currentDimensions = this.getCurrentDimensions();
    if (filters.length > 0) {
      window.logger.group(comment("setManyDimensions"));
      this.setIsLoading(true);
      for (let i = 0; i < filters.length; i++) {
        const f = filters[i];
        if (f) {
          const dimension = currentDimensions[f.key];
          await this.updateDimensionFilter({ bucket: f.bucket, dimension });
        }
      }
      if (refreshData) {
        await this.loadList();
      }
      window.logger.groupEnd();
    }

    return this.getCurrentDimensions();
  };

  /**
   */
  private setIsLoading = (isLoading: boolean): void => {
    this.appUpdateState(
      (s) => (s.users.isLoading = isLoading),
      comment("setIsLoading")
    );
  };

  /**
   */
  private updateDictionary = (items: TUserDictionary): void => {
    this.appUpdateState(
      (s) =>
        (s.users.globalDictionary = { ...s.users.globalDictionary, ...items }),
      comment("updateDictionary")
    );
  };

  /**
   */
  private setUserDetails = (user: TUser): void => {
    this.appUpdateState(
      (s) => (s.users.detail.user = user),
      comment("setUserDetails")
    );
  };

  private updateDimensionFilter = async ({
    bucket,
    dimension,
  }: {
    bucket?: TBucket | null;
    dimension?: IDimension;
  }) => {
    if (dimension) {
      const newBuckets = updateBuckets(bucket, dimension);
      this.appUpdateState(
        (s) =>
          (s.users.list.dimensions[dimension.key] = {
            ...dimension,
            buckets: newBuckets,
          }),
        comment("updateDimensionFilter")
      );
      return newBuckets;
    }
    return false;
  };

  private getCurrentDimensions = () => {
    const {
      users: {
        list: { dimensions },
      },
    } = this.appGetState();
    return dimensions as IUserListDimensions;
  };

  private getCurrentOrg = () => {
    const {
      core: { org },
    } = this.appGetState();
    return org.org_id;
  };
}

export const getName = (user: TUser) =>
  `${
    user.firstname && user.surname
      ? user.firstname + " " + user.surname
      : user.email
  }`;

// export const confirmDeleteUser = (customUser?: TUser) => {
//   const user = customUser || appGetState().users.detail.user

//   layoutService.confirm(
//     'Delete User',
//     `Are you sure you wish to delete ${getName(user)}? They will no longer be able to access this system.`,
//     'Delete User',
//     () => userService.suspend(true, customUser)
//   )
// }

const userService = new UserService();
export { userService };
