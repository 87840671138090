import React, { useEffect, useState } from "react";
import { TUser } from "models/User";
import styles from "components/users/common/User.module.scss";
import {
  getDefaultPermissions,
  PERMISSIONS,
  permissionsOptions,
  permissionsTable,
} from "models/Permissions";
import Button from "components/common/ui/button/Button";
import ConditionalLogicWrapper from "components/common/ui/form-elements/ConditionalLogicWrapper";
import { PermissionGroup } from "components/users/common/PermissionGroup";

interface Props {
  user: TUser;
  canEdit: boolean;
  onChange: (permissions: PERMISSIONS[]) => void;
}

const PermissionsTable = ({ user, onChange, canEdit }: Props) => {
  const [editting, setEdit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [roles, setRoles] = useState(getDefaultPermissions(user?.roles));

  const cancelEdit = () => {
    setEdit(false);
    setRoles(getDefaultPermissions(user?.roles));
  };

  const updateRole = (key: string, value: boolean) => {
    setRoles({ ...roles, [key]: value });
  };
  const bulkUpdateRole = (newRoles: Record<string, boolean>) => {
    setRoles({ ...roles, ...newRoles });
  };

  const handleSave = async () => {
    setSubmitting(true);
    const newRoles = permissionsOptions.reduce((arr, p) => {
      return roles[p.value] ? [...arr, p.value] : arr;
    }, [] as PERMISSIONS[]);
    await onChange(newRoles);
    setSubmitting(false);
    setEdit(false);
  };

  useEffect(() => {
    setRoles(getDefaultPermissions(user?.roles));
  }, [user]);

  return (
    <>
      <div className={styles.header}>
        <h2>Permissions</h2>
        {canEdit && (
          <Button
            onClick={() => setEdit(!editting)}
            disabled={editting}
            variant="outline"
            rounded
          >
            Edit Permissions
          </Button>
        )}
      </div>
      <div className={styles.permissionsTable}>
        {permissionsTable.map((section) => (
          <PermissionGroup
            key={section.label}
            section={section}
            editting={editting}
            roles={roles}
            onChange={updateRole}
            onBulkChange={bulkUpdateRole}
          />
        ))}
        <ConditionalLogicWrapper value={`${editting}`} logic={() => editting}>
          <div className={styles.actions}>
            <Button variant="empty" onClick={() => cancelEdit()}>
              Cancel
            </Button>
            <Button
              theme="success"
              rounded
              type="submit"
              submitting={submitting}
              onClick={handleSave}
            >
              Save Permissions
            </Button>
          </div>
        </ConditionalLogicWrapper>
      </div>
    </>
  );
};

export default PermissionsTable;
