import React from "react";
import { useSelector } from "react-redux";
import { UPLOAD_DETAIL } from "constants/routes";
import Table from "components/common/ui/table/Table";
import { IColumn } from "components/common/ui/table/utils";
import { SkeletonText } from "components/common/ui/skeleton/Skeleton";
import { formatUnixTime } from "utils/format/dateUtils";
import {
  IconDanger,
  IconError,
  IconWarning,
  IconSuccess,
} from "components/common/icons/twoToneIcons";
import { IconHeaders } from "components/upload/common/IconHeaders";
import ListFilters from "components/common/ui/filter/ListFilters";
import Container from "components/common/layouts/Container";
import { IconProgressQuestion } from "components/common/icons/IconProgress";
import Accordian from "components/common/ui/accordian/Accordian";
import { formatNumber } from "utils/format/numberUtils";
import { coreService } from "services/CoreService";
import { TSetActiveBucket, TSetManyBucketsActive } from "models/Dimension";
import {
  uploadDimSelector,
  uploadIsLoadingSelector,
  uploadListSelector,
  uploadSelectedSelector,
} from "store/selectors/uploads.selectors";
import { currentSchemaSelector } from "store/selectors/schema.selectors";
import styles from "components/upload/list/UploadsList.module.scss";
import { UPLOAD_FILTER_KEYS } from "models/Upload";

interface Props {
  children?: React.ReactNode;
  setActive: TSetActiveBucket;
  setManyActive: TSetManyBucketsActive;
  isAppLoaded: boolean;
}

export const UploadsList = ({
  setActive,
  setManyActive,
  isAppLoaded,
}: Props) => {
  const isLoading = useSelector(uploadIsLoadingSelector);
  const [recent, ...items] = useSelector(uploadListSelector);
  const allSelected = useSelector(uploadSelectedSelector);
  const dimensions = useSelector(uploadDimSelector);
  const schema_name = useSelector(currentSchemaSelector);
  const currentSchema = schema_name.buckets;

  const updating = isLoading || !isAppLoaded;

  const selected =
    currentSchema && typeof currentSchema === "string"
      ? allSelected[currentSchema]
      : undefined;
  const data = items.filter((d) => d.upload_id !== selected?.upload_id);
  const columns = [
    {
      id: "status",
      label: " ",
      type: "icon",
      style: { fontSize: "1.8rem" },
      cell: (c, row) => getStatus({ ...row.errors_by_level }, row.rows_scanned),
    },
    {
      id: "original_filename",
      width: 1.5,
      label: "Filename",
      cell: (c) => <b>{c}</b>,
    },
    {
      id: "created",
      label: "Uploaded Time",
      cell: (c) => formatUnixTime(+c, "do MMM yyyy 'at' p"),
    },
    {
      id: "uploaded_by_email",
      label: "Uploaded By",
    },

    {
      id: "rows_scanned",
      label: "Rows",
      width: 0.4,
      cell: (c) => formatNumber(c),
    },
    {
      id: "errors",
      label: <IconHeaders />,
      cell: (c, row) => (
        <div className={styles.errors}>
          <div>{formatNumber(row.errors_by_level?.warning)}</div>
          <div>{formatNumber(row.errors_by_level?.error)}</div>
          <div>{formatNumber(row.errors_by_level?.critical)}</div>
        </div>
      ),
      loadingCell: () => (
        <div className={styles.loadingErrors}>
          <SkeletonText className={styles.skeleton} />
          <SkeletonText className={styles.skeleton} />
          <SkeletonText className={styles.skeleton} />
        </div>
      ),
    },
  ] as IColumn[];
  const rowKey = (r: Record<string, any>) => r.upload_id;

  return (
    <>
      <Container spacing={"none"}>
        <ListFilters
          filters={UPLOAD_FILTER_KEYS}
          dimensions={{ ...dimensions, schema_name }}
          setActive={setActive}
          setManyActive={setManyActive}
          isLoading={!isAppLoaded}
          isUpdating={isLoading}
        />
        <h3 className={styles.selected}>Final Upload</h3>
      </Container>
      <Table
        data={selected && !updating ? [selected] : []}
        columns={columns}
        rowKeyAccessor={rowKey}
        getRowProps={(r) => ({
          to: coreService.getRoute(UPLOAD_DETAIL, { uploadId: r.upload_id }),
        })}
        loading={updating}
        placeholder={{ noDataLabel: "No Upload Selected" }}
      />

      <Accordian isOpen={recent && recent?.upload_id !== selected?.upload_id}>
        <Container>
          <h3 className={styles.recent}>Most Recent Upload</h3>
        </Container>
        <Table
          data={
            recent && recent?.upload_id !== selected?.upload_id ? [recent] : []
          }
          columns={columns}
          rowKeyAccessor={rowKey}
          getRowProps={(r) => ({
            to: coreService.getRoute(UPLOAD_DETAIL, { uploadId: r.upload_id }),
          })}
          loading={updating}
        />
      </Accordian>

      <Container>
        <h3 className={styles.previous}>Previous Uploads</h3>
      </Container>

      <Table
        data={data}
        columns={columns}
        rowKeyAccessor={rowKey}
        getRowProps={(r) => ({
          to: coreService.getRoute(UPLOAD_DETAIL, { uploadId: r.upload_id }),
        })}
        loading={updating}
        // loading={true}
        placeholder={{ noDataLabel: "No Previous Uploads", loadingRows: 2 }}
      />
    </>
  );
};

const getStatus = (
  {
    warning = 0,
    error = 0,
    critical = 0,
  }: {
    warning: number;
    error: number;
    critical: number;
  },
  row_scanned: number
) => {
  if (row_scanned === 0) {
    return <IconDanger className={styles.dangerIcon} />;
  }
  if (!row_scanned) {
    return <IconProgressQuestion className={styles.disabledIcon} />;
  }
  switch (true) {
    case critical > 0:
      return <IconDanger className={styles.dangerIcon} />;
    case error > 0:
      return <IconError className={styles.errorIcon} />;
    case warning > 0:
      return <IconWarning className={styles.warnIcon} />;
    default:
      return <IconSuccess className={styles.successIcon} />;
  }
};
