import React from "react";
import Meta from "components/common/Meta";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { AppContentContainer } from "components/common/layouts/appLayout/AppContentContainer";
import UserDetail from "components/users/admin/detail/UserDetail";
import { canEditUsers } from "utils/authUtils";
import { PERMISSIONS } from "models/Permissions";
import { create, ICognitoUserAttributes } from "models/User";
import { userService } from "services/UserService";
import { ADMIN_USERS } from "constants/routes";
import Loader from "components/common/ui/loader/Loader";
import { coreService } from "services/CoreService";
import { permissionsSelector } from "store/selectors/auth.selectors";
import { useQuery } from "react-query";
import { fetchOneById } from "api/UserApi";

const ViewUserDetail = () => {
  const { id, orgId } = useParams<{ id: string; orgId: string }>();

  const { isLoading, data: user } = useQuery(
    ["user.details", id, orgId],
    async () => {
      const d = await fetchOneById(id, orgId);
      return create(d);
    }
  );
  const permissions = useSelector(permissionsSelector);
  // const user = useSelector(userDetailSelector);
  // const isLoading = useSelector(isLoadingSelector);
  // if (!isLoading && user.id !== id) {
  //   userService.loadDetail(id, orgId);
  // }
  // useOnMount(() => {
  //   userService.loadDetail(id, orgId);
  // });

  const onUpdateAttributes = (attributes: ICognitoUserAttributes) => {
    return (
      user &&
      userService.saveDetail({ id: user.id, ...attributes }, user, orgId)
    );
  };
  const onUpdatePermission = (permissions: PERMISSIONS[]) => {
    user &&
      userService.saveDetail({ id: user.id, roles: permissions }, user, orgId);
  };

  return (
    <AppContentContainer
      spacing="none"
      backButton={{
        to: coreService.getRoute(ADMIN_USERS, { orgId }),
        label: "Back to Users",
      }}
    >
      <Meta
        title={`${user ? `${user.firstname} ${user.surname}` : "User"} details`}
      />
      <br />
      <br />
      {isLoading || !user ? (
        <Loader />
      ) : (
        <UserDetail
          user={user}
          canChangeUserAttributes={canEditUsers(permissions)}
          canChangePermissions={canEditUsers(permissions)}
          onUpdateAttributes={onUpdateAttributes}
          onUpdatePermission={onUpdatePermission}
        />
      )}
    </AppContentContainer>
  );
};

export default ViewUserDetail;
