import React, { useEffect } from "react";
import {
  Authenticator,
  Greetings,
  SignIn,
  ForgotPassword,
  RequireNewPassword,
  Loading,
  VerifyContact,
  ConfirmSignIn,
} from "aws-amplify-react";
import LighthouseAmplifyTheme from "config/amplify-theme";
import { CustomSignIn } from "components/auth/SignIn";
import { CustomForgotPassword } from "components/auth/ForgotPassword";
import { CustomRequireNewPassword } from "components/auth/RequireNewPassword";
import AuthUserWrapper from "components/auth/AuthUserWrapper";
import { CustomLoadingState } from "components/auth/Loading";
import { CustomVerifyContact } from "components/auth/VerifyContact";
import { CustomConfirmSignIn } from "components/auth/ConfirmSignIn";
import { useHistory } from "react-router";
import { CustomResetPassword } from "components/auth/ResetPasswordFromEmail";

const RESET_PASSWORD_HASHBANG = "reset-password";

interface RootProps {
  children: React.ReactNode;
  authState?: string;
}
const SignedInRoutes = ({ authState, children }: RootProps) => {
  return authState === "signedIn" ? <>{children}</> : null;
};

interface Props {
  children: React.ReactNode;
}

const AuthWrapper = (props: Props) => {
  const history = useHistory();

  const authProps =
    history.location.hash === `#${RESET_PASSWORD_HASHBANG}`
      ? { authState: "resetPassword" }
      : {};

  useEffect(() => {
    //Strip hashbang from URL after loaded
    if (history.location.hash === `#${RESET_PASSWORD_HASHBANG}`) {
      history.replace({
        pathname: history.location.pathname,
        search: history.location.search,
      });
    }
  }, [history]);
  return (
    <Authenticator
      hide={[
        SignIn,
        ForgotPassword,
        RequireNewPassword,
        Greetings,
        Loading,
        VerifyContact,
        ConfirmSignIn,
      ]}
      theme={LighthouseAmplifyTheme}
      {...authProps}
    >
      <CustomSignIn />
      <CustomForgotPassword />
      <CustomRequireNewPassword />
      <CustomResetPassword />
      <CustomVerifyContact />
      <CustomLoadingState />
      <CustomConfirmSignIn />
      <SignedInRoutes>
        <AuthUserWrapper>{props.children}</AuthUserWrapper>
      </SignedInRoutes>
    </Authenticator>
  );
};

export default AuthWrapper;
