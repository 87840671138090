import React from "react";
import { useSelector } from "react-redux";
import { verifyPermisisons } from "utils/authUtils";
import { PERMISSIONS } from "models/Permissions";
import { CREATE_UPLOAD } from "constants/routes";
import Button from "components/common/ui/button/Button";
import { coreService } from "services/CoreService";
import { permissionsSelector } from "store/selectors/auth.selectors";

interface Props {
  orgId: string;
  children?: React.ReactNode;
}

export const CreateUploadButton = ({ orgId, children }: Props) => {
  const permissions = useSelector(permissionsSelector);
  return verifyPermisisons(permissions, PERMISSIONS.CREATE_UPLOADS) ? (
    <Button
      rounded
      theme="primary"
      to={coreService.getRoute(CREATE_UPLOAD, { orgId })}
    >
      {children || "Create New Upload"}
    </Button>
  ) : (
    <></>
  );
};
