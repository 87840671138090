import React from "react";
import { DebounceInput } from "react-debounce-input";
import TextInput, {
  ITextInput,
} from "components/common/ui/form-elements/text-input/TextField";

interface Props extends ITextInput {
  debounceTimeout?: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => any;
}

const DebouncedTextInput = (props: Props) => (
  <DebounceInput {...props} element={TextInput} />
);

export default DebouncedTextInput;
