import React from "react";
import cx from "classnames";
import styles from "./Loader.module.scss";

interface Props {
  className?: string;
  variant?: "default" | "alt";
}
const Loader = ({ className, variant = "default" }: Props) => (
  <div className={cx(styles.root, styles[variant], className)}>
    <div className={styles.dot} />
    <div className={styles.dot2} />
  </div>
);

export default Loader;
