import { TOrg } from "models/Org";
import { PERMISSIONS, stripReadOnlyPermissions } from "models/Permissions";
import { DIMENSION_MATCH, IDimension, IDimensions } from "models/Dimension";

const initialData = {
  id: "",
  email: "",
  phone: "",
  firstname: "",
  surname: "",
  roles: [] as PERMISSIONS[],
  org_ids: [] as string[],
  status: "" as TUserStatus | undefined,
  mfa_enabled: false as boolean,
  orgs: [] as TOrg[],
  active: false as boolean,
  // avatar: "",
  // created: 0,
  // updated: 0,
  // suspended: false,
};

export type TUser = typeof initialData;

export enum USER_STATUS {
  CONFIRMED = "Active",
  FORCE_CHANGE_PASSWORD = "Activation required",
  UNCONFIRMED = "Awaiting confirmation",
  RESET_REQUIRED = "Password Reset required",
  ARCHIVED = "Archived",
  COMPROMISED = "Compromised",
  UNKNOWN = "Unknown",
}

export enum COGNITO_MFA_STATUS {
  MFA = "SMS",
  NONE = "NOMFA",
}

export const getCognitoMfaStatus = (mfa: boolean) => {
  return mfa ? COGNITO_MFA_STATUS.MFA : COGNITO_MFA_STATUS.NONE;
};

export type TUserStatus = keyof typeof USER_STATUS;

export interface ICognitoUser {
  Attributes?: { Name: string; Value: string }[];
  UserAttributes?: { Name: string; Value: string }[];
  Enabled: boolean;
  UserStatus: TUserStatus;
  Username: string;
  PreferredMfaSetting: string | null;
  permissions?: PERMISSIONS[];
  orgs?: TOrg[];
}

interface IServerAttribute {
  Name: string;
  Value: string;
}

export interface ICognitoUserAttributes {
  sub?: string;
  given_name?: string;
  family_name?: string;
  email?: string;
  phone_number?: string;
  "custom:org_ids"?: string[];
  email_verified?: string;
  phone_number_verified?: string;
}

export type TUserDictionary = { [key: string]: TUser };

export const create = (data: Partial<TUser> = {}): TUser => {
  //strip out undefined attributes to they can be replaced with defaults from initialData
  const cleanedData = { ...data };
  let k: keyof typeof cleanedData;
  for (k in cleanedData) {
    if (cleanedData[k] === undefined) {
      delete cleanedData[k];
    }
  }

  return {
    ...initialData,
    ...cleanedData,
  };
};

const getOrgs = (orgs: string) => JSON.parse(orgs) as string[];

export const map = (serverUser: ICognitoUser) => {
  const {
    UserStatus,
    UserAttributes,
    Attributes,
    PreferredMfaSetting,
    Enabled,
    permissions,
    orgs,
  } = serverUser;
  const attArray = UserAttributes || Attributes || [];
  const attributes = attArray.reduce(
    (obj: Record<string, any>, a: IServerAttribute) => ({
      ...obj,
      [a.Name]: a.Value,
    }),
    {}
  );
  return create({
    id: attributes.sub,
    email: attributes.email,
    phone: attributes.phone_number,
    firstname: attributes.given_name,
    surname: attributes.family_name,
    status: UserStatus,
    active: Enabled,
    roles: permissions,
    org_ids: getOrgs(attributes["custom:org_ids"]),
    mfa_enabled: !!PreferredMfaSetting,
    orgs,
  });
};

export const toServerObject = (
  {
    email,
    firstname,
    surname,
    phone,
    roles,
    org_ids,
    mfa_enabled,
    ...rest
  }: Partial<TUser>,
  emailKey = "username"
) => ({
  [emailKey]: email,
  given_name: firstname,
  family_name: surname,
  phone_number: phone,
  permissions: stripReadOnlyPermissions(roles),
  "custom:org_ids": org_ids,
  enable_mfa: !!mfa_enabled,
  ...rest,
});
export const toCognitoAttributes = ({
  email,
  firstname,
  surname,
  phone,
}: Partial<TUser>) => ({
  email,
  given_name: firstname,
  family_name: surname,
  phone_number: phone,
});

export interface IUserListDimensions extends IDimensions {}

export const USER_LIST_FILTER_KEYS = ["email"];

// const STATUS_OPTIONS = [
//   { key: null, label: "Select All" },
//   ...Object.keys(USER_STATUS).map((k) => ({
//     key: k,
//     // @ts-ignore No clue why TS thinks the keys of USER_STATUS can't be used as the index for USER_STATUS...
//     label: USER_STATUS[k],
//   })),
// ];

const UserListDimArray = [
  {
    key: "email",
    label: "Search by Email",
    match: DIMENSION_MATCH.FUZZY,
    buckets: null,
    options: [],
  },
  // {
  //   key: "user_status",
  //   label: "User Status",
  //   match: DIMENSION_MATCH.EXACT,
  //   buckets: null,
  //   options: STATUS_OPTIONS,
  // },
] as IDimension[];

export const userListDimensions = UserListDimArray.reduce(
  (s, d) => ({ ...s, [d.key]: d }),
  {}
) as IUserListDimensions;
