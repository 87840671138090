import React from "react";
import { useHover, useLayer, Arrow } from "react-laag";
import { motion, AnimatePresence } from "framer-motion";
import ResizeObserver from "resize-observer-polyfill";
import cx from "classnames";
import styles from "./Tooltip.module.scss";

interface Props {
  /** The content to be displayed within the tooltip */
  content: React.ReactNode;
  /** The direction of the tooltip relative to the element (will move if there isn't enough space) */
  direction?:
    | "top-center"
    | "top-start"
    | "top-end"
    | "left-start"
    | "left-center"
    | "left-end"
    | "right-start"
    | "right-center"
    | "right-end"
    | "bottom-start"
    | "bottom-center"
    | "bottom-end"
    | "center";

  /** Should the arrow show */
  arrow?: boolean;

  /** How much should the tooltip be offset from the element */
  offset?: number;

  /** Override hover trigger and force open */
  // open?: boolean;

  /** Framer motion animation object */
  animation?: {
    initial: object;
    animate: object;
    exit: object;
    transition: object;
  };

  /** Override default delays on hover enter and leave */
  delay?: {
    enter?: number;
    leave?: number;
  };

  className?: string;
  children: React.ReactElement;
}

export const Tooltip = React.memo(
  ({
    children,
    content,
    className,
    direction = "top-center",
    arrow = true,
    offset = 4,
    animation,
    delay,
  }: Props) => {
    const [isOpen, hoverProps] = useHover({
      delayEnter: delay?.enter || 100,
      delayLeave: delay?.leave || 200,
    });
    const { layerProps, triggerProps, arrowProps, renderLayer, layerSide } =
      useLayer({
        isOpen,
        triggerOffset: offset,
        containerOffset: offset,
        placement: direction,
        ResizeObserver,
      });

    const trigger = isReactText(children) ? (
      <span {...triggerProps} {...hoverProps}>
        {children}
      </span>
    ) : (
      React.cloneElement(children, {
        ...triggerProps,
        ...hoverProps,
      })
    );

    return (
      <>
        {trigger}
        {renderLayer(
          <TooltipWrapper
            isOpen={isOpen}
            className={className}
            animation={animation}
            layerSide={layerSide}
            layerProps={layerProps}
            arrowProps={arrowProps}
            arrow={arrow}
          >
            {content}
          </TooltipWrapper>
        )}
      </>
    );
  }
);

interface WrapperProps {
  isOpen: boolean;
  layerProps: any;
  layerSide: "top" | "left" | "right" | "bottom" | "center" | undefined;
  arrowProps: any;
  children: React.ReactNode;
  className?: string;
  arrow?: boolean;
  animation?: {
    initial: object;
    animate: object;
    exit: object;
    transition: object;
  };
}

const TooltipWrapper = ({
  isOpen,
  layerProps,
  layerSide,
  arrowProps,
  children,
  className,
  animation,
  arrow,
}: WrapperProps) => {
  const animateOptions = animation || {
    initial: {
      opacity: 0,
      scale: 0.8,
      y: layerSide === "top" ? -8 : 8,
    },
    animate: { opacity: 1, scale: 1, y: 0 },
    exit: {
      opacity: 0,
      scale: 0.8,
      y: layerSide === "top" ? -8 : 8,
    },
    transition: {
      type: "spring",
      damping: 30,
      stiffness: 500,
    },
  };
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={cx(className, styles.tooltip)}
          {...animateOptions}
          {...layerProps}
        >
          <div>{children}</div>

          {arrow && <Arrow layerSide={layerSide} {...arrowProps} />}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

function isReactText(children: any) {
  return ["string", "number"].includes(typeof children);
}
export default Tooltip;
