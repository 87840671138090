import React, { useCallback, useRef } from "react";
import styles from "./FileUpload.module.scss";
import { useDropzone } from "react-dropzone";
import { IconFile } from "components/common/icons/IconFile";
import IconClose from "components/common/icons/IconClose";
import { IFile } from "models/Upload";

interface Props {
  disabled?: boolean;
  maxSize?: number;
  multiple?: boolean;
  mimeType?: string;
  maxFiles?: number;
  onChange: (files: IFile[]) => void;
}

export const FILE_TYPES = {
  csv: "text/csv, .csv, application/vnd.ms-excel",
};

const FileUpload = ({ onChange, multiple = true, mimeType }: Props) => {
  const filesRef = useRef({
    prev: [] as any[],
    current: [] as any[],
  });
  const onDrop = useCallback(
    (files) => {
      const ref = filesRef.current;
      ref.prev = ref.current;
      const allFiles = multiple ? [...ref.current, ...files] : files;
      ref.current = allFiles;
      onChange(allFiles);
    },
    [onChange, multiple]
  );

  const removeFile = (index: number) => {
    const ref = filesRef.current;
    ref.prev = ref.current;
    const newFiles = [...ref.current];
    newFiles.splice(index, 1);
    ref.current = newFiles;
    onChange(newFiles);
  };
  const dropProps = mimeType ? { accept: mimeType } : {};

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    ...dropProps,
  });

  return (
    <>
      <div {...getRootProps({ className: styles.root })}>
        <input {...getInputProps()} className={styles.input} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag files here, or click to select files from your computer</p>
        )}
      </div>
      <div className={styles.fileList}>
        {filesRef.current.current.map((f: any, i) => (
          <button
            key={f.path}
            className={styles.fileButton}
            onClick={() => removeFile(i)}
            aria-label={`remove ${f.path}`}
          >
            <IconFile /> <span>{f.path}</span> <IconClose />
          </button>
        ))}
      </div>
    </>
  );
};

export default FileUpload;
