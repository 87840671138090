import React from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { layoutService } from "services/LayoutService";
import BrandName from "components/common/ui/logo/BrandName";
import NavItem from "components/common/layouts/appLayout/navBar/NavItem";
import { AnimatePresence, motion } from "framer-motion";
import NavBarExpander from "components/common/layouts/appLayout/navBar/NavBarExpander";
import { sidebarNav, TSidebarNav } from "config/sidebarNav";
import { verifyPermisisons } from "utils/authUtils";
import DataCommonsLogo from "components/common/ui/logo/DataCommonsLogo";
import styles from "components/common/layouts/appLayout/navBar/NavBar.module.scss";
import { isExpandedSelector } from "store/selectors/layout.selectors";
import { permissionsSelector } from "store/selectors/auth.selectors";
import { PERMISSIONS } from "models/Permissions";

const variants = {
  visible: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  hidden: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 as -1 },
  },
};

interface Props {
  className?: string;
  isDesktop: boolean;
  isMobileOpened: boolean;
  toggleMobileNav: () => void;
}

const NavBar = ({
  className,
  isDesktop,
  isMobileOpened,
  toggleMobileNav,
}: Props) => {
  const isExpanded = useSelector(isExpandedSelector);
  const permissions = useSelector(permissionsSelector);

  const mobileExpand = isDesktop ? false : isMobileOpened;
  const expandToggleSidebar = () =>
    layoutService.expandSidebarToggle(!isExpanded);

  const toggleMobileSidebar = () => {
    if (!isDesktop) {
      toggleMobileNav();
    }
  };
  const items = generateNavItems({
    items: sidebarNav,
    permissions,
    isExpanded,
    isDesktop,
  });

  return (
    <motion.nav
      className={cx(
        className,
        styles.root,
        isDesktop && styles.desktop,
        (isExpanded || mobileExpand) && styles.expanded
      )}
    >
      <div className={styles.sticky}>
        <div className={styles.logoContainer}>
          <DataCommonsLogo className={styles.logo} />
          <BrandName className={styles.logoText} />
        </div>

        <motion.div
          className={styles.navItemContainer}
          variants={variants}
          initial="hidden"
          animate={
            isDesktop || isExpanded || mobileExpand ? "visible" : "hidden"
          }
          onClick={toggleMobileSidebar}
        >
          <AnimatePresence exitBeforeEnter>{items}</AnimatePresence>
        </motion.div>
        {isDesktop && (
          <NavBarExpander
            className={styles.expander}
            isExpanded={isExpanded}
            onClick={expandToggleSidebar}
          />
        )}
      </div>
    </motion.nav>
  );
};

const generateNavItems = ({
  items,
  permissions,
  isExpanded,
  isDesktop,
}: {
  items: TSidebarNav;
  permissions: PERMISSIONS[];
  isExpanded: boolean;
  isDesktop: boolean;
}) => {
  const authorizedNav = items.reduce((n, s) => {
    const items = s.items.filter((i) =>
      verifyPermisisons(permissions, i.route?.requiredRole)
    );
    if (items.length) {
      n.push({ ...s, items });
    }
    return n;
  }, [] as TSidebarNav);

  return authorizedNav.map((section, i) => (
    <React.Fragment key={`${i}:${section.sectionName}`}>
      {section.sectionName !== undefined && (
        <li
          className={cx(styles.listSection, {
            [styles.sectionHasText]: section.sectionName !== "",
          })}
        >
          <p className={styles.listSectionLabel}>{section.sectionName}</p>
        </li>
      )}
      {section.items.map((item, k) => (
        <NavItem
          key={`${k}:${item.name}`}
          text={item.name}
          displayTooltip={!isExpanded && isDesktop}
          to={item.route?.path}
          href={item.href}
          {...item}
        />
      ))}
    </React.Fragment>
  ));
};

export default NavBar;
