import qs from "query-string";
import { appGetState, appNextState, appUpdateState } from "store";
import { TRoute, TRouteParamOptions } from "constants/routes";
import { createOrg, TOrg } from "models/Org";
import { layoutService } from "services/LayoutService";
import { getCachedCurrentOrg, setCachedCurrentOrg } from "api/localStorage";
import { captureError } from "utils/errorUtils";
import { schemaService } from "services/SchemaService";
import { fetchCurrentUser } from "api/UserApi";
import { extractCurrentPermissions, verifyPermisisons } from "utils/authUtils";
import { PERMISSIONS, TRequiredPermission } from "models/Permissions";
import { setRouteParams } from "utils/routeUtils";

const comment = (m: string) => `CoreService::${m}`;

class CoreService {
  private appGetState = appGetState;
  private appNextState = appNextState;
  private appUpdateState = appUpdateState;

  /**
   */
  public getRoute = (
    url: TRoute,
    match?: TRouteParamOptions,
    params?: Record<string, any>
  ) => {
    const {
      core: { user, org },
    } = this.appGetState();
    const p = params
      ? `?${qs.stringify(params, { arrayFormat: "comma" })}`
      : "";

    return setRouteParams(
      url.path,
      { orgId: org.org_id, id: user.id },
      match
    ).concat(p);
  };

  public getUserPermissions = () =>
    extractCurrentPermissions(this.appGetState());

  public verifyUserPermissions = (
    requiredRole?: TRequiredPermission,
    permissions?: PERMISSIONS[]
  ) => {
    const p = permissions || this.getUserPermissions();
    return verifyPermisisons(p, requiredRole);
  };

  public getCurrentUser = async () => {
    try {
      layoutService.setIsLoading(true);
      const user = await fetchCurrentUser();

      this.appUpdateState(
        (s) => (s.core.user = user),
        comment("getCurrentUser")
      );
      await this.resetCurrentOrg(user.orgs);
      layoutService.setIsLoading(false);
      return true;
    } catch (e) {
      this.setAppError(e);
      layoutService.setIsLoading(false);
      return false;
    }
  };

  public setCurrentOrg = async (org: TOrg) => {
    setCachedCurrentOrg(org.org_id);
    this.appUpdateState((s) => (s.core.org = org), comment("setCurrentOrg"));
    await schemaService.resetNameDimension(org.schemas);
    return;
  };

  public setAppLoaded = (isLoaded: boolean): void => {
    this.appUpdateState(
      (s) => (s.core.isAppLoaded = isLoaded),
      comment("setAppLoaded")
    );
  };

  public setAppError = (
    e: unknown,
    defaultMessage = "Critcal Error: Unable to load Application"
  ) => {
    if (e instanceof Error) {
      captureError(e);
      layoutService.error(e, defaultMessage);
      this.appUpdateState((s) => (s.core.error = e), comment("setAppError"));
    }
  };

  private resetCurrentOrg = async (orgList: TOrg[]) => {
    const currentId = getCachedCurrentOrg();
    const current = orgList.find((o) => o.org_id === currentId) || orgList[0];
    await this.setCurrentOrg(current || createOrg({}));
  };
}

const coreService = new CoreService();
export { coreService };
