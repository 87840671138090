import React from "react";
import cx from "classnames";
import styles from "components/common/ui/table/ResponsiveTable.module.scss";

export interface IRow {
  [key: string]: any;
}

export interface IColumn {
  id: string;
  width?: number | string;
  accessor?: (row: IRow, column: IColumn) => string;
  label?: React.ReactNode;
  header?: (column: IColumn) => React.ReactNode;
  cell?: (cell: string, row: IRow, column: IColumn) => React.ReactNode;
  className?: string;
  headerClassname?: string;
  type?: "icon" | "text";
  style?: React.CSSProperties;
  loadingCell?: (column: IColumn, index: number) => React.ReactNode;
}
// Create a default prop getter
export const defaultPropGetter = () => ({});
export const defaultColPropGetter = (c: IColumn) => ({ style: c.style });

export const getColumnClasses = (c: IColumn) =>
  cx(styles.column, c.type && styles.iconCol);
