import { otherChartColor } from "utils/chartColorUtils";

export enum AGE_DEF {
  "false" = "Under 65",
  "true" = "Over 65",
  // "all" = "All",
}
export enum ETHNICITY_DEF {
  "M" = "Māori",
  "P" = "Pacifika",
  "O" = "Other",
  // "all" = "All",
}

export enum MALIGNANT_DEF {
  "malignant" = "Malignant",
  "non-malignant" = "Non-Malignant",
  "missing" = "Not Recorded",
  // "all" = "All",
}

export const ageBandBuckets = [
  { key: "Under 18", label: "Under 18" },
  { key: "18-40", label: "18-40" },
  { key: "41-64", label: "41-64" },
  { key: "65-74", label: "65-74" },
  { key: "75-84", label: "75-84" },
  { key: "85-94", label: "85-94" },
  { key: "95+", label: "95+" },
];

export const ethnicityBuckets = [
  { key: "M", label: "Māori" },
  { key: "P", label: "Pacifika" },
  { key: "O", label: "Other", color: otherChartColor() },
];

export const genderBuckets = [
  { key: "Male", label: "Male" },
  { key: "Female", label: "Female" },
  { key: "Indeterminate", label: "Indeterminate" },
  { key: "Unknown", label: "Unknown", color: otherChartColor() },
];

export const ruralityBuckets = [
  { key: "rural", label: "Rural" },
  { key: "urban", label: "Urban" },
  { key: "invalid", label: "Unknown", color: otherChartColor() },
  { key: "missing", label: "Not Recorded", color: otherChartColor(1) },
];

export const livingSituationBuckets = [
  { key: "true", label: "Residential Care" },
  { key: "false", label: "Non-residential care" },
];

export const ageByEthnicityBucktes = [
  { key: "Under 18::M", label: "Under 18", yLabel: "Māori" },
  { key: "Under 18::P", label: "Under 18", yLabel: "Pacifika" },
  { key: "Under 18::O", label: "Under 18", yLabel: "Other" },
  { key: "18-40::M", label: "18-40", yLabel: "Māori" },
  { key: "18-40::P", label: "18-40", yLabel: "Pacifika" },
  { key: "18-40::O", label: "18-40", yLabel: "Other" },
  { key: "41-64::M", label: "41-64", yLabel: "Māori" },
  { key: "41-64::P", label: "41-64", yLabel: "Pacifika" },
  { key: "41-64::O", label: "41-64", yLabel: "Other" },
  { key: "65-74::M", label: "65-74", yLabel: "Māori" },
  { key: "65-74::P", label: "65-74", yLabel: "Pacifika" },
  { key: "65-74::O", label: "65-74", yLabel: "Other" },
  { key: "75-84::M", label: "75-84", yLabel: "Māori" },
  { key: "75-84::P", label: "75-84", yLabel: "Pacifika" },
  { key: "75-84::O", label: "75-84", yLabel: "Other" },
  { key: "85-94::M", label: "85-94", yLabel: "Māori" },
  { key: "85-94::P", label: "85-94", yLabel: "Pacifika" },
  { key: "85-94::O", label: "85-94", yLabel: "Other" },
  { key: "95+::M", label: "95+", yLabel: "Māori" },
  { key: "95+::P", label: "95+", yLabel: "Pacifika" },
  { key: "95+::O", label: "95+", yLabel: "Other" },
];

export const akpsBuckets = [
  { key: "80-100", label: "80-100" },
  { key: "60-70", label: "60-70" },
  { key: "40-50", label: "40-50" },
  { key: "20-30", label: "20-30" },
  { key: "0-10", label: "0-10" },
];

export const phaseBuckets = [
  { key: "Stable", label: "Stable" },
  { key: "Unstable", label: "Unstable" },
  { key: "Deteriorating", label: "Deteriorating" },
  { key: "Terminal", label: "Terminal" },
  { key: "Bereavement", label: "Bereavement" },
  { key: "", label: "Unknown", color: otherChartColor() },
];

export const iposBuckets = [
  { key: "0-10", label: "0-10" },
  { key: "11-20", label: "11-20" },
  { key: "21-30", label: "21-30" },
  { key: "31-40", label: "31-40" },
];

export const deliverySettingsBuckets = [
  // Private Residence
  {
    key: "Private residence",
    label: "Private residence",
    group: "Private residence",
  },

  //Res aged care
  {
    key: "Residential aged care, high level care (hospital level care)",
    label: "Residential aged care, high level care (hospital level care)",
    group: "Residential aged care",
  },
  {
    key: "Residential aged care, low level care (level 2 rest home)",
    label: "Residential aged care, low level care (level 2 rest home)",
    group: "Residential aged care",
  },

  // Public Hospital
  {
    key: "Public hospital inpatient unit",
    label: "Public hospital inpatient unit",
    group: "Public hospital",
  },
  {
    key: "Public hospital outpatient clinic",
    label: "Public hospital outpatient clinic",
    group: "Public hospital",
  },

  //Hospice
  {
    key: "Day Activities programmes",
    label: "Day Activities programmes",
    group: "Hospice",
  },
  {
    key: "Hospice inpatient unit",
    label: "Hospice inpatient unit",
    group: "Hospice",
  },
  {
    key: "Hospice outpatient clinic",
    label: "Hospice outpatient clinic",
    group: "Hospice",
  },

  // Private Hospital
  {
    key: "Private hospital inpatient unit",
    label: "Private hospital inpatient unit",
    group: "Private hospital",
  },
  {
    key: "Private hospital outpatient clinic",
    label: "Private hospital outpatient clinic",
    group: "Private hospital",
  },

  //Other
  {
    key: "Correctional Facility",
    label: "Correctional Facility",
    group: "Other",
  },

  {
    key: "Not applicable (patient/client not present at service contact)",
    label: "Not applicable (patient/client not present at service contact)",
    group: "Other",
  },
  { key: "Other", label: "Other", color: otherChartColor(), group: "Other" },
];

export const referralSourceBuckets = [
  // Hospital/specialist
  {
    key: "Private hospital",
    label: "Private hospital",
    group: "Hospital/specialist",
  },
  {
    key: "Public Hospital",
    label: "Public Hospital",
    group: "Hospital/specialist",
  },
  {
    key: "Specialist private medical practitioner",
    label: "Specialist private medical practitioner",
    group: "Hospital/specialist",
  },

  // Community
  {
    key: "Community services (DHB based - District Community Nurse)",
    label: "Community services (DHB based - District Community Nurse)",
    group: "Community",
  },
  {
    key: "General Practice team",
    label: "General Practice team",
    group: "Community",
  },

  {
    key: "Maori Health Services",
    label: "Māori Health Services",
    group: "Community",
  },

  // Hospice
  {
    key: "Hospice palliative care service",
    label: "Hospice palliative care service",
    group: "Hospice",
  },

  // Self
  {
    key: "Self, carer(s), family, friends, whānau",
    label: "Self, carer(s), family, friends, whānau",
    group: "Self",
  },

  // Residential aged care
  {
    key: "Residential aged care, high level care (hospital level care)",
    label: "Residential aged care, high level care (hospital level care)",
    group: "Residential aged care",
  },
  {
    key: "Residential aged care, low level care (level 2 rest home)",
    label: "Residential aged care, low level care (level 2 rest home)",
    group: "Residential aged care",
  },

  // Other
  { key: "Other", label: "Other", group: "Other", color: otherChartColor() },
  {
    key: "missing",
    label: "Not Recorded",
    group: "Not Recorded",
    color: otherChartColor(1),
  },
];

export const principalDiagnosisBuckets = [
  // Cancer
  { key: "Cancer - Breast", label: "Cancer - Breast", group: "Cancer" },
  { key: "Cancer - CNS", label: "Cancer - CNS", group: "Cancer" },
  { key: "Cancer - Colorectal", label: "Cancer - Colorectal", group: "Cancer" },
  {
    key: "Cancer - Gynaecological",
    label: "Cancer - Gynaecological",
    group: "Cancer",
  },
  {
    key: "Cancer - Haematological",
    label: "Cancer - Haematological",
    group: "Cancer",
  },
  {
    key: "Cancer - Head and Neck",
    label: "Cancer - Head and Neck",
    group: "Cancer",
  },
  { key: "Cancer - Lung", label: "Cancer - Lung", group: "Cancer" },
  {
    key: "Cancer - Melanoma / Skin",
    label: "Cancer - Melanoma / Skin",
    group: "Cancer",
  },
  { key: "Cancer - Other GIT", label: "Cancer - Other GIT", group: "Cancer" },
  {
    key: "Cancer - Other Primary Malignancy",
    label: "Cancer - Other Primary Malignancy",
    group: "Cancer",
  },
  {
    key: "Cancer - Other Urological Malignancy",
    label: "Cancer - Other Urological Malignancy",
    group: "Cancer",
  },
  { key: "Cancer - Pancreas", label: "Cancer - Pancreas", group: "Cancer" },
  { key: "Cancer - Prostate", label: "Cancer - Prostate", group: "Cancer" },
  {
    key: "Cancer - Unknown Primary",
    label: "Cancer - Unknown Primary",
    group: "Cancer",
  },
  {
    key: "Cancer - Bone and Soft Tissue",
    label: "Cancer - Bone and Soft Tissue",
    group: "Cancer",
  },
  {
    key: "Malignant - not further defined",
    label: "Malignant - not further defined",
    group: "Cancer",
  },

  // Cardio-respiratory
  {
    key: "Cardiovascular Disease",
    label: "Cardiovascular Disease",
    group: "Cardio-respiratory",
  },
  {
    key: "Respiratory Disease",
    label: "Respiratory Disease",
    group: "Cardio-respiratory",
  },

  // Neurological
  {
    key: "Motor Neurone Disease",
    label: "Motor Neurone Disease",
    group: "Neurological",
  },
  {
    key: "Other Neurological Disease",
    label: "Other Neurological Disease",
    group: "Neurological",
  },

  { key: "Stroke", label: "Stroke", group: "Neurological" },

  // Hepto-renal
  {
    key: "End Stage Kidney Disease (Renal Disease)",
    label: "End Stage Kidney Disease (Renal Disease)",
    group: "Hepato-renal",
  },
  {
    key: "End Stage Liver Disease (Hepatic disease)",
    label: "End Stage Liver Disease (Hepatic disease)",
    group: "Hepato-renal",
  },

  // Dementia
  {
    key: "Alzheimer's Dementia",
    label: "Alzheimer's Dementia",
    group: "Dementia",
  },
  { key: "Other Dementia", label: "Other Dementia", group: "Dementia" },

  // Diabetes
  {
    key: "Diabetes and its complications",
    label: "Diabetes and its complications",
    group: "Diabetes",
  },

  // Other

  {
    key: "HIV/AIDS",
    label: "HIV/AIDS",
    group: "Other",
  },

  { key: "Multi-organ Failure", label: "Multi-organ Failure", group: "Other" },
  {
    key: "Non Malignant - not further defined",
    label: "Non Malignant - not further defined",
    group: "Other",
  },

  {
    key: "Other non-malignancy",
    label: "Other non-malignancy",
    group: "Other",
  },

  { key: "Sepsis", label: "Sepsis", group: "Other" },
  {
    key: "Unknown",
    label: "Unknown",
    group: "Other",
    color: otherChartColor(),
  },
  {
    key: "missing",
    label: "Not Recorded",
    group: "Not Recorded",
    color: otherChartColor(1),
  },
];

export const contactProviderBuckets = [
  {
    key: "Medical practitioner",
    label: "Medical practitioner",
    group: "Nurse/Medical practitioner",
  },
  { key: "Nurse", label: "Nurse", group: "Nurse" },
  {
    key: "Nurse practitioner",
    label: "Nurse practitioner",
    group: "Nurse/Medical practitioner",
  },

  {
    key: "Grief/Bereavement counsellor",
    label: "Grief/Bereavement counsellor",
    group: "Counsellor/psychologist",
  },
  {
    key: "Psychologist",
    label: "Psychologist",
    group: "Counsellor/psychologist",
  },
  {
    key: "Other counsellor",
    label: "Other counsellor",
    group: "Counsellor/psychologist",
  },

  { key: "Social worker", label: "Social worker", group: "Social worker" },

  { key: "Dietician", label: "Dietician", group: "Allied health" },
  {
    key: "Occupational therapist",
    label: "Occupational therapist",
    group: "Allied health",
  },
  { key: "Physiotherapist", label: "Physiotherapist", group: "Allied health" },

  {
    key: "Māori health worker",
    label: "Māori health worker",
    group: "Cultural or spiritual carer",
  },
  {
    key: "Spiritual carer",
    label: "Spiritual carer",
    group: "Cultural or spiritual carer",
  },

  {
    key: "Personal care assistant/nurse assistant/health care assistant",
    label: "Personal care assistant/nurse assistant/health care assistant",
    group: "Other",
  },
  { key: "Volunteer", label: "Volunteer", group: "Other" },
  { key: "Pharmacist", label: "Pharmacist", group: "Other" },
  {
    key: "Complementary therapist",
    label: "Complementary therapist",
    group: "Other",
  },
  {
    key: "Other occupation",
    label: "Other occupation",
    group: "Other",
    color: otherChartColor(),
  },
  {
    key: "invalid",
    label: "invalid",
    group: "Other",
    color: otherChartColor(),
  },
  {
    key: "missing",
    label: "Not Recorded",
    group: "Not Recorded",
    color: otherChartColor(1),
  },
];

export const contactPurposeBuckets = [
  // Clinical care
  { key: "Clinical care", label: "Clinical care", group: "Clinical care" },

  // Case management
  {
    key: "Case management and/or care coordination",
    label: "Case management and/or care coordination",
    group: "Case management",
  },
  {
    key: "Comprehensive assessment",
    label: "Comprehensive assessment",
    group: "Case management",
  },
  {
    key: "Multi-disciplinary review",
    label: "Multi-disciplinary review",
    group: "Case management",
  },

  // Grief and loss support
  {
    key: "Grief and loss support",
    label: "Grief and loss support",
    group: "Grief and loss support",
  },

  // Psycho-emotional/spiritual/social support
  {
    key: "Psycho-emotional support",
    label: "Psycho-emotional support",
    group: "Psycho-emotional/spiritual/social support",
  },
  {
    key: "Social support",
    label: "Social support",
    group: "Psycho-emotional/spiritual/social support",
  },
  {
    key: "Spiritual care or support",
    label: "Spiritual care or support",
    group: "Psycho-emotional/spiritual/social support",
  },

  // Hospice/inpatient care
  {
    key: "Day activities programmes",
    label: "Day activities programmes",
    group: "Hospice/inpatient care",
  },
  {
    key: "Inpatient admission",
    label: "Inpatient admission",
    group: "Hospice/inpatient care",
  },
  {
    key: "Inpatient discharge",
    label: "Inpatient discharge",
    group: "Hospice/inpatient care",
  },

  // Family/whānau meetings and education
  {
    key: "Education of the patient and family/whānau",
    label: "Education of the patient and family/whānau",
    group: "Family/whānau meetings and education",
  },
  {
    key: "Family/whānau meeting",
    label: "Family/whānau meeting",
    group: "Family/whānau meetings and education",
  },

  // Other
  { key: "Personal care", label: "Personal care", group: "Other" },
  { key: "Other", label: "Other", color: otherChartColor(), group: "Other" },
  {
    key: "Not stated",
    label: "Not stated",
    color: otherChartColor(),
    group: "Other",
  },
];
